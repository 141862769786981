import React, { useEffect, useState, useContext } from 'react';
import './styles.scss';
import { CheckOutlined } from '@ant-design/icons';
import { campaignObjectives } from '../../utils/constants';
import { AxiosSingleton } from '../../utils/axiosinstance';
import { campaignContext } from '../campaignhoc/campaign.context';
import { AnghamiAdsFormInput } from '../core/AnghamiAdsFormInput';
import { AnghamiAdsFormSelector } from '../core/AnghamiAdsFormSelector';
import { logAmplitudeEvent } from '../../utils/analytics';
import { CampaignAmplitudeEvents } from '../../enums/campaignamplitudeevents';
import SpinningCircle from '../spinningcircle';
import useAuth from '../../hooks/useAuth';
import { PublisherRoles } from '../../enums/publisherroles';

type Props = {
  submitCampaignSetup: () => void;
  mode: string;
  isGroupCampaignLoading: boolean;
};

export default function CampaignSetup({
  submitCampaignSetup,
  mode,
  isGroupCampaignLoading
}: Props) {
  const axios = AxiosSingleton.axiosInstance;
  const {
    groupCampaignStatus,
    groupCampaignName,
    setGroupCampaignName,
    groupCampaignObjective,
    setGroupCampaignObjective,
    advertiserId,
    setAdvertiserId,
    setAdvertiserName,
    industryId,
    setIndustryId,
    setIndustryName,
    publisherId,
    publisherName,
    setPublisherId,
    setPublisherName,
    specialPublishers,
    competitorId,
    setCompetitorId,
    agencyId,
    setAgencyId
  } = useContext(campaignContext);

  const { role, user } = useAuth();

  const canChangePubisher = mode === 'create' || groupCampaignStatus <= 0;

  const [advertisers, setAdvertisers] = useState<any>([]);
  const [agencies, setAgencies] = useState([]);
  const [industries, setIndustries] = useState<any>([]);
  const [isNameEmpty, setIsNameEmpty] = useState(false);
  const [isUserSpecialPublisher, setIsUserSpecialPublisher] = useState<boolean>(false);

  const submitCampaign = () => {
    if (!groupCampaignName) {
      setIsNameEmpty(true);
      return;
    }

    submitCampaignSetup();
  };

  const getSelectorOptions = () => {
    axios.get('/adplanner/advertisers').then((res) => {
      const { data } = res;
      setAdvertisers(data);
      if (data.length > 0 && mode === 'create') {
        setAdvertiserId(data[0].id);
        setAdvertiserName(data[0].text);
      }
    });

    axios.get('/adplanner/industries').then((res) => {
      const { data } = res;
      setIndustries(data);
    });

    axios.get('/adplanner/agencies').then((res) => {
      const { data } = res;
      setAgencies(data);
    });
  };

  useEffect(getSelectorOptions, []);

  useEffect(() => {
    logAmplitudeEvent(CampaignAmplitudeEvents.ViewCreateCampaignPage, {
      new: mode === 'create' ? 1 : 0
    });
  }, []);

  useEffect(() => {
    const _publisherName = specialPublishers.find((e) => e.id === publisherId.toString())?.name;
    if (!_publisherName) return;
    setPublisherName(_publisherName);
  }, [publisherId, specialPublishers]);

  useEffect(() => {
    const isSpecialUserIndex = specialPublishers.findIndex(
      (p) => p.id === user?.publisher_id.toString()
    );
    if (isSpecialUserIndex > -1) {
      setIsUserSpecialPublisher(true);
    }
  }, [specialPublishers]);

  return (
    <div className="campaign-container campaign-setup-container">
      <div className="campaign-container-header">
        {mode === 'create' && (
          <div className="campaign-container-header-title">Setup Your New Campaign</div>
        )}
        {mode === 'edit' && (
          <div className="campaign-container-header-title">Edit Your Campaign</div>
        )}
        {mode === 'preview' && (
          <div className="campaign-container-header-title">Preview Campaign</div>
        )}
        <button
          className={`campaign-container-header-btn ${groupCampaignName ? '' : 'disabled'}`}
          type="button"
          onClick={() => {
            if (isGroupCampaignLoading) return;
            submitCampaign();
          }}
        >
          {isGroupCampaignLoading ? <SpinningCircle /> : 'Next'}
        </button>
      </div>
      <div className={mode === 'preview' ? 'campaign-form-preview-mask' : ''}>
        <div className="campaign-setup-form-input-row">
          <AnghamiAdsFormInput
            label="Campaign Name"
            type="text"
            placeholder="Choose a name for your campaign"
            value={groupCampaignName}
            width="30rem"
            invalid={isNameEmpty}
            onChange={(e) => {
              setIsNameEmpty(false);
              setGroupCampaignName(e.target.value);
            }}
          />
          {isUserSpecialPublisher && (
            <AnghamiAdsFormSelector
              label="Publisher"
              placeholder="Select publisher"
              value={publisherName || null}
              options={specialPublishers}
              width="49%"
              onChange={(value: string) => {
                setPublisherId(value.toString());
              }}
              disabled={!canChangePubisher}
            />
          )}
        </div>
        <div className="ad-type-section">
          <div className="ad-type-section-title">CAMPAIGN OBJECTIVE *</div>
          <div className="ad-type-section-options">
            {campaignObjectives.map((obj) => (
              <div
                className={`ad-type-section-option campaign-form-input-container ${
                  obj.id === groupCampaignObjective ? 'selected-ad-type' : ''
                }`}
                key={obj.id}
                onClick={() => {
                  setGroupCampaignObjective(obj.id);
                  logAmplitudeEvent(CampaignAmplitudeEvents.SelectCampaignObjective, {
                    type: obj.title
                  });
                }}
              >
                <div className="ad-type-section-option-title">
                  <img src={obj.icon} alt="Campaign Objective" />
                  <label>{obj.title}</label>
                </div>
                <div className="ad-type-section-option-description">{obj.description}</div>
                <div
                  className={`ad-type-check ${
                    obj.id === groupCampaignObjective ? 'visible-ad-type-check' : ''
                  }`}
                >
                  <CheckOutlined />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="campaign-setup-form-input-row">
          <AnghamiAdsFormSelector
            label="Advertiser"
            placeholder="Select advertiser"
            mode="tags"
            options={advertisers}
            width="49%"
            value={advertiserId ? [advertiserId] : []}
            onChange={(value: any) => {
              const selectedAdvertiserId = value.pop();
              setAdvertiserId(selectedAdvertiserId);
              const advertiserName = advertisers.find(
                (el: any) => el.id === selectedAdvertiserId
              )?.text;
              setAdvertiserName(advertiserName);
            }}
          />
          <AnghamiAdsFormSelector
            label="Industry"
            placeholder="Select industry"
            value={industryId || undefined}
            options={industries}
            width="49%"
            onChange={(value) => {
              setIndustryId(value);
              const industryName = industries.find((el: any) => el.id === value)?.text;
              setIndustryName(industryName);
            }}
          />
        </div>
        {(role === PublisherRoles.DMSPublisher ||
          role === PublisherRoles.DMSAdmin ||
          role === PublisherRoles.DMSTrafficker ||
          role === PublisherRoles.Anghami) && (
          <div className="campaign-setup-form-input-row">
            <AnghamiAdsFormSelector
              label="Agency"
              placeholder="Select agency"
              value={agencyId || undefined}
              options={agencies}
              width="49%"
              onChange={(value) => {
                setAgencyId(value);
              }}
            />
            <AnghamiAdsFormSelector
              label="Competitors"
              placeholder="Select competitors"
              mode="multiple"
              value={competitorId || undefined}
              options={advertisers}
              width="49%"
              onChange={(value) => {
                setCompetitorId(value);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
