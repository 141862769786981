import React from 'react';
import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import StatusUnderDelivering from '../../public/assets/images/status/under_delivering.png';
import StatusPending from '../../public/assets/images/status/pending.png';
import StatusPaused from '../../public/assets/images/status/paused.png';
import StatusCompleted from '../../public/assets/images/status/completed.png';
import StatusIncomplete from '../../public/assets/images/status/incomplete.png';
import '../overview/styles.scss';

interface ICampaign {
  id: number;
  name: string;
  status: keyof typeof campaignStatus;
}

const campaignStatus = {
  undelivering: {
    color: '#D60031',
    icon: StatusUnderDelivering,
    label: 'under delivering',
    lineColor: '#D60031'
  },
  pending: {
    color: '#FFAF5C',
    icon: StatusPending,
    label: 'pending approval',
    lineColor: '#FFAF5C'
  },
  completed: {
    color: 'black',
    icon: StatusCompleted,
    label: 'completed',
    lineColor: 'transparent'
  },
  paused: {
    color: 'black',
    icon: StatusPaused,
    label: 'paused',
    lineColor: 'transparent'
  },
  incomplete: {
    color: 'black',
    icon: StatusIncomplete,
    label: 'not completed',
    lineColor: 'transparent'
  }
};

type Props = {
  campaigns: ICampaign[];
};

const OverviewCampaigns = ({ campaigns }: Props) => {
  const navigate = useNavigate();

  return (
    <div className="Overview-grid Overview-grid-borders campaigns-container">
      <Row gutter={[32, 32]} align="middle" justify="space-between" className="row-bottom-padding">
        <Col span={12} flex="1 1 200px">
          <div className="Overview-grid-header extra-row-padding">Campaigns</div>
        </Col>
        <Col span={12} flex="0 1 200px" style={{ textAlign: 'right' }}>
          <button
            type="button"
            className="Overview-grid-button extra-row-margin"
            onClick={() => {
              navigate('/manage-campaigns');
            }}
          >
            Manage
          </button>
        </Col>
      </Row>
      {campaigns.map((campaign: ICampaign) => (
        <Row
          align="middle"
          gutter={[32, 32]}
          key={campaign?.id}
          className="Overview-vertical-row-gap Overview-row-borders campaign-row"
        >
          <Col span={12} className="col-flex-start">
            <div
              className="campaigns-status-vertical-line"
              style={{
                borderColor: campaignStatus[campaign?.status]?.lineColor
              }}
            />
            <div className="Overview-campaigns-title">{campaign.name}</div>
          </Col>
          <Col span={12} className="col-flex-end">
            <div
              className="Overview-campaigns-status-label"
              style={{
                color: campaignStatus[campaign?.status]?.color
              }}
            >
              {campaignStatus[campaign?.status]?.label}
            </div>
            <img
              className="Overview-campaigns-status-img"
              src={campaignStatus[campaign?.status]?.icon}
            />
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default OverviewCampaigns;
