import React, { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTus } from 'use-tus';
import { UploadBoxStates } from '../../enums/uploadboxstates';
import UploadBox from '../uploadbox';
import './styles.scss';
import useAuth from '../../hooks/useAuth';

type UploadBoxStateType = 'preupload' | 'upload' | 'uploaded' | 'uploadfailed' | 'invalidfile';

type Props = {
  uploadSuccessHandler: (fileLocation: string) => void;
  clearUploadHandler: () => void;
  fileName: string;
  inputID: string;
};

export default function SponsoredPlaylistsUploadBox({
  uploadSuccessHandler,
  clearUploadHandler,
  fileName,
  inputID
}: Props) {
  const [uploadBoxState, setUploadBoxState] = useState<UploadBoxStateType>(
    UploadBoxStates.PreUpload
  );
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [metadataFileName, setMetadataFileName] = useState('');
  const { user } = useAuth();
  const { upload, setUpload, isSuccess } = useTus();

  useEffect(() => {
    if (fileName) {
      setUploadedFileName(fileName);
      setUploadBoxState(UploadBoxStates.Uploaded);
    }
  }, [fileName]);

  const handleSetUpload = async (file) => {
    if (!file) {
      return;
    }

    const outputFile = file;

    setUploadBoxState(UploadBoxStates.Uploading);

    const name = `${String(Math.floor(Math.random() * 9999 + 1000))}_${file.name
      .replace(/[^A-Za-z0-9\-._]/g, '')
      .toLowerCase()}`;
    const filename = `${user.publisher_id
      .toString()
      .replace(/[^A-Za-z0-9\-._]/g, '')
      .toLowerCase()}/${name}`;
    setMetadataFileName(filename);

    setUpload(file, {
      endpoint: 'https://tusk.anghami.com/files/',
      retryDelays: [0, 1000, 3000, 5000],
      metadata: {
        name,
        filename,
        filetype: file.type,
        token: 'C5E386CD1A2A2F1DD93841AD811F7',
        bucket: 'anghami.newads'
      },
      removeFingerprintOnSuccess: true
    });

    setUploadedFileName(outputFile.name);
  };

  useEffect(() => {
    if (!upload) {
      return;
    }

    upload.start();
  }, [upload]);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    setUploadBoxState(UploadBoxStates.Uploaded);
    uploadSuccessHandler(`https://anghnewads.anghcdn.co/${metadataFileName}`);
  }, [isSuccess]);

  return (
    <div className="custom-audiences-upload-box">
      <FileUploader
        handleChange={handleSetUpload}
        name={inputID}
        types={['PNG', 'JPG', 'JPEG']}
        disabled={uploadBoxState === UploadBoxStates.Uploaded}
      >
        <UploadBox
          type="image"
          uploadedFilename={uploadedFileName}
          state={uploadBoxState}
          clearUploadValue={() => {
            clearUploadHandler();
            setUploadBoxState(UploadBoxStates.PreUpload);
          }}
        />
      </FileUploader>
    </div>
  );
}
