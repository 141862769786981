import React from 'react';
import { DeliveryRotationsTypes } from '../../enums';

type Props = {
  deliveryRotaion: number;
};

const DeliveryRotationsCell = ({ deliveryRotaion }: Props) => {
  let renderedComponent = <span>{deliveryRotaion || '-'}</span>;

  switch (deliveryRotaion) {
    case DeliveryRotationsTypes.even:
      renderedComponent = <span>Even</span>;
      break;

    case DeliveryRotationsTypes.sequential:
      renderedComponent = <span>Sequential</span>;
      break;

    case DeliveryRotationsTypes.weighted:
      renderedComponent = <span>Weighted</span>;
      break;
    default:
  }

  return renderedComponent;
};

export default DeliveryRotationsCell;
