import React, { useState } from 'react';
import { colorDarkenLighten } from '../../../helpers/color-darken-lighten';
import './styles.scss';

type Value = {
  title: string;
  percentage: string;
};

type Props = {
  values: Value[];
  color: string;
};

const BarChart = ({ values = [], color }: Props) => {
  const [activeTooltipData, setActiveTooltipData] = useState(null);
  const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 });

  const calculateTooltipPosition = (e: any) => {
    let { clientX, clientY } = e;

    if (clientX + 200 >= window.innerWidth) {
      clientX -= 150;
    }

    if (clientY + 150 >= window.innerHeight) {
      clientY -= 100;
    }

    setPointerPosition({ x: clientX + 20, y: clientY + 20 });
  };

  return (
    <div className="BarChart" onMouseMove={calculateTooltipPosition}>
      {values.map((value, index) => (
        <div
          key={value.title}
          className="BarChart-bar"
          onMouseEnter={(e) => {
            calculateTooltipPosition(e);
            setActiveTooltipData(value);
          }}
          onMouseLeave={() => setActiveTooltipData(null)}
        >
          <span className="BarChart-bar-title">{value.title}</span>
          <span className="BarChart-bar-wrapper">
            <span
              className="BarChart-bar-wrapper-inner"
              style={{
                width: `${value.percentage}%`,
                backgroundColor: colorDarkenLighten(color, index, values.length)
              }}
            />
          </span>
          <span className="BarChart-bar-value">{value.percentage} %</span>
        </div>
      ))}

      {activeTooltipData ? (
        <div
          className="BarChart-tooltip"
          style={{ left: pointerPosition.x, top: pointerPosition.y }}
        >
          <div className="HorizontalChart-tooltip-title">{activeTooltipData.title}</div>
          <span>percentage: {activeTooltipData.percentage}%</span>
        </div>
      ) : null}
    </div>
  );
};

export default BarChart;
