import { Select } from 'antd';
import React from 'react';
import './styles.scss';

type SelectorOptionType = {
  id: string | number;
  text: string | number;
};

interface AnghamiAdsFormSelectorProps {
  label: string;
  placeholder?: string;
  value?: string | number | null | any[];
  showSearch?: boolean;
  mode?: 'multiple' | 'tags' | undefined;
  width?: string;
  options: SelectorOptionType[];
  disabled?: boolean;
  onChange?: (value: any) => void;
}

export const AnghamiAdsFormSelector = ({
  label,
  options,
  showSearch = true,
  width,
  ...props
}: AnghamiAdsFormSelectorProps) => (
  <div className="anghami-ads-form-selector" style={{ width }}>
    <label>{label}</label>
    <Select
      style={{ width: '100%' }}
      bordered={false}
      showSearch={showSearch}
      filterOption={(input, option: any) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...props}
    >
      {options.map((option: SelectorOptionType) => (
        <Select.Option value={option.id} key={option.id}>
          {option.text}
        </Select.Option>
      ))}
    </Select>
  </div>
);
