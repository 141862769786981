import React from 'react';
import { logRaygunCustomError } from '../../utils/analytics';
import ErrorPage from '../errorpage';
import genericImage from '../../public/assets/images/generic.png';

class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    logRaygunCustomError(error, ['error-boundary'], info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorPage
          title="Oops!"
          subTitle="Looks like something went wrong, it's not your fault We're looking to see what happened"
          img={genericImage}
          ctaTitle="Please try again"
          ctaFunc={() => window.location.reload()}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
