import React, { useEffect, useState } from 'react';
import { SponsoredPlaylistNavigation } from '../../enums/sponsoredplaylistsnavigation';
import { AxiosSingleton } from '../../utils/axiosinstance';
import DebounceSelect from '../debounceselect';

type Props = {
  mode: 'create' | 'edit';
  setSelectedPlaylist: (playlist: any) => void;
  selectedPlaylist: any;
  setNavigationPage: (navigationPage: any) => void;
  setCampaignDataFromPlaylistPage: (data: any) => void;
};

export default function SponsoredPlaylistsPlaylist({
  mode,
  setSelectedPlaylist,
  selectedPlaylist,
  setNavigationPage,
  setCampaignDataFromPlaylistPage
}: Props) {
  const axios = AxiosSingleton.axiosInstance;
  const [value, setValue] = useState();

  useEffect(() => {
    if (mode === 'edit' && selectedPlaylist) {
      setValue(selectedPlaylist.title);
    }
  }, [selectedPlaylist]);

  const getSelectedPlaylist = (playlistId) => {
    axios
      .get('/sponsored-playlists/GETplaylistinfo', {
        params: {
          playlist_id: playlistId
        }
      })
      .then((res) => {
        const playlistData = {
          title: res.data.PlaylistName,
          title_ar: res.data.PlaylistNameAr,
          id: res.data.PlaylistID,
          image: `https://angartwork.anghcdn.co/?id=${res.data.coverartid}&size=252`,
          cover_location: res.data.cover_location,
          cover_ar_location: res.data.cover_ar_location,
          cover_fr_location: res.data.cover_fr_location,
          user: res.data.User,
          userid: res.data.UserID,
          artists: res.data.artists.map((artist) => ({
            id: artist.artistid,
            text: artist.Name
          })),
          artist_ids: res.data.artists.map((artist) => artist.artistid)
        };
        setSelectedPlaylist(playlistData);

        const d = new Date();

        const campaignData = {
          artists: playlistData.artists,
          campaignName: `PLAYLIST PROMOTION: ${playlistData.title}[${
            playlistData.id
          }] - ${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`,
          campaignTitle: playlistData.title,
          campaignTitleAr: playlistData.title_ar
        };

        setCampaignDataFromPlaylistPage(campaignData);
      });
  };

  const fetchPlaylists = (query) =>
    axios
      .get('/sponsored-playlists/search', {
        params: {
          q: query,
          search_type: 'playlists'
        }
      })
      .then((res) => {
        const { data } = res;
        let results;
        if (query.toString().indexOf('anghami.com/playlist/') !== -1 || Number(query)) {
          let id = query.split('/');
          id = id[id.length - 1];
          // eslint-disable-next-line prefer-destructuring
          id = id.split('?')[0];
          id = Number(id);
          results = data.unshift({
            _value: `Load from [${query}]`,
            _attributes: {
              type: 'playlist',
              id
            }
          });
        }

        results = data.reduce((result, item) => {
          // eslint-disable-next-line no-underscore-dangle
          if (item._attributes.type === 'playlist') {
            // eslint-disable-next-line no-underscore-dangle
            result.push({ id: item._attributes.id, text: item._value });
          }
          return result;
        }, []);

        return results.map((result) => ({
          label: result.text,
          value: result.id
        }));
      });

  return (
    <div className="campaign-container">
      <div className="campaign-container-header">
        {mode === 'create' && (
          <div className="campaign-container-header-title">Setup Your New Campaign</div>
        )}
        {mode === 'edit' && (
          <div className="campaign-container-header-title">Edit Your Campaign</div>
        )}
        <button
          className={`campaign-container-header-btn ${!selectedPlaylist ? 'disabled' : ''}`}
          type="button"
          onClick={() => {
            if (!selectedPlaylist) {
              return;
            }
            setNavigationPage(SponsoredPlaylistNavigation.Audience);
          }}
        >
          Next
        </button>
      </div>
      <div className="campaign-section-title">Playlist Selection</div>
      <div className="campaign-form-input-container mb-1rem">
        <label>Playlist</label>
        <DebounceSelect
          showSearch
          value={value}
          placeholder="Select playlists"
          fetchOptions={fetchPlaylists}
          onChange={(newValue) => {
            setValue(newValue);
            getSelectedPlaylist(newValue);
          }}
          style={{
            width: '100%'
          }}
        />
      </div>
      {selectedPlaylist && (
        <div className="playlist-info-container">
          <div className="playlist-img-container">
            <img src={selectedPlaylist.image} />
          </div>
          <div>
            <a
              target="_blank"
              href={`https://www.anghami.com/playlist/${selectedPlaylist.id}`}
              rel="noreferrer"
            >
              <h4>{selectedPlaylist.title}</h4>
            </a>
            {selectedPlaylist.user && (
              <>
                <h6>
                  by{' '}
                  <a
                    href={`https://www.anghami.com/profile/${selectedPlaylist.userid}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {selectedPlaylist.user}
                  </a>
                </h6>
                <strong>[{selectedPlaylist.id}]</strong>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
