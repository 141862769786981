import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AxiosSingleton } from '../../utils/axiosinstance';
import AdsTable from '../adstable';
import { budgetListTableColumn } from './tableColumns';
import './styles.scss';
import { statusOptions } from '../budget/selectorOptions';

export default function BudgetsList() {
  const axios = AxiosSingleton.axiosInstance;
  const navigate = useNavigate();
  const [tableMeta, setTableMeta] = useState<any>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [isInfiniteLoading, setIsInfiniteLoading] = useState(false);
  const [selectedTableKeys, setSelectedTableKeys] = useState<any[]>([]);
  const [selectedEditStatusValue, setSelectedEditStatusValue] = useState('');
  const [isIncompleteBudgetSelected, setIsIncompleteBudgetSelected] = useState(false);
  const [serverHasNoBudgetsData, setServerHasNoBudgetsData] = useState(false);

  useEffect(() => {
    axios
      .get('/budget/all', {
        params: {
          page
        }
      })
      .then(({ data }) => {
        setTableMeta(data.meta);
        setTableData(
          [...tableData, ...data.data].map((el) => ({
            ...el,
            key: el.id
          }))
        );
        setIsInfiniteLoading(false);
      })
      .catch(() => {
        setServerHasNoBudgetsData(true);
      });
  }, [page]);

  return (
    <div className="reports-list-container">
      <div className="reports-list-header">
        <div className="reports-list-header-actions">
          <div className="reports-list-header-heading">Budgets</div>
          <button
            type="button"
            className="reports-list-header-button"
            onClick={() => {
              window.open('/budget/create');
            }}
          >
            <PlusOutlined />
            NEW BUDGET
          </button>
        </div>
      </div>
      <div
        className={`budgets-list-filter-container ${
          selectedTableKeys.length === 0 || isIncompleteBudgetSelected ? 'disabled' : ''
        }`}
      >
        <div className="budgets-list-filter-title">Edit Status</div>
        <div className="budgets-list-status-filter">
          <div>
            <span style={{ fontWeight: 'bold' }}>Status</span> <span>is</span>
          </div>
          <div>
            <Select
              style={{
                marginLeft: '0.5rem',
                paddingTop: '0.2rem',
                width: '100%'
              }}
              bordered={false}
              placeholder="Select status"
              onChange={(value: any) => {
                setSelectedEditStatusValue(value);
              }}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {statusOptions
                .filter((option) => option.id !== -1)
                .map((option: any) => (
                  <Select.Option value={option?.id} key={option?.text}>
                    {option?.text}
                  </Select.Option>
                ))}
            </Select>
          </div>
        </div>
        <button
          type="button"
          onClick={() => {
            axios
              .post('/budget/change-status', {
                ids: selectedTableKeys,
                status: selectedEditStatusValue
              })
              .then(() => {
                navigate(0);
              });
          }}
        >
          Apply
        </button>
      </div>
      {tableData ? (
        <AdsTable
          data={tableData}
          tableColumns={budgetListTableColumn}
          onFetch={() => {
            if (tableData.length >= tableMeta.total) return;
            setIsInfiniteLoading(true);
            setPage(page + 1);
          }}
          isLoading={tableData.length === 0 && !serverHasNoBudgetsData}
          isInfiniteLoading={isInfiniteLoading}
          rowSelection
          selectedIDs={selectedTableKeys}
          onChange={(selectedKeys, selectedRows) => {
            setSelectedTableKeys(selectedKeys);
            setIsIncompleteBudgetSelected(
              selectedRows.filter((el: any) => el.status === -1).length > 0
            );
          }}
        />
      ) : null}
    </div>
  );
}
