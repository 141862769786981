import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Input, Checkbox, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FilterTypes, IFilter } from '../managecampaigns/filtersSchema';
import FilterDate from './filterdate';
import { FiltersSelectTypes, OprationsStrings } from '../../enums';
import arrowImg from '../../public/assets/images/arrow.png';
import closeImg from '../../public/assets/images/ClearField.png';
import './styles.scss';
import { campaignContext } from '../campaignhoc/campaign.context';
import useAuth from '../../hooks/useAuth';

type Props = {
  onValueChange: (IFilter: IFilter) => void;
  onFilterRemoved: (filterId: string) => any;
  filter: IFilter;
};

const convertCSVToArray = (multiNumberValue: string | undefined) =>
  multiNumberValue
    ?.split(',')
    .map((s) => s.trim())
    .filter((s) => s !== '') || [];

const FilterItem = ({ filter, onFilterRemoved = () => null, onValueChange }: Props) => {
  const { specialPublishers } = useContext(campaignContext);
  const { user } = useAuth();

  const [showDropdown, setShowDropdown] = useState(!filter.initiallyHideDropdown);
  const [selectValue, setSelectValue] = useState<FiltersSelectTypes>(filter.defaultSelect);
  const [multiselectValue, setMultiselectValue] = useState<any[]>(
    filter.default ? [filter.default] : []
  );
  const [multiNumberValue, setMultiNumberValue] = useState<string | undefined>(
    typeof filter.default !== 'object' ? filter.default : undefined
  );
  const [singleValue, setSingleValue] = useState<string>(
    typeof filter.default !== 'object' ? filter.default : ''
  );
  const [filterSearchQuery, setFilterSearchQuery] = useState('');

  const handleOnApply = () => {
    // force closing the dropdown
    setShowDropdown(false);

    // eslint-disable-next-line default-case
    switch (filter.type) {
      case FilterTypes.multiselect:
        if (multiselectValue.length > 0) {
          onValueChange({
            ...filter,
            value: { value: multiselectValue.map((item) => item.id), operator: selectValue }
          });
        } else {
          // to not send the empty filter object
          onFilterRemoved(filter.id);
        }
        break;

      case FilterTypes.bool:
        onValueChange({ ...filter, value: { value: 'true', operator: filter.defaultSelect } });
        break;

      case FilterTypes.number:
        onValueChange({ ...filter, value: { value: singleValue, operator: selectValue } });
        break;

      case FilterTypes.numberInArray:
        onValueChange({ ...filter, value: { value: [singleValue], operator: selectValue } });
        break;

      case FilterTypes.multiNumbers:
        onValueChange({
          ...filter,
          value: { value: convertCSVToArray(multiNumberValue), operator: selectValue }
        });
        break;

      case FilterTypes.date:
        if (selectValue === FiltersSelectTypes.between) {
          onValueChange({
            ...filter,
            value: {
              value: '',
              min: multiselectValue[0],
              max: multiselectValue[1],
              operator: selectValue
            }
          });
        } else {
          onValueChange({ ...filter, value: { value: singleValue, operator: selectValue } });
        }
        break;
    }
  };

  useEffect(() => {
    if (filter.type === FilterTypes.multiNumbers && multiNumberValue === '') {
      handleOnApply();
    }
  }, [multiNumberValue]);

  useEffect(() => {
    // only for bool types because they don't have apply button
    if (filter.type === FilterTypes.bool) {
      handleOnApply();
    }
  }, []);

  useEffect(() => {
    const isUserSpecialPublisher = specialPublishers.find(
      (p) => p.id === user?.publisher_id.toString()
    );
    if (!isUserSpecialPublisher) return;

    if (filter.id === 'publisher') {
      setMultiselectValue(specialPublishers);
      onValueChange({
        ...filter,
        value: { value: specialPublishers.map((item) => Number(item.id)), operator: selectValue }
      });
    }
  }, []);

  let renderedMenu: ReactElement | null = null;

  // eslint-disable-next-line default-case
  switch (filter.type) {
    case FilterTypes.multiselect:
      renderedMenu = (
        <div className="FilterItem-filter-menu">
          <div className="FilterItem-filter-menu-title">{filter.title}</div>
          {filter.hideSelect ? null : (
            <Select
              className="FilterItem-filter-menu-input"
              defaultValue={selectValue}
              onChange={(e) => setSelectValue(e)}
            >
              {filter.select.map((sel: string) => (
                <Select.Option value={sel} key={sel}>
                  {OprationsStrings[sel]}
                </Select.Option>
              ))}
            </Select>
          )}

          {filter.hideSearch ? null : (
            <Input
              autoFocus
              className="FilterItem-filter-menu-input"
              prefix={<SearchOutlined />}
              placeholder="Search..."
              value={filterSearchQuery}
              onChange={(e) => setFilterSearchQuery(e.target.value)}
            />
          )}
          <div className="FilterItem-filter-menu-overflow">
            {filter.options
              ? filter.options
                  .filter(
                    (item) =>
                      item.children ||
                      !filterSearchQuery ||
                      item.text.toLowerCase().search(filterSearchQuery.toLowerCase()) > -1
                  )
                  .map((item, index) =>
                    item.children ? (
                      <div key={item.id + index.toString()}>
                        <div key={item.text} className="FilterItem-filter-menu-item-title">
                          {item.text}
                        </div>
                        {
                          // eslint-disable-next-line @typescript-eslint/no-shadow
                          item.children
                            .filter(
                              (fitem) =>
                                !filterSearchQuery ||
                                fitem.text.toLowerCase().search(filterSearchQuery.toLowerCase()) >
                                  -1
                            )
                            .map((child) => (
                              <div
                                key={child.id}
                                className="FilterItem-filter-menu-item"
                                onClick={() => {
                                  // @ts-ignore
                                  if (
                                    multiselectValue?.find(
                                      (inItem) => child.id !== undefined && inItem.id.toString() === child.id.toString()
                                    )
                                  ) {
                                    setMultiselectValue(
                                      multiselectValue.filter(
                                        (inItem) => child.id !== undefined && inItem.id !== child.id.toString()
                                      )
                                    );
                                  } else {
                                    setMultiselectValue([...multiselectValue, child]);
                                  }
                                }}
                              >
                                <Checkbox
                                  className="FilterItem-filter-menu-item-checkbox"
                                  checked={multiselectValue.find(
                                    (inItem) => child.id !== undefined && inItem.id.toString() === child.id.toString()
                                  )}
                                />
                                {child.text}
                              </div>
                            ))
                        }
                      </div>
                    ) : (
                      <div
                        key={item.id}
                        className="FilterItem-filter-menu-item"
                        onClick={() => {
                          if (
                            multiselectValue?.find(
                              (inItem) => item.id !== undefined && inItem.id.toString() === item.id.toString()
                            )
                          ) {
                            setMultiselectValue(
                              multiselectValue.filter(
                                (inItem) => item.id !== undefined && inItem.id.toString() !== item.id.toString()
                              )
                            );
                          } else {
                            setMultiselectValue([...multiselectValue, item]);
                          }
                        }}
                      >
                        <Checkbox
                          className="FilterItem-filter-menu-item-checkbox"
                          checked={multiselectValue.find(
                            (inItem) => item.id !== undefined && inItem.id.toString() === item.id.toString()
                          )}
                        />
                        {item.text}
                      </div>
                    )
                  )
              : null}
          </div>
          <div className="FilterItem-filter-menu-bottom">
            <span>{multiselectValue.length} Selected</span>
            <button
              type="button"
              className="FilterItem-filter-menu-bottom-button"
              onClick={handleOnApply}
            >
              Apply
            </button>
          </div>
        </div>
      );
      break;

    case FilterTypes.bool:
      renderedMenu = <div />;
      break;

    case FilterTypes.multiNumbers:
      renderedMenu = (
        <div className="FilterItem-filter-menu">
          <div className="FilterItem-filter-menu-title">{filter.title}</div>
          {filter.hideSearch ? null : (
            <Input
              autoFocus
              className="FilterItem-filter-menu-input"
              prefix={<SearchOutlined />}
              placeholder="Search..."
            />
          )}

          {filter.hideSelect ? null : (
            <Select
              className="FilterItem-filter-menu-input"
              defaultValue={selectValue}
              onChange={(e) => setSelectValue(e)}
            >
              {filter.select.map((sel: string) => (
                <Select.Option value={sel} key={sel}>
                  {OprationsStrings[sel]}
                </Select.Option>
              ))}
            </Select>
          )}

          <Input
            autoFocus
            className="FilterItem-filter-menu-input"
            type="string"
            placeholder="value"
            value={multiNumberValue}
            onChange={(e) => setMultiNumberValue(e.target.value)}
          />

          <div className="FilterItem-filter-menu-bottom">
            <button
              type="button"
              className="FilterItem-filter-menu-bottom-button"
              style={{
                marginLeft: '1.5rem',
                width: '40%'
              }}
              onClick={handleOnApply}
            >
              Submit
            </button>
            <button
              type="button"
              className="FilterItem-filter-menu-bottom-button"
              style={{
                backgroundColor: 'black',
                width: '40%',
                marginRight: '1.2rem'
              }}
              onClick={() => {
                setMultiNumberValue('');
              }}
            >
              Clear
            </button>
          </div>
        </div>
      );
      break;

    case FilterTypes.numberInArray:
    case FilterTypes.number:
      renderedMenu = (
        <div className="FilterItem-filter-menu">
          <div className="FilterItem-filter-menu-title">{filter.title}</div>
          {filter.hideSearch ? null : (
            <Input
              autoFocus
              className="FilterItem-filter-menu-input"
              prefix={<SearchOutlined />}
              placeholder="Search..."
            />
          )}

          {filter.hideSelect ? null : (
            <Select
              className="FilterItem-filter-menu-input"
              defaultValue={selectValue}
              onChange={(e) => setSelectValue(e)}
            >
              {filter.select.map((sel: string) => (
                <Select.Option value={sel} key={sel}>
                  {OprationsStrings[sel]}
                </Select.Option>
              ))}
            </Select>
          )}

          <Input
            autoFocus
            className="FilterItem-filter-menu-input"
            type="number"
            placeholder="value"
            value={singleValue}
            onChange={(e) => setSingleValue(e.target.value)}
          />

          <div className="FilterItem-filter-menu-bottom">
            <span />
            <button
              type="button"
              className="FilterItem-filter-menu-bottom-button"
              onClick={handleOnApply}
            >
              Apply
            </button>
          </div>
        </div>
      );
      break;

    case FilterTypes.date:
      renderedMenu = (
        <div className="FilterItem-filter-menu">
          <div className="FilterItem-filter-menu-title">{filter.title}</div>
          {filter.hideSearch ? null : (
            <Input
              className="FilterItem-filter-menu-input"
              prefix={<SearchOutlined />}
              placeholder="Search..."
            />
          )}

          {filter.hideSelect ? null : (
            <Select
              className="FilterItem-filter-menu-input"
              defaultValue={selectValue}
              onChange={(e) => {
                setSelectValue(e);
                if (e === FiltersSelectTypes.between) {
                  setSingleValue('');
                } else {
                  setMultiselectValue([]);
                }
              }}
            >
              {filter.select.map((sel: string) => (
                <Select.Option value={sel} key={sel}>
                  {OprationsStrings[sel]}
                </Select.Option>
              ))}
            </Select>
          )}

          <FilterDate
            type={selectValue}
            onChange={(e) => {
              if (Array.isArray(e)) {
                setMultiselectValue(e);
              } else {
                setSingleValue(e);
              }
            }}
          />

          <div className="FilterItem-filter-menu-bottom">
            <span />
            <button
              type="button"
              className="FilterItem-filter-menu-bottom-button"
              onClick={handleOnApply}
            >
              Apply
            </button>
          </div>
        </div>
      );
      break;
  }

  const getFilterCascaderValue = () => {
    if (filter.type === FilterTypes.multiselect) {
      return multiselectValue.map((val, index) => {
        const addComma = index + 1 !== multiselectValue.length;
        return (val.text || val) + (addComma ? ', ' : '');
      });
    }

    if (filter.type === FilterTypes.multiNumbers) {
      return multiNumberValue;
    }

    return singleValue;
  };

  return (
    <div className="FilterItem" style={{ width: `${filter.width}rem` || 'auto' }}>
      <div className={`FilterItem-filter ${filter.type}`}>
        <span className="FilterItem-filter-title">{filter.title}</span>
        {filter.hideSelect || filter.type === FilterTypes.bool ? null : (
          <>
            <span className="FilterItem-filter-text">{OprationsStrings[selectValue]}</span>
            <Select
              bordered={false}
              className="FilterItem-filter-cascader"
              mode="multiple"
              placeholder="Select Item..."
              maxTagCount="responsive"
              notFoundContent=""
              value={getFilterCascaderValue()}
              removeIcon={null}
            />
          </>
        )}
        {filter.type !== FilterTypes.bool ? (
          <span className="FilterItem-filter-arrow" onClick={() => setShowDropdown(true)}>
            <img src={arrowImg} alt="arrow" />
          </span>
        ) : null}
        <span className="FilterItem-filter-x" onClick={() => onFilterRemoved(filter.id)}>
          <img src={closeImg} />
        </span>
      </div>
      {showDropdown ? (
        <>
          <div className="FilterItem-dropdown2">{renderedMenu}</div>
          <span
            className="FilterItem-dropdown2-catchclick"
            onClick={() => setShowDropdown(false)}
          />
        </>
      ) : null}
    </div>
  );
};

export default FilterItem;
