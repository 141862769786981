import React from 'react';
import { FilterStatusTypes, InvoicesStatusTypes, CampaignStatusTypes } from '../../enums';
import pausedImg from '../../public/assets/images/paused.png';
import completedImg from '../../public/assets/images/delivered.png';
import draftImg from '../../public/assets/images/Not completed, draft.png';
import errorImg from '../../public/assets/images/error2.png';
import approveImg from '../../public/assets/images/Approve.svg';

import './styles.scss';

type ManageProps = {
  status: CampaignStatusTypes | FilterStatusTypes;
  isCampaign?: boolean;
};

export const ManageStatusCell = ({ status, isCampaign = false }: ManageProps) => {
  let renderedComponent = <span>{status}</span>;

  // In case this cell is in Campaigns Tab
  if (isCampaign) {
    switch (status) {
      case CampaignStatusTypes.delivering:
        renderedComponent = (
          <span className="StatusCell-status running">
            Delivering
            <img src={approveImg} />
          </span>
        );
        break;
      case CampaignStatusTypes.notDelivering:
        renderedComponent = (
          <span className="StatusCell-status rejected">
            Not Delivering
            <img src={errorImg} />
          </span>
        );
        break;

      default:
        renderedComponent = <span className="StatusCell-status">-</span>;
        break;
    }
    return renderedComponent;
  }

  // Otherwise, it's Adsets Tab:
  switch (status) {
    case FilterStatusTypes.rejected:
      renderedComponent = (
        <span className="StatusCell-status rejected">
          Rejected
          <img src={errorImg} />
        </span>
      );
      break;
    case FilterStatusTypes.incomplete:
      renderedComponent = (
        <span className="StatusCell-status completed">
          Incomplete
          <img src={draftImg} />
        </span>
      );
      break;

    case FilterStatusTypes.completed:
      renderedComponent = (
        <span className="StatusCell-status completed">
          Completed
          <img src={completedImg} />
        </span>
      );
      break;

    case FilterStatusTypes.draft:
      renderedComponent = (
        <span className="StatusCell-status draft">
          Draft
          <img src={draftImg} />
        </span>
      );
      break;

    case FilterStatusTypes.paused:
      renderedComponent = (
        <span className="StatusCell-status paused">
          Paused
          <img src={pausedImg} />
        </span>
      );
      break;

    case FilterStatusTypes.running:
      renderedComponent = (
        <span className="StatusCell-status running">
          Running
          <img src={approveImg} />
        </span>
      );
      break;

    case FilterStatusTypes.pending:
      renderedComponent = (
        <span className="StatusCell-status pending">
          {' '}
          Pending review
          <img src={pausedImg} />
        </span>
      );
      break;

    case FilterStatusTypes.scheduled:
      renderedComponent = <span className="StatusCell-status paused">Scheduled</span>;
      break;

    default:
      renderedComponent = <span className="StatusCell-status">-</span>;
  }

  return renderedComponent;
};

type InvoiceProps = {
  status: InvoicesStatusTypes;
};

export const InvoiceStatusCell = ({ status }: InvoiceProps) => {
  let renderedComponent = <span>{status}</span>;

  switch (status) {
    case InvoicesStatusTypes.charged_successfully:
      renderedComponent = (
        <span className="StatusCell-status running">
          Charged Successfully
          <img src={approveImg} />
        </span>
      );
      break;

    case InvoicesStatusTypes.invoiced_manually:
      renderedComponent = (
        <span className="StatusCell-status running">
          Invoiced Manually
          <img src={approveImg} />
        </span>
      );
      break;

    case InvoicesStatusTypes.refunded:
      renderedComponent = <span className="StatusCell-status paused">Refunded</span>;
      break;

    case InvoicesStatusTypes.error_charging:
      renderedComponent = (
        <span className="StatusCell-status error">
          Error Charging
          <img src={errorImg} />
        </span>
      );
      break;

    default:
      renderedComponent = <span className="StatusCell-status">-</span>;
  }

  return renderedComponent;
};
