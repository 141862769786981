import React from 'react';
import './styles.scss';

const SpinningLoader = () => (
  <div className="SpinningLoader">
    <div className="SpinningLoader-inner" />
  </div>
);

export default SpinningLoader;
