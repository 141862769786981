import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { AmplitudeEvents } from '@anghami/neoanalytics/dist/enums/events';
import { AxiosSingleton } from '../../utils/axiosinstance';
import environment from '../env/env';
import { CreativesStatusTypes, FilterStatusTypes, Tabs } from '../../enums';
import { Adstablebtn } from './adstablebtn';
import { DataType } from '../managecampaigns/tabledatatype';
import pauseImg from '../../public/assets/images/Pause.png';
import resumeImg from '../../public/assets/images/Resume.png';
import pauseToastImg from '../../public/assets/images/paused.png';
import duplicateImg from '../../public/assets/images/Duplicate.png';
import publishImg from '../../public/assets/images/Publish.png';
import { message } from '../../utils/custommessage';
import { Messages } from '../../enums/messages';
import './styles.scss';
import { logAmplitudeEvent } from '../../utils/analytics';
import WarningModal from '../warningmodal';
import useAuth from '../../hooks/useAuth';
import { PublisherRoles } from '../../enums/publisherroles';

type Props = {
  selectedRowsIds: number[];
  tabType: Tabs;
  data: DataType[];
  onReload: () => void;
  shouldBlockEditing: boolean;
};

type ActionResponseType = {
  message: string;
  error: string;
};

export enum ButtonsStatus {
  disabled = 'disabled',
  active = 'active',
  loading = 'loading',
  error = 'error',
  hidden = 'hidden'
}

enum ActionButtonTypes {
  Resume = 'resume',
  Pause = 'pause',
  Publish = 'publish',
  Duplicate = 'duplicate',
  Activate = 'activate',
  Deactivate = 'deactivate'
}

const AdsTableButtons = ({
  selectedRowsIds = [],
  tabType,
  data,
  onReload,
  shouldBlockEditing
}: Props) => {
  const { role } = useAuth();

  const buttonsDefaultState = {
    resume: tabType === Tabs.Creatives ? ButtonsStatus.hidden : ButtonsStatus.disabled,
    pause: tabType === Tabs.Creatives ? ButtonsStatus.hidden : ButtonsStatus.disabled,
    publish: tabType === Tabs.Creatives ? ButtonsStatus.hidden : ButtonsStatus.disabled,
    run:
      tabType === Tabs.Creatives || role !== PublisherRoles.Anghami
        ? ButtonsStatus.hidden
        : ButtonsStatus.disabled,
    activate: tabType !== Tabs.Creatives ? ButtonsStatus.hidden : ButtonsStatus.disabled,
    deactivate: tabType !== Tabs.Creatives ? ButtonsStatus.hidden : ButtonsStatus.disabled,
    duplicate: ButtonsStatus.disabled
  };

  const [buttonsStatus, setButtonsStatus] = useState<{ [x: string]: ButtonsStatus }>(
    buttonsDefaultState
  );

  const [showPauseWarningModal, setShowPauseWarningModal] = useState(false);

  useEffect(() => {
    if (shouldBlockEditing) {
      setButtonsStatus(buttonsDefaultState);
      return;
    }

    if (selectedRowsIds.length > 0) {
      const selectedRows = selectedRowsIds.map((id) => data.find((row) => row.id === id));
      setButtonsStatus({
        ...buttonsStatus,
        resume:
          tabType === Tabs.Creatives
            ? ButtonsStatus.hidden
            : selectedRows.every((row) => row?.status === FilterStatusTypes.paused)
            ? ButtonsStatus.active
            : ButtonsStatus.disabled,
        pause:
          tabType === Tabs.Creatives
            ? ButtonsStatus.hidden
            : selectedRows.every((row) => row?.status === FilterStatusTypes.running)
            ? ButtonsStatus.active
            : ButtonsStatus.disabled,
        publish:
          tabType === Tabs.Creatives
            ? ButtonsStatus.hidden
            : selectedRows.every((row) => row?.status === FilterStatusTypes.draft)
            ? ButtonsStatus.active
            : ButtonsStatus.disabled,
        run:
          tabType === Tabs.Creatives
            ? ButtonsStatus.hidden
            : selectedRows.every((row) => row?.status === FilterStatusTypes.pending)
            ? ButtonsStatus.active
            : ButtonsStatus.disabled,
        activate:
          tabType !== Tabs.Creatives
            ? ButtonsStatus.hidden
            : selectedRows.every((row) => row?.active === CreativesStatusTypes.notactive)
            ? ButtonsStatus.active
            : ButtonsStatus.disabled,
        deactivate:
          tabType !== Tabs.Creatives
            ? ButtonsStatus.hidden
            : selectedRows.every((row) => row?.active === CreativesStatusTypes.active)
            ? ButtonsStatus.active
            : ButtonsStatus.disabled,
        duplicate: ButtonsStatus.active
      });
    } else {
      // if no item is selected
      setButtonsStatus(buttonsDefaultState);
    }
  }, [selectedRowsIds, tabType]);

  let endpoint = '';
  switch (tabType) {
    case Tabs.Campaigns:
      endpoint = '/group-campaigns/';
      break;
    case Tabs.AdsSets:
      endpoint = '/campaigns/';
      break;
    case Tabs.Creatives:
      endpoint = '/ads/';
      break;
    default:
  }

  const handleOnDuplicate = () => {
    setButtonsStatus({
      ...buttonsStatus,
      duplicate: ButtonsStatus.loading
    });

    AxiosSingleton.axiosInstance
      .post<ActionResponseType>(`${environment.getBaseAPIUrl() + endpoint}duplicate`, {
        ids: selectedRowsIds
      })
      .then((response) => {
        if (response.data.message === 'success') {
          onReload();
          setButtonsStatus({
            ...buttonsStatus,
            duplicate: ButtonsStatus.active
          });
          logAmplitudeEvent(AmplitudeEvents.campaignAction, {
            type: 'duplicate',
            objectid: selectedRowsIds,
            select_all: selectedRowsIds.length === data.length
          });
          message.success(Messages.DuplicatedSuccessfully);
        } else {
          setButtonsStatus({
            ...buttonsStatus,
            duplicate: ButtonsStatus.error
          });
          message.error(response.data.error || Messages.FailedDuplicated);
        }
      })
      .catch((error) => {
        setButtonsStatus({
          ...buttonsStatus,
          duplicate: ButtonsStatus.error
        });
        message.error(error.response?.data?.message || error.message || Messages.FailedDuplicated);
      });
  };

  const handleChangeStatus = (
    status: FilterStatusTypes | CreativesStatusTypes,
    buttonName: string
  ) => {
    setButtonsStatus({
      ...buttonsStatus,
      [buttonName]: ButtonsStatus.loading
    });

    AxiosSingleton.axiosInstance
      .post<ActionResponseType>(`${environment.getBaseAPIUrl() + endpoint}change-status`, {
        ids: selectedRowsIds,
        status
      })
      .then((response) => {
        if (response.data.message === 'success') {
          onReload();
          setButtonsStatus({
            ...buttonsStatus,
            [buttonName]: ButtonsStatus.active
          });
          logAmplitudeEvent(AmplitudeEvents.campaignAction, {
            type: buttonName,
            objectid: selectedRowsIds,
            select_all: selectedRowsIds.length === data.length
          });

          switch (buttonName) {
            case ActionButtonTypes.Resume:
              message.success(Messages.ResumedSuccessfully);
              break;
            case ActionButtonTypes.Pause:
              message.success({
                content: Messages.PausedSuccessfully,
                icon: <img src={pauseToastImg} />
              });
              break;
            case ActionButtonTypes.Activate:
              message.success(Messages.ActivatedSuccessfully);
              break;
            case ActionButtonTypes.Deactivate:
              message.success({
                content: Messages.PausedSuccessfully,
                icon: <img src={pauseToastImg} />
              });
              break;
            case ActionButtonTypes.Publish:
              message.success(Messages.PublishedSuccessfully);
              break;
            default:
          }
        } else {
          setButtonsStatus({
            ...buttonsStatus,
            [buttonName]: ButtonsStatus.error
          });

          switch (buttonName) {
            case ActionButtonTypes.Resume:
              message.error(response.data.error || Messages.FailedResumed);
              break;
            case ActionButtonTypes.Pause:
              message.error(response.data.error || Messages.FailedPaused);
              break;
            case ActionButtonTypes.Activate:
              message.error(response.data.error || Messages.FailedActivated);
              break;
            case ActionButtonTypes.Deactivate:
              message.error(response.data.error || Messages.FailedDeactivated);
              break;
            case ActionButtonTypes.Publish:
              message.error(response.data.error || Messages.FailedPublished);
              break;
            default:
          }
        }
      })
      .catch((error) => {
        setButtonsStatus({
          ...buttonsStatus,
          [buttonName]: ButtonsStatus.error
        });
        message.error(error.response?.data?.message || error.message);
      });
  };

  return (
    <>
      <div className="AdsTableButtons">
        <Adstablebtn
          title="Resume"
          status={buttonsStatus.resume}
          onClick={() => handleChangeStatus(FilterStatusTypes.running, 'resume')}
          defaultIcon={<img src={resumeImg} />}
        />
        <Adstablebtn
          title="Pause"
          status={buttonsStatus.pause}
          onClick={() => setShowPauseWarningModal(true)}
          defaultIcon={<img src={pauseImg} />}
        />
        <Adstablebtn
          title="Activate"
          status={buttonsStatus.activate}
          onClick={() => handleChangeStatus(CreativesStatusTypes.active, 'activate')}
          defaultIcon={<img src={resumeImg} />}
        />
        <Adstablebtn
          title="Deactivate"
          status={buttonsStatus.deactivate}
          onClick={() => handleChangeStatus(CreativesStatusTypes.notactive, 'deactivate')}
          defaultIcon={<img src={pauseImg} />}
        />
        <Adstablebtn
          title="Publish"
          status={buttonsStatus.publish}
          onClick={() => handleChangeStatus(FilterStatusTypes.pending, 'publish')}
          defaultIcon={<img src={publishImg} />}
        />
        <Adstablebtn
          title="Run"
          status={buttonsStatus.run}
          onClick={() => handleChangeStatus(FilterStatusTypes.running, 'run')}
          defaultIcon={<img src={publishImg} />}
        />
        <Adstablebtn
          title="Duplicate"
          status={buttonsStatus.duplicate}
          onClick={handleOnDuplicate}
          defaultIcon={<img src={duplicateImg} />}
        />
      </div>
      <Modal centered visible={showPauseWarningModal} footer={null} closable={false} width="26rem">
        <WarningModal
          onOk={() => {
            handleChangeStatus(FilterStatusTypes.paused, 'pause');
            setShowPauseWarningModal(false);
          }}
          onCancel={() => {
            setShowPauseWarningModal(false);
          }}
          titleText="You are about to pause this campaign"
          subtitleText="Resuming this campaign will resume all the ad-sets under this campaign"
          okButtonText="Pause"
          cancelButtonText="Cancel"
        />
      </Modal>
    </>
  );
};

export default AdsTableButtons;
