import React, { useState } from 'react';
import moment from 'moment';
import { Col, Row } from 'antd';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import './styles.scss';
import { AxiosSingleton } from '../../utils/axiosinstance';
import SpinningCircle from '../spinningcircle';
import { availableCountries } from './selectorOptions';
import { AnghamiAdsFormSelector } from '../core/AnghamiAdsFormSelector';

export default function SellThroughRate() {
  const axios = AxiosSingleton.axiosInstance;
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().subtract(1, 'days').format('YYYY-MM-DD'));
  const [countries, setCountries] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [str, setSTR] = useState(0);
  const [strPerDay, setSTRPerDay] = useState([]);

  const clearSTR = () => {
    setSTR(0);
    setSTRPerDay([]);
  };

  const getSTR = () => {
    setIsLoading(true);
    clearSTR();

    const params = {
      ad_type: '',
      start_date: startDate,
      end_date: endDate,
      countries
    };

    axios
      .get('/inventory/str', { params })
      .then((res) => {
        const { data } = res;
        setIsLoading(false);
        setSTR(data.str);
        setSTRPerDay(data.str_per_day);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="str-container">
      <div className="str-header">Sell Through Rate</div>
      <div className="str-flex-container">
        <div className="campaign-form-input-container">
          <label>Start Date</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
          />
        </div>
        <div className="campaign-form-input-container">
          <label>End Date</label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          />
        </div>
      </div>
      <Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
        <Col span={12}>
          <AnghamiAdsFormSelector
            label="Countries *"
            showSearch
            mode="multiple"
            options={availableCountries}
            placeholder="Select one or more countries"
            value={countries}
            onChange={(value: any) => {
              setCountries(value);
            }}
          />
        </Col>
      </Row>
      <button
        type="button"
        className={`calculate-str-btn ${isLoading ? 'loading' : ''}`}
        onClick={() => {
          if (isLoading) return;
          getSTR();
        }}
      >
        {isLoading ? <SpinningCircle /> : 'Calculate STR'}
      </button>
      {Boolean(str) && (
        <>
          <div className="str-flex-container">
            <div className="campaign-form-input-container str-results">
              <label>STR FOR THE SELECTED MARKET</label>
              <div className="total-str">{`${str}%`}</div>
            </div>
          </div>
          <div className="str-flex-container">
            <div className="campaign-form-input-container str-results">
              <label>STR PER DAY FOR THE SELECTED MARKET</label>
              <ResponsiveContainer height={250}>
                <LineChart data={strPerDay} margin={{ left: -20, top: 15 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Line type="monotone" dataKey="str" stroke="#D60031" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
