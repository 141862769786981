import React from 'react';
import { formatNumber } from '../../helpers/format-numbers';

type Props = {
  value: string;
};

const MetricsCell = ({ value }: Props) => {
  let renderValue = value;

  // https://redmine.anghami.com/issues/481220
  if (value === undefined) {
    // when value is not loaded yet
    renderValue = '-';
  } else if (value === null) {
    // when API returns null, or value manually set to null
    renderValue = 'N/A';
  } else if (Number(value) < 0) {
    renderValue = 'N/A';
  } else {
    renderValue = formatNumber(renderValue);
  }

  return <span className="AdsTable-value">{renderValue}</span>;
};

export default MetricsCell;
