import React, {useEffect, useState} from 'react';
import {Col, message, Row, Tabs} from 'antd';
import {TusClientProvider} from 'use-tus';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import moment from 'moment';
import {AnghamiAdsFormSelector} from '../core/AnghamiAdsFormSelector';
import {AnghamiAdsFormInput} from '../core/AnghamiAdsFormInput';
import {AnghamiAdsButton} from '../core/AnghamiAdsButton';
import './styles.scss';
import {countriesOptions} from './selectorOptions';
import MastheadCampaignDatePicker from './MastheadCampaignDatePicker';
import {AxiosSingleton} from '../../utils/axiosinstance';
import AnghamiAdsFullPageLoader from '../core/AnghamiAdsFullPageLoader';
import Upload from "./VideoMastheadCampaignUploadBox";

const VideoMastheadCampaign = () => {
  const axios = AxiosSingleton.axiosInstance;
  const mode = window.location.href.includes('create') ? 'create' : 'edit';
  const {campaignId} = useParams();
  const [searchParams] = useSearchParams();
  const countryParam = searchParams.get('countryParam');
  const dateParam = moment(searchParams.get('dateParam') || undefined);



  const [titleKey, setTitleKey] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [titleAr, setTitleAr] = useState('');

  const [thumbnailKey, setThumbnailKey] = useState('');
  const [thumbnailEn, setThumbnailEn] = useState('');
  const [thumbnailAr, setThumbnailAr] = useState('');

  const [bannerKey, setBannerKey] = useState('');
  const [bannerEn, setBannerEn] = useState('');
  const [bannerAr, setBannerAr] = useState('');

  const [descriptionKey, setDescriptionKey] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [descriptionAr, setDescriptionAr] = useState('');

  const [ctaTextKey, setCtaTextKey] = useState('');
  const [ctaTextEn, setCtaTextEn] = useState('');
  const [ctaTextAr, setCtaTextAr] = useState('');

  const [ctaUrlKey, setCtaUrlKey] = useState('');
  const [ctaUrlEn, setCtaUrlEn] = useState('');
  const [ctaUrlAr, setCtaUrlAr] = useState('');

  const [videoUrlKey, setVideoUrlKey] = useState('');
  const [videoUrlEn, setVideoUrlEn] = useState('');
  const [videoUrlAr, setVideoUrlAr] = useState('');

  const [featureSupport, setFeatureSupport] = useState('');


  const [countries, setCountries] = useState<string[]>(countryParam ? [countryParam] : []);
  const [date, setDate] = useState<any>(dateParam);

  const [disabledDates] = useState([]);
  const [isSubmissionLoading, setIsSubmissionLoading] = useState(false);
  const [isFullPageLoaderVisible, setIsFullPageLoaderVisible] = useState(mode === 'edit');

  const isSaveButtonDisabled =
    countries?.length === 0 ||
    !date || !titleEn || !descriptionEn || !ctaTextEn || !ctaUrlEn || !videoUrlEn || !thumbnailEn || !bannerEn;

  const navigate = useNavigate();

  const saveMastheadCampaign = () => {
    if (isSaveButtonDisabled) return;
    setIsSubmissionLoading(true);
    axios
      .post(`/masthead-campaigns/${mode === 'create' ? 'store' : 'update'}`, {
        id: campaignId,
        title: {
          en: titleEn,
          ar: titleAr,
          keyText: titleKey
        },
        countries,
        day: date?.format('YYYY-MM-DD'),
        video_url: {
          en: videoUrlEn,
          ar: videoUrlAr,
          keyText: videoUrlKey
        },
        thumbnail_url: {
          en: thumbnailEn,
          ar: thumbnailAr,
          keyText: thumbnailKey
        },
        banner_url: {
          en: bannerEn,
          ar: bannerAr,
          keyText: bannerKey
        },
        description: {
          en: descriptionEn,
          ar: descriptionAr,
          keyText: descriptionKey
        },
        cta_text: {
          en: ctaTextEn,
          ar: ctaTextAr,
          keyText: ctaTextKey
        },
        cta_url: {
          en: ctaUrlEn,
          ar: ctaUrlAr,
          keyText: ctaUrlKey
        },
        feature_support : featureSupport,
        type: 'video',
      })
      .then(() => {
        navigate('/video-masthead-campaigns/list');
        setIsSubmissionLoading(false);
      })
      .catch(({response}) => {
        const {data} = response;
        if (data.error) {
          message.error(data.error);
        } else {
          message.error('Something went wrong');
        }
        setIsSubmissionLoading(false);
      });
  };

  useEffect(() => {
    if (mode === 'create' || !campaignId) return;

    setIsFullPageLoaderVisible(true);
    axios
      .get('/masthead-campaigns/get', {
        params: {
          id: campaignId
        }
      })
      .then(({data}) => {
        const campaignData = data?.data;
        setTitleEn(campaignData.title?.en);
        setTitleAr(campaignData.title?.ar);
        setTitleKey(campaignData.title?.keyText);
        let countries : Array<string> = [];
        campaignData.countries?.split(',').forEach((country:string) => {
          countries.push(country);
        })
        setCountries(countries);
        setDate(moment(campaignData.day));
        setVideoUrlEn(campaignData.video_url?.en);
        setVideoUrlAr(campaignData.video_url?.ar);
        setVideoUrlKey(campaignData.video_url?.keyText);
        setThumbnailEn(campaignData.thumbnail_url?.en);
        setThumbnailAr(campaignData.thumbnail_url?.ar);
        setThumbnailKey(campaignData.thumbnail_url?.keyText);
        setBannerEn(campaignData.banner_url?.en);
        setBannerAr(campaignData.banner_url?.ar);
        setBannerKey(campaignData.banner_url?.keyText);
        setDescriptionEn(campaignData.description?.en);
        setDescriptionAr(campaignData.description?.ar);
        setDescriptionKey(campaignData.description?.keyText);
        setCtaTextEn(campaignData.cta_text?.en);
        setCtaTextAr(campaignData.cta_text?.ar);
        setCtaTextKey(campaignData.cta_text?.keyText);
        setCtaUrlEn(campaignData.cta_url?.en);
        setCtaUrlAr(campaignData.cta_url?.ar);
        setCtaUrlKey(campaignData.cta_url?.keyText);
        setFeatureSupport(campaignData.feature_support);
        setIsFullPageLoaderVisible(false);
      });
  }, []);

  if (isFullPageLoaderVisible) {
    return <AnghamiAdsFullPageLoader/>;
  }

  return (
    <div className="masthead-campaign-container">
      <div className="masthead-campaign-title">
        {mode === 'create' ? 'Setup Your New Masthead Campaign' : 'Edit Your Masthead Campaign'}
      </div>

      <div className="masthead-campaign-section-title">Title</div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="English" key="item-1">
          <Row gutter={[16, 16]} style={{marginBottom: '1rem'}}>
            <Col span={16}>
              <div style={{ marginBottom: '1rem' }}>
                <AnghamiAdsFormInput
                  label="Title"
                  placeholder="Insert english title"
                  value={titleEn}
                  onChange={(e) => {
                    setTitleEn(e.target.value);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Arabic" key="item-2">
          <Row gutter={[16, 16]} style={{marginBottom: '1rem'}}>
            <Col span={16}>
              <div style={{ marginBottom: '1rem' }}>
                <AnghamiAdsFormInput
                  label="Title"
                  placeholder="Insert arabic title"
                  value={titleAr}
                  onChange={(e) => {
                    setTitleAr(e.target.value);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
      <div className="masthead-campaign-section-title">Video</div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="English" key="item-1">
          <Row gutter={[16, 16]} style={{marginBottom: '1rem'}}>
            <Col span={16}>
              <TusClientProvider>
                <Upload
                  uploadSuccessHandler={(fileLocation) => {
                    setVideoUrlEn(fileLocation);
                  }}
                  clearUploadHandler={() => {
                    setVideoUrlEn('');
                  }}
                  fileName={videoUrlEn}
                  inputID="video_en"
                  key="video_en"
                 uploadType={"video"}/>
              </TusClientProvider>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Arabic" key="item-2">
          <Row gutter={[16, 16]} style={{marginBottom: '1rem'}}>
            <Col span={16}>
              <TusClientProvider>
                <Upload
                  uploadSuccessHandler={(fileLocation) => {
                    setVideoUrlAr(fileLocation);
                  }}
                  clearUploadHandler={() => {
                    setVideoUrlAr('');
                  }}
                  fileName={videoUrlAr}
                  inputID="videoAr"
                  key="videoAr"
                 uploadType={'video'}/>
              </TusClientProvider>
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
      <div className="masthead-campaign-section-title">Thumbnail</div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="English" key="item-1">
          <Row gutter={[16, 16]} style={{marginBottom: '1rem'}}>
            <Col span={16}>
              <TusClientProvider>
                <Upload
                  uploadSuccessHandler={(fileLocation) => {
                    setThumbnailEn(fileLocation);
                  }}
                  clearUploadHandler={() => {
                    setThumbnailEn('');
                  }}
                  fileName={thumbnailEn}
                  inputID="thumbnailEn"
                  key="thumbnailEn"
                 uploadType={"image"}/>
              </TusClientProvider>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Arabic" key="item-2">
          <Row gutter={[16, 16]} style={{marginBottom: '1rem'}}>
            <Col span={16}>
              <TusClientProvider>
                <Upload
                  uploadSuccessHandler={(fileLocation) => {
                    setThumbnailAr(fileLocation);
                  }}
                  clearUploadHandler={() => {
                    setThumbnailAr('');
                  }}
                  fileName={thumbnailAr}
                  inputID="thumbnailAr"
                  key="thumbnailAr"
                  uploadType={'image'}
                />
              </TusClientProvider>
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
      <div className="masthead-campaign-section-title">Banner URL</div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="English" key="item-1">
          <Row gutter={[16, 16]} style={{marginBottom: '1rem'}}>
            <Col span={16}>
              <div style={{ marginBottom: '1rem' }}>
                <AnghamiAdsFormInput
                  label="English Banner URL"
                  placeholder="Insert english banner url"
                  value={bannerEn}
                  onChange={(e) => {
                    setBannerEn(e.target.value);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Arabic" key="item-2">
          <Row gutter={[16, 16]} style={{marginBottom: '1rem'}}>
            <Col span={16}>
              <div style={{ marginBottom: '1rem' }}>
                <AnghamiAdsFormInput
                  label="Arabic Banner URL"
                  placeholder="Insert arabic banner url"
                  value={bannerAr}
                  onChange={(e) => {
                    setBannerAr(e.target.value);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
      <div className="masthead-campaign-section-title">Description</div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="English" key="item-1">
          <Row gutter={[16, 16]} style={{marginBottom: '1rem'}}>
            <Col span={16}>
              <div style={{ marginBottom: '1rem' }}>
                <AnghamiAdsFormInput
                  label="Description"
                  placeholder="Insert english description"
                  value={descriptionEn}
                  onChange={(e) => {
                    setDescriptionEn(e.target.value);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Arabic" key="item-2">
          <Row gutter={[16, 16]} style={{marginBottom: '1rem'}}>
            <Col span={16}>
              <div style={{ marginBottom: '1rem' }}>
                <AnghamiAdsFormInput
                  label="Description"
                  placeholder="Insert arabic description"
                  value={descriptionAr}
                  onChange={(e) => {
                    setDescriptionAr(e.target.value);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
      <div className="masthead-campaign-section-title">CTA Text</div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="English" key="item-1">
          <Row gutter={[16, 16]} style={{marginBottom: '1rem'}}>
            <Col span={16}>
              <div style={{ marginBottom: '1rem' }}>
                <AnghamiAdsFormInput
                  label="cta text"
                  placeholder="Insert english cta text"
                  value={ctaTextEn}
                  onChange={(e) => {
                    setCtaTextEn(e.target.value);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Arabic" key="item-2">
          <Row gutter={[16, 16]} style={{marginBottom: '1rem'}}>
            <Col span={16}>
              <div style={{ marginBottom: '1rem' }}>
                <AnghamiAdsFormInput
                  label="cta text"
                  placeholder="Insert arabic cta text"
                  value={ctaTextAr}
                  onChange={(e) => {
                    setCtaTextAr(e.target.value);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
      <div className="masthead-campaign-section-title">CTA Url</div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="English" key="item-1">
          <Row gutter={[16, 16]} style={{marginBottom: '1rem'}}>
            <Col span={16}>
              <div style={{ marginBottom: '1rem' }}>
                <AnghamiAdsFormInput
                  label="Cta url"
                  placeholder="Insert english cta url"
                  value={ctaUrlEn}
                  onChange={(e) => {
                    setCtaUrlEn(e.target.value);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Arabic" key="item-2">
          <Row gutter={[16, 16]} style={{marginBottom: '1rem'}}>
            <Col span={16}>
              <div style={{ marginBottom: '1rem' }}>
                <AnghamiAdsFormInput
                  label="Cta Url"
                  placeholder="Insert arabic cta url"
                  value={ctaUrlAr}
                  onChange={(e) => {
                    setCtaUrlAr(e.target.value);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
      <div className="masthead-campaign-section-title">Feature Support</div>

      <Row gutter={[16, 16]} style={{marginBottom: '1rem'}}>
        <Col span={16}>
          <div style={{ marginBottom: '1rem' }}>
            <AnghamiAdsFormInput
              label="Feature Support"
              placeholder="Insert feature support"
              value={featureSupport}
              onChange={(e) => {
                setFeatureSupport(e.target.value);
              }}
            />
          </div>
        </Col>
      </Row>
      <div className="masthead-campaign-section-title">Market and Time</div>
      <div className="masthead-campaign-section-subtitle">
        Please insert the targeted countries and the day you want your masthead to be visible
      </div>
      <AnghamiAdsFormSelector
        label="Country*"
        placeholder="Select country"
        mode="multiple"
        value={countries}
        options={countriesOptions}
        onChange={(value) => {
          setCountries([...value]);
        }}
      />
      <div
        className="campaign-form-input-container"
        style={{height: '4.7rem', marginTop: '1rem'}}
      >
        <label>Day*</label>
        <MastheadCampaignDatePicker
          date={date}
          setDate={setDate}
          disabledDates={disabledDates}
          blockedDays={null}/>
      </div>
      <AnghamiAdsButton
        style={{marginTop: '1rem', marginBottom: '1rem'}}
        disabled={isSaveButtonDisabled}
        label="Save"
        onClick={saveMastheadCampaign}
        isLoading={isSubmissionLoading}
      />
    </div>
  );
};

export default VideoMastheadCampaign;
