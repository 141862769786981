import React, { useState } from 'react';
import { colorDarkenLighten } from '../../../helpers/color-darken-lighten';
import './styles.scss';

type Value = {
  title: string;
  percentage: string;
};

type Props = {
  values: Value[];
  color: string;
};

const HorizontalChart = ({ values, color }: Props) => {
  const [activeTooltipData, setActiveTooltipData] = useState(null);
  const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 });

  const calculateTooltipPosition = (e: any) => {
    let { clientX, clientY } = e;

    if (clientX + 200 >= window.innerWidth) {
      clientX -= 150;
    }

    if (clientY + 150 >= window.innerHeight) {
      clientY -= 120;
    }

    setPointerPosition({ x: clientX + 20, y: clientY + 20 });
  };

  return (
    <div className="HorizontalChart">
      <div className="HorizontalChart-bars">
        {values.map((value, index) => (
          <span
            key={value.title}
            className="HorizontalChart-bars-bar"
            style={{
              width: `${Number(value.percentage) > 5 ? value.percentage : 5}%`,
              backgroundColor: colorDarkenLighten(color, index, values.length)
            }}
            onMouseEnter={(e) => {
              calculateTooltipPosition(e);
              setActiveTooltipData(value);
            }}
            onMouseMove={calculateTooltipPosition}
            onMouseOut={() => setActiveTooltipData(null)}
          />
        ))}
      </div>

      <div className="HorizontalChart-titles">
        {values.map((value) => (
          <span
            key={value.title}
            className="HorizontalChart-titles-title"
            style={{ width: `${Number(value.percentage) > 5 ? value.percentage : 5}%` }}
          >
            <div className="HorizontalChart-titles-title-header">{value.title}</div>
            <div className="HorizontalChart-titles-title-text">{value.percentage}%</div>
          </span>
        ))}
      </div>

      {activeTooltipData ? (
        <div
          className="HorizontalChart-tooltip"
          style={{ left: pointerPosition.x, top: pointerPosition.y }}
        >
          <div className="HorizontalChart-tooltip-title">{activeTooltipData.title}</div>
          <span>percentage: {activeTooltipData.percentage}%</span>
        </div>
      ) : null}
    </div>
  );
};

export default HorizontalChart;
