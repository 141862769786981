import React from 'react';
import './styles.scss';
import WarningModalIcon from '../../public/assets/images/warning-modal-icon.svg';

type Props = {
  onOk: () => void;
  onCancel: () => void;
  titleText: string;
  subtitleText: string;
  okButtonText: string;
  cancelButtonText: string;
};

export default function WarningModal({
  onOk,
  onCancel,
  titleText,
  subtitleText,
  okButtonText,
  cancelButtonText
}: Props) {
  return (
    <div className="warning-modal-content">
      <img src={WarningModalIcon} className="warning-modal-icon" />
      <div className="warning-modal-title">{titleText}</div>
      <div className="warning-modal-subtitle">{subtitleText}</div>
      <div className="warning-modal-btns-container">
        <button type="button" onClick={onCancel} className="warning-modal-cancel-btn">
          {cancelButtonText}
        </button>
        <button type="button" onClick={onOk} className="warning-modal-ok-btn">
          {okButtonText}
        </button>
      </div>
    </div>
  );
}
