import React, { useRef, useState } from 'react';
import { CaretRightOutlined, CloseOutlined, PauseOutlined } from '@ant-design/icons';
// import { IAdSet, ICreative } from '../campaignhoc';
import './styles.scss';

type Props = {
  adSet: any;
  creative: any;
  groupCampaignObjective: string;
  updateLeadGenForm: (adSet: any, shouldPreviewForm: any) => null;
  buttonText: string;
  songData: any;
};

type PreviewTab = 'main' | 'postroll';

export default function PreviewComponent({
  adSet,
  creative,
  groupCampaignObjective,
  updateLeadGenForm,
  buttonText,
  songData
}: Props) {
  const [currentTab, setCurrentTab] = useState<PreviewTab>('main');
  const audioPlayer = useRef<HTMLAudioElement>(null);
  const [isAudioPlayerPlaying, setIsAudioPlayerPlaying] = useState(false);

  const previewAdImage = (image: string, width: number, height: number): string => {
    if (!image) {
      return `https://via.placeholder.com/${width}x${height}/f9f9f9/f9f9f9.jpg?text=...`;
    }

    if (image.indexOf('presets/generic') !== -1) {
      return image;
    }

    return `https://angimg.anghcdn.co/presets/generic/template?template=${encodeURI(
      image
    )}&image=${encodeURI(image)}&canvas_h=${height}&canvas_w=${width}&no_margin=1`;
  };

  const renderSongAd = () => {
    if (songData?.coverArt) {
      return (
        <div className="song-ad-preview">
          <img className="song-ad-preview-img" src={songData.coverArt} />
          <div className="song-ad-preview-title">{`${songData.title} by ${songData.artist}`}</div>
          <div className="song-ad-preview-genre">{songData.genre}</div>
        </div>
      );
    }
  };

  const renderMainAd = () => {
    if (!creative.creativeImageEn && !creative.creativeVideoEn) {
      return (
        <>
          <div className="placeholder-container">
            {(adSet.adSetType === 'audio' || adSet.adSetType === 'display') && (
              <>
                <div className="ad-header">
                  <div className="ad-header-text">Ad</div>
                  <button type="button">Remove Ads</button>
                </div>
                <div className="audio-ad-placeholder" />
                <button type="button" className="preview-cta">
                  {buttonText}
                </button>
              </>
            )}
            {adSet.adSetType === 'video' && (
              <>
                <div className="ad-header">
                  <div className="ad-header-text">Ad</div>
                  <button type="button">Remove Ads</button>
                </div>
                <div className="video-ad-placeholder" />
                <button type="button" className="preview-cta">
                  {buttonText}
                </button>
              </>
            )}
          </div>
        </>
      );
    }

    if (adSet.adSetType === 'audio') {
      return (
        <div className="audio-preview">
          <img src={previewAdImage(creative.creativeImageEn, 720, 1520)} />
          <div className="preview-cta-container">
            <button type="button" className="preview-cta">
              {buttonText}
            </button>
          </div>
          {isAudioPlayerPlaying ? (
            <div className="play-pause-container">
              <div
                className="play-pause"
                onClick={() => {
                  if (audioPlayer.current) {
                    audioPlayer.current.pause();
                  }
                }}
              >
                <PauseOutlined />
              </div>
            </div>
          ) : (
            <div className="play-pause-container">
              <div
                className="play-pause"
                onClick={() => {
                  if (audioPlayer.current) {
                    audioPlayer.current.play();
                  }
                }}
              >
                <CaretRightOutlined />
              </div>
            </div>
          )}
          {creative.creativeAudioEn && (
            <audio
              controls
              ref={audioPlayer}
              onPause={() => {
                setIsAudioPlayerPlaying(false);
              }}
              onPlay={() => {
                setIsAudioPlayerPlaying(true);
              }}
              onEnded={() => {
                setIsAudioPlayerPlaying(false);
              }}
            >
              <source src={creative.creativeAudioEn} />
              Your browser does not support the audio element.
            </audio>
          )}
        </div>
      );
    }

    if (adSet.adSetType === 'video') {
      return (
        <div className="video-preview">
          <video controls controlsList="nofullscreen nodownload">
            <source src={creative.creativeVideoEn} type="video/mp4" />
            Your browser does not support the video tag
          </video>
          <div className="preview-cta-container">
            <button type="button" className="preview-cta">
              {buttonText}
            </button>
          </div>
        </div>
      );
    }

    if (adSet.adSetType === 'display') {
      return (
        <div className="audio-preview">
          <img src={previewAdImage(creative.creativeImageEn, 720, 1520)} />
          <div className="preview-cta-container">
            <button type="button" className="preview-cta">
              {buttonText}
            </button>
          </div>
        </div>
      );
    }

    return null;
  };

  const renderPostRollAd = () => (
    <div className="post-roll-container">
      <div className="post-roll-ad-container">
        <div className="post-roll-ad-header">
          <div className="post-roll-close-btn">
            <CloseOutlined />
          </div>
        </div>
        <div className="post-roll-ad-body">
          <div
            className={`post-roll-ad-image-parent ${
              creative.creativeImageEn ? 'not-placeholder' : ''
            }`}
          >
            {creative.creativeImageEn ? (
              <img
                className="post-roll-ad-placeholder-image"
                src={previewAdImage(creative.creativeImageEn, 600, 600)}
              />
            ) : (
              <div className="post-roll-ad-placeholder-image">Display ad will show here</div>
            )}
          </div>
          <div className="post-roll-footer">
            <div className="post-roll-footer-text">
              {creative.creativeTitleEn || 'Post-roll text will show here!'}
            </div>
            <button type="button" className="post-roll-cta">
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className="preview-ad-container"
      style={{ height: adSet.adSetType === 'song' ? '25rem' : '36rem' }}
    >
      {adSet.adSetType === 'song' ? (
        renderSongAd()
      ) : (
        <>
          <div className="preview-tabs-container">
            <span
              className={`preview-tab ${currentTab === 'main' ? 'selected-preview-tab' : ''}`}
              onClick={() => {
                setCurrentTab('main');
              }}
            >
              {adSet.adSetType === 'audio' ? 'Audio Ad' : ''}
              {adSet.adSetType === 'display' ? 'Display Ad' : ''}
              {adSet.adSetType === 'video' ? 'Video Ad' : ''}
            </span>
            {(adSet.adSetType === 'audio' || adSet.adSetType === 'video') && (
              <span
                className={`preview-tab ${currentTab === 'postroll' ? 'selected-preview-tab' : ''}`}
                onClick={() => {
                  setIsAudioPlayerPlaying(false);
                  setCurrentTab('postroll');
                }}
              >
                Post-Roll Ad
              </span>
            )}
          </div>
          <div className="preview-body">
            {currentTab === 'main' && renderMainAd()}
            {currentTab === 'postroll' && renderPostRollAd()}
          </div>
          {groupCampaignObjective === 'lead' && (
            <div
              className="lead-gen-button"
              onClick={() => {
                updateLeadGenForm(adSet, true);
              }}
            >
              Preview Lead Gen
            </div>
          )}
        </>
      )}
    </div>
  );
}
