import React from 'react';
import { MetaType } from '../managecampaigns/tabledatatype';
import './styles.scss';

type Props = {
  paginationMeta: MetaType;
  length: number;
};

export const AdsTableButtons = ({ paginationMeta, length }: Props) => (
  <div className="AdsTablePagination">
    <span className="AdsTablePagination-text">
      {' '}
      {length} of {paginationMeta.total || 0}{' '}
    </span>
  </div>
);

export default AdsTableButtons;
