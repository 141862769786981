import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AxiosSingleton } from '../../utils/axiosinstance';
import AdsTable from '../adstable';
import './styles.scss';
import { contendingCampaignsTableColumns } from './tableColumns';

export default function ContendingCampaigns() {
  const axios = AxiosSingleton.axiosInstance;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const impressionsKey = searchParams.get('key');
  const [contendingCampaigns, setContendingCampaigns] = useState([]);

  useEffect(() => {
    if (!impressionsKey) {
      navigate('/inventory');
    }

    axios
      .get('/inventory/contendinglines', {
        params: {
          key: impressionsKey
        }
      })
      .then((res) => {
        const { data } = res;
        setContendingCampaigns(data.data);
      });
  }, []);
  return (
    <div className="contending-campaigns-container">
      <div className="contending-campaigns-header-heading">Contending Campaigns</div>
      {contendingCampaigns.length > 0 && (
        <AdsTable
          rowSelection={null}
          data={contendingCampaigns}
          tableColumns={contendingCampaignsTableColumns}
          onFetch={() => null}
          isLoading={false}
          isInfiniteLoading={false}
        />
      )}
    </div>
  );
}
