import React from 'react';
import './styles.scss';

type Props = {
  /**
   * Text of the page title
   */
  label: string;
};

const AnghamiAdsPageTitle = ({ label }: Props) => (
  <div className="anghami-ads-page-title">{label}</div>
);

export default AnghamiAdsPageTitle;
