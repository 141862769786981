import React from 'react';
import SponsoredPlaylistsPlacementPreview from './SponsoredPlaylistsPlacementPreview';

type Props = {
  selectedPlaylist: any;
  playlistTitleEn: string;
  playlistTitleAr: string;
  budget: any;
  countries: any[];
  artists: any[];
  gender: any;
  maxAge: any;
  minAge: any;
  startDate: any;
  endDate: any;
  advertiser: any;
  brand: any;
  save: (isDraft: boolean) => void;
  cover: string;
  overrideImageCoverFr: string;
  overrideImageCoverAr: string;
};

export default function SponsoredPlaylistsReview({
  selectedPlaylist,
  playlistTitleEn,
  playlistTitleAr,
  budget,
  countries,
  artists,
  gender,
  maxAge,
  minAge,
  startDate,
  endDate,
  advertiser,
  brand,
  overrideImageCoverFr,
  overrideImageCoverAr,
  save,
  cover
}: Props) {
  return (
    <div className="campaign-container">
      <div className="campaign-container-header">
        <div className="campaign-container-header-title">Review & Submit</div>
        <div className="save-btns-container">
          <button
            className="save-for-later-btn"
            type="button"
            onClick={() => {
              save(true);
            }}
          >
            Save as Draft
          </button>
          <button
            className="campaign-container-header-btn"
            type="button"
            onClick={() => {
              save(false);
            }}
          >
            Save
          </button>
        </div>
      </div>
      {selectedPlaylist && (
        <div className="playlist-info-container">
          <div className="playlist-img-container">
            <img src={selectedPlaylist.image} />
          </div>
          <div>
            <a
              target="_blank"
              href={`https://www.anghami.com/playlist/${selectedPlaylist.id}`}
              rel="noreferrer"
            >
              <h4>{selectedPlaylist.title}</h4>
            </a>
            {selectedPlaylist.user && (
              <>
                <h6>
                  by{' '}
                  <a
                    href={`https://www.anghami.com/profile/${selectedPlaylist.userid}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {selectedPlaylist.user}
                  </a>
                </h6>
                <strong>[{selectedPlaylist.id}]</strong>
              </>
            )}
          </div>
        </div>
      )}
      <div className="campaign-info-container ">
        <div className="campaign-section-title">Campaign Info</div>
        <div>
          <b>Advertiser</b>: {advertiser}
        </div>
        <div>
          <b>Brand</b>: {brand}
        </div>
        <div>
          <b>Playlist Title</b>: {playlistTitleEn}
        </div>
        <div>
          <b>Arabic Playlist Title</b>: {playlistTitleAr}
        </div>
        <div>
          <b>Budget</b>: {budget}
        </div>
        <div>
          <b>Starts On</b>: {startDate}
        </div>
        <div>
          <b>Ends On</b>: {endDate}
        </div>
      </div>
      <div className="campaign-info-container ">
        <div className="campaign-section-title">Covers</div>
        {overrideImageCoverAr && (
          <div>
            <b>Arabic Cover</b>:{' '}
            <img className="review-override-cover" src={overrideImageCoverAr} />
          </div>
        )}
        {overrideImageCoverFr && (
          <div>
            <b>French Cover</b>:{' '}
            <img className="review-override-cover" src={overrideImageCoverFr} />
          </div>
        )}
      </div>
      <div className="campaign-info-container ">
        <div className="campaign-section-title">Audience</div>
        <div>
          <b>Countries</b>: {countries}
        </div>
        <div>
          <b>Targeted Artists</b>: {artists.join(', ')}
        </div>
        <div>
          <b>Gender</b>: {gender}
        </div>
        <div>
          <b>Age</b>: {minAge}-{maxAge}
        </div>
      </div>
      <div>
        <div className="campaign-section-title">Placement Preview</div>
        <SponsoredPlaylistsPlacementPreview cover={cover} />
      </div>
    </div>
  );
}
