import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

type Props = {
  children: React.ReactChild;
};

const MastheadCampaignsCalendarGuard = ({ children }: Props) => {
  const { user } = useAuth();
  const canViewPage = user?.publisher_id === 2 || user?.publisher_id === 4;
  if (canViewPage) {
    return <>{children}</>;
  }

  return <Navigate replace to="/overview" />;
};

export default MastheadCampaignsCalendarGuard;
