import moment from 'moment';
import React from 'react';

export type TableColumnType = {
  title: string;
  width: string;
  dataIndex: string;
  key: string;
  fixed?: string;
  render: (value: any, data: any) => any;
};

const formatStatus = (status) => {
  switch (status) {
    case 0:
      return 'Pending';
    case 1:
      return 'Running';
    case 2:
      return 'Stopped';
    case 3:
      return 'Ended';
    case -1:
      return 'Rejected';
    default:
      return 'Inactive';
  }
};

export const sponsoredPlaylistsTableColumn: TableColumnType[] = [
  {
    title: 'Cover',
    width: '5.3rem',
    dataIndex: 'cover',
    key: '0',
    render: (cover) => (
      <img
        src={cover}
        style={{
          width: '10rem',
          height: '10rem',
          borderRadius: '0.5rem'
        }}
      />
    )
  },
  {
    title: 'Name',
    dataIndex: 'title',
    key: '1',
    width: '9rem',
    render: (name: string) => <span>{name}</span>
  },
  {
    title: 'Start Date',
    dataIndex: 'start_date',
    key: '2',
    width: '6rem',
    render: (startDate: string) => <span>{moment(startDate).format('MMM DD, YYYY')}</span>
  },
  {
    title: 'End Date',
    dataIndex: 'end_date',
    key: '3',
    width: '6rem',
    render: (endDate: string) => <span>{moment(endDate).format('MMM DD, YYYY')}</span>
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: '4',
    width: '9rem',
    render: (status: string) => <span>{formatStatus(Number(status))}</span>
  },
  {
    title: 'Created By',
    dataIndex: 'brandname',
    key: '5',
    width: '6rem',
    render: (createdBy: string) => <span>{createdBy}</span>
  }
];
