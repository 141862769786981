import React from 'react';
import { Col, Row } from 'antd';
import RewardsImg from '../../public/assets/images/overview/rewards.png';
import ReportsImg from '../../public/assets/images/overview/reports.png';
import AudienceImg from '../../public/assets/images/overview/reach-audience.png';
import firstCampaignImg from '../../public/assets/images/overview/first-campaign.png';
import { logAmplitudeEvent } from '../../utils/analytics';
import { CampaignAmplitudeEvents } from '../../enums/campaignamplitudeevents';
import './styles.scss';
import '../overview/styles.scss';
import { PublisherRoles } from '../../enums/publisherroles';
import useAuth from '../../hooks/useAuth';

const OverviewEmpty = () => {
  const { role } = useAuth();
  const canCreateCampaigns =
    role === PublisherRoles.DirectClient ||
    role === PublisherRoles.Anghami ||
    role === PublisherRoles.DMSPublisher ||
    role === PublisherRoles.DMSAdmin ||
    role === PublisherRoles.DMSTrafficker;
  return (
    <>
      <Row gutter={[32, 32]} justify="center">
        <Col className="flexbox colls first-campaign-container">
          <img src={firstCampaignImg} alt="first campaign" className="first-campaign-image" />
          <div className="first-campaign-header">Let&apos;s start with your first campaign</div>
          <div className="first-campaign-body">You don&apos;t have any campaigns running yet!</div>
          {canCreateCampaigns && (
            <button
              className="first-campaign-button"
              type="button"
              onClick={() => {
                logAmplitudeEvent(CampaignAmplitudeEvents.ClickButton, {
                  button_text: 'Start your first campaign',
                  page_url: window.location.href
                });
                window.open('/campaign/create');
              }}
            >
              Start your first campaign
            </button>
          )}
        </Col>
      </Row>
      <Row justify="center">
        <Col className="flexbox colls learn">
          <div className="learn-header">LAUNCH YOUR FIRST CAMPAIGN ON ANGHAMI</div>
          <div className="learn-body">
            Your audio, video or display ads can be up and running with 4 easy steps.
          </div>
        </Col>
      </Row>
      <Row className="flexbox learn-containers-wrapper" justify="space-around" align="top">
        <Col span={7} className="flexbox colls learn-container">
          <img src={AudienceImg} />
          <div className="learn-container-header">Create a campaign within minutes</div>
          <div className="learn-container-body">
            Launching audio, video or display campaigns on Anghami has been made easier with Anghami
            Ads Manager.
            <br />
            No audio ad? No problem. Fill in the brief and our team will create it for you within
            2-3 working days.
          </div>
        </Col>
        <Col span={7} className="flexbox colls learn-container">
          <img src={ReportsImg} />
          <div className="learn-container-header">Target the right listeners at the right time</div>
          <div className="learn-container-body">
            Deliver your message to the right audience, at the right time depending on their music
            preferences, age, location and more.
          </div>
        </Col>
        <Col span={7} className="flexbox colls learn-container">
          <img src={RewardsImg} />
          <div className="learn-container-header">Monitor, Optimize & Report on the go</div>
          <div className="learn-container-body">
            You can always edit, monitor, optimize your campaign&apos;s performance on the go with
            Anghami Ads Manager. You can also generate detailed reports within a minute.
          </div>
        </Col>
      </Row>
      <Row align="middle" justify="center">
        <Col>
          {canCreateCampaigns && (
            <button
              className="first-campaign-button"
              type="button"
              onClick={() => {
                logAmplitudeEvent(CampaignAmplitudeEvents.ClickButton, {
                  button_text: 'Start your first campaign',
                  page_url: window.location.href
                });
                window.open('/campaign/create');
              }}
            >
              Get started
            </button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default OverviewEmpty;
