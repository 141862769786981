import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import Papa from 'papaparse';
import shaJs from 'js-sha256';
import { useTus } from 'use-tus';
import { UploadBoxStates } from '../../enums/uploadboxstates';
import UploadBox from '../uploadbox';
import './styles.scss';
import useAuth from '../../hooks/useAuth';
import environment from '../env/env';

type UploadBoxStateType = 'preupload' | 'upload' | 'uploaded' | 'uploadfailed' | 'invalidfile';

type Props = {
  isHashed: boolean;
  setCsvURL: Dispatch<SetStateAction<string>>;
  csvURL: string;
};

export default function CustomAudiencesUploadBox({ isHashed, setCsvURL, csvURL }: Props) {
  const [uploadBoxState, setUploadBoxState] = useState<UploadBoxStateType>(
    csvURL ? UploadBoxStates.Uploaded : UploadBoxStates.PreUpload
  );
  const [uploadedFileName, setUploadedFileName] = useState(csvURL || '');
  const [metadataFileName, setMetadataFileName] = useState('');
  const { user } = useAuth();
  const { upload, setUpload, isSuccess } = useTus();

  const readCsvHeader = (csvFile) =>
    new Promise((resolve) => {
      csvFile.text().then((res) => {
        Papa.parse(res, {
          complete: (result) => {
            resolve(result.meta.fields);
          },
          header: true
        });
      });
    });

  const sanitize = (cellValue) => cellValue.trim().toLowerCase();

  const parseCsv = (csvFile) =>
    new Promise((resolve, reject) => {
      const csvLines = [];
      let delimiter;
      let newline;

      Papa.parse(csvFile, {
        complete(result, inputFile) {
          if (result.errors.length > 0) {
            reject(result.errors);
            return;
          }

          readCsvHeader(inputFile).then((fields: any) => {
            csvLines[0] = fields.join(delimiter) + newline;
            resolve(csvLines);
          });
        },
        error: (error) => {
          reject(error);
        },
        step(result) {
          const rows = result.data;
          if (delimiter === undefined) {
            delimiter = result.meta.delimiter;
            newline = result.meta.linebreak;
          }
          const csvLine =
            rows.map((cell) => (cell !== '' ? shaJs.sha256(sanitize(cell)) : '')).join(delimiter) +
            newline;
          csvLines.push(csvLine);
        }
      });
    });

  const outputFilename = (inputFilename) => {
    const fileExtension = /\.\w+$/;
    const hashInFix = '.sha256';

    return inputFilename.match(fileExtension)
      ? inputFilename.replace(fileExtension, `${hashInFix}$&`)
      : inputFilename + hashInFix;
  };

  const hashFileBeforeUpload = (file) =>
    new Promise((resolve, reject) => {
      parseCsv(file).then(
        (csvLines: any) => {
          const outputFileBlob = new Blob(csvLines, { type: 'text/csv' });
          const nameOfOutputFile = outputFilename(file.name);
          const outputFile = new File([outputFileBlob], nameOfOutputFile, {
            type: 'text/csv',
            lastModified: Number(new Date())
          });
          if (outputFile instanceof File) {
            resolve(outputFile);
          } else {
            resolve(null);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });

  const handleSetUpload = async (file) => {
    if (!file) {
      return;
    }

    let outputFile = file;

    if (!isHashed) {
      outputFile = await hashFileBeforeUpload(file);
    }

    setUploadBoxState(UploadBoxStates.Uploading);

    const name = `${String(Math.floor(Math.random() * 9999 + 1000))}_${file.name
      .replace(/[^A-Za-z0-9\-._]/g, '')
      .toLowerCase()}`;
    const filename = `${user.publisher_id
      .toString()
      .replace(/[^A-Za-z0-9\-._]/g, '')
      .toLowerCase()}/${name}`;
    setMetadataFileName(filename);

    setUpload(file, {
      endpoint: 'https://tusk.anghami.com/files/',
      retryDelays: [0, 1000, 3000, 5000],
      metadata: {
        name,
        filename,
        filetype: file.type,
        token: 'C5E386CD1A2A2F1DD93841AD811F7',
        bucket: 'anghami.newads'
      },
      removeFingerprintOnSuccess: true
    });

    setUploadedFileName(outputFile.name);
  };

  useEffect(() => {
    if (!upload) {
      return;
    }

    upload.start();
  }, [upload]);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    setCsvURL(`${environment.ads_cdn_url + metadataFileName}`);
    setUploadBoxState(UploadBoxStates.Uploaded);
  }, [isSuccess]);

  return (
    <div className="custom-audiences-upload-box">
      <FileUploader
        handleChange={handleSetUpload}
        name="file"
        types={['CSV']}
        disabled={uploadBoxState === UploadBoxStates.Uploaded}
      >
        <UploadBox
          type="file"
          uploadedFilename={uploadedFileName}
          state={uploadBoxState}
          clearUploadValue={() => {
            setMetadataFileName('');
            setCsvURL('');
            setUploadBoxState(UploadBoxStates.PreUpload);
          }}
        />
      </FileUploader>
    </div>
  );
}
