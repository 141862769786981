export enum Tabs {
  Campaigns = 'Campaign',
  AdsSets = 'Ad Set',
  Creatives = 'Creative'
}

export enum AdTypes {
  display = 'display',
  video = 'video',
  audio = 'audio',
  song = 'song'
}

export enum FilterObjectiveTypes {
  audienceReach = 'reach',
  leadGeneration = 'lead',
  webConversion = 'conversion'
}

export enum FiltersSelectTypes {
  is = 'is',
  isNot = 'is_not',
  between = 'between',
  lessThan = 'less_than',
  greaterThan = 'greater_than'
}

export const OprationsStrings: { [x: string]: string } = {
  is: 'Is',
  is_not: 'Is not',
  between: 'Between',
  less_than: 'Is <',
  greater_than: 'Is >'
};

export enum FilterStatusTypes {
  rejected = -2,
  incomplete = -1,
  draft = 0,
  pending = 1,
  running = 2,
  paused = 3,
  completed = 4,
  scheduled = 5
}

export enum CampaignStatusTypes {
  delivering = 1,
  notDelivering = 0
}

export enum CreativesStatusTypes {
  notactive = 0,
  active = 1
}

export enum InvoicesStatusTypes {
  error_charging = -1,
  charged_successfully = 3,
  refunded = 4,
  invoiced_manually = 5
}

export enum ActionButtonState {
  loading = 'loading',
  error = 'error',
  done = 'done',
  default = ''
}

export enum DeliveryRotationsTypes {
  even = 1,
  sequential = 2,
  weighted = 3
}
