import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

const Home = () => {
  const { isLoggedIn } = useAuth();
  const { search } = window.location;

  if (isLoggedIn) {
    return <Navigate replace to={`/overview${search}`} />;
  }
  return <Navigate replace to={`/login${search}`} />;
};

export default Home;
