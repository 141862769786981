import React from 'react';
import './LoadingScreen.scss';
import SpinningLoader from '../spinningloader';

const LoadingScreen = () => (
  <div className="campaign-hoc-loading-screen">
    <SpinningLoader />
  </div>
);

export default LoadingScreen;
