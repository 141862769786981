import React from 'react';
import { AdTypes } from '../../enums';
import videoImg from '../../public/assets/images/video.png';
import audioImg from '../../public/assets/images/audio.png';
import displayImg from '../../public/assets/images/display.png';

import './styles.scss';

type Props = {
  adType: AdTypes;
};

const AdTypeCell = function ({ adType }: Props) {
  let renderedComponent = null;

  switch (adType) {
    case AdTypes.video:
      renderedComponent = (
        <>
          <span className="AdTypeCell-img">
            <img src={videoImg} alt="video" />
          </span>
          <span>Video</span>
        </>
      );
      break;

    case AdTypes.display:
      renderedComponent = (
        <>
          <span className="AdTypeCell-img">
            <img src={displayImg} alt="video" />
          </span>
          <span>Display</span>
        </>
      );
      break;

    case AdTypes.audio:
      renderedComponent = (
        <>
          <span className="AdTypeCell-img">
            <img src={audioImg} alt="video" />
          </span>
          <span>Audio</span>
        </>
      );
      break;

    case AdTypes.song:
      renderedComponent = (
        <>
          <span className="AdTypeCell-img">
            <img src={audioImg} alt="video" />
          </span>
          <span>Song</span>
        </>
      );
      break;

    default:
      renderedComponent = <span>-</span>;
  }

  return <div className="AdTypeCell">{renderedComponent}</div>;
};

export default AdTypeCell;
