import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { PlusOutlined } from '@ant-design/icons';
import { AxiosSingleton } from '../../utils/axiosinstance';
import AdsTable from '../adstable';
import { reportListTableColumns } from './tableColumns';
import './styles.scss';

export default function ReportsList() {
  const axios = AxiosSingleton.axiosInstance;
  const [tableMeta, setTableMeta] = useState<any>();
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [isInfiniteLoading, setIsInfiniteLoading] = useState(false);

  const stringifyNumber = (n) => {
    if (n > 10 && n < 20) {
      return `${n}th`;
    }
    switch (n % 10) {
      case 1:
        return `${n}st`;
      case 2:
        return `${n}nd`;
      case 3:
        return `${n}rd`;
      default:
        return `${n}th`;
    }
  };

  const formatScheduleInfo = (scheduleType, scheduleAt, scheduleOn) => {
    if (scheduleType === 'daily') {
      return `Daily @ ${moment.utc(scheduleAt, 'HH:mm').local().format('hh:mm a')}`;
    }

    if (scheduleType === 'weekly' && scheduleOn) {
      return `Weekly on ${scheduleOn.charAt(0).toUpperCase() + scheduleOn.substring(1)} @ ${moment
        .utc(scheduleAt, 'HH:mm')
        .local()
        .format('hh:mm a')}`;
    }

    if (scheduleType === 'monthly' && scheduleOn) {
      return `Monthly on the ${stringifyNumber(scheduleOn)} @ ${moment
        .utc(scheduleAt, 'HH:mm')
        .local()
        .format('hh:mm a')}`;
    }

    return '-';
  };

  const formatReportsList = (reports) =>
    reports.map((report) => ({
      ...report,
      report_dates:
        report.date_identifier === 'previous'
          ? `${
              report.date_identifier.charAt(0).toUpperCase() + report.date_identifier.substring(1)
            } ${report.date_count} ${report.date_type}`
          : `From ${report.start_date} to ${report.end_date}`,
      schedule_info: formatScheduleInfo(
        report.schedule_type,
        report.schedule_at,
        report.schedule_on
      ),
      type: `${report.type.charAt(0).toUpperCase() + report.type.substring(1)}`,
      reportCopy: report // very hacky way of adding a a copy of the report inside itself to be accessed by the table, should allow table to read from multiple object keys
    }));

  useEffect(() => {
    axios
      .get('/report/all', {
        params: {
          page
        }
      })
      .then((res) => {
        const { data } = res;
        setTableMeta(data);
        const updatedTableData = formatReportsList([...tableData, ...data.data]);
        setTableData(updatedTableData);
        setIsInfiniteLoading(false);
      });
  }, [page]);

  return (
    <div className="reports-list-container">
      <div className="reports-list-header">
        <div className="reports-list-header-actions">
          <div className="reports-list-header-heading">Reports</div>
          <button
            type="button"
            className="reports-list-header-button"
            onClick={() => {
              window.open('/reports/generate');
            }}
          >
            <PlusOutlined />
            NEW REPORT
          </button>
        </div>
      </div>
      {tableData ? (
        <AdsTable
          data={tableData}
          tableColumns={reportListTableColumns}
          onFetch={() => {
            if (tableData.length >= tableMeta.total) return;
            setIsInfiniteLoading(true);
            setPage(page + 1);
          }}
          isLoading={tableData.length === 0}
          isInfiniteLoading={isInfiniteLoading}
          rowSelection={false}
        />
      ) : null}
    </div>
  );
}
