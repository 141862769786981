import React from 'react';
import './styles.scss';

export type ErrorPageProps = {
  title: string;
  subTitle?: string;
  img: string;
  ctaTitle: string;
  ctaFunc: () => void;
  marginTop?: string;
};

const ErrorPage = ({
  title,
  subTitle,
  img,
  ctaTitle,
  ctaFunc,
  marginTop = '-4rem'
}: ErrorPageProps) => (
  <div className="ErrorPage" style={{ marginTop }}>
    <img className="ErrorPage-img" src={img} alt="" />

    <span className="ErrorPage-title">{title}</span>

    {subTitle ? <span className="ErrorPage-subtitle">{subTitle}</span> : null}

    <button type="button" className="ErrorPage-btn" onClick={ctaFunc}>
      {ctaTitle}
    </button>
  </div>
);

export default ErrorPage;
