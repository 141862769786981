import React, { useEffect, useRef } from 'react';

type Props = {
  cover: string;
};

export default function SponsoredPlaylistsPlacementPreview({ cover }: Props) {
  const homepageImage = useRef(null);
  const homepageCanvas = useRef(null);
  const searchImage = useRef(null);
  const searchCanvas = useRef(null);
  const tagImage = useRef(null);
  const tagCanvas = useRef(null);
  const cardImage = useRef(null);
  const cardCanvas = useRef(null);

  const roundedImage = (ctx, x, y, width, height, radius) => {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
  };

  const drawPlacement = (canvas, image, coverImg, x, y, width, height, radius) => {
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d');
    context.drawImage(image, 0, 0);
    roundedImage(context, x, y, width, height, radius);
    context.clip();
    context.drawImage(coverImg, x, y, width, height);
    canvas.style.width = '70%';
  };

  useEffect(() => {
    if (cover) {
      const coverImg = new Image();
      coverImg.src = cover;
      coverImg.onload = () => {
        drawPlacement(
          homepageCanvas.current,
          homepageImage.current,
          coverImg,
          67,
          551,
          840,
          856,
          20
        );
        drawPlacement(searchCanvas.current, searchImage.current, coverImg, 9, 139, 76, 82, 10);
        drawPlacement(tagCanvas.current, tagImage.current, coverImg, 192, 423, 172, 167, 10);
        drawPlacement(cardCanvas.current, cardImage.current, coverImg, 193, 459, 141, 151, 10);
      };
    }
  }, [cover]);

  return (
    <div className="placement-preview-container">
      <div className="placement-preview-element">
        <canvas ref={homepageCanvas}>
          <img
            ref={homepageImage}
            src="https://anghdash.anghcdn.co/commercial-promo/homepage.png"
          />
        </canvas>
      </div>
      <div className="placement-preview-element">
        <canvas ref={searchCanvas}>
          <img ref={searchImage} src="https://anghdash.anghcdn.co/commercial-promo/search.png" />
        </canvas>
      </div>
      <div className="placement-preview-element">
        <canvas ref={tagCanvas}>
          <img ref={tagImage} src="https://anghdash.anghcdn.co/commercial-promo/tag.png" />
        </canvas>
      </div>
      <div className="placement-preview-element">
        <canvas ref={cardCanvas}>
          <img
            ref={cardImage}
            src="https://anghdash.anghcdn.co/commercial-promo/homepage-card.png"
          />
        </canvas>
      </div>
    </div>
  );
}
