import React, { useContext, useEffect, useState } from 'react';
import { AxiosSingleton } from '../../utils/axiosinstance';
import { message } from '../../utils/custommessage';
import AdSetCreative from '../adsetcreative';
import AdSetSetup from '../adsetsetup';
import AdSetTargeting from '../adsettargeting';
import { IAdSet } from '../campaignhoc';
import { campaignContext } from '../campaignhoc/campaign.context';
import { AdSetNavigation } from '../../enums/campaignnavigation';
import { logAmplitudeEvent } from '../../utils/analytics';
import { CampaignAmplitudeEvents } from '../../enums/campaignamplitudeevents';
import { getTargetImpressions } from '../../helpers/campaign-form-helper';
import { mediumOptions } from '../adsettargeting/selectorOptions';

type Props = {
  mode: string;
  adSet: IAdSet;
  index: number;
};

export default function AdSet({ mode, adSet, index }: Props) {
  const axios = AxiosSingleton.axiosInstance;
  const {
    groupCampaignId,
    groupCampaignObjective,
    advertiserId,
    competitorId,
    agencyId,
    industryId,
    updateAdSetValue,
    navigationData,
    setNavigationData,
    publisherId,
    specialPublishers
  } = useContext(campaignContext);

  const [isTargetingLoading, setIsTargetingLoading] = useState(false);
  const [shouldBlockAdTypeChanges, setShouldBlockAdTypeChanges] = useState(Boolean(adSet.adSetId));

  useEffect(() => {
    setShouldBlockAdTypeChanges(Boolean(adSet.adSetId));
  }, [adSet.adSetId]);

  const saveAudienceProgress = async (audienceParams) => {
    const audienceEndpoint = `audiences/${adSet.adSetAudienceId ? 'update' : 'store'}`;

    setIsTargetingLoading(true);

    try {
      const { data } = await axios.post(audienceEndpoint, audienceParams);

      updateAdSetValue({
        id: adSet.id,
        key: 'adSetAudienceId',
        value: data
      });
    } catch (err) {
      message.error(err.response.data.message);
      setIsTargetingLoading(false);
    }
  };

  const savetargetingProgress = async (audienceParams) => {
    const adSetParams = {
      id: adSet.adSetId,
      name: adSet.adSetName,
      start_date: adSet.adSetStartDate,
      end_date: adSet.adSetEndDate,
      priority: adSet.adSetPriority,
      target_impressions: getTargetImpressions(
        adSet.adSetTargetImpressions,
        adSet.adSetPaidImpressions,
        adSet.adSetFreeImpressions,
        adSet.adSetShowFirstToggled
      ),
      paid_impressions: adSet.adSetPaidImpressions,
      free_impressions: adSet.adSetFreeImpressions,
      streams_based: adSet.adSetToggleTargetStreams,
      target_streams: adSet.adSetShowFirstToggled ? 0 : adSet.adSetTargetStreams,
      delivery_rotation: adSet.adSetDeliveryRotation,
      frequencies:
        adSet.adSetFrequencies.length > 0
          ? adSet.adSetFrequencies.map((freq) => `${freq.count}:${freq.duration}`).join(',')
          : '',
      budget: adSet.adSetBudget,
      show_first: adSet.adSetShowFirstToggled,
      audience_id: adSet.adSetAudienceId,
      feature_support: adSet.adSetFeatureSupport, // for anghami and dms
      playlists: adSet.adSetPlaylists, // for dms
      objective: groupCampaignObjective,
      type: adSet.adSetType,
      ad_type: adSet.adSetAdType,
      booking_number: adSet.adSetBookingNumber, // dms
      booking_value: adSet.adSetBookingValue, // dms
      booking_value_currency: adSet.adSetBookingValueCurrency, // dms
      advertiser_id: advertiserId,
      competitor_id: competitorId,
      agency_id: agencyId,
      industry_id: industryId,
      group_campaign_id: groupCampaignId,
      start_time: adSet.adSetStartTime, // for scheduled ad sets
      end_time: adSet.adSetEndTime, // for scheduled ad sets
      audience: audienceParams,
      progress: 1, // send 1 when saving progress
      adplanner: 1, // send 1 when saving progress
      publisher_id: specialPublishers.find((p) => p.id === publisherId.toString())
        ? publisherId
        : undefined
    };

    const adSetEndpoint = `campaigns/${adSet.adSetId ? 'update' : 'store'}`;

    try {
      const { data } = await axios.post(adSetEndpoint, adSetParams);

      updateAdSetValue({
        id: adSet.id,
        key: 'adSetId',
        value: data
      });

      logAmplitudeEvent(CampaignAmplitudeEvents.CreateCampaignProceed, {
        new: window.location.href.indexOf('create') > -1 ? 1 : 0,
        campaign_objective: groupCampaignObjective,
        campaign_type: adSet.adSetType,
        step: 'setup',
        objectid: adSet.adSetId
      });

      setNavigationData({
        ...navigationData,
        navigationAdSetSublevel: 'creative'
      });

      setIsTargetingLoading(false);
      setShouldBlockAdTypeChanges(true);
    } catch (err) {
      message.error(err.response.data.message);
      setIsTargetingLoading(false);
    }
  };

  const saveAudienceAndTargetingProgress = async () => {
    if (mode === 'preview') {
      setNavigationData({
        ...navigationData,
        navigationAdSetSublevel: 'creative',
        navigationCreativeActiveId: 0
      });
      return;
    }

    const audienceParams = adSet.adSetFeatureSupport
      ? {}
      : {
          id: adSet.adSetAudienceId,
          name: `AUDIENCE - ${adSet.adSetName} - ${Math.floor(Math.random() * 9999 + 1000)}`,
          gender: adSet.audienceGender.join(','),
          age_min: adSet.audienceMinAge,
          age_max: adSet.audienceMaxAge,
          countries: adSet.audienceCountries.join(','), // comma separated array values
          cities: adSet.audienceCities.join(','), // comma separated array values
          mobile_operators: adSet.audienceMobileOperators.join(','),
          medium: adSet.audienceMedium,
          platforms:
            adSet.audienceMedium === mediumOptions[0].id ? adSet.audiencePlatforms.join(',') : '', // comma separated array values
          app_language: adSet.audienceAppLanguage.join(','), // comma separated array values
          music_preference: adSet.audienceMusicPreference.join(','), // comma separated array values
          retargeting: adSet.audienceRetargeting,
          adplanner: 1, // send 1 to get the id in the response
          publisher_id: specialPublishers.find((p) => p.id === publisherId.toString())
            ? publisherId
            : undefined
        };

    if (!adSet.adSetFeatureSupport) {
      await saveAudienceProgress(audienceParams);
    }

    await savetargetingProgress(audienceParams);
  };

  return (
    <div className="adset">
      <div>
        {navigationData.navigationAdSetSublevel === AdSetNavigation.Setup && (
          <AdSetSetup
            mode={mode}
            adSet={adSet}
            index={index}
            shouldBlockAdTypeChanges={shouldBlockAdTypeChanges}
          />
        )}
      </div>
      <div>
        {navigationData.navigationAdSetSublevel === AdSetNavigation.Targeting && (
          <AdSetTargeting
            adSet={adSet}
            saveAudienceAndTargetingProgress={saveAudienceAndTargetingProgress}
            isTargetingLoading={isTargetingLoading}
          />
        )}
      </div>
      <div>
        {navigationData.navigationAdSetSublevel === AdSetNavigation.Creative && (
          <AdSetCreative adSet={adSet} />
        )}
      </div>
    </div>
  );
}
