import React, { useState, useEffect } from 'react';
import { Link, Navigate, useLocation, useSearchParams } from 'react-router-dom';
import AnghamiAdsBetaLogo from '../../public/assets/images/Ads-Logo-Dark.png';
import { AxiosSingleton } from '../../utils/axiosinstance';
import useInterval from '../../hooks/useInterval';
import useAuth from '../../hooks/useAuth';

export default function Verification() {
  const axios = AxiosSingleton.axiosInstance;
  const { state } = useLocation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const fromLogin = searchParams.get('fromlogin');
  // used to check if user coming from email is already logged in or not
  // cz we dont have access to useAuth hook in auth pages
  const apiToken = window.localStorage.getItem('ads_token');
  const { setUser } = useAuth();

  const resendEmail = () => {
    axios.post('/send-verification', {}).then(() => null);
  };

  const handleVerificationResponse = (data: any) => {
    setError(null);
    if (data.verified) {
      setIsSuccess(true);
      setUser(
        {
          verified: 1
        },
        true
      );
    } else if (data?.error) {
      setError(data?.error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const verifyEmail = (token?: string | null) => {
    const apiEndpoint = token ? 'verify-token' : 'check-verified';
    if (token) {
      const params = {
        token
      };
      axios.post(`/${apiEndpoint}`, params).then((res: any) => {
        const { data } = res;
        handleVerificationResponse(data);
      });
    } else {
      axios.get(`/${apiEndpoint}`).then((res: any) => {
        const { data } = res;
        handleVerificationResponse(data);
      });
    }
  };

  const renderPendingVerification = () => (
    <div className="auth-container">
      <div className="auth-logo">
        <img src={AnghamiAdsBetaLogo} alt="Anghami Beta Ads" />
      </div>
      <div className="auth-title">Verify your email</div>
      <div className="auth-subtitle">
        We&apos;ve sent you an email on <strong>{(state as any)?.email}</strong> to verify your
        account
      </div>
      <div className="auth-subtitle">
        Didn&apos;t receive email?
        <span className="resend-email" onClick={resendEmail}>
          Resend!
        </span>
      </div>
      <div className="auth-footer">
        <div className="auth-footer-text">Have another account?</div>
        <Link to="/login">
          <button className="auth-secondary-btn" type="button">
            Sign In
          </button>
        </Link>
      </div>
    </div>
  );

  const renderVerificationResponse = () => {
    let verificationResponse: React.ReactElement;

    if (error) {
      verificationResponse = (
        <>
          <div className="error-title">{error}</div>
          <Link to="/">
            <button className="verify-success-login-btn auth-primary-btn" type="button">
              Try again
            </button>
          </Link>
        </>
      );
    } else {
      verificationResponse = (
        <>
          <div className="verify-success-msg">Your email has been successfully verified.</div>
          {apiToken ? (
            <Navigate to="/overview" replace />
          ) : (
            <Link to="/login">
              <button className="verify-success-login-btn auth-primary-btn" type="button">
                Login to Continue
              </button>
            </Link>
          )}
        </>
      );
    }

    return <div className={`verify-container ${error ? 'error' : ''}`}>{verificationResponse}</div>;
  };

  useEffect(() => {
    if (fromLogin) {
      resendEmail();
    }
    verifyEmail(token);
  }, []);

  useInterval(() => {
    if (!isSuccess && !error) {
      verifyEmail(token);
    }
  }, 10000);

  return <>{isSuccess || error ? renderVerificationResponse() : renderPendingVerification()}</>;
}
