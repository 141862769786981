export const availableCountries = [
  { id: 'all', text: 'All Countries' },
  { id: 'AE', text: 'United Arab Emirates' },
  { id: 'SA', text: 'Saudi Arabia' },
  { id: 'KW', text: 'Kuwait' },
  { id: 'BH', text: 'Bahrain' },
  { id: 'OM', text: 'Oman' },
  { id: 'QA', text: 'Qatar' },
  { id: 'EG', text: 'Egypt' },
  { id: 'SS', text: 'South Sudan' },
  { id: 'SD', text: 'Sudan' },
  { id: 'LB', text: 'Lebanon' },
  { id: 'JO', text: 'Jordan' },
  { id: 'PS', text: 'Palestine' },
  { id: 'SY', text: 'Syria' },
  { id: 'IQ', text: 'Iraq' },
  { id: 'DZ', text: 'Algeria' },
  { id: 'LY', text: 'Libya' },
  { id: 'MA', text: 'Morocco' },
  { id: 'TN', text: 'Tunisia' },
  { id: 'ROW', text: 'Rest of the World' }
];


export const groupByOptions = [
  { id: 'total', text: 'Total' },
  { id: 'ad_type', text: 'Ad Type' }
];

export const adTypeOptions = [
  { id: 'all', text: 'All' },
  { id: 'excluding', text: 'Exclude Display' }
];

