import { FiltersSelectTypes } from '../../enums';

export enum FilterTypes {
  multiselect = 'multiselect',
  bool = 'bool',
  date = 'date',
  number = 'number',
  numberInArray = 'numberInArray',
  multiNumbers = 'multiNumbers'
}

export type FilterValue = {
  value: string[] | number[] | string;
  operator: string;
  min?: string;
  max?: string;
};

type Options = {
  id?: string | number;
  text: string;
  children?: Options[];
};

export interface IFilter {
  id: string;
  title: string;
  type: FilterTypes;
  default: string | boolean | any;
  options?: Options[];
  select: FiltersSelectTypes[];
  defaultSelect: FiltersSelectTypes;
  hideSelect: boolean;
  hideSearch: boolean;
  initiallyHideDropdown?: boolean;
  lazyLoad?: boolean;
  width?: number;
  value?: FilterValue;
}

const countries: Options[] = [
  {
    text: 'GCC',
    children: [
      { id: 'AE', text: 'United Arab Emirates' },
      { id: 'SA', text: 'Saudi Arabia' },
      { id: 'KW', text: 'Kuwait' },
      { id: 'BH', text: 'Bahrain' },
      { id: 'OM', text: 'Oman' },
      { id: 'QA', text: 'Qatar' }
    ]
  },
  {
    text: 'North Africa',
    children: [
      { id: 'EG', text: 'Egypt' },
      { id: 'SS', text: 'South Sudan' },
      { id: 'SD', text: 'Sudan' }
    ]
  },
  {
    text: 'Levant',
    children: [
      { id: 'LB', text: 'Lebanon' },
      { id: 'JO', text: 'Jordan' },
      { id: 'PS', text: 'Palestine' },
      { id: 'SY', text: 'Syria' }
    ]
  },
  {
    text: 'Iraq',
    children: [{ id: 'IQ', text: 'Iraq' }]
  },
  {
    text: 'Maghreb',
    children: [
      { id: 'DZ', text: 'Algeria' },
      { id: 'LY', text: 'Libya' },
      { id: 'MA', text: 'Morocco' },
      { id: 'TN', text: 'Tunisia' }
    ]
  },
  {
    text: 'Other',
    children: [{ id: 'ROW', text: 'Rest of the World' }]
  }
];

export const filters: IFilter[] = [
  {
    id: 'publisher',
    title: 'Publisher',
    type: FilterTypes.multiselect,
    default: '',
    options: [],
    select: [FiltersSelectTypes.is, FiltersSelectTypes.isNot],
    defaultSelect: FiltersSelectTypes.is,
    hideSelect: false,
    hideSearch: false,
    lazyLoad: true,
    width: 21.875
  },
  {
    id: 'status',
    title: 'Status',
    type: FilterTypes.multiselect,
    default: '',
    options: [
      {
        id: 0,
        text: 'Draft'
      },
      {
        id: 1,
        text: 'Pending'
      },
      {
        id: 2,
        text: 'Running'
      },
      {
        id: 3,
        text: 'Paused'
      },
      {
        id: 5,
        text: 'Scheduled'
      },
      {
        id: -1,
        text: 'Incomplete'
      },
      {
        id: 4,
        text: 'Completed'
      }
    ],
    select: [FiltersSelectTypes.is, FiltersSelectTypes.isNot],
    defaultSelect: FiltersSelectTypes.is,
    hideSelect: false,
    hideSearch: false,
    width: 21.875
  },
  {
    id: 'country',
    title: 'Country',
    type: FilterTypes.multiselect,
    default: '',
    options: countries,
    select: [FiltersSelectTypes.is, FiltersSelectTypes.isNot],
    defaultSelect: FiltersSelectTypes.is,
    hideSelect: false,
    hideSearch: false,
    width: 21.875
  },
  {
    id: 'advertiser',
    title: 'Advertiser',
    type: FilterTypes.multiselect,
    default: '',
    options: [],
    select: [FiltersSelectTypes.is, FiltersSelectTypes.isNot],
    defaultSelect: FiltersSelectTypes.is,
    hideSelect: false,
    hideSearch: false,
    lazyLoad: true,
    width: 21.875
  },
  {
    id: 'ad_product',
    title: 'Ad Product',
    type: FilterTypes.multiselect,
    default: '',
    options: [
      {
        id: 'audio',
        text: 'Audio Ad'
      },
      {
        id: 'display',
        text: 'Display Ad'
      },
      {
        id: 'video',
        text: 'Video Ad'
      },
      {
        id: 'song',
        text: 'Song Ad'
      }
    ],
    select: [FiltersSelectTypes.is, FiltersSelectTypes.isNot],
    defaultSelect: FiltersSelectTypes.is,
    hideSelect: false,
    hideSearch: false,
    width: 18.75
  },
  {
    id: 'start_date',
    title: 'Start Date',
    type: FilterTypes.date,
    default: '',
    select: [
      FiltersSelectTypes.between,
      FiltersSelectTypes.lessThan,
      FiltersSelectTypes.greaterThan
    ],
    defaultSelect: FiltersSelectTypes.lessThan,
    hideSelect: false,
    hideSearch: true,
    width: 26.875
  },
  {
    id: 'end_date',
    title: 'End Date',
    type: FilterTypes.date,
    default: '',
    select: [
      FiltersSelectTypes.between,
      FiltersSelectTypes.lessThan,
      FiltersSelectTypes.greaterThan
    ],
    defaultSelect: FiltersSelectTypes.lessThan,
    hideSelect: false,
    hideSearch: true,
    width: 25
  },
  {
    id: 'objective',
    title: 'Objective',
    type: FilterTypes.multiselect,
    default: '',
    options: [
      {
        id: 'reach',
        text: 'Audience Reach'
      },
      {
        id: 'conversion',
        text: 'Web Conversion'
      },
      {
        id: 'lead',
        text: 'Lead Generation'
      }
    ],
    select: [FiltersSelectTypes.is, FiltersSelectTypes.isNot],
    defaultSelect: FiltersSelectTypes.is,
    hideSelect: false,
    hideSearch: false,
    width: 21.875
  },
  {
    id: 'archived',
    title: 'Archived',
    type: FilterTypes.bool,
    default: 'true',
    select: [FiltersSelectTypes.is, FiltersSelectTypes.isNot],
    defaultSelect: FiltersSelectTypes.is,
    hideSelect: true,
    hideSearch: true,
    width: 9.375
  },
  {
    id: 'underdelivering',
    title: 'Underdelivering',
    type: FilterTypes.bool,
    default: 'true',
    select: [FiltersSelectTypes.is, FiltersSelectTypes.isNot],
    defaultSelect: FiltersSelectTypes.is,
    hideSelect: true,
    hideSearch: true,
    width: 13.125
  },
  {
    id: 'budget',
    title: 'Budget',
    type: FilterTypes.number,
    default: '',
    select: [
      FiltersSelectTypes.is,
      FiltersSelectTypes.isNot,
      FiltersSelectTypes.lessThan,
      FiltersSelectTypes.greaterThan
    ],
    defaultSelect: FiltersSelectTypes.is,
    hideSelect: false,
    hideSearch: true,
    width: 15.625
  },
  {
    id: 'campaign_id',
    title: 'Ad Set ID',
    type: FilterTypes.multiNumbers,
    default: undefined,
    select: [FiltersSelectTypes.is, FiltersSelectTypes.isNot],
    defaultSelect: FiltersSelectTypes.is,
    hideSelect: false,
    hideSearch: true,
    width: 17.5
  },
  {
    id: 'user_id',
    title: 'Created By',
    type: FilterTypes.multiselect,
    default: '',
    select: [FiltersSelectTypes.is, FiltersSelectTypes.isNot],
    defaultSelect: FiltersSelectTypes.is,
    hideSelect: false,
    hideSearch: true,
    width: 21
  }
];
