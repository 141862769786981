import React from 'react';
import './auth.scss';

type Props = {
  children: any;
};

export default function AuthPage({ children }: Props) {
  return <div className="auth-page">{children}</div>;
}
