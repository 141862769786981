import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AmplitudeEvents } from '@anghami/neoanalytics/dist/enums/events';
import Auth from './components/auth';
import { logAmplitudeEvent, logGoogleAnalyticsPageview } from './utils/analytics';
import AppRoutes from './routes';
import ErrorBoundary from './components/error-boundary';

function App() {
  const location = useLocation();

  useEffect(() => {
    logAmplitudeEvent(AmplitudeEvents.openApp, {
      pageUrl: window.location.href
    });
  }, []);

  useEffect(() => {
    logGoogleAnalyticsPageview(location.pathname);
  }, [location.pathname]);

  return (
    <ErrorBoundary>
      <Auth>
        <AppRoutes />
      </Auth>
    </ErrorBoundary>
  );
}

export default App;
