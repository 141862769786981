import { createContext } from 'react';
import { PublisherRoles } from '../../enums/publisherroles';

export interface User {
  id: number;
  name: string;
  email: string;
  publisher_id: number;
  publisher_name: string;
  publisher_type: string[];
  active: number;
  advertiser_id: number;
  role_id: number;
  role_name: string;
  role: {
    id: number;
    name: string;
    permissions: Array<any>;
  };
  billing: {
    has_billing: boolean;
    billing_expired: boolean;
    billing_expire_soon: boolean;
    can_use_coupon: boolean;
    coupon_used: boolean;
    can_view_billing: boolean;
    topup_coupon: number;
  };
  can_manage_reports: number;
  can_view_invoices: number;
  can_view_inventory: number;
  created_at: Date;
  deleted_at: Date;
  updated_at: Date;
  verified: boolean;
}

export interface UserResponse {
  access_token?: string;
  expires_in?: number;
  message?: string;
  permissions?: any; // for now
  token_type?: string;
  user?: User;
  verified?: number;
}

export interface Auth {
  user: User | null;
  setUser: (user: UserResponse, appendOldUser?: boolean) => void;
  role: string;
  isLoggedIn: boolean;
  setIsLoggedIn: (forceLogin: boolean) => void;
  setShowProfileModal: (state: any) => void;
  logout: () => void;
}

const userContext = createContext<Auth>({
  user: null,
  setUser: () => null,
  role: PublisherRoles.Unknown,
  isLoggedIn: false,
  setIsLoggedIn: () => null,
  setShowProfileModal: () => null,
  logout: () => null
});

export { userContext };
