/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal, Form, Input } from 'antd';
import { AmplitudeEvents } from '@anghami/neoanalytics/dist/enums/events';
import billingCCImg from '../../public/assets/images/billing/billing-cc-icon.png';
import { AxiosSingleton } from '../../utils/axiosinstance';
import { getCheckoutData, createBilling, updateBilling } from '../../helpers/payment-helper';
import './styles.scss';
import PaymentForm from '../payment-form';
import SpinningLoader from '../spinningloader';
import useAuth from '../../hooks/useAuth';
import {
  IBillingCard,
  IBillingParams,
  ICardParams,
  IPersonalFieldError,
  IPersonalInfo
} from '../../interfaces/billing.interface';
import { hasAllValid, hasAtLeastOneValidValue, hasSameData } from '../../utils/utils';
import { message } from '../../utils/custommessage';
import { Messages } from '../../enums/messages';
import { logAmplitudeEvent } from '../../utils/analytics';
import { BillingModalContext } from '../basepage';

interface IError {
  message?: string;
  isTryAgain?: boolean;
}

interface INewValue {
  [key: string]: any;
  firstname?: string;
  lastname?: string;
  billing_address?: string;
  cardParams?: ICardParams;
}

const BillingModal = () => {
  const axios = AxiosSingleton.axiosInstance;
  const { user } = useAuth();
  const location = useLocation();
  const { showBillingModal, setShowBillingModal } = useContext(BillingModalContext);

  const [originPersonalInfo, setOriginPersonalInfo] = useState<IPersonalInfo>({});
  const [personalInfo, setPersonalInfo] = useState<IPersonalInfo>({});
  const [fieldErrors, setFieldErrors] = useState<IPersonalFieldError>(null);
  const [cardParams, setCardParams] = useState<ICardParams>(null);

  const [billingCard, setBillingCard] = useState<IBillingCard>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [isEmptyCCForm, setIsEmptyCCForm] = useState(true);
  const [mainError, setMainError] = useState<IError>(null);

  const [isUpdateState, setIsUpdateState] = useState(false);
  const [errors, setErrors] = useState<string[]>(null);

  const fetchBillingInfo = () => {
    setIsLoading(true);
    setMainError(null);
    axios
      .get('/billing/get')
      .then((res) => {
        const { data } = res;
        if (data?.data) {
          const info: IPersonalInfo = {
            firstname: data.data.firstname,
            lastname: data.data.lastname,
            billing_address: data.data.billing_address
          };
          if (data.data?.cc_info) {
            setBillingCard(data.data?.cc_info);
          }
          // We need to check if the user is updating his info or creating his billing
          const hasPersonalInfo = hasAtLeastOneValidValue(info);
          setIsUpdateState(hasPersonalInfo);

          setOriginPersonalInfo({ ...info });
          setPersonalInfo({ ...info });
          setIsLoading(false);
          logAmplitudeEvent(AmplitudeEvents.openUpdateBillingInfo, {});
        }
      })
      .catch(() => {
        setMainError({
          message: 'Oops, something went wrong! Please try again.',
          isTryAgain: true
        });
        setIsLoading(false);
      });
  };

  /**
   * @description loops over personal info details (firstname, lastname, billing_address)
   * @param newValue
   * @returns
   */
  const isValidPersonalInfo = (newValue: INewValue) => {
    if (!newValue?.cardParams) {
      const data: IPersonalInfo = {
        ...personalInfo,
        ...newValue
      };
      const hasValidInfo = hasAllValid(data);
      return hasValidInfo;
    }
    return true;
  };

  const isValidCardForm = (newValue: INewValue) => {
    if (cardParams && newValue?.cardParams) {
      const data: ICardParams = {
        ...cardParams,
        ...newValue.cardParams
      };

      // Weird edge case was causing validation to fail, need to check all this logic again later
      if (data.expiry_year) {
        data.expiry_year = String(data.expiry_year);
      }

      const hasValidData = hasAllValid(data);
      return hasValidData;
    }
    return false;
  };

  /**
   * @description checks whether a change has happened in personal info;
   * compares new value of input with the original personalInfo state (originPersonalInfo)
   * @param newValue
   * @returns
   */
  const isPersonalInfoChanged = (newValue: INewValue) =>
    Boolean(!newValue?.cardParams && hasSameData(newValue, originPersonalInfo));

  const isValidInput = (value) =>
    Boolean(/^[a-zA-Z\-\. ]+$/.test(value) || value === '' || /^[\u0621-\u064A\040]+$/.test(value));

  /**
   * @description Validates if all conditions check to disable the save changes button
   * and be able to proceed with a billing
   * @param errFields input fields' errors, it should be null
   * @param newValue can be a new value in personal info details, or new cc info
   * @param isEmptyForm flag to check if the card form is empty or not
   * (sometimes it might not be empty but with errors, in this case we should not enable to save changes button)
   */
  const validateBilling = (errFields, newValue: INewValue, isEmptyForm = isEmptyCCForm) => {
    const inputErrs = errFields && Object.keys(errFields).filter((key) => errFields[key]);
    const isValidInfo = isValidPersonalInfo(newValue);

    if ((!inputErrs || inputErrs?.length === 0) && isValidInfo) {
      if (isUpdateState) {
        // validations:
        // empty form with changes in personal info -> btn should be enabled
        // not empty form -> check if valid changes in cc form and personal info

        setIsBtnDisabled(
          isEmptyForm ? !isPersonalInfoChanged(newValue) : !isValidCardForm(newValue)
        );
      } else {
        setIsBtnDisabled(false);
      }
    } else {
      setIsBtnDisabled(true);
    }
  };

  const onInputChange = (e) => {
    const errLst = {
      ...fieldErrors
    };
    if (isValidInput(e.target.value)) {
      errLst[e.target.name] = null;
      setPersonalInfo({
        ...personalInfo,
        [e.target.name]: e.target.value
      });
    } else {
      errLst[e.target.name] = 'Invalid data';
    }
    setFieldErrors(errLst);
    validateBilling(errLst, { [e.target.name]: e.target.value || '' });
  };

  const handleBillingResponse = (response) => {
    const { data } = response;
    if (data?.error) {
      setMainError({
        message: data?.error,
        isTryAgain: true
      });
      setIsBtnLoading(false);
      logAmplitudeEvent(AmplitudeEvents.submitBillingInfo, {
        status: 'failed',
        error_message: data?.error
      });
    } else {
      setShowBillingModal(false);
      message.success(Messages.UpdatedSuccessfully);
      logAmplitudeEvent(AmplitudeEvents.submitBillingInfo, {
        status: 'success'
      });
      setTimeout(() => {
        if (location.pathname.indexOf('/overview') > -1) {
          window.location.reload();
        }
      }, 3000);
    }
  };

  const updateBillingInfo = (params: IBillingParams) => {
    updateBilling(params)
      .then((res) => {
        handleBillingResponse(res);
      })
      .catch((_err) => {
        const { response } = _err;
        handleBillingResponse(response);
      });
  };

  const createBillingInfo = (params: IBillingParams) => {
    createBilling(params)
      .then((res) => {
        handleBillingResponse(res);
      })
      .catch((_err) => {
        const { response } = _err;
        handleBillingResponse(response);
      });
  };

  const submitBilling = async () => {
    setErrors(null);
    if (!isBtnLoading) {
      setIsBtnLoading(true);
      if (isUpdateState && !cardParams) {
        updateBillingInfo({ ...personalInfo });
      } else {
        getCheckoutData(cardParams).then((response) => {
          if (response?.data?.errors) {
            setErrors(response?.data?.errors);
            setIsBtnLoading(false);
          } else {
            const billingParams: IBillingParams = {
              ...personalInfo,
              checkout_token: response?.data?.token,
              card: {
                expiryMonth:  response?.data?.expiry_month,
                expiryYear: response?.data?.expiry_year,
                last4: response?.data?.last4,
                bin: response?.data?.bin,
                paymentMethod: response?.data?.card_type,
                name: response?.data?.name,
                id: response?.data?.bin
              },
            };
            if (isUpdateState) {
              updateBillingInfo(billingParams);
            } else {
              createBillingInfo(billingParams);
            }
          }
        });
      }
    }
  };

  const setCheckoutParams = (data) => {
    let checkoutParams: any = {
      country: user?.publisher?.country || ''
    };

    if (data?.card) {
      checkoutParams = {
        ...checkoutParams,
        ...data.card,
        number: data.card.number.replace(/\s/g, '').trim()
      };
    }
    setIsEmptyCCForm(data.isEmptyForm);
    const params: ICardParams = data.card ? checkoutParams : {};
    setCardParams(params);
    validateBilling(fieldErrors, { cardParams: params }, data?.isEmptyForm);
  };

  useEffect(() => {
    fetchBillingInfo();
  }, []);

  return (
    <Modal
      className={`billing-info-container ${isLoading ? 'loading' : ''}`}
      visible={showBillingModal}
      closable
      maskClosable={false}
      footer={null}
      onCancel={() => setShowBillingModal(false)}
    >
      <div className="close-txt">Close</div>
      <img src={billingCCImg} className="billing-cc-img" alt="" />
      <div className="billing-modal-container flexbox colls start">
        <div className="billing-modal-title">Update your billing info</div>
        {isLoading ? (
          <SpinningLoader />
        ) : mainError ? (
          <div className="main-error-wrapper flexbox colls centered">
            <div className="main-err">{mainError?.message}</div>
            {mainError?.isTryAgain && (
              <button
                type="button"
                className="Billing-button"
                onClick={() => {
                  fetchBillingInfo();
                }}
              >
                Try again
              </button>
            )}
          </div>
        ) : (
          <>
            <div className="errors-wrapper">
              {errors?.map((error) => (
                <div key={error} className="main-err-msg">
                  {error}
                </div>
              ))}
            </div>
            <Form onFinish={submitBilling}>
              <div className="billing-form-container flexbox corners">
                <div className="personal-info-container">
                  <div className="billing-info-title">Personal Information</div>
                  <div className={`input-container ${fieldErrors?.firstname ? 'warning' : ''}`}>
                    <div className="flexbox corners">
                      <label>first name*</label>
                      {fieldErrors?.firstname && (
                        <div className="input-err-msg">{fieldErrors?.firstname}</div>
                      )}
                    </div>
                    <Input
                      value={personalInfo?.firstname}
                      placeholder="Your first name here"
                      name="firstname"
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                    />
                  </div>
                  <div className={`input-container ${fieldErrors?.lastname ? 'warning' : ''}`}>
                    <div className="flexbox corners">
                      <label>last name*</label>
                      {fieldErrors?.lastname && (
                        <div className="input-err-msg">{fieldErrors?.lastname}</div>
                      )}
                    </div>
                    <Input
                      value={personalInfo?.lastname}
                      placeholder="Your last name here"
                      name="lastname"
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                    />
                  </div>
                  <div className="input-container">
                    <label>address*</label>
                    <Input
                      value={personalInfo?.billing_address}
                      placeholder="Your billing address goes here"
                      onChange={(e) => {
                        setPersonalInfo({
                          ...personalInfo,
                          billing_address: e.target.value
                        });
                        validateBilling(fieldErrors, { billing_address: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="cc-info-container">
                  <PaymentForm
                    data={billingCard}
                    onSubmitCardInfo={(e) => {
                      setCheckoutParams(e);
                    }}
                    onError={(e) => {
                      setMainError({
                        message: e?.error || 'Oops, something went wrong! Please try again'
                      });
                    }}
                    onSuccessDelete={() => {
                      setShowBillingModal(false);
                    }}
                  />
                </div>
              </div>
            </Form>
            <div className="flexbox end button-wrapper">
              <button
                type="button"
                className={`Billing-button ${isBtnDisabled ? 'disabled' : ''}`}
                onClick={() => {
                  if (!isBtnDisabled) {
                    submitBilling();
                  }
                }}
              >
                {isBtnLoading ? <SpinningLoader /> : 'Save Changes'}
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default BillingModal;
