/* eslint-disable camelcase */
import React from 'react';
import { Col, Row } from 'antd';
import SpinningLoader from '../spinningloader';
import { formatNumber } from '../../helpers/format-numbers';

type Props = {
  total_insights: { [key: string]: string } | null;
  isLoading: boolean;
};

export const InsightsTotal = ({ total_insights, isLoading }: Props) => (
  <div className="CampaignInsights-total">
    <div className="CampaignInsights-summary-content-header">SUMMARY</div>
    {!isLoading ? (
      <Row>
        <Col span={6} className="CampaignInsights-value">
          <div className="CampaignInsights-value-header">Reach</div>
          <div className="CampaignInsights-value-text">
            {formatNumber(total_insights?.unique_impressions)}
          </div>
        </Col>
        {total_insights?.streams_based ? (
          <Col span={6} className="CampaignInsights-value">
            <div className="CampaignInsights-value-header">Total Streams</div>
            <div className="CampaignInsights-value-text">
              {formatNumber(total_insights?.streams)}
            </div>
          </Col>
        ) : (
          <Col span={6} className="CampaignInsights-value">
            <div className="CampaignInsights-value-header">Total Impressions</div>
            <div className="CampaignInsights-value-text">
              {formatNumber(total_insights?.impressions)}
            </div>
          </Col>
        )}
        <Col span={6} className="CampaignInsights-value">
          <div className="CampaignInsights-value-header">Frequency</div>
          <div className="CampaignInsights-value-text">{total_insights?.frequency}</div>
        </Col>
        {Boolean(total_insights?.clicks) && (
          <Col span={6} className="CampaignInsights-value">
            <div className="CampaignInsights-value-header">Total Clicks</div>
            <div className="CampaignInsights-value-text">
              {formatNumber(total_insights?.clicks)}
            </div>
          </Col>
        )}
        {Boolean(total_insights?.unique_clicks) && (
          <Col span={6} className="CampaignInsights-value">
            <div className="CampaignInsights-value-header">Unique Clicks</div>
            <div className="CampaignInsights-value-text">
              {formatNumber(total_insights?.unique_clicks)}
            </div>
          </Col>
        )}
        {Boolean(total_insights?.streams) && (
          <Col span={6} className="CampaignInsights-value">
            <div className="CampaignInsights-value-header">Streams</div>
            <div className="CampaignInsights-value-text">
              {formatNumber(total_insights?.streams)}
            </div>
          </Col>
        )}
        {Boolean(total_insights?.ctr) && (
          <Col span={6} className="CampaignInsights-value">
            <div className="CampaignInsights-value-header">CTR</div>
            <div className="CampaignInsights-value-text">{total_insights?.ctr}%</div>
          </Col>
        )}
      </Row>
    ) : (
      <SpinningLoader />
    )}
  </div>
);
