import React from 'react';
import './styles.scss';

interface AnghamiAdsFormInputProps {
  label: string;
  placeholder?: string;
  value?: string | number;
  type?: string;
  width?: string;
  invalid?: boolean;
  maxLength?: number;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (e: any) => void;
}

export const AnghamiAdsFormInput = ({
  label,
  type = 'text',
  width,
  invalid = false,
  disabled = false,
  ...props
}: AnghamiAdsFormInputProps) => (
  <div
    className={[
      'anghami-ads-form-input',
      invalid ? 'anghami-ads-form-input--invalid' : '',
      disabled ? 'anghami-ads-form-input--disabled' : ''
    ].join(' ')}
    style={{ width }}
  >
    <label>{label}</label>
    <input type={type} {...props} />
  </div>
);
