export const countriesOptions = [
  { id: 'AE', text: 'United Arab Emirates' },
  { id: 'SA', text: 'Saudi Arabia' },
  { id: 'KW', text: 'Kuwait' },
  { id: 'BH', text: 'Bahrain' },
  { id: 'OM', text: 'Oman' },
  { id: 'QA', text: 'Qatar' },
  { id: 'EG', text: 'Egypt' },
  { id: 'SS', text: 'South Sudan' },
  { id: 'SD', text: 'Sudan' },
  { id: 'LB', text: 'Lebanon' },
  { id: 'JO', text: 'Jordan' },
  { id: 'PS', text: 'Palestine' },
  { id: 'SY', text: 'Syria' },
  { id: 'IQ', text: 'Iraq' },
  { id: 'DZ', text: 'Algeria' },
  { id: 'LY', text: 'Libya' },
  { id: 'MA', text: 'Morocco' },
  { id: 'TN', text: 'Tunisia' },
  { id: 'ROW', text: 'Rest of the World' }
];

export const blsTypeOptions = [
  { id: 1, text: 'Brand Awareness' },
  { id: 2, text: 'Ad Recall' },
  { id: 3, text: 'Brand Consideration - How Likely' },
  { id: 4, text: 'Brand Consideration - Which of the Following' }
];

export const howLikelyAnswers = [
  {
    en: 'Very Likely',
    ar: 'محتمل جداً'
  },
  {
    en: 'Somewhat Likely',
    ar: 'محتمل إلى حدٍ ما'
  },
  {
    en: 'Neutral',
    ar: 'محايد'
  },
  {
    en: 'Somewhat unlikely',
    ar: 'غير محتمل'
  },
  {
    en: 'Very Unlikely',
    ar: 'غير محتمل على الاطلاق'
  },
  {
    en: "Don't know #brand_name#",
    ar: '#brand_name# لا اعرف'
  }
];

export const statusOptions = [
  { id: 0, text: 'Inactive' },
  { id: 1, text: 'Active' }
];
