import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DownloadFile from 'js-file-download';
import {
  CloseOutlined,
  FileExcelOutlined,
  FilePdfOutlined,
  LeftOutlined,
  SyncOutlined
} from '@ant-design/icons';
import { AmplitudeEvents } from '@anghami/neoanalytics/dist/enums/events';
import { toPng } from 'html-to-image';
import jsPDF from 'jspdf';
import SpinningLoader from '../spinningloader';
import { AxiosSingleton } from '../../utils/axiosinstance';
import environment from '../env/env';
import { ICampaignData } from './index';
import { logAmplitudeEvent } from '../../utils/analytics';

enum ExportButtonState {
  active = 'active',
  loading = 'loading',
  error = 'error'
}

type Props = {
  campaignId: string;
  campaignData: ICampaignData;
  insightsType: 'campaign' | 'groupcampaign';
};

export const InsightsHeader = ({ campaignId, campaignData, insightsType }: Props) => {
  const axios = AxiosSingleton.axiosInstance;
  const navigate = useNavigate();
  const [exportToExcel, setExportToExcel] = useState<ExportButtonState>(ExportButtonState.active);

  const handleExportToExcel = () => {
    setExportToExcel(ExportButtonState.loading);
    axios
      .post(
        `${environment.getBaseAPIUrl()}/${
          insightsType === 'campaign' ? 'campaigns' : 'group-campaigns'
        }/download-insight`,
        {
          campaignid: campaignId,
          conversion: false,
          lead: false
        },
        {
          responseType: 'blob'
        }
      )
      .then((response: any) => {
        DownloadFile(response.data, `insights_${campaignData.name}_${campaignId}.xlsx`);
        setExportToExcel(ExportButtonState.active);
        logAmplitudeEvent(AmplitudeEvents.exportCampaignInsights);
      })
      .catch(() => {
        setExportToExcel(ExportButtonState.error);
      });
  };

  const handleExportToPDF = () => {
    const filter = (node) => {
      const exclusionClass = 'pdf-hidden';

      if (!node.classList) {
        return true;
      }

      return !node.classList.contains(exclusionClass);
    };

    const element = document.getElementById('CampaignInsights');
    toPng(element as HTMLElement, { filter, backgroundColor: '#ffffff' }).then(
      (dataUrl: string) => {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF({
          compress: true
        });
        doc.addImage(dataUrl, 'png', 15, 0, 180, 300, 'SLOW');
        doc.save(`${campaignData.name}_${campaignId}`);
      }
    );
  };

  return (
    <>
      <div className="CampaignInsights-header">
        <span className="CampaignInsights-header-heading">Campaign Insights</span>
        <span className="CampaignInsights-header-buttons">
          <button
            type="button"
            className={`CampaignInsights-header-buttons-excel pdf-hidden ${exportToExcel}`}
            disabled={exportToExcel === ExportButtonState.loading}
            onClick={handleExportToExcel}
          >
            {exportToExcel === ExportButtonState.loading ? (
              <SyncOutlined spin />
            ) : exportToExcel === ExportButtonState.error ? (
              <CloseOutlined />
            ) : (
              <FileExcelOutlined />
            )}
            Export to Excel
          </button>
          <button
            type="button"
            className="CampaignInsights-header-buttons-excel pdf-hidden"
            onClick={handleExportToPDF}
          >
            <FilePdfOutlined />
            Export to PDF
          </button>
        </span>
      </div>

      <div className="CampaignInsights-title">
        <span className="CampaignInsights-title-back" onClick={() => navigate(-1)}>
          <LeftOutlined />
        </span>
        {campaignData ? (
          <span className="CampaignInsights-title-text">{campaignData.name}</span>
        ) : (
          <SpinningLoader />
        )}
      </div>
    </>
  );
};
