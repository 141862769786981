import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import AudioAd from '../../public/assets/images/audioad.svg';
import VideoAd from '../../public/assets/images/videoad.svg';
import DisplayAd from '../../public/assets/images/displayad.svg';
import { UploadBoxStates } from '../../enums/uploadboxstates';
import './styles.scss';

type Props = {
  type: string;
  uploadedFilename: string;
  state: string;
  clearUploadValue: () => void;
};

const imagesMap = {
  video: VideoAd,
  audio: AudioAd,
  image: DisplayAd,
  file: DisplayAd
};

const textMap = {
  video: 'Drag & drop your video here or tap to upload it',
  audio: 'Drag & drop your audio here or tap to upload it',
  image: 'Drag & drop your image here or tap to upload it',
  file: 'Drag & drop your file here or tap to upload it'
};

export default function UploadBox({ type, uploadedFilename, state, clearUploadValue }: Props) {
  const renderBoxState = () => {
    let renderedComponent: any = null;
    // eslint-disable-next-line default-case
    switch (state) {
      case UploadBoxStates.PreUpload:
        renderedComponent = <div className="preupload-text">{textMap[type]}</div>;
        break;
      case UploadBoxStates.Uploading:
        renderedComponent = (
          <div>
            <div className="upload-loader-title">Uploading...</div>
            <div className="upload-loader-container">
              <div className="upload-loader" />
            </div>
          </div>
        );
        break;
      case UploadBoxStates.Uploaded:
        renderedComponent = (
          <>
            <div>
              <div className="preupload-text">Tap to change it</div>
              <div className="upload-file-container">
                <div className="uploaded-file">
                  {uploadedFilename.length > 31
                    ? `${uploadedFilename.substring(0, 30)}...`
                    : uploadedFilename}
                </div>
                <div
                  className="uploaded-file-close-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    clearUploadValue();
                  }}
                >
                  <CloseOutlined />
                </div>
              </div>
            </div>
          </>
        );
        break;
      case UploadBoxStates.UploadFailed:
        renderedComponent = (
          <div>
            <div className="upload-loader-failure-title">Upload failed</div>
            <div className="upload-loader-container" />
          </div>
        );
        break;
      case UploadBoxStates.InvalidFile:
        renderedComponent = (
          <div>
            <div className="upload-loader-failure-title">File does not match requirements</div>
            <div className="upload-loader-container" />
          </div>
        );
        break;
    }
    return renderedComponent;
  };

  return (
    <div className="upload-box">
      <img src={imagesMap[type]} />
      <div>{renderBoxState()}</div>
    </div>
  );
}
