import React, { useEffect, useState } from 'react';
import { Row, Col, Tabs, message } from 'antd';
import { TusClientProvider } from 'use-tus';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { AnghamiAdsFormSelector } from '../core/AnghamiAdsFormSelector';
import { AnghamiAdsFormInput } from '../core/AnghamiAdsFormInput';
import { AnghamiAdsButton } from '../core/AnghamiAdsButton';
import './styles.scss';
import { countriesOptions } from './selectorOptions';
import MastheadCampaignUploadBox from './MastheadCampaignUploadBox';
import MastheadCampaignDatePicker from './MastheadCampaignDatePicker';
import { AxiosSingleton } from '../../utils/axiosinstance';
import AnghamiAdsFullPageLoader from '../core/AnghamiAdsFullPageLoader';

const MastheadCampaign = () => {
  const axios = AxiosSingleton.axiosInstance;
  const mode = window.location.href.includes('create') ? 'create' : 'edit';
  const { campaignId } = useParams();
  const [searchParams] = useSearchParams();
  const countryParam = searchParams.get('countryParam');
  const dateParam = moment(searchParams.get('dateParam') || undefined);

  const [title, setTitle] = useState('');
  const [countries, setCountries] = useState<string[]>(countryParam ? [countryParam] : []);
  const [date, setDate] = useState<any>(dateParam);
  const [deeplink, setDeeplink] = useState();
  const [deeplinkAr, setDeeplinkAr] = useState();
  const [deeplinkFr, setDeeplinkFr] = useState();
  const [image, setImage] = useState('');
  const [imageAr, setImageAr] = useState('');
  const [imageFr, setImageFr] = useState('');
  const [disabledDates, setDisabledDates] = useState([]);
  const [blockedDays, setBlockedDays] = useState({});
  const [isSubmissionLoading, setIsSubmissionLoading] = useState(false);
  const [isFullPageLoaderVisible, setIsFullPageLoaderVisible] = useState(mode === 'edit');

  const isSaveButtonDisabled =
    countries.length === 0 ||
    !date ||
    (!image && !imageAr && !imageFr) ||
    Boolean((image && !deeplink) || (imageAr && !deeplinkAr) || (imageFr && !deeplinkFr));

  const navigate = useNavigate();

  const getBlockedDates = (selectedCountries) => {
    axios
      .get('/masthead/blocked-dates', {
        params: {
          country: selectedCountries.toString()
        }
      })
      .then(({ data }) => {
        const blockedDaysMap = {};
        data.data.forEach((el) => {
          blockedDaysMap[el.blocked_day] = true;
        });
        setBlockedDays(blockedDaysMap);
      });
  };

  const saveMastheadCampaign = () => {
    if (isSaveButtonDisabled) return;
    setIsSubmissionLoading(true);
    axios
      .post(`/masthead/${mode === 'create' ? 'store' : 'update'}`, {
        id: campaignId,
        title,
        countries,
        start_date: date?.format('YYYY-MM-DD'),
        deeplink,
        deeplinkAr,
        deeplinkFr,
        image,
        imageFr,
        imageAr
      })
      .then(() => {
        navigate('/masthead-campaigns/list');
        setIsSubmissionLoading(false);
      })
      .catch(({ response }) => {
        const { data } = response;
        if (data.error) {
          message.error(data.error);
        } else {
          message.error('Something went wrong');
        }
        setIsSubmissionLoading(false);
      });
  };

  const getBookings = (selectedCountries) => {
    axios
      .get('/masthead/bookings', {
        params: {
          countries: selectedCountries,
          start_date: moment().format('YYYY-MM-DD'),
          end_date: moment().add(12, 'months').format('YYYY-MM-DD')
        }
      })
      .then(({ data }) => {
        setDisabledDates(
          data?.data?.map((booking) => moment(booking.startdate).format('YYYY-MM-DD'))
        );
      });
  };

  useEffect(() => {
    if (mode === 'create' || !campaignId) return;

    setIsFullPageLoaderVisible(true);
    axios
      .get('/masthead/get', {
        params: {
          id: campaignId
        }
      })
      .then(({ data }) => {
        const campaignData = data?.data;
        setTitle(campaignData.title);
        setCountries(campaignData.usercountries);
        setDate(moment(campaignData.startdate));
        setDeeplink(campaignData.deeplink);
        setDeeplinkAr(campaignData.deeplinkAr);
        setDeeplinkFr(campaignData.deeplinkFr);
        setImage(campaignData.image);
        setImageAr(campaignData.imageAr);
        setImageFr(campaignData.imageFr);
        getBookings(campaignData.usercountries);
        getBlockedDates(campaignData.usercountries);
        setIsFullPageLoaderVisible(false);
      });
  }, []);

  if (isFullPageLoaderVisible) {
    return <AnghamiAdsFullPageLoader />;
  }

  return (
    <div className="masthead-campaign-container">
      <div className="masthead-campaign-title">
        {mode === 'create' ? 'Setup Your New Masthead Campaign' : 'Edit Your Masthead Campaign'}
      </div>
      <Row gutter={[16, 16]} style={{ marginBottom: '1rem', marginTop: '1rem' }}>
        <Col span={12}>
          <AnghamiAdsFormInput
            label="Campaign Name"
            placeholder="Insert campaign name"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </Col>
      </Row>
      <div className="masthead-campaign-section-title">Market and Time</div>
      <div className="masthead-campaign-section-subtitle">
        Please insert the targeted countries and the day you want your masthead to be visible
      </div>
      <AnghamiAdsFormSelector
        label="Country*"
        placeholder="Select country"
        value={countries}
        options={countriesOptions}
        onChange={(value) => {
          setCountries([value]);
          setDate(undefined);
          getBookings([value]);
          getBlockedDates([value]);
        }}
      />
      <div
        className="campaign-form-input-container"
        style={{ height: '4.7rem', marginTop: '1rem' }}
      >
        <label>Day*</label>
        <MastheadCampaignDatePicker
          date={date}
          setDate={setDate}
          disabledDates={disabledDates}
          blockedDays={blockedDays}
        />
      </div>
      <div className="masthead-campaign-section-title">Masthead Creative</div>
      <div className="masthead-campaign-section-subtitle">
        Please upload your masthead image(s). make sure that the image have the following specs
        850x330, otherwise the masthead request will not be approved
      </div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="English" key="item-1">
          <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
            <Col span={12}>
              <div style={{ marginBottom: '1rem' }}>
                <AnghamiAdsFormInput
                  label="Deeplink"
                  placeholder="Insert english deeplink"
                  value={deeplink}
                  onChange={(e) => {
                    setDeeplink(e.target.value);
                  }}
                />
              </div>
              <TusClientProvider>
                <MastheadCampaignUploadBox
                  uploadSuccessHandler={(fileLocation) => {
                    setImage(fileLocation);
                  }}
                  clearUploadHandler={() => {
                    setImage('');
                  }}
                  fileName={image}
                  inputID="image"
                  key="image"
                />
              </TusClientProvider>
            </Col>
          </Row>
          <div style={{ marginTop: '1rem' }}>
            {image && <img src={image} style={{ height: '165px', width: '425px' }} />}
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Arabic" key="item-2">
          <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
            <Col span={12}>
              <div style={{ marginBottom: '1rem' }}>
                <AnghamiAdsFormInput
                  label="Deeplink"
                  placeholder="Insert arabic deeplink"
                  value={deeplinkAr}
                  onChange={(e) => {
                    setDeeplinkAr(e.target.value);
                  }}
                />
              </div>
              <TusClientProvider>
                <MastheadCampaignUploadBox
                  uploadSuccessHandler={(fileLocation) => {
                    setImageAr(fileLocation);
                  }}
                  clearUploadHandler={() => {
                    setImageAr('');
                  }}
                  fileName={imageAr}
                  inputID="imageAr"
                  key="imageAr"
                />
              </TusClientProvider>
            </Col>
          </Row>
          <div style={{ marginTop: '1rem' }}>
            {imageAr && <img src={imageAr} style={{ height: '165px', width: '425px' }} />}
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="French" key="item-3">
          <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
            <Col span={12}>
              <div style={{ marginBottom: '1rem' }}>
                <AnghamiAdsFormInput
                  label="Deeplink"
                  placeholder="Insert french deeplink"
                  value={deeplinkFr}
                  onChange={(e) => {
                    setDeeplinkFr(e.target.value);
                  }}
                />
              </div>
              <TusClientProvider>
                <MastheadCampaignUploadBox
                  uploadSuccessHandler={(fileLocation) => {
                    setImageFr(fileLocation);
                  }}
                  clearUploadHandler={() => {
                    setImageFr('');
                  }}
                  fileName={imageFr}
                  inputID="imageFr"
                  key="imageFr"
                />
              </TusClientProvider>
            </Col>
          </Row>
          <div style={{ marginTop: '1rem' }}>
            {imageFr && <img src={imageFr} style={{ height: '165px', width: '425px' }} />}
          </div>
        </Tabs.TabPane>
      </Tabs>
      <AnghamiAdsButton
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
        disabled={isSaveButtonDisabled}
        label="Save"
        onClick={saveMastheadCampaign}
        isLoading={isSubmissionLoading}
      />
    </div>
  );
};

export default MastheadCampaign;
