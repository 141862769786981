import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { AmplitudeEvents } from '@anghami/neoanalytics/dist/enums/events';
import { IFilter } from '../managecampaigns/filtersSchema';
import { logAmplitudeEvent } from '../../utils/analytics';
import './styles.scss';

type Props = {
  onFilterSelect: (filter: any) => void;
  filters: IFilter[];
};

const FilterSelect = ({ onFilterSelect = () => null, filters = [] }: Props) => {
  const [showDropdown, setShowDropdown] = useState(false);

  // to hide dropdown after selelcting all filters
  useEffect(() => {
    if (filters.length === 0) {
      setShowDropdown(false);
    }
  }, [filters.length]);

  const filtersMenu = (
    <div className="FilterSelect-menu">
      <div className="FilterSelect-menu-title">Filter by</div>
      {filters.map((filter) => (
        <div
          key={filter.id}
          className="FilterSelect-menu-item"
          onClick={() => {
            onFilterSelect(filter);
            setShowDropdown(false);
            logAmplitudeEvent(AmplitudeEvents.addNewFilter, {
              filter_name: filter.title
            });
          }}
        >
          {filter.title}
        </div>
      ))}
    </div>
  );

  return (
    <div className="FilterSelect">
      <button
        className="FilterSelect-button"
        onClick={() => {
          setShowDropdown(true);
        }}
      >
        <PlusOutlined />
        ADD FILTER
      </button>
      {showDropdown ? (
        <>
          <div className="FilterSelect-dropdown">{filtersMenu}</div>
          <span
            className="FilterSelect-dropdown-catchclick"
            onClick={() => setShowDropdown(false)}
          />
        </>
      ) : null}
    </div>
  );
};

export default FilterSelect;
