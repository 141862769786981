import React from 'react';
import { Col, Row } from 'antd';
import SpinningLoader from '../spinningloader';
import { formatNumber } from '../../helpers/format-numbers';

type Props = {
  insights_range: { [key: string]: string } | null;
  isLoading: boolean;
};

export const InsightsSummary = ({ isLoading, insights_range }: Props) =>
  !isLoading ? (
    <div className="CampaignInsights-summary-content">
      <Row>
        <Col span={6} className="CampaignInsights-value">
          <div className="CampaignInsights-value-header">Reach</div>
          <div className="CampaignInsights-value-text">
            {formatNumber(insights_range?.unique_impressions)}
          </div>
        </Col>
        {insights_range?.streams_based ? (
          <Col span={6} className="CampaignInsights-value">
            <div className="CampaignInsights-value-header">Streams</div>
            <div className="CampaignInsights-value-text">
              {formatNumber(insights_range?.streams)}
            </div>
          </Col>
        ) : (
          <Col span={6} className="CampaignInsights-value">
            <div className="CampaignInsights-value-header">Impressions</div>
            <div className="CampaignInsights-value-text">
              {formatNumber(insights_range?.impressions)}
            </div>
          </Col>
        )}
        <Col span={6} className="CampaignInsights-value">
          <div className="CampaignInsights-value-header">Frequency</div>
          <div className="CampaignInsights-value-text">{insights_range?.frequency}</div>
        </Col>
        {Boolean(insights_range?.clicks) && (
          <Col span={6} className="CampaignInsights-value">
            <div className="CampaignInsights-value-header">Clicks</div>
            <div className="CampaignInsights-value-text">
              {formatNumber(insights_range?.clicks)}
            </div>
          </Col>
        )}
        {Boolean(insights_range?.unique_clicks) && (
          <Col span={6} className="CampaignInsights-value">
            <div className="CampaignInsights-value-header">Unique Clicks</div>
            <div className="CampaignInsights-value-text">
              {formatNumber(insights_range?.unique_clicks)}
            </div>
          </Col>
        )}
        {Boolean(insights_range?.streams) && (
          <Col span={6} className="CampaignInsights-value">
            <div className="CampaignInsights-value-header">Streams</div>
            <div className="CampaignInsights-value-text">
              {formatNumber(insights_range?.streams)}
            </div>
          </Col>
        )}
        {Boolean(insights_range?.ctr) && (
          <Col span={6} className="CampaignInsights-value">
            <div className="CampaignInsights-value-header">CTR</div>
            <div className="CampaignInsights-value-text">{insights_range?.ctr}%</div>
          </Col>
        )}
        {Boolean(insights_range?.unique_ctr) && (
          <Col span={6} className="CampaignInsights-value">
            <div className="CampaignInsights-value-header">Unique CTR</div>
            <div className="CampaignInsights-value-text">{insights_range?.unique_ctr}%</div>
          </Col>
        )}
      </Row>
    </div>
  ) : (
    <SpinningLoader />
  );
