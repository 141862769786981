import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Col, Row, Select } from 'antd';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AudioAd from '../../public/assets/images/audioad.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VideoAd from '../../public/assets/images/videoad.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DisplayAd from '../../public/assets/images/displayad.svg';
import './styles.scss';
import { AxiosSingleton } from '../../utils/axiosinstance';
import SpinningCircle from '../spinningcircle';
import InventoryTabbedSection from './InventoryTabbedSection';
import { AnghamiAdsFormSelector } from '../core/AnghamiAdsFormSelector';
import { AnghamiAdsFormInput } from '../core/AnghamiAdsFormInput';
import {
  availableCountries,
  genderOptions,
  goalOptions,
  frequencyCapDurations
} from './selectorOptions';

const { Option } = Select;

type SelectorOptionType = {
  id: string | number;
  text: string | number;
};

export default function Inventory() {
  const axios = AxiosSingleton.axiosInstance;
  const navigate = useNavigate();
  const [adType, setAdType] = useState('audio');
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().add(7, 'd').format('YYYY-MM-DD'));
  const [gender, setGender] = useState<string | undefined>();
  const [minAge, setMinAge] = useState(2);
  const [maxAge, setMaxAge] = useState(100);
  const [countries, setCountries] = useState([]);
  const [goal, setGoal] = useState(goalOptions[0].id);
  const [targetImpressions, setTargetImpressions] = useState<number | undefined | string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isFrequencyCapsToggled, setIsFrequencyCapsToggled] = useState(false);
  const [frequencies, setFrequencies] = useState([{ count: 1, duration: 'day' }]);
  const [impressionsByCountry, setImpressionsByCountry] = useState([]);
  const [campaignType, setCampaignType] = useState('');
  const [impressionsKey, setImpressionsKey] = useState('');

  const generateFrequencyCapObject = (freq) => {
    const frequencyCap = {};
    freq.forEach((element: any) => {
      frequencyCap[element.duration] = element.count;
    });
    return frequencyCap;
  };

  const getImpressions = () => {
    setIsLoading(true);
    const params = {
      ad_type: adType,
      start_date: startDate,
      end_date: endDate,
      countries,
      age_from: minAge,
      age_to: maxAge,
      gender: gender || '',
      goal,
      target_impressions: goal === 'custom' ? targetImpressions : 0,
      frequency_cap: isFrequencyCapsToggled ? generateFrequencyCapObject(frequencies) : {}
    };

    axios.get('/inventory/impressions', { params }).then((res) => {
      const { data } = res;
      setImpressionsByCountry(data.countries);
      setCampaignType(params.ad_type);
      setImpressionsKey(data.key);
      setIsLoading(false);
    });
  };

  const exportToCSV = () => {
    axios
      .post(
        '/inventory/export',
        {
          key: impressionsKey,
          goal
        },
        {
          responseType: 'arraybuffer'
        }
      )
      .then((res) => {
        const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const rand = Math.floor(Math.random() * 9999 + 1000);
        link.download = `inventory_${rand}.xlsx`;
        link.click();
      });
  };

  return (
    <div className="inventory-container">
      <div className="inventory-header">Inventory</div>
      <div className="campaign-section-title">Select Ad Type</div>
      <div className="ad-type-container">
        <div
          className={`ad-type ${adType === 'audio' ? 'selected' : ''}`}
          onClick={() => {
            setAdType('audio');
          }}
        >
          <img src={AudioAd} alt="Audio Ad" />
          <div>Audio Ad</div>
          <div className="ad-type-check">
            <CheckOutlined />
          </div>
        </div>
        <div
          className={`ad-type ${adType === 'video' ? 'selected' : ''}`}
          onClick={() => {
            setAdType('video');
          }}
        >
          <img src={VideoAd} alt="Video Ad" />
          <div>Video Ad</div>
          <div className="ad-type-check">
            <CheckOutlined />
          </div>
        </div>
        <div
          className={`ad-type ${adType === 'display' ? 'selected' : ''}`}
          onClick={() => {
            setAdType('display');
          }}
        >
          <img src={DisplayAd} alt="Display Ad" />
          <div>Display Ad</div>
          <div className="ad-type-check">
            <CheckOutlined />
          </div>
        </div>
      </div>
      <div className="inventory-flex-container">
        <div className="campaign-form-input-container">
          <label>Start Date</label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
          />
        </div>
        <div className="campaign-form-input-container">
          <label>End Date</label>
          <input
            type="date"
            min={moment(startDate).add(1, 'd').format('YYYY-MM-DD')}
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          />
        </div>
      </div>
      <Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
        <Col span={12}>
          <AnghamiAdsFormSelector
            label="Gender"
            placeholder="Select gender"
            value={gender}
            options={genderOptions}
            onChange={(value: any) => {
              setGender(value);
            }}
          />
        </Col>
        <Col span={12}>
          <div className="campaign-form-input-container">
            <label>Age</label>
            <div className="age-selector-container">
              <Select
                showSearch
                style={{ width: '45%' }}
                bordered={false}
                placeholder="From"
                value={minAge}
                onChange={(value: any) => {
                  setMinAge(value);
                }}
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {[...Array(101).keys()].map((age: number) => (
                  <Option value={age} key={age}>
                    {age}
                  </Option>
                ))}
              </Select>
              <Select
                showSearch
                style={{ width: '45%' }}
                bordered={false}
                placeholder="To"
                value={maxAge}
                onChange={(value: any) => {
                  setMaxAge(value);
                }}
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {[...Array(101).keys()].map((age: number) => (
                  <Option value={age} key={age}>
                    {age}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Col span={12}>
          <AnghamiAdsFormSelector
            label="Goal"
            value={goal}
            options={goalOptions}
            onChange={(value: any) => {
              setGoal(value);
            }}
          />
        </Col>
        {goal === 'custom' && (
          <Col span={12}>
            <AnghamiAdsFormInput
              label="Target Impressions *"
              type="number"
              placeholder="Enter your target impressions"
              onChange={(e) => {
                setTargetImpressions(e.target.value);
              }}
            />
          </Col>
        )}
      </Row>
      <AnghamiAdsFormSelector
        label="Countries *"
        showSearch
        mode="multiple"
        placeholder="Select one or more countries"
        value={countries}
        options={availableCountries}
        onChange={(value: any) => {
          setCountries(value);
        }}
      />
      <div className="frequency-cap-toggle">
        <input
          type="checkbox"
          onChange={(e) => {
            setIsFrequencyCapsToggled(e.target.checked);
          }}
        />
        Set Frequency Caps
      </div>
      {isFrequencyCapsToggled && (
        <div className="frequency-cap-section-container">
          {frequencies.map((frequencyObj, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="frequency-cap-section" key={index}>
              <div className="campaign-form-input-container mb-1rem">
                <label>Frequency Cap</label>
                <div className="frequency-cap-inputs">
                  <div className="impression-per-days">
                    <input
                      type="text"
                      value={frequencyObj.count}
                      onChange={(e) => {
                        const newFrequencyCap = [...frequencies];
                        newFrequencyCap[index].count = Number(e.target.value);
                        setFrequencies(newFrequencyCap);
                      }}
                    />
                    <span>impressions per </span>
                  </div>
                  <div className="frequency-cap-durations">
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      bordered={false}
                      placeholder="Add value here"
                      value={frequencyObj.duration}
                      onChange={(value: any) => {
                        const newFrequencyCap = [...frequencies];
                        newFrequencyCap[index].duration = value;
                        setFrequencies(newFrequencyCap);
                      }}
                      filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {frequencyCapDurations.map((duration: SelectorOptionType) => (
                        <Option value={duration.id} key={duration.id}>
                          {duration.text}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
              <div
                className="remove-frequency-cap"
                onClick={() => {
                  const newFrequencyCap = [...frequencies];
                  newFrequencyCap.splice(index, 1);
                  setFrequencies(newFrequencyCap);
                }}
              >
                <CloseOutlined />
              </div>
            </div>
          ))}
          <div
            className="add-frequency-cap"
            onClick={() => {
              const newFrequencyCap = [...frequencies];
              newFrequencyCap.push({ count: 1, duration: 'day' });
              setFrequencies(newFrequencyCap);
            }}
          >
            + ADD FREQUENCY CAP
          </div>
        </div>
      )}
      <button
        type="button"
        className={`get-impressions-btn ${isLoading ? 'loading' : ''} ${
          countries.length === 0 ? 'disabled' : ''
        }`}
        onClick={() => {
          if (isLoading || countries.length === 0) return;
          getImpressions();
        }}
      >
        {isLoading ? <SpinningCircle /> : 'Get Impressions'}
      </button>
      {impressionsByCountry.length > 0 && (
        <>
          <div>
            <InventoryTabbedSection impressionsByCountry={impressionsByCountry} campaignType={campaignType} />
          </div>
          <div className="tabbed-section-btns-container">
            <button type="button" onClick={exportToCSV} className="export-btn">
              Export Results
            </button>
            <button
              type="button"
              className="view-contending-btn"
              onClick={() => {
                navigate(`/contending-campaigns?key=${impressionsKey}`);
              }}
            >
              View Contending Campaigns
            </button>
          </div>
        </>
      )}
    </div>
  );
}
