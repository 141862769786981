import React from 'react';
import { DataType } from '../invoices/tabledatatype';
import './styles.scss';

type Props = {
  name: string;
  data: DataType;
};

const PublisherCell = ({ name, data }: Props) => (
  <div className="PublisherCell">
    <div className="PublisherCell-name">{name}</div>
    <div className="PublisherCell-email">{data.email}</div>
  </div>
);

export default PublisherCell;
