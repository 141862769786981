import React, { useState } from 'react';
import { TusClientProvider } from 'use-tus';
import { AxiosSingleton } from '../../utils/axiosinstance';
import { message } from '../../utils/custommessage';
import CustomAudiencesUploadBox from './CustomAudiencesUploadBox';
import { AnghamiAdsFormInput } from '../core/AnghamiAdsFormInput';
import './styles.scss';

type Props = {
  mode: 'edit' | 'create';
  selectedAudience: any;
  closeModal: () => void;
};

export default function CustomAudiencesModal({ mode, selectedAudience, closeModal }: Props) {
  const axios = AxiosSingleton.axiosInstance;
  const isEditMode = mode === 'edit';

  const [audienceName, setAudienceName] = useState(isEditMode ? selectedAudience.name : '');
  const [isHashed, setIsHashed] = useState(false);
  const [csvURL, setCsvURL] = useState(isEditMode ? selectedAudience.url : '');

  const submitCSV = () => {
    const params = {
      name: audienceName,
      url: csvURL
    };

    axios
      .post('/audiences/custom/save', params)
      .then((res) => {
        if (res.data.id) {
          message.success('Audience saved');
          closeModal();
        }
      })
      .catch(() => {
        message.error('An error occurred');
      });
  };

  return (
    <div className="custom-audiences-modal">
      <div className="custom-audiences-modal-title">{mode} Audience</div>
      <div className="custom-audiences-modal-description">
        Target Anghami users who match with your existing CRM base. Your csv file should include at
        least users&apos; email and/or phone numbers.{' '}
        <a
          target="_blank"
          href="https://aam.anghami.com/files/Audience-Match-Template.csv"
          rel="noreferrer"
        >
          as per this template
        </a>
        .
      </div>
      <div className="custom-audiences-modal-privacy">
        <b>Privacy</b>
        <br />
        To ensure private consumer data, the email and/or phone numbers in your selected file, will
        be hashed locally using the SHA256 algorithm before its uploaded on our servers.
      </div>
      <div className="file-hashed-container">
        <input
          type="checkbox"
          onChange={(e) => {
            setIsHashed(e.target.checked);
          }}
        />
        <div>FILE IS ALREADY HASHED</div>
      </div>
      <AnghamiAdsFormInput
        label="Name"
        placeholder="Audience Name"
        value={audienceName}
        onChange={(e) => {
          setAudienceName(e.target.value);
        }}
      />
      <div className="audience-upload-container">
        <TusClientProvider>
          <CustomAudiencesUploadBox
            isHashed={isHashed}
            setCsvURL={setCsvURL}
            csvURL={mode === 'edit' ? selectedAudience.url : ''}
          />
        </TusClientProvider>
      </div>
      <button
        type="button"
        className={`save-btn ${!audienceName || !csvURL ? 'disabled' : ''}`}
        onClick={() => {
          submitCSV();
        }}
      >
        Save
      </button>
    </div>
  );
}
