import { PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosSingleton } from '../../utils/axiosinstance';
import { brandStudiesTableColumns } from './tableColumns';
import AdsTable from '../adstable';
import './styles.scss';
import AnghamiAdsFullPageLoader from '../core/AnghamiAdsFullPageLoader';

export default function BrandUpliftStudies() {
  const axios = AxiosSingleton.axiosInstance;
  const navigate = useNavigate();
  const [brandStudies, setBrandStudies] = useState([]);
  const [tableMeta, setTableMeta] = useState<any>();
  const [isInfiniteLoading, setIsInfiniteLoading] = useState(false);
  const [page, setPage] = useState(1);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios.get('/brandstudies/all').then((res) => {
      const { data } = res;
      setTableMeta(data);
      const formattedStudies = data.data.map((study) => ({
        ...study,
        key: study.id
      }));
      setBrandStudies(formattedStudies);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <AnghamiAdsFullPageLoader />;
  }

  return (
    <>
      <div className="brand-studies-container">
        <div className="brand-studies-header">
          <div className="brand-studies-header-actions">
            <div className="brand-studies-header-heading">Brand Uplift Studies</div>
            <button
              type="button"
              className="brand-studies-header-button create-btn"
              onClick={() => {
                navigate('/brandupliftstudies/create');
              }}
            >
              <PlusOutlined />
              CREATE
            </button>
          </div>
        </div>
        {brandStudies.length > 0 ? (
          <AdsTable
            data={brandStudies}
            tableColumns={brandStudiesTableColumns}
            onFetch={() => {
              if (brandStudies.length >= tableMeta.total) return;
              setIsInfiniteLoading(true);
              setPage(page + 1);
            }}
            isLoading={brandStudies.length === 0}
            isInfiniteLoading={isInfiniteLoading}
            rowSelection={false}
          />
        ) : null}
      </div>
    </>
  );
}
