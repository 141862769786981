import { Select } from 'antd';
import moment from 'moment';
import React from 'react';
import { SponsoredPlaylistNavigation } from '../../enums/sponsoredplaylistsnavigation';
import { AnghamiAdsFormInput } from '../core/AnghamiAdsFormInput';
import { AnghamiAdsFormSelector } from '../core/AnghamiAdsFormSelector';
import { countries, genderOptions } from './selectorOptions';
// import { AxiosSingleton } from '../../utils/axiosinstance';
// import DebounceSelect from '../debounceselect';

const { Option } = Select;

type Props = {
  mode: 'create' | 'edit';
  setNavigationPage: (navigationPage: any) => void;
  startDate: any;
  setStartDate: (date: any) => void;
  endDate: any;
  setEndDate: (date: any) => void;
  budget: any;
  setBudget: (budget: any) => void;
  selectedCountries: any[];
  setSelectedCountries: (countries: any[]) => void;
  gender: string;
  setGender: (gender: string) => void;
  maxAge: any;
  setMaxAge: (age: any) => void;
  minAge: any;
  setMinAge: (age: any) => void;
  artists: any[];
};

export default function SponsoredPlaylistsAudience({
  mode,
  setNavigationPage,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  budget,
  setBudget,
  selectedCountries,
  setSelectedCountries,
  gender,
  setGender,
  minAge,
  setMinAge,
  maxAge,
  setMaxAge,
  artists
}: Props) {
  return (
    <div className="campaign-container">
      <div className="campaign-container-header">
        {mode === 'create' && (
          <div className="campaign-container-header-title">Setup Your New Campaign</div>
        )}
        {mode === 'edit' && (
          <div className="campaign-container-header-title">Edit Your Campaign</div>
        )}
        <button
          className={`campaign-container-header-btn ${
            selectedCountries.length > 0 && endDate && budget && startDate ? '' : 'disabled'
          }`}
          type="button"
          onClick={() => {
            if (selectedCountries.length === 0 || !endDate || !startDate || !budget) {
              return;
            }
            setNavigationPage(SponsoredPlaylistNavigation.Cover);
          }}
        >
          Next
        </button>
      </div>
      <div className="campaign-section-title">Audience & Schedule</div>
      <div className="flex-input-container mb-1rem">
        <div className="campaign-form-input-container">
          <label>Start Date *</label>
          <input
            type="date"
            min={moment().format('YYYY-MM-DD')}
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
          />
        </div>
        <div className="campaign-form-input-container">
          <label>End Date *</label>
          <input
            type="date"
            min={startDate}
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          />
        </div>
      </div>
      <div style={{ marginBottom: '1rem' }}>
        <AnghamiAdsFormInput
          label="Budget (USD) *"
          placeholder="Add your budget"
          value={budget}
          onChange={(e) => {
            setBudget(e.target.value);
          }}
        />
      </div>
      <div style={{ marginBottom: '1rem' }}>
        <AnghamiAdsFormSelector
          label="Countries *"
          showSearch
          mode="multiple"
          placeholder="Select one or more countries"
          options={countries}
          value={selectedCountries}
          onChange={(value: any) => {
            setSelectedCountries(value);
          }}
        />
      </div>
      <div className="disabled-input" style={{ marginBottom: '1rem' }}>
        {/* <DebounceSelect
          mode="multiple"
          value={artists}
          placeholder="Select artists"
          fetchOptions={fetchArtists}
          onChange={(newValue) => {
            setArtists(newValue);
          }}
          style={{
            width: '100%',
          }}
        /> */}
        <AnghamiAdsFormSelector
          label="artists"
          showSearch
          mode="multiple"
          value={artists}
          options={[]}
        />
      </div>
      <div>
        <div className="campaign-form-input-container mb-1rem">
          <label>Age</label>
          <div className="age-selector-container">
            <Select
              style={{ width: '49%' }}
              bordered={false}
              placeholder="From"
              value={minAge}
              onChange={(value: any) => {
                setMinAge(value);
              }}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {[...Array(101).keys()].map((age: number) => (
                <Option value={age} key={age}>
                  {age}
                </Option>
              ))}
            </Select>
            <Select
              style={{ width: '49%' }}
              bordered={false}
              placeholder="To"
              value={maxAge}
              onChange={(value: any) => {
                setMaxAge(value);
              }}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {[...Array(101).keys()].map((age: number) => (
                <Option value={age} key={age}>
                  {age}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <AnghamiAdsFormSelector
          label="Gender"
          showSearch
          placeholder="Select gender"
          value={gender}
          onChange={(value: any) => {
            setGender(value);
          }}
          options={genderOptions}
        />
      </div>
    </div>
  );
}
