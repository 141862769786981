import React, { useContext } from 'react';
import { IAdSet, ICreative } from '../campaignhoc';
import { campaignContext } from '../campaignhoc/campaign.context';
import SpinningCircle from '../spinningcircle';
import './styles.scss';
import PreviewComponent from '../newpreview';
import { ctaKeyToTextMap } from '../../utils/constants';

type Props = {
  adSets: IAdSet[];
};

const reachFormattingMap = {
  reach: 'Audience Reach',
  lead: 'Lead Generation'
};

export default function CampaignReview({ adSets }: Props) {
  const {
    mode,
    groupCampaignName,
    groupCampaignObjective,
    advertiserName,
    industryName,
    saveAll,
    isSaveAllLoading,
    isSubmitSaveAll,
    isSaveForLaterSaveAll,
    updateLeadGenForm
  } = useContext(campaignContext);

  const capitalize = (str: string) => (str ? str[0].toUpperCase() + str.substring(1) : '');

  const joinAndCapitalize = (arr: string[]) => {
    const capitalizedArr = arr.map((element) => capitalize(String(element)));
    return capitalizedArr.join(', ');
  };

  const formatFrequencies = (arr: any[]) =>
    arr.map((element, index) => (
      <div key={element.count + index}>{`${element.count} impression${
        element.count > 1 ? 's' : ''
      } per ${element.duration.toLowerCase()}`}</div>
    ));

  const renderCreativeSummary = (creative: ICreative, adSet: IAdSet) => (
    <div className="creative-summary" key={creative.id}>
      <div className="creative-summary-preview">
        <PreviewComponent
          creative={creative}
          adSet={adSet}
          groupCampaignObjective={groupCampaignObjective}
          updateLeadGenForm={updateLeadGenForm}
          buttonText={
            ctaKeyToTextMap[creative.creativeButtonTextEn] || creative.creativeButtonTextEn
          }
          songData={creative.creativeSong}
        />
      </div>
      <div className="creative-summary-info">
        <div className="creative-name">{creative.creativeName}</div>
        <div className="creative-summary-element">
          <div>CTA URL</div>
          <div>{creative.creativeLinkEn}</div>
        </div>
        <div className="creative-summary-element">
          <div>FROM</div>
          <div>{`${adSet.adSetStartDate} ${creative.creativeStartTime}`}</div>
        </div>
        <div className="creative-summary-element">
          <div>TO</div>
          <div>{`${adSet.adSetEndDate} ${creative.creativeEndTime}`}</div>
        </div>
        <div className="creative-summary-element">
          <div>DAYS</div>
          <div>{joinAndCapitalize(Object.keys(creative.creativeDaysOfWeek))}</div>
        </div>
      </div>
    </div>
  );

  const renderAdSetSummary = (adSet, index) => (
    <div className="adset-summary">
      <div className="adset-summary-title">
        <div className="adset-index">{index + 1}</div>
        <div className="adset-label">AD SET</div>
        <div className="adset-name">{adSet.adSetName}</div>
      </div>
      <div className="adset-information-container">
        <div className="adset-left-information-sections">
          <div className="adset-information-section">
            <div className="adset-information-section-title">DURATION</div>
            <div className="adset-summary-element">
              <div className="adset-summary-label">FROM</div>
              <div className="adset-summary-value">{`${adSet.adSetStartDate} ${
                adSet.adSetStartTime || ''
              }`}</div>
            </div>
            <div className="adset-summary-element">
              <div className="adset-summary-label">TO</div>
              <div className="adset-summary-value">{`${adSet.adSetEndDate} ${
                adSet.adSetEndTime || ''
              }`}</div>
            </div>
          </div>
          <div className="adset-information-section">
            <div className="adset-information-section-title">DELIVERY</div>
            <div className="adset-summary-element">
              <div className="adset-summary-label">TARGET IMPRESSIONS</div>
              <div className="adset-summary-value">{adSet.adSetTargetImpressions}</div>
            </div>
            <div className="adset-summary-element">
              <div className="adset-summary-label">AD ROTATION</div>
              <div className="adset-summary-value">{adSet.adSetDeliveryRotation}</div>
            </div>
            <div className="adset-summary-element">
              <div className="adset-summary-label">FREQUENCY CAP</div>
              <div className="adset-summary-value">{formatFrequencies(adSet.adSetFrequencies)}</div>
            </div>
            <div className="adset-summary-element">
              <div className="adset-summary-label">BUDGET</div>
              <div className="adset-summary-value">${adSet.adSetBudget}</div>
            </div>
          </div>
        </div>
        <div className="adset-right-information-sections">
          <div className="adset-information-section">
            <div className="adset-information-section-title">TARGETING</div>
            <div className="adset-summary-element">
              <div className="adset-summary-label">COUNTRIES</div>
              <div className="adset-summary-value">
                {joinAndCapitalize(adSet.audienceCountries)}
              </div>
            </div>
            <div className="adset-summary-element">
              <div className="adset-summary-label">GENDER</div>
              <div className="adset-summary-value">{joinAndCapitalize(adSet.audienceGender)}</div>
            </div>
            <div className="adset-summary-element">
              <div className="adset-summary-label">AGE</div>
              <div className="adset-summary-value">{`${adSet.audienceMinAge} to ${adSet.audienceMaxAge}`}</div>
            </div>
            <div className="adset-summary-element">
              <div className="adset-summary-label">MUSIC PREFERENCE</div>
              <div className="adset-summary-value">
                {joinAndCapitalize(adSet.audienceMusicPreference)}
              </div>
            </div>
            <div className="adset-summary-element">
              <div className="adset-summary-label">APP LANGUAGE</div>
              <div className="adset-summary-value">
                {joinAndCapitalize(adSet.audienceAppLanguage)}
              </div>
            </div>
          </div>
          <div className="adset-information-section">
            <div className="adset-information-section-title">RE-TARGETING</div>
            <div className="adset-summary-element">
              <div className="adset-summary-label">INTERACTION TYPE</div>
              <div className="adset-summary-value">
                {joinAndCapitalize([adSet.audienceRetargeting?.interaction])}
              </div>
            </div>
            <div className="adset-summary-element">
              <div className="adset-summary-label">LOGICAL OPERATOR</div>
              <div className="adset-summary-value">
                {joinAndCapitalize([adSet.audienceRetargeting?.operator])}
              </div>
            </div>
            <div className="adset-summary-element">
              <div className="adset-summary-label">CAMPAIGNS</div>
              <div className="adset-summary-value">
                {joinAndCapitalize(adSet.audienceRetargeting?.campaigns)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="creative-section-title">Creatives</div>
        <div className="creative-summary-container">
          {adSet.creatives.map((creative) => renderCreativeSummary(creative, adSet))}
        </div>
      </div>
    </div>
  );

  const renderCampaignSummary = () => (
    <div className="campaign-summary">
      <div className="campaign-summary-title">{`Your "${groupCampaignName}" Summary`}</div>
      <div className="campaign-summary-element">
        <div className="campaign-summary-label">TYPE</div>
        <div className="campaign-summary-value">{reachFormattingMap[groupCampaignObjective]}</div>
      </div>
      <div className="campaign-summary-element">
        <div className="campaign-summary-label">ADVERTISER</div>
        <div className="campaign-summary-value">{advertiserName}</div>
      </div>
      <div className="campaign-summary-element">
        <div className="campaign-summary-label">INDUSTRY</div>
        <div className="campaign-summary-value">{industryName}</div>
      </div>
    </div>
  );
  return (
    <div className="campaign-container review-container">
      <div className="campaign-container-header">
        <div className="campaign-container-header-title">Review Your Campaign</div>
        {mode !== 'preview' && (
          <div className="save-btns-container">
            <button
              className={`save-for-later-btn ${
                isSaveAllLoading && isSaveForLaterSaveAll ? 'disabled' : ''
              }`}
              type="button"
              onClick={() => {
                if (isSaveAllLoading) {
                  return;
                }
                saveAll({ draft: 1 });
              }}
            >
              {isSaveAllLoading && isSaveForLaterSaveAll ? <SpinningCircle /> : 'Save for later'}
            </button>
            <button
              className={`campaign-container-header-btn ${
                isSaveAllLoading && isSubmitSaveAll ? 'disabled' : ''
              }`}
              type="button"
              onClick={() => {
                if (isSaveAllLoading) {
                  return;
                }
                saveAll({ draft: 0 });
              }}
            >
              {isSaveAllLoading && isSubmitSaveAll ? <SpinningCircle /> : 'Submit'}
            </button>
          </div>
        )}
      </div>
      {renderCampaignSummary()}
      {adSets.map((adSet: IAdSet, index) => (
        <div key={adSet.id}>{renderAdSetSummary(adSet, index)}</div>
      ))}
    </div>
  );
}
