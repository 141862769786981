import React, { useContext, useState } from 'react';
import { CloseOutlined, FileOutlined, SyncOutlined, CheckOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import './styles.scss';
import { Tooltip, Modal } from 'antd';
import DownloadFile from 'js-file-download';
import { Link } from 'react-router-dom';
import { AmplitudeEvents } from '@anghami/neoanalytics/dist/enums/events';
import leadGenerationImg from '../../public/assets/images/lead-generation.png';
import webConversionImg from '../../public/assets/images/web-conversion.png';
import audienceReachImg from '../../public/assets/images/audience-reach.png';
import editImg from '../../public/assets/images/edit.svg';
import insightsImg from '../../public/assets/images/insight.svg';
import linkImg from '../../public/assets/images/link.svg';
import archiveImg from '../../public/assets/images/archive.svg';
import WarningModal from '../warningmodal';
import PreviewModal from '../previewmodal';
import { ActionButtonState, FilterObjectiveTypes, FilterStatusTypes, Tabs } from '../../enums';
import { tableContext } from '../managecampaigns';
import { AxiosSingleton } from '../../utils/axiosinstance';
import environment from '../env/env';
import { DataType as ManageDataType } from '../managecampaigns/tabledatatype';
import { DataType as InvoiceDataType } from '../invoices/tabledatatype';
import { logAmplitudeEvent } from '../../utils/analytics';
import { message } from '../../utils/custommessage';
import { Messages } from '../../enums/messages';
import useAuth from '../../hooks/useAuth';
import { PublisherRoles } from '../../enums/publisherroles';

type ManageProps = {
  name: string;
  data: ManageDataType;
  tabType: string;
};

export const ManageNameCell = ({ name, data, tabType }: ManageProps) => {
  const { reload } = useContext(tableContext);
  const { role } = useAuth();
  const [archiveState, setArchiveState] = useState<ActionButtonState>(ActionButtonState.default);
  const [copyState, setCopyState] = useState<ActionButtonState>(ActionButtonState.default);
  const [showArchiveWarningModal, setShowArchiveWarningModal] = useState(false);
  const [showCreativePreviewModal, setShowCreativePreviewModal] = useState(false);

  const isUserDMS =
    role === PublisherRoles.DMSAdmin ||
    role === PublisherRoles.DMSPublisher ||
    role === PublisherRoles.DMSAdvertiser ||
    role === PublisherRoles.DMSTrafficker ||
    role === PublisherRoles.DMS_CRM;
  const isPublisherAnghamiOrCommercial = data.publisher_id === 1 || data.publisher_id === 2;
  const shouldShowCampaignPreviewButton =
    role === PublisherRoles.AnghamiAdvertiser || role === PublisherRoles.DMSAdvertiser;
  const showEditActions =
    !(isUserDMS && isPublisherAnghamiOrCommercial) &&
    !shouldShowCampaignPreviewButton &&
    !data.isBls;

  let renderedComponent = null;

  switch (data?.objective) {
    case FilterObjectiveTypes.leadGeneration:
      renderedComponent = (
        <Tooltip title="Lead Generation" placement="top">
          <span className="NameCell-objective-badge">
            <span className="NameCell-objective-img">
              <img alt="lead generation" src={leadGenerationImg} />
            </span>
          </span>
        </Tooltip>
      );
      break;

    case FilterObjectiveTypes.audienceReach:
      renderedComponent = (
        <Tooltip title="Audience Reach" placement="top">
          <span className="NameCell-objective-badge">
            <span className="NameCell-objective-img">
              <img alt="audience reach" src={audienceReachImg} />
            </span>
          </span>
        </Tooltip>
      );
      break;

    case FilterObjectiveTypes.webConversion:
      renderedComponent = (
        <Tooltip title="Web Conversion" placement="top">
          <span className="NameCell-objective-badge">
            <span className="NameCell-objective-img">
              <img alt="web conversion" src={webConversionImg} />
            </span>
          </span>
        </Tooltip>
      );
      break;
    default:
  }

  let endpoint = '';
  switch (tabType) {
    case Tabs.Campaigns:
      endpoint = '/group-campaigns/';
      break;
    case Tabs.AdsSets:
      endpoint = '/campaigns/';
      break;
    case Tabs.Creatives:
      endpoint = '/ads/';
      break;

    default:
  }

  const handleOnArchive = () => {
    setArchiveState(ActionButtonState.loading);
    AxiosSingleton.axiosInstance
      .post<{ message: string; error: string }>(
        `${environment.getBaseAPIUrl() + endpoint}destroy`,
        {
          ids: [data.id]
        }
      )
      .then((response) => {
        if (response.data.message === 'success') {
          setArchiveState(ActionButtonState.default);
          reload();
          logAmplitudeEvent(AmplitudeEvents.campaignAction, {
            type: 'archive',
            objectid: [data.id],
            select_all: false
          });
          message.success(Messages.ArchivedSuccessfully);
          setShowArchiveWarningModal(false);
        } else {
          setArchiveState(ActionButtonState.error);
          message.error(response.data.error || Messages.FailedActivated);
          setShowArchiveWarningModal(false);
        }
      })
      .catch((e) => {
        setArchiveState(ActionButtonState.error);
        message.error(e.message || Messages.FailedArchived);
        setShowArchiveWarningModal(false);
      });
  };

  const handleCopyToClipboard = () => {
    copy(data.id.toString());
    setCopyState(ActionButtonState.done);

    if (copyState === ActionButtonState.default) {
      message.success(Messages.CopiedSuccessfully);
    }

    setTimeout(() => {
      setCopyState(ActionButtonState.default);
    }, 2000);
  };

  const renderNameCellImg = () => {
    if (data?.banner_en) {
      return (
        <span className="NameCell-img">
          <img src={data.banner_en} alt="" />
        </span>
      );
    }

    if (data?.format === 'song' && data?.song?.coverArt) {
      return (
        <span className="NameCell-img">
          <img src={data.song.coverArt} alt="" />
        </span>
      );
    }

    if (tabType === Tabs.Creatives) {
      return <div className="NameCell-img-placeholder" />;
    }
  };

  return (
    <div className="NameCell">
      {renderNameCellImg()}
      <div className="NameCell-text-overflow">
        <div className="NameCell-name">
          {renderedComponent || null}
          <span>{name}</span>
        </div>
        <div className="NameCell-actions">
          {showEditActions && (
            <span
              className="NameCell-actions-action"
              onClick={() => {
                logAmplitudeEvent(AmplitudeEvents.campaignAction, {
                  type: 'edit',
                  objectid: [data.id],
                  select_all: false
                });
                window.open(`/campaign/edit/${data.group_campaign_id || data.id}`);
              }}
            >
              <img src={editImg} /> Edit
            </span>
          )}
          {tabType === Tabs.Creatives && data.image_en && (
            <span
              className="NameCell-actions-action"
              onClick={() => {
                logAmplitudeEvent(AmplitudeEvents.campaignAction, {
                  type: 'preview',
                  objectid: [data.id],
                  select_all: false
                });
                setShowCreativePreviewModal(true);
              }}
            >
              <img src={editImg} /> Preview
            </span>
          )}
          {shouldShowCampaignPreviewButton &&
            (tabType === Tabs.Campaigns || tabType === Tabs.AdsSets) && (
              <Link to={`/campaign/preview/${data.group_campaign_id || data.id}`}>
                <span
                  className="NameCell-actions-action"
                  onClick={() => {
                    logAmplitudeEvent(AmplitudeEvents.campaignAction, {
                      type: 'preview',
                      objectid: [data.id],
                      select_all: false
                    });
                  }}
                >
                  <img src={insightsImg} /> Preview
                </span>
              </Link>
            )}
          {(tabType === Tabs.Campaigns || (tabType === Tabs.AdsSets &&
          (data.status === FilterStatusTypes.running ||
            data.status === FilterStatusTypes.paused ||
            data.status === FilterStatusTypes.completed))) ? (

            <Link
              to={`/insights/${tabType === Tabs.AdsSets ? 'campaign' : 'groupcampaign'}/${data.id}`}
            >
              <span
                className="NameCell-actions-action"
                onClick={() => {
                  logAmplitudeEvent(AmplitudeEvents.campaignAction, {
                    type: 'insights',
                    objectid: [data.id],
                    select_all: false
                  });
                }}
              >
                <img src={insightsImg} /> Insights
              </span>
            </Link>
          ) : null}
          {!data.deleted_at && showEditActions && (
            <span
              className="NameCell-actions-action"
              onClick={() => {
                setShowArchiveWarningModal(true);
              }}
            >
              {archiveState === 'loading' ? (
                <SyncOutlined spin />
              ) : archiveState === 'error' ? (
                <CloseOutlined />
              ) : (
                <img src={archiveImg} />
              )}
              Archive
            </span>
          )}
          <span className="NameCell-actions-action" onClick={handleCopyToClipboard}>
            {copyState === 'done' ? <CheckOutlined /> : <img src={linkImg} />} Copy ID
          </span>
        </div>
      </div>
      <Modal
        centered
        visible={showArchiveWarningModal}
        footer={null}
        closable={false}
        width="26rem"
      >
        <WarningModal
          onOk={handleOnArchive}
          onCancel={() => {
            setShowArchiveWarningModal(false);
          }}
          titleText="Archive"
          subtitleText="Are you sure you want to archive this item?"
          okButtonText="Yes"
          cancelButtonText="Cancel"
        />
      </Modal>
      <Modal
        centered
        visible={showCreativePreviewModal}
        footer={null}
        closable
        width="45rem"
        destroyOnClose
        onCancel={() => {
          setShowCreativePreviewModal(false);
        }}
      >
        <PreviewModal creativeData={data} />
      </Modal>
    </div>
  );
};

type InvoiceProps = {
  name: string;
  data: InvoiceDataType;
};

export const InvoiceNameCell = ({ name, data }: InvoiceProps) => {
  const [downloadState, setDownloadState] = useState<ActionButtonState>(ActionButtonState.default);

  const shouldShowDownloadButton = data.status === 3 || data.status === 5;

  const handleOnDownload = () => {
    setDownloadState(ActionButtonState.loading);
    AxiosSingleton.axiosInstance
      .get(`${environment.getBaseAPIUrl()}/billing/receipt`, {
        params: {
          i: data.id,
          p: data.publisher_id
        },
        responseType: 'blob',
        withCredentials: false
      })
      .then((response) => {
        DownloadFile(response.data, `invoice_${data.id}.pdf`);
        setDownloadState(ActionButtonState.default);
      })
      .catch(() => {
        message.error('Error downloading file', 2);
        setDownloadState(ActionButtonState.error);
      });
  };

  return (
    <div className="NameCell">
      <div className="NameCell-text-overflow">
        <div className="NameCell-name disable-hover">
          <span>{name}</span>
        </div>
      </div>
      {shouldShowDownloadButton && (
        <button type="button" className="NameCell-button" onClick={handleOnDownload}>
          {downloadState === 'loading' ? (
            <SyncOutlined spin />
          ) : downloadState === 'error' ? (
            <CloseOutlined />
          ) : (
            <FileOutlined />
          )}
          <span className="NameCell-button-text">Download</span>
        </button>
      )}
    </div>
  );
};
