import React from 'react';
import './styles.scss';

type Props = {
  children: any;
  width: string;
  height: string;
  title: string;
};

const InsightsCard = ({ children, width, height, title }: Props) => (
  <div className="insights-card-container" style={{ width, height }}>
    <div className="insights-card-title">{title}</div>
    {children}
  </div>
);

export default InsightsCard;
