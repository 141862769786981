import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Messages } from '../../enums/messages';
import useAuth from '../../hooks/useAuth';
import { AxiosSingleton } from '../../utils/axiosinstance';
import { message } from '../../utils/custommessage';
import { campaignContext, Campaign } from './campaign.context';
import { logAmplitudeEvent } from '../../utils/analytics';
import { CampaignAmplitudeEvents } from '../../enums/campaignamplitudeevents';
import { UserResponse } from '../auth/auth.context';
import { AdSetNavigation } from '../../enums/campaignnavigation';
import LoadingScreen from './LoadingScreen';
import { getTargetImpressions } from '../../helpers/campaign-form-helper';
import { AmplitudeEvents } from '@anghami/neoanalytics';
import { Publisher } from '../../interfaces/publisher.interface';
import { mediumOptions } from '../adsettargeting/selectorOptions';

type Props = {
  children: any;
  mode: 'create' | 'edit' | 'preview';
};

type NavigationTopLevel = 'setup' | 'adsets' | 'review';
type NavigationAdSetSublevel = 'setup' | 'targeting' | 'creative';
type NavigationData = {
  navigationTopLevel: NavigationTopLevel;
  navigationAdSetActiveId: number;
  navigationAdSetSublevel: NavigationAdSetSublevel;
  navigationCreativeActiveId?: number;
};

export interface ICreative {
  id: number;
  creativeId: string | number;
  creativeName: string;
  creativeType: string;
  creativeTitleEn: string;
  creativeLinkEn: string;
  creativeAudioEn: string;
  creativeVideoEn: string;
  creativeImageEn: string;
  creativeButtonTextEn: string;
  creativeStartDate: string;
  creativeEndDate: string;
  creativeThirdPartyStart: string[];
  creativeThirdPartyEnd: string;
  creativeThirdPartyTap: string[];
  creativeThirdPartyQuartile: string[];
  creativeDaysOfWeek: {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
  creativeStartTime: string;
  creativeEndTime: string;
  creativeSongId: string;
  creativeSong: any;
  creativeReasonText: string;
  creativeNotes: string;
}

export interface IAdSet {
  id: number;
  audienceName: string;
  audienceGender: string[];
  audienceMinAge: string | number;
  audienceMaxAge: string | number;
  audienceCountries: string[];
  audienceCities: string[];
  audienceMobileOperators: string[];
  audiencePlatforms: string[];
  audienceMedium: string;
  audienceAppLanguage: string[];
  audienceMusicPreference: string[];
  audienceRetargeting: {
    interaction: string;
    operator: string;
    campaigns: any[];
    ads: any[];
  };
  adSetId: string;
  adSetName: string;
  adSetStartDate: string;
  adSetEndDate: string;
  adSetPriority: number | string;
  adSetTargetImpressions: string;
  adSetPaidImpressions: string;
  adSetFreeImpressions: string;
  adSetToggleTargetStreams: boolean;
  adSetTargetStreams: string;
  adSetDeliveryRotation: number | string;
  adSetFrequencies: any[];
  adSetBudget: string | number;
  adSetShowFirstToggled: boolean;
  adSetAudienceId: string | number;
  adSetFeatureSupport: string;
  adSetPlaylists: any[];
  adSetType: 'audio' | 'video' | 'display' | 'song';
  adSetAdType: string;
  adSetBookingNumber: string;
  adSetBookingValue: string;
  adSetBookingValueCurrency: string;
  adSetStartTime: string;
  adSetEndTime: string;
  adSetForm: {
    name: string;
    description: string;
    terms: string;
    button_cta: string;
    image: string;
    fields: {
      fullname: boolean;
      email: boolean;
      msidn: boolean;
      birthday: boolean;
      gender: boolean;
    };
  };
  creatives: ICreative[];
  adSetEditMode?: string;
}

export default function CampaignHoc({ children, mode }: Props) {
  const axios = AxiosSingleton.axiosInstance;
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const [groupCampaignId, setGroupCampaignId] = useState('');
  const [groupCampaignName, setGroupCampaignName] = useState('');
  const [groupCampaignStatus, setGroupCampaignStatus] = useState<number>(-1);
  const [groupCampaignObjective, setGroupCampaignObjective] = useState<
    'reach' | 'conversion' | 'lead'
  >('reach');
  const [advertiserId, setAdvertiserId] = useState('');
  const [advertiserName, setAdvertiserName] = useState('');
  const [competitorId, setCompetitorId] = useState([]);
  const [agencyId, setAgencyId] = useState('');
  const [industryId, setIndustryId] = useState('');
  const [industryName, setIndustryName] = useState('');
  const [publisherId, setPublisherId] = useState<string>('');
  const [publisherName, setPublisherName] = useState('');
  const [specialPublishers, setSpecialPublishers] = useState<Publisher[]>([]);
  const [adSetCounter, setAdSetCounter] = useState(1); // used for generating new IDs for every newly added ad set
  const [creativesCounter, setCreativesCounter] = useState({
    0: { creativeCount: 1 }
  });

  const [isSaveAllLoading, setIsSaveAllLoading] = useState(false);
  const [isSubmitSaveAll, setIsSubmitSaveAll] = useState(false);
  const [isSaveForLaterSaveAll, setIsSaveForLaterSaveAll] = useState(false);
  const [isEditPageLoading, setIsEditPageLoading] = useState(false);

  const [navigationData, setNavigationData] = useState<NavigationData>({
    navigationTopLevel: 'setup',
    navigationAdSetActiveId: 0,
    navigationAdSetSublevel: 'setup',
    navigationCreativeActiveId: 0
  });

  useEffect(() => {
    const scrollTopCondition =
      navigationData.navigationAdSetSublevel === AdSetNavigation.Creative &&
      navigationData.navigationCreativeActiveId === 0 &&
      !window.location.href.includes('#');

    if (scrollTopCondition) {
      window.scrollTo(0, 0);
    }
  }, [navigationData]);

  useEffect(() => {
    axios.get('/admin/publishers/dms').then((res) => {
      const { data } = res;
      setSpecialPublishers(
        data.map((d) => {
          return {
            ...d,
            id: d.id.toString()
          };
        })
      );
    });
  }, []);

  const [originalCreativesMap, setOriginalCreativesMap] = useState<any>({});

  let shouldSetTopupCouponToZero = false;

  const [adSets, setAdSets] = useState<IAdSet[]>([
    {
      id: 0,
      // Audience data
      audienceName: '',
      audienceGender: ['male', 'female'],
      audienceMinAge: '',
      audienceMaxAge: '',
      audienceCountries: [],
      audienceCities: [],
      audienceMobileOperators: [],
      audienceMedium: mediumOptions[0].id,
      audiencePlatforms: ['android', 'ios', 'web'],
      audienceAppLanguage: ['en', 'ar'],
      audienceMusicPreference: ['arabic', 'intl'],
      audienceRetargeting: {
        interaction: 'all',
        operator: 'or',
        campaigns: [],
        ads: []
      },
      // Campaign (ad set) data
      adSetId: '',
      adSetName: '',
      adSetStartDate: moment().format('YYYY-MM-DD'),
      adSetEndDate: moment().add(7, 'days').format('YYYY-MM-DD'),
      adSetPriority: '',
      adSetTargetImpressions: '',
      adSetPaidImpressions: '',
      adSetFreeImpressions: '',
      adSetToggleTargetStreams: false,
      adSetTargetStreams: '',
      adSetDeliveryRotation: 1,
      adSetFrequencies: [],
      adSetBudget: '',
      adSetShowFirstToggled: false,
      adSetAudienceId: '',
      adSetFeatureSupport: '',
      adSetPlaylists: [],
      adSetType: 'audio',
      adSetAdType: 'standard',
      adSetBookingNumber: '',
      adSetBookingValue: '',
      adSetBookingValueCurrency: 'usd',
      adSetStartTime: '',
      adSetEndTime: '',
      adSetForm: {
        name: '',
        description: '',
        terms: '',
        button_cta: '',
        image: '',
        fields: {
          fullname: true,
          email: true,
          msidn: true,
          birthday: false,
          gender: false
        }
      },
      creatives: [
        {
          id: 0, // used to track creatives client side
          creativeId: '', // used to track creatives server side
          creativeName: '',
          creativeType: '',
          creativeTitleEn: '',
          creativeLinkEn: '',
          creativeAudioEn: '',
          creativeVideoEn: '',
          creativeImageEn: '',
          creativeButtonTextEn: 'adbtn_submitNow',
          creativeStartDate: '',
          creativeEndDate: '',
          creativeThirdPartyStart: [''],
          creativeThirdPartyEnd: '',
          creativeThirdPartyTap: [''],
          creativeThirdPartyQuartile: [''],
          creativeDaysOfWeek: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false
          },
          creativeStartTime: '',
          creativeEndTime: '',
          creativeSongId: '',
          creativeSong: {},
          creativeReasonText: '',
          creativeNotes: ''
        }
      ],
      adSetEditMode: 'create'
    }
  ]);

  const addEmptyAdSet = () => {
    const adSetsCopy = [...adSets];
    adSetsCopy.push({
      id: adSetCounter,
      // Audience data
      audienceName: '',
      audienceGender: ['male', 'female'],
      audienceMinAge: '',
      audienceMaxAge: '',
      audienceCountries: [],
      audienceCities: [],
      audienceMobileOperators: [],
      audienceMedium: mediumOptions[0].id,
      audiencePlatforms: ['android', 'ios', 'web'],
      audienceAppLanguage: ['en', 'ar'],
      audienceMusicPreference: ['arabic', 'intl'],
      audienceRetargeting: {
        interaction: 'all',
        operator: 'or',
        campaigns: [],
        ads: []
      },
      // Campaign (ad set) data
      adSetId: '',
      adSetName: '',
      adSetStartDate: moment().format('YYYY-MM-DD'),
      adSetEndDate: moment().add(7, 'days').format('YYYY-MM-DD'),
      adSetPriority: '',
      adSetTargetImpressions: '',
      adSetPaidImpressions: '',
      adSetFreeImpressions: '',
      adSetToggleTargetStreams: false,
      adSetTargetStreams: '',
      adSetDeliveryRotation: 1,
      adSetFrequencies: [],
      adSetBudget: '',
      adSetShowFirstToggled: false,
      adSetAudienceId: '',
      adSetFeatureSupport: '',
      adSetPlaylists: [],
      adSetType: 'audio',
      adSetAdType: 'standard',
      adSetBookingNumber: '',
      adSetBookingValue: '',
      adSetBookingValueCurrency: 'usd',
      adSetStartTime: '',
      adSetEndTime: '',
      adSetForm: {
        name: '',
        description: '',
        terms: '',
        button_cta: '',
        image: '',
        fields: {
          fullname: true,
          email: true,
          msidn: true,
          birthday: false,
          gender: false
        }
      },
      creatives: [
        {
          id: 0, // used to track creatives client side
          creativeId: '', // used to track creatives server side
          creativeName: '',
          creativeType: '',
          creativeTitleEn: '',
          creativeLinkEn: '',
          creativeAudioEn: '',
          creativeVideoEn: '',
          creativeImageEn: '',
          creativeButtonTextEn: 'adbtn_submitNow',
          creativeStartDate: '',
          creativeEndDate: '',
          creativeThirdPartyStart: [''],
          creativeThirdPartyEnd: '',
          creativeThirdPartyTap: [''],
          creativeThirdPartyQuartile: [''],
          creativeDaysOfWeek: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false
          },
          creativeStartTime: '',
          creativeEndTime: '',
          creativeSongId: '',
          creativeSong: {},
          creativeReasonText: '',
          creativeNotes: ''
        }
      ],
      adSetEditMode: 'create'
    });

    const creativesCounterCopy = {
      ...creativesCounter
    };
    creativesCounterCopy[adSetCounter] = { creativeCount: 1 };
    setCreativesCounter(creativesCounterCopy);
    setAdSets(adSetsCopy);
    setAdSetCounter(adSetCounter + 1);
  };

  const updateAdSetValue = ({ id, key, value }) => {
    const adSetsCopy = [...adSets];
    const adSetIndex = adSetsCopy.findIndex((x: any) => x.id === id);

    if (adSetIndex < 0) {
      return;
    }

    adSetsCopy[adSetIndex][key] = value;
    setAdSets(adSetsCopy);
  };

  const removeAdSetById = async (id) => {
    let adSetsCopy = [...adSets];
    const adSetIndex = adSetsCopy.findIndex((x: IAdSet) => x.id === id);

    if (adSetIndex < 0) {
      return;
    }

    const targetAdSetId = adSetsCopy[adSetIndex].adSetId;

    // Destroy adset on API only if it was saved already on API
    if (targetAdSetId) {
      AxiosSingleton.axiosInstance
        .post<{ message: string; error: string }>(`/campaigns/destroy`, {
          ids: [targetAdSetId]
        })
        .then((response) => {
          if (response.data.message === 'success') {
            logAmplitudeEvent(AmplitudeEvents.campaignAction, {
              type: 'destroy',
              objectid: [targetAdSetId],
              select_all: false
            });
            message.success(Messages.DeletedSuccessfully);
          } else {
            message.error(response.data.error || Messages.FailedDelete);
          }
        })
        .catch((e) => {
          message.error(e.message || Messages.FailedDelete);
        });
    }

    adSetsCopy = adSetsCopy.filter((set: IAdSet) => set.id !== id);

    // Send amplitude event with the first adset in the new list
    await logAmplitudeEvent(CampaignAmplitudeEvents.CreateCampaignProceed, {
      new: window.location.href.indexOf('create') > -1 ? 1 : 0,
      campaign_objective: groupCampaignObjective,
      campaign_type: adSetsCopy[0].adSetType,
      step: 'setup',
      objectid: adSetsCopy[0].adSetId
    });

    // set the navigation data to the first adset in the new list
    setNavigationData({
      ...navigationData,
      navigationAdSetActiveId: adSetsCopy[0].id,
      navigationAdSetSublevel: 'setup'
    });

    setAdSets(adSetsCopy);
  };

  const updateCreativeValue = ({ adSetId, creativeId, key, value }) => {
    const adSetsCopy = [...adSets];
    const adSetIndex = adSetsCopy.findIndex((x: any) => x.id === adSetId);

    if (adSetIndex < 0) {
      return;
    }

    const { creatives } = adSetsCopy[adSetIndex];
    const creativeIndex = creatives.findIndex((x: any) => x.id === creativeId);

    if (creativeIndex < 0) {
      return;
    }

    creatives[creativeIndex][key] = value;
    adSetsCopy[adSetIndex].creatives = creatives;

    setAdSets(adSetsCopy);
  };

  const addEmptyCreative = ({ adSetId }) => {
    const adSetsCopy = [...adSets];
    const adSetIndex = adSetsCopy.findIndex((x: any) => x.id === adSetId);

    if (adSetIndex < 0) {
      return;
    }

    adSetsCopy[adSetIndex].creatives.push({
      id: creativesCounter[adSetIndex].creativeCount, // used to track creatives client side
      creativeId: '', // used to track creatives server side
      creativeName: '',
      creativeType: '',
      creativeTitleEn: '',
      creativeLinkEn: '',
      creativeAudioEn: '',
      creativeVideoEn: '',
      creativeImageEn: '',
      creativeButtonTextEn: 'adbtn_submitNow',
      creativeStartDate: '',
      creativeEndDate: '',
      creativeThirdPartyStart: [''],
      creativeThirdPartyEnd: '',
      creativeThirdPartyTap: [''],
      creativeThirdPartyQuartile: [''],
      creativeDaysOfWeek: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
      },
      creativeStartTime: '',
      creativeEndTime: '',
      creativeSongId: '',
      creativeSong: {},
      creativeReasonText: '',
      creativeNotes: ''
    });

    const creativesCounterCopy = {
      ...creativesCounter
    };
    creativesCounterCopy[adSetIndex].creativeCount += 1;
    setCreativesCounter(creativesCounterCopy);
    setAdSets(adSetsCopy);
    logAmplitudeEvent(CampaignAmplitudeEvents.AddCreative, {});
  };

  const removeCreativeById = ({ adSetId, creativeId }) => {
    const adSetsCopy = [...adSets];
    const adSetIndex = adSetsCopy.findIndex((x: IAdSet) => x.id === adSetId);

    if (adSetIndex < 0) {
      return;
    }

    const creativeIndex = adSetsCopy[adSetIndex].creatives.findIndex(
      (x: ICreative) => x.id === creativeId
    );

    if (creativeIndex < 0) {
      return;
    }

    adSetsCopy[adSetIndex].creatives = adSetsCopy[adSetIndex].creatives.filter(
      (creative: ICreative) => creative.id !== creativeId
    );

    setAdSets(adSetsCopy);
  };

  // The code below is written for the following reasons
  // 1. If a user edits a campaign that is live, API need to pause that campaign
  // 2. Old code diffs the new array of creatives to the one returned by the API in edit mode
  // 3. I'm copying the logic word for word cause this shouldn't even be handled client side

  const generatePauseCampaignObject = (creatives, adSetId) => {
    const pauseCampaign = { action: false, reason: '' };
    const originalCreatives = originalCreativesMap[adSetId];
    if (!originalCreatives) {
      return pauseCampaign;
    }

    if (originalCreatives.length !== creatives.length) {
      pauseCampaign.action = true;
      pauseCampaign.reason += 'Number of creatives has changed. ';
    }

    creatives.forEach((creative, index) => {
      if (originalCreatives[index] !== undefined) {
        if (originalCreatives[index].creativeAudioEn !== creative.creativeAudioEn) {
          pauseCampaign.action = true;
          pauseCampaign.reason += 'Audio changed. ';
        }
        if (originalCreatives[index].creativeTitleEn !== creative.creativeTitleEn) {
          pauseCampaign.action = true;
          pauseCampaign.reason += 'Title changed. ';
        }
        if (originalCreatives[index].creativeImageEn !== creative.creativeImageEn) {
          pauseCampaign.action = true;
          pauseCampaign.reason += 'Image changed. ';
        }
        if (originalCreatives[index].creativeLinkEn !== creative.creativeLinkEn) {
          pauseCampaign.action = true;
          pauseCampaign.reason += 'Link changed. ';
        }
        if (originalCreatives[index].creativeVideoEn !== creative.creativeVideoEn) {
          pauseCampaign.action = true;
          pauseCampaign.reason += 'Video changed. ';
        }
      }
    });

    return pauseCampaign;
  };

  const saveAll = async ({ draft }) => {
    setIsSaveAllLoading(true);
    if (draft) {
      setIsSaveForLaterSaveAll(true);
    } else {
      setIsSubmitSaveAll(true);
    }
    await Promise.all(
      adSets.map(async (adSet: IAdSet) => {
        const { action, reason } = generatePauseCampaignObject(adSet.creatives, adSet.adSetId);
        const applyTopup =
          user?.billing.topup_coupon &&
          Number(adSet.adSetBudget) >= 250 &&
          adSet.adSetId === adSets[0].adSetId;
        const AdPublisherId = specialPublishers.find((p) => p.id === publisherId?.toString())
          ? publisherId
          : undefined;
        const params = {
          audience: adSet.adSetFeatureSupport
            ? {}
            : {
                id: adSet.adSetAudienceId,
                name: `AUDIENCE - ${adSet.adSetName} - ${Math.floor(Math.random() * 9999 + 1000)}`,
                gender: adSet.audienceGender.join(','),
                age_min: adSet.audienceMinAge,
                age_max: adSet.audienceMaxAge,
                countries: adSet.audienceCountries.join(','), // comma separated array values
                cities: adSet.audienceCities.join(','), // comma separated array values
                mobile_operators: adSet.audienceMobileOperators.join(','),
                medium: adSet.audienceMedium,
                platforms:
                  adSet.audienceMedium === mediumOptions[0].id
                    ? adSet.audiencePlatforms.join(',')
                    : '', // comma separated array values
                app_language: adSet.audienceAppLanguage.join(','), // comma separated array values
                music_preference: adSet.audienceMusicPreference.join(','), // comma separated array values
                retargeting: adSet.audienceRetargeting,
                publisher_id: AdPublisherId
              },
          campaign: {
            group_campaign_id: groupCampaignId,
            id: adSet.adSetId,
            name: adSet.adSetName,
            start_date: adSet.adSetStartDate,
            end_date: adSet.adSetEndDate,
            priority: adSet.adSetPriority,
            target_impressions: getTargetImpressions(
              adSet.adSetTargetImpressions,
              adSet.adSetPaidImpressions,
              adSet.adSetFreeImpressions,
              adSet.adSetShowFirstToggled
            ),
            paid_impressions: adSet.adSetPaidImpressions,
            free_impressions: adSet.adSetFreeImpressions,
            streams_based: adSet.adSetToggleTargetStreams,
            target_streams: adSet.adSetShowFirstToggled ? 0 : adSet.adSetTargetStreams,
            delivery_rotation: adSet.adSetDeliveryRotation,
            frequencies:
              adSet.adSetFrequencies.length > 0
                ? adSet.adSetFrequencies.map((freq) => `${freq.count}:${freq.duration}`).join(',')
                : '',
            budget: applyTopup ? Number(adSet.adSetBudget) + 250 : adSet.adSetBudget, // API wants me to add the 250 for clients with coupons on the client side, don't know why.
            show_first: adSet.adSetShowFirstToggled,
            topup_value: applyTopup ? '250' : '',
            audience_id: adSet.adSetAudienceId,
            feature_support: adSet.adSetFeatureSupport, // for anghami and dms
            playlists: adSet.adSetPlaylists, // for dms
            objective: groupCampaignObjective,
            type: adSet.adSetType,
            ad_type: adSet.adSetAdType,
            booking_number: adSet.adSetBookingNumber, // dms
            booking_value: adSet.adSetBookingValue, // dms
            booking_value_currency: adSet.adSetBookingValueCurrency, // dms
            advertiser_id: advertiserId,
            competitor_id: competitorId.join(','),
            agency_id: agencyId,
            industry_id: industryId,
            start_time: adSet.adSetStartTime, // for scheduled ad sets
            end_time: adSet.adSetEndTime, // for scheduled ad sets
            form: adSet.adSetForm,
            form_name: adSet.adSetForm.name,
            savedraft: draft,
            publisher_id: AdPublisherId
          },
          ads: adSet.creatives.map((creative: any) => ({
            id: creative.creativeId,
            name: creative.creativeName,
            type: adSet.adSetType, // 'display' | 'audio' | 'video';
            title_en: creative.creativeTitleEn,
            link_en: creative.creativeLinkEn,
            audio_en: creative.creativeAudioEn,
            video_en: creative.creativeVideoEn,
            image_en: creative.creativeImageEn,
            button_text_en: creative.creativeButtonTextEn,
            start_date: creative.creativeStartDate,
            end_date: creative.creativeEndDate,
            advertiser_id: advertiserId,
            thirdparty_start: creative.creativeThirdPartyStart.join(','),
            thirdparty_end: creative.creativeThirdPartyEnd,
            thirdparty_tap: creative.creativeThirdPartyTap.join(','),
            thirdparty_quartile: creative.creativeThirdPartyQuartile.join(','),
            daysofweek: creative.creativeDaysOfWeek, // for scheduled ads
            start_time: creative.creativeStartTime, // for scheduled ads
            end_time: creative.creativeEndTime, // for scheduled ads;
            song_id: creative.creativeSongId,
            song: creative.creativeSong,
            reason_text: creative.creativeReasonText,
            notes: creative.creativeNotes,
            is_new_ad: adSet.adSetEditMode === 'edit' && !String(creative.creativeId), // helping API resolve its confusions
            publisher_id: AdPublisherId
          })),
          pausepaidcampaign: action ? 1 : 0,
          pausepaidcampaign_reason: action
            ? `${reason}Campaign ${adSet.adSetName} [${adSet.adSetId}] will be pending for review.`
            : ''
        };

        if (applyTopup) {
          shouldSetTopupCouponToZero = true;
        }

        logAmplitudeEvent(CampaignAmplitudeEvents.SubmitAdSet, {
          new: window.location.href.indexOf('create') > -1 ? 1 : 0,
          campaign_objective: groupCampaignObjective,
          objectid: groupCampaignId,
          params
        });

        const endpoint = `adplanner/${adSet.adSetEditMode === 'edit' ? 'update' : 'store-all'}`;

        return axios.post(endpoint, params);
      })
    )
      .then(() => {
        setIsSaveAllLoading(false);
        setIsSubmitSaveAll(false);
        setIsSaveForLaterSaveAll(false);

        if (shouldSetTopupCouponToZero) {
          setUser({
            user: {
              ...user,
              billing: {
                ...user?.billing,
                topup_coupon: 0
              }
            }
          } as UserResponse);
        }

        message.success(Messages.CampaignSavedSuccessfully);
        logAmplitudeEvent(
          draft
            ? CampaignAmplitudeEvents.SaveDraftCampaign
            : CampaignAmplitudeEvents.SubmitCampaign,
          {
            new: window.location.href.indexOf('create') > -1 ? 1 : 0,
            campaign_objective: groupCampaignObjective,
            objectid: groupCampaignId
          }
        );
        navigate('/manage-campaigns');
      })
      .catch((err) => {
        setIsSaveAllLoading(false);
        setIsSubmitSaveAll(false);
        setIsSaveForLaterSaveAll(false);
        message.error(err.response.data.message);
      });
  };

  const getGroupCampaignDataAsync = async ({ id }) => {
    setIsEditPageLoading(true);
    const groupCampaignsRes = await axios.post('/group-campaigns/get', {
      campaignid: id
    });

    const groupCampaign = groupCampaignsRes.data[0];
    setGroupCampaignId(groupCampaign.id);
    setGroupCampaignName(groupCampaign.name);
    setGroupCampaignStatus(groupCampaign.status);
    setGroupCampaignObjective(groupCampaign.objective);
    setAdvertiserId(groupCampaign.advertiser_id);
    setCompetitorId(
      groupCampaign.competitor_id
        ? groupCampaign.competitor_id.split(',').map((competitor) => Number(competitor))
        : []
    );
    setAgencyId(groupCampaign.agency_id);
    setIndustryId(groupCampaign.industry_id);
    setPublisherId(groupCampaign.publisher_id.toString());

    const campaignsRes = await axios.get('/campaigns/all', {
      params: {
        group_campaign_id: `[${id}]`,
        filters: {}
      }
    });

    const campaigns = campaignsRes.data.data;

    const newAdSets = campaigns.map((set: any, index) => ({
      id: index,
      adSetId: set.id,
      adSetName: set.name,
      adSetStartDate: set.start_date,
      adSetEndDate: set.end_date,
      adSetPriority: set.priority,
      adSetTargetImpressions: set.impressions || set.target_impressions,
      adSetPaidImpressions: set.paid_impressions,
      adSetFreeImpressions: set.free_impressions,
      adSetToggleTargetStreams: Boolean(set.streams_based),
      adSetTargetStreams: set.target_streams,
      adSetDeliveryRotation: set.delivery_rotation,
      adSetFrequencies: set.frequencies
        ? set.frequencies.map((freq) => {
            const splitFreq = freq.split(':');
            return {
              count: splitFreq[0],
              duration: splitFreq[1]
            };
          })
        : [],
      adSetBudget: set.budget,
      adSetShowFirstToggled: Boolean(set.show_first),
      adSetAudienceId: set.audience_id,
      adSetFeatureSupport: set.feature_support,
      adSetPlaylists: set.playlists?.split(',') || [],
      adSetType: set.format,
      adSetAdType: set.ad_type,
      adSetBookingNumber: set.booking_number,
      adSetBookingValue: set.booking_value,
      adSetBookingValueCurrency: set.booking_value_currency,
      adSetStartTime: set.start_time,
      adSetEndTime: set.end_time,
      adSetForm: {
        name: set.form?.name || '',
        description: set.form?.description || '',
        terms: set.form?.terms || '',
        button_cta: set.form?.button_cta || '',
        image: set.form?.image || '',
        fields: set.form?.fields || {
          fullname: true,
          email: true,
          msidn: true,
          birthday: false,
          gender: false
        }
      },
      creatives: [
        {
          id: 0, // used to track creatives client side
          creativeId: '', // used to track creatives server side
          creativeName: '',
          creativeType: '',
          creativeTitleEn: '',
          creativeLinkEn: '',
          creativeAudioEn: '',
          creativeVideoEn: '',
          creativeImageEn: '',
          creativeButtonTextEn: 'adbtn_submitNow',
          creativeStartDate: '',
          creativeEndDate: '',
          creativeThirdPartyStart: [''],
          creativeThirdPartyEnd: '',
          creativeThirdPartyTap: [''],
          creativeThirdPartyQuartile: [''],
          creativeDaysOfWeek: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false
          },
          creativeStartTime: '',
          creativeEndTime: '',
          creativeSongId: '',
          creativeSong: {},
          creativeReasonText: '',
          creativeNotes: ''
        }
      ],
      adSetEditMode: set.status === -1 ? 'create' : 'edit'
    }));

    const updatedNewAdSets: any[] = [];

    await Promise.all(
      newAdSets.map(async (newSet) => {
        const audienceRes = await axios.get('/audiences/get', {
          params: {
            audience_id: newSet.adSetAudienceId
          }
        });

        const creativesRes = await axios.get('/ads/all', {
          params: {
            campaign_id: `[${newSet.adSetId}]`,
            filters: {}
          }
        });

        const isAudienceEmptyArray = audienceRes.data.length === 0;
        const audienceData = isAudienceEmptyArray
          ? {
              name: '',
              gender: 'male,female',
              age_min: null,
              age_max: null,
              countries: '',
              cities: '',
              medium: mediumOptions[0].id,
              platforms: 'android,ios,web',
              app_language: 'en,ar',
              music_preefrence: 'arabic,intl'
            }
          : audienceRes.data[0];

        // API assumes that null means all countries when the ad set type is 'song' so we have to correct them
        if (newSet.adSetType === 'song' && audienceData.countries === null) {
          audienceData.countries = 'all';
        }

        let creativesData;

        if (mode === 'preview') {
          // When in preview mode, API can't return creatives data when we call /ads/all (idk why)
          // so we have to call /campaigns/get instead where they return the creatives data as
          // property of the campaign object
          const alternativeCampaignData = await axios.post('/campaigns/get', {
            campaignid: newSet.adSetId
          });
          creativesData = alternativeCampaignData.data.data[0].ads;
        } else {
          creativesData = creativesRes.data.data;
        }

        const audienceRetargeting = isAudienceEmptyArray
          ? {
              interaction: 'all',
              operator: 'or',
              campaigns: [],
              ads: []
            }
          : JSON.parse(audienceData.retargeting);

        updatedNewAdSets.push({
          ...newSet,
          audienceName: audienceData.name,
          audienceGender: audienceData.gender?.split(',') || [],
          audienceMinAge: audienceData.age_min,
          audienceMaxAge: audienceData.age_max,
          audienceCountries: audienceData.countries?.split(',') || [],
          audienceCities: audienceData.cities?.split(',') || [],
          audienceMobileOperators: audienceData.mobile_operators?.split(',') || [],
          audienceMedium: audienceData.medium,
          audiencePlatforms: audienceData.platforms?.split(',') || [],
          audienceAppLanguage: audienceData.app_language?.split(',') || [],
          audienceMusicPreference: audienceData.music_preference?.split(',') || [],
          audienceRetargeting: {
            interaction: audienceRetargeting?.interaction || 'all',
            operator: audienceRetargeting?.operator || 'or',
            campaigns: audienceRetargeting?.campaigns || [],
            ads: audienceRetargeting?.ads || []
          },
          creatives:
            creativesData.length > 0
              ? creativesData.map((creative, index) => ({
                  id: index, // used to track creatives client side
                  creativeId: creative.id, // used to track creatives server side
                  creativeName: creative.name,
                  creativeType: '',
                  creativeTitleEn: creative.title_en,
                  creativeLinkEn: creative.link_en,
                  creativeAudioEn: creative.audio_en,
                  creativeVideoEn: creative.video_en,
                  creativeImageEn: creative.image_raw, // need to rely on image raw because API gets confused if we send the formatted version of image_en they return
                  creativeButtonTextEn: creative.button_text_en,
                  creativeStartDate: creative.start_date,
                  creativeEndDate: creative.end_date,
                  creativeThirdPartyStart: creative.thirdparty_start || [''],
                  creativeThirdPartyEnd: creative.thirdparty_end,
                  creativeThirdPartyTap: creative.thirdparty_tap || [''],
                  creativeThirdPartyQuartile: creative.thirdparty_quartile || [''],
                  creativeDaysOfWeek: creative.daysofweek,
                  creativeStartTime: creative.start_time,
                  creativeEndTime: creative.end_time,
                  creativeSongId: creative.song?.id,
                  creativeSong: creative.song,
                  creativeReasonText: creative.notes,
                  creativeNotes: creative.notes
                }))
              : [
                  {
                    id: 0, // used to track creatives client side
                    creativeId: '', // used to track creatives server side
                    creativeName: '',
                    creativeType: '',
                    creativeTitleEn: '',
                    creativeLinkEn: '',
                    creativeAudioEn: '',
                    creativeVideoEn: '',
                    creativeImageEn: '',
                    creativeButtonTextEn: 'adbtn_submitNow',
                    creativeStartDate: '',
                    creativeEndDate: '',
                    creativeThirdPartyStart: [''],
                    creativeThirdPartyEnd: '',
                    creativeThirdPartyTap: [''],
                    creativeThirdPartyQuartile: [''],
                    creativeDaysOfWeek: {
                      monday: false,
                      tuesday: false,
                      wednesday: false,
                      thursday: false,
                      friday: false,
                      saturday: false,
                      sunday: false
                    },
                    creativeStartTime: '',
                    creativeEndTime: '',
                    creativeSongId: '',
                    creativeSong: {},
                    creativeReasonText: '',
                    creativeNotes: ''
                  }
                ]
        });
      })
    );

    const newCreativesCounter = {
      0: { creativeCount: 1 }
    };

    updatedNewAdSets.forEach((set, index) => {
      newCreativesCounter[index] = {
        creativeCount: set.creatives.length
      };
    });

    if (updatedNewAdSets.length === 0) {
      setIsEditPageLoading(false);
      return;
    }

    setAdSetCounter(updatedNewAdSets.length);

    setCreativesCounter(newCreativesCounter);
    setAdSets(updatedNewAdSets);

    // cache old creatives
    const originalCreativesTemporaryObject = {};
    updatedNewAdSets.forEach((adSet: IAdSet) => {
      originalCreativesTemporaryObject[adSet.adSetId] = [];
      adSet.creatives.forEach((creative) => {
        originalCreativesTemporaryObject[adSet.adSetId].push({
          ...creative
        });
      });
    });
    setOriginalCreativesMap({ ...originalCreativesTemporaryObject });
    setIsEditPageLoading(false);
  };

  // The code below is written for the following reasons
  // 1. When creating or editing a lead generation form, API needs a parameter called form_name
  // 2. form_name is generated by the API, so we need to create the form to get the form_name
  // 3. API can't create the form unless it has a form name
  // 4. Updating the form's data needs to occur separately from the saving logic as a result

  const updateLeadGenForm = async (adSet: IAdSet, shouldPreviewForm: boolean) => {
    const params = {
      campaign_name: adSet.adSetName,
      ...adSet.adSetForm
    };

    const { data } = await axios.post('/adplanner/customize-form', params);

    if (!data.form_name) {
      return;
    }

    updateAdSetValue({
      id: adSet.id,
      key: 'adSetForm',
      value: {
        ...adSet.adSetForm,
        name: data.form_name
      }
    });

    if (shouldPreviewForm) {
      const newwindow = window.open(
        `https://campaigns.anghami.com/form/${data.form_name}`,
        'preview',
        'height=650,width=400'
      );
      if (newwindow) {
        newwindow.focus();
      }
      return false;
    }
  };

  const campaign: Campaign = {
    mode,
    groupCampaignId,
    setGroupCampaignId,
    groupCampaignName,
    setGroupCampaignName,
    groupCampaignStatus,
    setGroupCampaignStatus,
    groupCampaignObjective,
    setGroupCampaignObjective,
    advertiserId,
    advertiserName,
    setAdvertiserId,
    setAdvertiserName,
    competitorId,
    setCompetitorId,
    agencyId,
    setAgencyId,
    industryId,
    industryName,
    setIndustryId,
    setIndustryName,
    publisherId,
    publisherName,
    setPublisherId,
    setPublisherName,
    adSets,
    setAdSets,
    addEmptyAdSet,
    updateAdSetValue,
    removeAdSetById,
    updateCreativeValue,
    addEmptyCreative,
    removeCreativeById,
    saveAll,
    getGroupCampaignDataAsync,
    navigationData,
    setNavigationData,
    isSaveAllLoading,
    updateLeadGenForm,
    isSubmitSaveAll,
    isSaveForLaterSaveAll,
    specialPublishers,
    setSpecialPublishers
  };

  return (
    <campaignContext.Provider value={campaign}>
      {isEditPageLoading && <LoadingScreen />}
      {children}
    </campaignContext.Provider>
  );
}
