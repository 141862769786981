import React, { useEffect, useRef, useState } from 'react';
import { Table } from 'antd';
import SpinningLoader from '../spinningloader';
import './styles.scss';

type Props = {
  data: any[];
  tableColumns: any[]; // set to any to allow using the table in other pages like invoices
  onChange?: (selectedKeys: number[], selectedRows: object[]) => void;
  selectedIDs?: number[];
  isLoading: boolean;
  onFetch: () => void;
  isInfiniteLoading: boolean;
  rowSelection: boolean | null;
};

let scrollTimeout: ReturnType<typeof setTimeout>;

const AdsTable = ({
  data,
  tableColumns,
  onChange = () => null,
  selectedIDs = [],
  isLoading,
  onFetch,
  isInfiniteLoading = false,
  rowSelection
}: Props) => {
  const [tableHeight, setTableHeight] = useState('');
  const [refreshKey, setRefreshKey] = useState(1);

  const tableRef = useRef<HTMLDivElement>(null);
  // we have to use querySelector we don't have access to table body component
  const tBody = tableRef.current?.querySelector('.ant-table-body');

  const onScroll = (e: any) => {
    const element = e.target;
    if (element?.offsetHeight + element?.scrollTop >= element?.scrollHeight - 50) {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        onFetch();
      }) as unknown as ReturnType<typeof setTimeout>;
    }
  };

  // to trigger onFetch method on scroll end
  // to force the table to take the remaining height
  useEffect(() => {
    if (!tBody) return;
    const bodyTop = tBody.getBoundingClientRect().top;
    const height = `calc(100vh - ${bodyTop}px)`;
    setTableHeight(height);
    tBody.addEventListener('scroll', onScroll);
    return () => {
      if (!tBody) return;
      tBody.removeEventListener('scroll', onScroll);
    };
  }, [data, tableColumns, refreshKey]);

  // to reset the table scrollTop
  useEffect(() => {
    if (!tBody) return;
    setTimeout(() => {
      tBody.scrollTop = 0;
    }, 200);
  }, [tableColumns]);

  useEffect(() => {
    setRefreshKey(refreshKey + 1);
  }, []);

  return (
    <div className="AdsTable">
      <Table
        bordered
        ref={tableRef}
        pagination={false}
        rowSelection={
          rowSelection
            ? {
                type: 'checkbox',
                onChange: (selectedKeys, selectedRows) =>
                  onChange(selectedKeys as number[], selectedRows),
                selectedRowKeys: selectedIDs
              }
            : (false as any)
        }
        columns={tableColumns as any}
        dataSource={data.map((d) => {
          // Adding key to avoid every child unique key error
          return { ...d, key: d.id };
        })}
        scroll={{ x: 1500, y: tableHeight }}
      />

      {isLoading ? (
        <div className="AdsTable-loader">
          <SpinningLoader />
        </div>
      ) : null}

      <div className={`AdsTable-infinite-loader ${isInfiniteLoading ? 'active' : ''}`}>
        <div className="AdsTable-infinite-loader-wrapper">
          <SpinningLoader />
        </div>
      </div>
    </div>
  );
};

export default AdsTable;
