export const reportTypeOptions = [{ id: 'financial', text: 'Financial' }];

export const reportDimensionsOptions = [
  {
    id: 'advertiser_id',
    text: 'Advertiser',
    path: '/adplanner/advertisers',
    options: []
  },
  {
    id: 'industry_id',
    text: 'Industry',
    path: '/adplanner/industries',
    options: []
  },
  {
    id: 'agency_id',
    text: 'Agency',
    path: '/adplanner/agencies',
    options: []
  },
  {
    id: 'publisher_id',
    text: 'Publisher',
    path: '/admin/publishers/all',
    options: []
  },
  {
    id: 'campaigntype',
    text: 'Ad Product',
    path: '',
    options: [
      { id: 'audio', text: 'Audio' },
      { id: 'display', text: 'Display' },
      { id: 'playlist', text: 'Playlist' }
    ]
  },
  {
    id: 'region',
    text: 'Region',
    path: '',
    options: [
      { id: 'gcc', text: 'GCC' },
      { id: 'levant', text: 'LEVANT' },
      { id: 'na', text: 'NORTH AFRICA' }
    ]
  },
  {
    id: 'country',
    text: 'Country',
    path: '',
    options: [
      { id: 'AF', text: 'Afghanistan', icon: 'flag-icon flag-icon-af' },
      { id: 'AX', text: 'Åland Islands', icon: 'flag-icon flag-icon-ax' },
      { id: 'AL', text: 'Albania', icon: 'flag-icon flag-icon-al' },
      { id: 'DZ', text: 'Algeria', icon: 'flag-icon flag-icon-dz' },
      { id: 'AS', text: 'American Samoa', icon: 'flag-icon flag-icon-as' },
      { id: 'AD', text: 'Andorra', icon: 'flag-icon flag-icon-ad' },
      { id: 'AO', text: 'Angola', icon: 'flag-icon flag-icon-ao' },
      { id: 'AI', text: 'Anguilla', icon: 'flag-icon flag-icon-ai' },
      { id: 'AQ', text: 'Antarctica', icon: 'flag-icon flag-icon-aq' },
      { id: 'AG', text: 'Antigua and Barbuda', icon: 'flag-icon flag-icon-ag' },
      { id: 'AR', text: 'Argentina', icon: 'flag-icon flag-icon-ar' },
      { id: 'AM', text: 'Armenia', icon: 'flag-icon flag-icon-am' },
      { id: 'AW', text: 'Aruba', icon: 'flag-icon flag-icon-aw' },
      { id: 'AU', text: 'Australia', icon: 'flag-icon flag-icon-au' },
      { id: 'AT', text: 'Austria', icon: 'flag-icon flag-icon-at' },
      { id: 'AZ', text: 'Azerbaijan', icon: 'flag-icon flag-icon-az' },
      { id: 'BS', text: 'Bahamas', icon: 'flag-icon flag-icon-bs' },
      { id: 'BH', text: 'Bahrain', icon: 'flag-icon flag-icon-bh' },
      { id: 'BD', text: 'Bangladesh', icon: 'flag-icon flag-icon-bd' },
      { id: 'BB', text: 'Barbados', icon: 'flag-icon flag-icon-bb' },
      { id: 'BY', text: 'Belarus', icon: 'flag-icon flag-icon-by' },
      { id: 'BE', text: 'Belgium', icon: 'flag-icon flag-icon-be' },
      { id: 'BZ', text: 'Belize', icon: 'flag-icon flag-icon-bz' },
      { id: 'BJ', text: 'Benin', icon: 'flag-icon flag-icon-bj' },
      { id: 'BM', text: 'Bermuda', icon: 'flag-icon flag-icon-bm' },
      { id: 'BT', text: 'Bhutan', icon: 'flag-icon flag-icon-bt' },
      { id: 'BO', text: 'Bolivia', icon: 'flag-icon flag-icon-bo' },
      { id: 'BA', text: 'Bosnia and Herzegovina', icon: 'flag-icon flag-icon-ba' },
      { id: 'BW', text: 'Botswana', icon: 'flag-icon flag-icon-bw' },
      { id: 'BV', text: 'Bouvet Island', icon: 'flag-icon flag-icon-bv' },
      { id: 'BR', text: 'Brazil', icon: 'flag-icon flag-icon-br' },
      { id: 'IO', text: 'British Indian Ocean Territory', icon: 'flag-icon flag-icon-io' },
      { id: 'BN', text: 'Brunei Darussalam', icon: 'flag-icon flag-icon-bn' },
      { id: 'BG', text: 'Bulgaria', icon: 'flag-icon flag-icon-bg' },
      { id: 'BF', text: 'Burkina Faso', icon: 'flag-icon flag-icon-bf' },
      { id: 'BI', text: 'Burundi', icon: 'flag-icon flag-icon-bi' },
      { id: 'KH', text: 'Cambodia', icon: 'flag-icon flag-icon-kh' },
      { id: 'CM', text: 'Cameroon', icon: 'flag-icon flag-icon-cm' },
      { id: 'CA', text: 'Canada', icon: 'flag-icon flag-icon-ca' },
      { id: 'CV', text: 'Cape Verde', icon: 'flag-icon flag-icon-cv' },
      { id: 'KY', text: 'Cayman Islands', icon: 'flag-icon flag-icon-ky' },
      { id: 'CF', text: 'Central African Republic', icon: 'flag-icon flag-icon-cf' },
      { id: 'TD', text: 'Chad', icon: 'flag-icon flag-icon-td' },
      { id: 'CL', text: 'Chile', icon: 'flag-icon flag-icon-cl' },
      { id: 'CN', text: 'China', icon: 'flag-icon flag-icon-cn' },
      { id: 'CX', text: 'Christmas Island', icon: 'flag-icon flag-icon-cx' },
      { id: 'CC', text: 'Cocos (Keeling) Islands', icon: 'flag-icon flag-icon-cc' },
      { id: 'CO', text: 'Colombia', icon: 'flag-icon flag-icon-co' },
      { id: 'KM', text: 'Comoros', icon: 'flag-icon flag-icon-km' },
      { id: 'CG', text: 'Congo', icon: 'flag-icon flag-icon-cg' },
      { id: 'CD', text: 'Congo, The Democratic Republic of the', icon: 'flag-icon flag-icon-cd' },
      { id: 'CK', text: 'Cook Islands', icon: 'flag-icon flag-icon-ck' },
      { id: 'CR', text: 'Costa Rica', icon: 'flag-icon flag-icon-cr' },
      { id: 'CI', text: "Cote D'Ivoire", icon: 'flag-icon flag-icon-ci' },
      { id: 'HR', text: 'Croatia', icon: 'flag-icon flag-icon-hr' },
      { id: 'CU', text: 'Cuba', icon: 'flag-icon flag-icon-cu' },
      { id: 'CY', text: 'Cyprus', icon: 'flag-icon flag-icon-cy' },
      { id: 'CZ', text: 'Czech Republic', icon: 'flag-icon flag-icon-cz' },
      { id: 'DK', text: 'Denmark', icon: 'flag-icon flag-icon-dk' },
      { id: 'DJ', text: 'Djibouti', icon: 'flag-icon flag-icon-dj' },
      { id: 'DM', text: 'Dominica', icon: 'flag-icon flag-icon-dm' },
      { id: 'DO', text: 'Dominican Republic', icon: 'flag-icon flag-icon-do' },
      { id: 'EC', text: 'Ecuador', icon: 'flag-icon flag-icon-ec' },
      { id: 'EG', text: 'Egypt', icon: 'flag-icon flag-icon-eg' },
      { id: 'SV', text: 'El Salvador', icon: 'flag-icon flag-icon-sv' },
      { id: 'GQ', text: 'Equatorial Guinea', icon: 'flag-icon flag-icon-gq' },
      { id: 'ER', text: 'Eritrea', icon: 'flag-icon flag-icon-er' },
      { id: 'EE', text: 'Estonia', icon: 'flag-icon flag-icon-ee' },
      { id: 'ET', text: 'Ethiopia', icon: 'flag-icon flag-icon-et' },
      { id: 'FK', text: 'Falkland Islands (Malvinas)', icon: 'flag-icon flag-icon-fk' },
      { id: 'FO', text: 'Faroe Islands', icon: 'flag-icon flag-icon-fo' },
      { id: 'FJ', text: 'Fiji', icon: 'flag-icon flag-icon-fj' },
      { id: 'FI', text: 'Finland', icon: 'flag-icon flag-icon-fi' },
      { id: 'FR', text: 'France', icon: 'flag-icon flag-icon-fr' },
      { id: 'GF', text: 'French Guiana', icon: 'flag-icon flag-icon-gf' },
      { id: 'PF', text: 'French Polynesia', icon: 'flag-icon flag-icon-pf' },
      { id: 'TF', text: 'French Southern Territories', icon: 'flag-icon flag-icon-tf' },
      { id: 'GA', text: 'Gabon', icon: 'flag-icon flag-icon-ga' },
      { id: 'GM', text: 'Gambia', icon: 'flag-icon flag-icon-gm' },
      { id: 'GE', text: 'Georgia', icon: 'flag-icon flag-icon-ge' },
      { id: 'DE', text: 'Germany', icon: 'flag-icon flag-icon-de' },
      { id: 'GH', text: 'Ghana', icon: 'flag-icon flag-icon-gh' },
      { id: 'GI', text: 'Gibraltar', icon: 'flag-icon flag-icon-gi' },
      { id: 'GR', text: 'Greece', icon: 'flag-icon flag-icon-gr' },
      { id: 'GL', text: 'Greenland', icon: 'flag-icon flag-icon-gl' },
      { id: 'GD', text: 'Grenada', icon: 'flag-icon flag-icon-gd' },
      { id: 'GP', text: 'Guadeloupe', icon: 'flag-icon flag-icon-gp' },
      { id: 'GU', text: 'Guam', icon: 'flag-icon flag-icon-gu' },
      { id: 'GT', text: 'Guatemala', icon: 'flag-icon flag-icon-gt' },
      { id: 'GG', text: 'Guernsey', icon: 'flag-icon flag-icon-gg' },
      { id: 'GN', text: 'Guinea', icon: 'flag-icon flag-icon-gn' },
      { id: 'GW', text: 'Guinea-Bissau', icon: 'flag-icon flag-icon-gw' },
      { id: 'GY', text: 'Guyana', icon: 'flag-icon flag-icon-gy' },
      { id: 'HT', text: 'Haiti', icon: 'flag-icon flag-icon-ht' },
      { id: 'HM', text: 'Heard Island and Mcdonald Islands', icon: 'flag-icon flag-icon-hm' },
      { id: 'VA', text: 'Holy See (Vatican City State)', icon: 'flag-icon flag-icon-va' },
      { id: 'HN', text: 'Honduras', icon: 'flag-icon flag-icon-hn' },
      { id: 'HK', text: 'Hong Kong', icon: 'flag-icon flag-icon-hk' },
      { id: 'HU', text: 'Hungary', icon: 'flag-icon flag-icon-hu' },
      { id: 'IS', text: 'Iceland', icon: 'flag-icon flag-icon-is' },
      { id: 'IN', text: 'India', icon: 'flag-icon flag-icon-in' },
      { id: 'ID', text: 'Indonesia', icon: 'flag-icon flag-icon-id' },
      { id: 'IR', text: 'Iran, Islamic Republic Of', icon: 'flag-icon flag-icon-ir' },
      { id: 'IQ', text: 'Iraq', icon: 'flag-icon flag-icon-iq' },
      { id: 'IE', text: 'Ireland', icon: 'flag-icon flag-icon-ie' },
      { id: 'IM', text: 'Isle of Man', icon: 'flag-icon flag-icon-im' },
      { id: 'IL', text: 'Israel', icon: 'flag-icon flag-icon-il' },
      { id: 'IT', text: 'Italy', icon: 'flag-icon flag-icon-it' },
      { id: 'JM', text: 'Jamaica', icon: 'flag-icon flag-icon-jm' },
      { id: 'JP', text: 'Japan', icon: 'flag-icon flag-icon-jp' },
      { id: 'JE', text: 'Jersey', icon: 'flag-icon flag-icon-je' },
      { id: 'JO', text: 'Jordan', icon: 'flag-icon flag-icon-jo' },
      { id: 'KZ', text: 'Kazakhstan', icon: 'flag-icon flag-icon-kz' },
      { id: 'KE', text: 'Kenya', icon: 'flag-icon flag-icon-ke' },
      { id: 'KI', text: 'Kiribati', icon: 'flag-icon flag-icon-ki' },
      { id: 'KP', text: "Korea, Democratic People's Republic of", icon: 'flag-icon flag-icon-kp' },
      { id: 'KR', text: 'Korea, Republic of', icon: 'flag-icon flag-icon-kr' },
      { id: 'KW', text: 'Kuwait', icon: 'flag-icon flag-icon-kw' },
      { id: 'KG', text: 'Kyrgyzstan', icon: 'flag-icon flag-icon-kg' },
      { id: 'LA', text: "Lao People's Democratic Republic", icon: 'flag-icon flag-icon-la' },
      { id: 'LV', text: 'Latvia', icon: 'flag-icon flag-icon-lv' },
      { id: 'LB', text: 'Lebanon', icon: 'flag-icon flag-icon-lb' },
      { id: 'LS', text: 'Lesotho', icon: 'flag-icon flag-icon-ls' },
      { id: 'LR', text: 'Liberia', icon: 'flag-icon flag-icon-lr' },
      { id: 'LY', text: 'Libyan Arab Jamahiriya', icon: 'flag-icon flag-icon-ly' },
      { id: 'LI', text: 'Liechtenstein', icon: 'flag-icon flag-icon-li' },
      { id: 'LT', text: 'Lithuania', icon: 'flag-icon flag-icon-lt' },
      { id: 'LU', text: 'Luxembourg', icon: 'flag-icon flag-icon-lu' },
      { id: 'MO', text: 'Macao', icon: 'flag-icon flag-icon-mo' },
      {
        id: 'MK',
        text: 'Macedonia, The Former Yugoslav Republic of',
        icon: 'flag-icon flag-icon-mk'
      },
      { id: 'MG', text: 'Madagascar', icon: 'flag-icon flag-icon-mg' },
      { id: 'MW', text: 'Malawi', icon: 'flag-icon flag-icon-mw' },
      { id: 'MY', text: 'Malaysia', icon: 'flag-icon flag-icon-my' },
      { id: 'MV', text: 'Maldives', icon: 'flag-icon flag-icon-mv' },
      { id: 'ML', text: 'Mali', icon: 'flag-icon flag-icon-ml' },
      { id: 'MT', text: 'Malta', icon: 'flag-icon flag-icon-mt' },
      { id: 'MH', text: 'Marshall Islands', icon: 'flag-icon flag-icon-mh' },
      { id: 'MQ', text: 'Martinique', icon: 'flag-icon flag-icon-mq' },
      { id: 'MR', text: 'Mauritania', icon: 'flag-icon flag-icon-mr' },
      { id: 'MU', text: 'Mauritius', icon: 'flag-icon flag-icon-mu' },
      { id: 'YT', text: 'Mayotte', icon: 'flag-icon flag-icon-yt' },
      { id: 'MX', text: 'Mexico', icon: 'flag-icon flag-icon-mx' },
      { id: 'FM', text: 'Micronesia, Federated States of', icon: 'flag-icon flag-icon-fm' },
      { id: 'MD', text: 'Moldova, Republic of', icon: 'flag-icon flag-icon-md' },
      { id: 'MC', text: 'Monaco', icon: 'flag-icon flag-icon-mc' },
      { id: 'ME', text: 'Montenegro', icon: 'flag-icon flag-icon-me' },
      { id: 'MN', text: 'Mongolia', icon: 'flag-icon flag-icon-mn' },
      { id: 'MS', text: 'Montserrat', icon: 'flag-icon flag-icon-ms' },
      { id: 'MA', text: 'Morocco', icon: 'flag-icon flag-icon-ma' },
      { id: 'MZ', text: 'Mozambique', icon: 'flag-icon flag-icon-mz' },
      { id: 'MM', text: 'Myanmar', icon: 'flag-icon flag-icon-mm' },
      { id: 'NA', text: 'Namibia', icon: 'flag-icon flag-icon-na' },
      { id: 'NR', text: 'Nauru', icon: 'flag-icon flag-icon-nr' },
      { id: 'NP', text: 'Nepal', icon: 'flag-icon flag-icon-np' },
      { id: 'NL', text: 'Netherlands', icon: 'flag-icon flag-icon-nl' },
      { id: 'NC', text: 'New Caledonia', icon: 'flag-icon flag-icon-nc' },
      { id: 'NZ', text: 'New Zealand', icon: 'flag-icon flag-icon-nz' },
      { id: 'NI', text: 'Nicaragua', icon: 'flag-icon flag-icon-ni' },
      { id: 'NE', text: 'Niger', icon: 'flag-icon flag-icon-ne' },
      { id: 'NG', text: 'Nigeria', icon: 'flag-icon flag-icon-ng' },
      { id: 'NU', text: 'Niue', icon: 'flag-icon flag-icon-nu' },
      { id: 'NF', text: 'Norfolk Island', icon: 'flag-icon flag-icon-nf' },
      { id: 'MP', text: 'Northern Mariana Islands', icon: 'flag-icon flag-icon-mp' },
      { id: 'NO', text: 'Norway', icon: 'flag-icon flag-icon-no' },
      { id: 'OM', text: 'Oman', icon: 'flag-icon flag-icon-om' },
      { id: 'PK', text: 'Pakistan', icon: 'flag-icon flag-icon-pk' },
      { id: 'PW', text: 'Palau', icon: 'flag-icon flag-icon-pw' },
      { id: 'PS', text: 'Palestinian Territory, Occupied', icon: 'flag-icon flag-icon-ps' },
      { id: 'PA', text: 'Panama', icon: 'flag-icon flag-icon-pa' },
      { id: 'PG', text: 'Papua New Guinea', icon: 'flag-icon flag-icon-pg' },
      { id: 'PY', text: 'Paraguay', icon: 'flag-icon flag-icon-py' },
      { id: 'PE', text: 'Peru', icon: 'flag-icon flag-icon-pe' },
      { id: 'PH', text: 'Philippines', icon: 'flag-icon flag-icon-ph' },
      { id: 'PN', text: 'Pitcairn', icon: 'flag-icon flag-icon-pn' },
      { id: 'PL', text: 'Poland', icon: 'flag-icon flag-icon-pl' },
      { id: 'PT', text: 'Portugal', icon: 'flag-icon flag-icon-pt' },
      { id: 'PR', text: 'Puerto Rico', icon: 'flag-icon flag-icon-pr' },
      { id: 'QA', text: 'Qatar', icon: 'flag-icon flag-icon-qa' },
      { id: 'RE', text: 'Reunion', icon: 'flag-icon flag-icon-re' },
      { id: 'RO', text: 'Romania', icon: 'flag-icon flag-icon-ro' },
      { id: 'RU', text: 'Russian Federation', icon: 'flag-icon flag-icon-ru' },
      { id: 'RW', text: 'Rwanda', icon: 'flag-icon flag-icon-rw' },
      { id: 'SH', text: 'Saint Helena', icon: 'flag-icon flag-icon-sh' },
      { id: 'KN', text: 'Saint Kitts and Nevis', icon: 'flag-icon flag-icon-kn' },
      { id: 'LC', text: 'Saint Lucia', icon: 'flag-icon flag-icon-lc' },
      { id: 'PM', text: 'Saint Pierre and Miquelon', icon: 'flag-icon flag-icon-pm' },
      { id: 'VC', text: 'Saint Vincent and the Grenadines', icon: 'flag-icon flag-icon-vc' },
      { id: 'WS', text: 'Samoa', icon: 'flag-icon flag-icon-ws' },
      { id: 'SM', text: 'San Marino', icon: 'flag-icon flag-icon-sm' },
      { id: 'ST', text: 'Sao Tome and Principe', icon: 'flag-icon flag-icon-st' },
      { id: 'SA', text: 'Saudi Arabia', icon: 'flag-icon flag-icon-sa' },
      { id: 'SN', text: 'Senegal', icon: 'flag-icon flag-icon-sn' },
      { id: 'RS', text: 'Serbia', icon: 'flag-icon flag-icon-rs' },
      { id: 'SC', text: 'Seychelles', icon: 'flag-icon flag-icon-sc' },
      { id: 'SL', text: 'Sierra Leone', icon: 'flag-icon flag-icon-sl' },
      { id: 'SG', text: 'Singapore', icon: 'flag-icon flag-icon-sg' },
      { id: 'SK', text: 'Slovakia', icon: 'flag-icon flag-icon-sk' },
      { id: 'SI', text: 'Slovenia', icon: 'flag-icon flag-icon-si' },
      { id: 'SB', text: 'Solomon Islands', icon: 'flag-icon flag-icon-sb' },
      { id: 'SO', text: 'Somalia', icon: 'flag-icon flag-icon-so' },
      { id: 'ZA', text: 'South Africa', icon: 'flag-icon flag-icon-za' },
      {
        id: 'GS',
        text: 'South Georgia and the South Sandwich Islands',
        icon: 'flag-icon flag-icon-gs'
      },
      { id: 'ES', text: 'Spain', icon: 'flag-icon flag-icon-es' },
      { id: 'LK', text: 'Sri Lanka', icon: 'flag-icon flag-icon-lk' },
      { id: 'SD', text: 'Sudan', icon: 'flag-icon flag-icon-sd' },
      { id: 'SR', text: 'Suriname', icon: 'flag-icon flag-icon-sr' },
      { id: 'SJ', text: 'Svalbard and Jan Mayen', icon: 'flag-icon flag-icon-sj' },
      { id: 'SZ', text: 'Swaziland', icon: 'flag-icon flag-icon-sz' },
      { id: 'SE', text: 'Sweden', icon: 'flag-icon flag-icon-se' },
      { id: 'CH', text: 'Switzerland', icon: 'flag-icon flag-icon-ch' },
      { id: 'SY', text: 'Syrian Arab Republic', icon: 'flag-icon flag-icon-sy' },
      { id: 'TW', text: 'Taiwan, Province of China', icon: 'flag-icon flag-icon-tw' },
      { id: 'TJ', text: 'Tajikistan', icon: 'flag-icon flag-icon-tj' },
      { id: 'TZ', text: 'Tanzania, United Republic of', icon: 'flag-icon flag-icon-tz' },
      { id: 'TH', text: 'Thailand', icon: 'flag-icon flag-icon-th' },
      { id: 'TL', text: 'Timor-Leste', icon: 'flag-icon flag-icon-tl' },
      { id: 'TG', text: 'Togo', icon: 'flag-icon flag-icon-tg' },
      { id: 'TK', text: 'Tokelau', icon: 'flag-icon flag-icon-tk' },
      { id: 'TO', text: 'Tonga', icon: 'flag-icon flag-icon-to' },
      { id: 'TT', text: 'Trinidad and Tobago', icon: 'flag-icon flag-icon-tt' },
      { id: 'TN', text: 'Tunisia', icon: 'flag-icon flag-icon-tn' },
      { id: 'TR', text: 'Turkey', icon: 'flag-icon flag-icon-tr' },
      { id: 'TM', text: 'Turkmenistan', icon: 'flag-icon flag-icon-tm' },
      { id: 'TC', text: 'Turks and Caicos Islands', icon: 'flag-icon flag-icon-tc' },
      { id: 'TV', text: 'Tuvalu', icon: 'flag-icon flag-icon-tv' },
      { id: 'UG', text: 'Uganda', icon: 'flag-icon flag-icon-ug' },
      { id: 'UA', text: 'Ukraine', icon: 'flag-icon flag-icon-ua' },
      { id: 'AE', text: 'United Arab Emirates', icon: 'flag-icon flag-icon-ae' },
      { id: 'GB', text: 'United Kingdom', icon: 'flag-icon flag-icon-gb' },
      { id: 'US', text: 'United States', icon: 'flag-icon flag-icon-us' },
      { id: 'UY', text: 'Uruguay', icon: 'flag-icon flag-icon-uy' },
      { id: 'UZ', text: 'Uzbekistan', icon: 'flag-icon flag-icon-uz' },
      { id: 'VU', text: 'Vanuatu', icon: 'flag-icon flag-icon-vu' },
      { id: 'VE', text: 'Venezuela', icon: 'flag-icon flag-icon-ve' },
      { id: 'VN', text: 'Viet Nam', icon: 'flag-icon flag-icon-vn' },
      { id: 'VG', text: 'Virgin Islands, British', icon: 'flag-icon flag-icon-vg' },
      { id: 'VI', text: 'Virgin Islands, U.S.', icon: 'flag-icon flag-icon-vi' },
      { id: 'WF', text: 'Wallis and Futuna', icon: 'flag-icon flag-icon-wf' },
      { id: 'EH', text: 'Western Sahara', icon: 'flag-icon flag-icon-eh' },
      { id: 'YE', text: 'Yemen', icon: 'flag-icon flag-icon-ye' },
      { id: 'ZM', text: 'Zambia', icon: 'flag-icon flag-icon-zm' },
      { id: 'ZW', text: 'Zimbabwe', icon: 'flag-icon flag-icon-zw' }
    ]
  },
  {
    id: 'booking_number',
    text: 'BO Number',
    path: '/adplanner/bonumbers',
    options: []
  }
];

export const reportDateIdentifiers = [
  { id: 'previous', text: 'Previous' },
  { id: 'between', text: 'Between' }
];

export const reportDateTypes = [
  { id: 'days', text: 'Days' },
  { id: 'weeks', text: 'Weeks' },
  { id: 'months', text: 'Months' }
];

export const reportScheduleTypes = [
  { id: 'daily', text: 'Daily' },
  { id: 'weekly', text: 'Weekly' },
  { id: 'monthly', text: 'Monthly' }
];

export const reportScheduleDaysOfWeek = [
  { id: 'monday', text: 'Monday' },
  { id: 'tuesday', text: 'Tuesday' },
  { id: 'wednesday', text: 'Wednesday' },
  { id: 'thursday', text: 'Thursday' },
  { id: 'friday', text: 'Friday' },
  { id: 'saturday', text: 'Saturday' },
  { id: 'sunday', text: 'Sunday' }
];
