import React from 'react';
import { AmplitudeEvents } from '@anghami/neoanalytics/dist/enums/events';
import AdsTableButtons from '../adstablebuttons';
import AdsTablePagination from '../adstablepagination';
import AdsTable from '../adstable';
import SpinningLoader from '../spinningloader';
import { Tabs } from '../../enums';
import { DataType, MetaType } from '../managecampaigns/tabledatatype';
import { TableColumnType } from '../managecampaigns/tableColumns';
import { checkPlural } from '../../helpers/check-plural';
import { logAmplitudeEvent } from '../../utils/analytics';

type Props = {
  data: DataType[];
  onRowSelect: (id: number[]) => void;
  selectedRows: number[];
  tableColumns: TableColumnType[];
  tabType: Tabs;
  onPagination: (e: 'prev' | 'next' | 'reload') => void;
  paginationMeta: MetaType;
  isLoading: boolean;
  isTableLoading: boolean;
  isInfiniteLoading: boolean;
  shouldBlockEditing: boolean;
};

const AdsTab = ({
  data,
  onRowSelect,
  tableColumns,
  tabType,
  selectedRows,
  onPagination = () => null,
  paginationMeta,
  isLoading,
  isTableLoading,
  isInfiniteLoading,
  shouldBlockEditing
}: Props) => (
  <>
    <div className="ManageCampaigns-actions">
      <span className="ManageCampaigns-actions-right">
        {data.length > 0 ? (
          <span className="ManageCampaigns-actions-right-text">
            <span className="ManageCampaigns-actions-right-text-bold">Edit</span>
            <span className="ManageCampaigns-actions-right-text-grey">
              {selectedRows?.length} {tabType}
              {checkPlural(selectedRows)}
            </span>
          </span>
        ) : (
          <span />
        )}
        {data.length > 0 ? (
          <AdsTableButtons
            selectedRowsIds={selectedRows}
            tabType={tabType}
            data={data}
            onReload={() => onPagination('reload')}
            shouldBlockEditing={shouldBlockEditing}
          />
        ) : null}
      </span>
      <span className="ManageCampaigns-actions-pagination">
        {isLoading ? <SpinningLoader /> : null}
        <AdsTablePagination paginationMeta={paginationMeta} length={data.length} />
      </span>
    </div>
    {data.length > 0 ? (
      <AdsTable
        rowSelection
        data={data}
        tableColumns={tableColumns}
        onChange={(e: number[]) => {
          onRowSelect(e);
          logAmplitudeEvent(AmplitudeEvents.selectItem, {
            objectid: e,
            select_all: e.length === data.length
          });
        }}
        selectedIDs={selectedRows}
        isLoading={isTableLoading}
        isInfiniteLoading={isInfiniteLoading}
        onFetch={() => onPagination('next')}
      />
    ) : null}
  </>
);

export default AdsTab;
