import React from 'react';
import { Col, Row } from 'antd';
import '../overview/styles.scss';
import { IStats } from '../overview';

type Props = {
  stats: IStats;
};

const campaignStatusColor = {
  pending: '#D60031',
  running: '#52D57D',
  paused: '#FFAF5C',
  completed: '#0099F5'
};

const OverviewStats = ({ stats }: Props) => {
  const { campaigns } = stats;
  if (campaigns) {
    return (
        <div className="Overview-grid">
          <div className="Overview-grid-header">Total Campaigns</div>
          <Row gutter={[16, 16]} align="middle">
            <Col span={24} className="Campaigns-wrapper">
              {Object.keys(campaigns).map((status) => (
                <Col span={12} className="col-spacing" key={status}>
                  <Row gutter={[32, 32]}>
                    <Col>
                      <Row align="middle">
                        <div
                          className="campaigns-status-vertical-line"
                          style={{
                            borderColor: campaignStatusColor[status]
                          }}
                        />
                        <div className="Overview-campaigns-status-title">{status}</div>
                      </Row>
                    </Col>
                  </Row>
                  <Row gutter={[32, 32]}>
                    <Col>
                      <div className="Overview-campaigns-status-val">
                        {stats?.campaigns[status]}
                      </div>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Col>
          </Row>
        </div>
    );
  }

  return null;
};

export default OverviewStats;
