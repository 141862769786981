import { Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AmplitudeEvents } from '@anghami/neoanalytics/dist/enums/events';
import AnghamiAdsBetaLogo from '../../public/assets/images/Ads-Logo-Dark.png';
import useAuth from '../../hooks/useAuth';
import { isEmailValid } from '../../helpers/email-validaton';
import { AxiosSingleton } from '../../utils/axiosinstance';
import { logAmplitudeEvent } from '../../utils/analytics';

const PASSWORD_MIN_LENGTH = 6;

export default function SignUp() {
  useEffect(() => {
    logAmplitudeEvent(AmplitudeEvents.viewRegisterPage);
  }, []);

  const axios = AxiosSingleton.axiosInstance;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAuthFailedMessage, setShowAuthFailedMessage] = useState(false);
  const [emailHighlighted, setEmailHighlighted] = useState(false);
  const [passwordHighlighted, setPasswordHighlighted] = useState(false);
  const [secondPasswordHighlighted, setSecondPasswordHighlighted] = useState(false);
  const navigate = useNavigate();
  const { setUser, setIsLoggedIn } = useAuth();

  const register = () => {
    setLoading(true);
    setShowAuthFailedMessage(false);
    const params = {
      email,
      password,
      password_confirmation: passwordConfirmation
    };

    if (!isEmailValid(email)) {
      setShowAuthFailedMessage(true);
      setEmailError(
        email.length > 0
          ? 'Email format not valid, please insert a valid email'
          : 'Please fill in the required information to proceed'
      );
      setEmailHighlighted(true);
      setLoading(false);
      return;
    }

    setEmailError('');

    if (password.length < PASSWORD_MIN_LENGTH) {
      setShowAuthFailedMessage(true);
      setPasswordError('Password is too short');
      setPasswordHighlighted(true);
      setLoading(false);
      return;
    }

    if (password !== passwordConfirmation) {
      setShowAuthFailedMessage(true);
      setPasswordError('Password confirmation does not match');
      setPasswordHighlighted(true);
      setSecondPasswordHighlighted(true);
      setLoading(false);
      return;
    }

    setPasswordError('');

    axios
      .post('/register', params, {
        headers: {
          Authorization: ''
        }
      })
      .then((res: any) => {
        setLoading(false);
        const { data } = res;
        setUser(data);
        setIsLoggedIn(true);
        AxiosSingleton.setAccessToken(data.access_token);
        navigate('/verify', { state: { email } });

        logAmplitudeEvent(AmplitudeEvents.registerSuccessful);
      })
      .catch((err) => {
        if (err.response) {
          setLoading(false);
          setShowAuthFailedMessage(true);
          const { data } = err.response;
          if (data.error) {
            setPasswordError(data.error.password);
            setEmailError(data.error.email);
          } else {
            setErrorMessage(data.message);
          }
        }

        logAmplitudeEvent(AmplitudeEvents.registerFail, {
          error_message: err.message
        });
      });
  };
  return (
    <div className="auth-container">
      <div className="auth-logo">
        <img src={AnghamiAdsBetaLogo} alt="Anghami Beta Ads" />
      </div>
      <div className="auth-title">Register to start advertising</div>
      {showAuthFailedMessage && (
        <>
          {errorMessage && <div className="auth-failed-msg">{errorMessage}</div>}
          {emailError && <div className="auth-failed-msg">{emailError}</div>}
          {passwordError && <div className="auth-failed-msg">{passwordError}</div>}
        </>
      )}
      <Form onFinish={register}>
        <div
          data-cy="email-input-container"
          className={`auth-input-container ${emailHighlighted ? 'auth-input-error' : ''}`}
        >
          <label>Email</label>
          <Input
            name="email"
            placeholder="user@example.com"
            onChange={(e) => {
              setEmailHighlighted(false);
              setEmail(e.target.value);
            }}
          />
        </div>
        <div
          data-cy="password-input-container"
          className={`auth-input-container ${passwordHighlighted ? 'auth-input-error' : ''}`}
        >
          <label>Password</label>
          <Input
            name="password"
            placeholder="••••••••"
            type="password"
            onChange={(e) => {
              setPasswordHighlighted(false);
              setPassword(e.target.value);
            }}
          />
        </div>
        <div
          data-cy="second-password-input-container"
          className={`auth-input-container ${secondPasswordHighlighted ? 'auth-input-error' : ''}`}
        >
          <label>Confirm Password</label>
          <Input
            name="second-password"
            placeholder="••••••••"
            type="password"
            onChange={(e) => {
              setSecondPasswordHighlighted(false);
              setPasswordConfirmation(e.target.value);
            }}
          />
        </div>
        <div className="auth-primary-btn-container">
          <Button
            className="auth-primary-btn"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={register}
            disabled={loading}
          >
            Register
          </Button>
        </div>
      </Form>
      <div className="auth-footer">
        <div className="auth-footer-text">Already have an account?</div>
        <Link to="/login">
          <button className="auth-secondary-btn" type="button">
            Sign In
          </button>
        </Link>
      </div>
    </div>
  );
}
