import React, {useEffect, useState} from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import AdsTable from '../adstable';
import {mastheadCampaignsTableColumn} from './tableColumns';
import {AxiosSingleton} from '../../utils/axiosinstance';
import {FilterValue, IFilter} from "../managecampaigns/filtersSchema";
import {IMastheadFilters, mastheadFilters} from "./filtersSchema";
import ActiveFilters from "../activefilters";

export default function MastheadCampaignList() {
  const axios = AxiosSingleton.axiosInstance;
  const [tableMeta, setTableMeta] = useState<any>({});
  const [tableData, setTableData] = useState<any[]>([]);
  const [augmentedTableData, setAugmentedTableData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [isInfiniteLoading, setIsInfiniteLoading] = useState(false);
  const [isEmptyTable, setIsEmptyTable] = useState(false);
  const [extendedFilters, setExtendedFilters] = useState<IMastheadFilters[]>(mastheadFilters);
  const [searchRefreshKey, setSearchRefreshKey] = useState(0);
  const [selectedTableKeys, setSelectedTableKeys] = useState<any[]>([]);
  const [selectedFiltersValues, setSelectedFiltersValues] = useState<{
    [filterId: string]: IMastheadFilters;
  }>({});


  const navigate = useNavigate();

  const getImpressions = (campaigns) => {
    if (campaigns.length === 0) return;

    axios
      .post('/analytics/mastheads-impressions', {
        mastheadid: campaigns.map((el: any) => el.id),
        type: 'clicks'
      })
      .then(({data}) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        setAugmentedTableData((augmentedTableData) =>
          augmentedTableData.map((el) => {
            if (!data[el.id]) {
              return el;
            }

            return {
              ...el,
              clicks: data[el.id]?.clicks,
              unique_clicks: data[el.id]?.unique_clicks
            };
          })
        );
      });

    axios
      .post('/analytics/mastheads-impressions', {
        mastheadid: campaigns.map((el: any) => el.id),
        type: 'impressions'
      })
      .then(({data}) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        setAugmentedTableData((augmentedTableData) =>
          augmentedTableData.map((el) => {
            if (!data[el.id]) {
              return el;
            }

            return {
              ...el,
              impressions: data[el.id]?.impressions,
              unique_impressions: data[el.id]?.unique_impressions
            };
          })
        );
      });
  };

  let filtersJSON: { [filterId: string]: FilterValue | undefined } = {};

  Object.values(selectedFiltersValues).forEach((filter) => {
    filtersJSON[filter.id] = filter.value;
  });

  useEffect(() => {
    axios
      .get('/masthead/all', {
        params: {
          page,
          limit: 10,
          filters: filtersJSON
        }
      })
      .then((res) => {
        const {data} = res;
        setIsEmptyTable(data.data.length === 0);
        setTableData((prevTableData) => (page == 1 ? data.data : [...prevTableData, ...data.data]));
        setAugmentedTableData((prevAugmentedTableData) =>
          page === 1 ? data.data : [...prevAugmentedTableData, ...data.data]
        );
        setTableMeta(() => data.meta);
        getImpressions(data.data);
        setIsInfiniteLoading(false);
      });
  }, [page, searchRefreshKey]);

  return (
    <div className="sponsored-playlists-list-container">
      <div className="sponsored-playlists-list-header">
        <div className="sponsored-playlists-list-header-actions">
          <div className="sponsored-playlists-list-header-heading">Masthead Campaigns</div>
          <button
            type="button"
            className="sponsored-playlists-list-header-button create-btn"
            onClick={() => {
              navigate('/masthead-campaigns/create');
            }}
          >
            <PlusOutlined/>
            NEW MASTHEAD CAMPAIGN
          </button>
        </div>
      </div>
      <div style={{
        marginBottom: '10px'
      }}>
        <ActiveFilters
          filters={extendedFilters}
          selectedFiltersValues={selectedFiltersValues}
          setSelectedFiltersValues={(e) => {
            setSelectedFiltersValues(e);
            setPage(1);
          }}
          reloadTable={() => setSearchRefreshKey(searchRefreshKey + 1)}
        />
      </div>

      <div>
        {tableData ? (
          <AdsTable
            data={augmentedTableData}
            tableColumns={mastheadCampaignsTableColumn}
            onFetch={() => {
              if (
                !augmentedTableData ||
                !tableMeta ||
                augmentedTableData.length >= Number(tableMeta?.total)
              )
                return;
              setIsInfiniteLoading(true);
              setPage(page + 1);
            }}
            isLoading={!isEmptyTable && augmentedTableData.length === 0}
            isInfiniteLoading={isInfiniteLoading}
            rowSelection={false}
            selectedIDs={selectedTableKeys}
            onChange={(selectedKeys) => {
              setSelectedTableKeys(selectedKeys);
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
