import axios, { AxiosInstance } from 'axios';
import environment from '../components/env/env';

const redirectToLogin = () => {
  window.localStorage.removeItem('ads_user');
  window.localStorage.removeItem('ads_token');
  const { pathname, search } = window.location;
  const url = `/login?redirecturl=${pathname}${search}`;
  window.location.href = url;
};

const reInitUser = (data: any) => {
  window.localStorage.setItem('ads_token', data.access_token as string);
  window.localStorage.setItem(
    'ads_user',
    JSON.stringify({
      ...data.user,
      verified: Boolean(data?.verified)
    })
  );
  window.location.reload();
};

class HttpService {
  public axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: environment.getBaseAPIUrl()
    });

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.data?.message === 'unauthorised access') {
          if (error.response?.data?.refresh && this.axiosInstance.defaults.withCredentials) {
            this.axiosInstance
              .post(`${environment.getBaseAPIUrl()}/refresh`)
              .then((res) => {
                reInitUser(res.data);
              })
              .catch(() => redirectToLogin());
          } else {
            redirectToLogin();
          }
        }
        return Promise.reject(error);
      }
    );

    const localStorageAccessToken = window.localStorage.getItem('ads_token');

    if (localStorageAccessToken) {
      this.setAccessToken(localStorageAccessToken);
    }
  }

  setAccessToken(token: string) {
    this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    this.axiosInstance.defaults.withCredentials = true;
  }
}

export const AxiosSingleton = new HttpService();
