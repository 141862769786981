import React, {useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTus } from 'use-tus';
import useAuth from '../../hooks/useAuth';
import UploadBox from '../uploadbox';
import { UploadBoxStates } from '../../enums/uploadboxstates';
import './styles.scss';

type Props = {
  uploadType: 'video' | 'audio' | 'image';
  uploadSuccessHandler: (fileLocation: string) => void;
  fileName: string;
  inputID: string;
  clearUploadHandler: () => void;

};

type UploadBoxStateType = 'preupload' | 'upload' | 'uploaded' | 'uploadfailed' | 'invalidfile';

const fileTypesMap = {
  image: ['JPEG', 'PNG', 'JPG'],
  video: ['MP4']
};

export default function Upload({
                                 uploadSuccessHandler,
                                 clearUploadHandler,
                                 fileName,
                                 inputID,
                                 uploadType
}: Props) {
  const { upload, setUpload, isSuccess } = useTus();

  const { user } = useAuth();

  const [uploadedFileName, setUploadedFileName] = useState('');
  const [metadataFileName, setMetadataFileName] = useState('');
  const [uploadBoxState, setUploadBoxState] = useState<UploadBoxStateType>(
    UploadBoxStates.PreUpload
  );

  useEffect(() => {
    if (fileName) {
      setUploadedFileName(fileName);
      setUploadBoxState(UploadBoxStates.Uploaded);
    }
  }, [fileName]);


  const handleSetUpload = (file) => {
    if (!file) {
      return;
    }

    const outputFile = file;
    setUploadBoxState(UploadBoxStates.Uploading);

    const name = `${String(Math.floor(Math.random() * 9999 + 1000))}_${file.name
      .replace(/[^A-Za-z0-9\-._]/g, '')
      .toLowerCase()}`;
    const filename = `${user?.publisher_id
      .toString()
      .replace(/[^A-Za-z0-9\-._]/g, '')
      .toLowerCase()}/${name}`;
    setMetadataFileName(filename);

    setUpload(file, {
      endpoint: 'https://tusk.anghami.com/files/',
      retryDelays: [0, 1000, 3000, 5000],
      metadata: {
        name,
        filename,
        filetype: file.type,
        token: 'C5E386CD1A2A2F1DD93841AD811F7',
        bucket: 'anghami.newads'
      },
      removeFingerprintOnSuccess: true
    });

    setUploadedFileName(outputFile.name);
  };

  useEffect(() => {
    if (!upload) {
      return;
    }

    upload.start();
  }, [upload]);


  useEffect(() => {
    if (uploadType == 'image' || !isSuccess) {

      if (!isSuccess) {
        return;
      }

      const image = new Image();
      image.onload = function () {
      // eslint-disable-next-line react/no-this-in-sfc
      setUploadBoxState(UploadBoxStates.Uploaded);
      uploadSuccessHandler(`https://anghnewads.anghcdn.co/${metadataFileName}`);
      };
      image.src = `https://anghnewads.anghcdn.co/${metadataFileName}`;
    }
  }, [isSuccess]);
  useEffect(() => {
    if (uploadType == 'video' || !isSuccess) {
      if (!isSuccess) {
        return;
      }
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = function () {
      // eslint-disable-next-line react/no-this-in-sfc
      setUploadBoxState(UploadBoxStates.Uploaded);
      uploadSuccessHandler(`https://anghnewads.anghcdn.co/${metadataFileName}`);
      };
      video.src = `https://anghnewads.anghcdn.co/${metadataFileName}`;
    }
  });



  return (
    <div className="file-uploader-container">
      <div>
        <FileUploader
          handleChange={handleSetUpload}
          name={inputID}
          types={fileTypesMap[uploadType]}
          disabled={uploadBoxState === UploadBoxStates.Uploaded}
        >
          <UploadBox
            type={uploadType}
            uploadedFilename={uploadedFileName}
            state={uploadBoxState}
            clearUploadValue={() => {
              clearUploadHandler();
              setUploadBoxState(UploadBoxStates.PreUpload);
            }
          }
          />
        </FileUploader>
      </div>
      <div
        className={`upload-requirements ${
          uploadBoxState === UploadBoxStates.InvalidFile ? 'invalid-file' : ''
        }`}
      >
      </div>
    </div>
  );
}
