import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../pageheader';
import ArrowUp from '../../public/assets/images/arrows/arrow-up.png';
import ArrowDown from '../../public/assets/images/arrows/arrow-down.png';
import './styles.scss';
import { AxiosSingleton } from '../../utils/axiosinstance';
import OverviewStats from '../overview-stats';
import {
  AdTypeStats,
  CampaignStats,
  RegionStats,
  RunningCampaign,
  TotalRevenueStats
} from './types';
import AnghamiAdsFullPageLoader from '../core/AnghamiAdsFullPageLoader';

const RED_COLOR = '#d03150';
const GREEN_COLOR = '#58d47c';

type RevenueCardProps = {
  title: string;
  diff: number;
  revenue: number;
};

type RegionStatusCardProps = {
  title: string;
  diff: number;
  revenue: number;
  runningCampaigns: RunningCampaign[];
};

function RegionStatusCard({ title, diff, revenue, runningCampaigns }: RegionStatusCardProps) {
  return (
    <div className="total-revenue-container reports-overview-card">
      <div className="reports-overview-card-title">{title}</div>
      <div className="total-revenue-content">
        <div className="total-revenue-percentage-change">
          <img src={diff > 0 ? ArrowUp : ArrowDown} />
          <div
            className="percentage-value"
            style={{ color: diff > 0 ? GREEN_COLOR : RED_COLOR }}
          >{`${diff}%`}</div>
        </div>
        <div className="total-revenue-amount">{`$${revenue}`}</div>
      </div>
      <div className="region-running-campaigns">
        <span>{runningCampaigns.length}</span> campaigns currently running
      </div>
      <div>
        <div className="reports-overview-card-title">Top 5</div>
        {runningCampaigns.slice(0, 5).map((campaign) => (
          <div key={campaign.name}>
            <span>{`$${campaign.budget}`}</span>
            <span> </span>
            <span>{campaign.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

function RevenueCard({ title, diff, revenue }: RevenueCardProps) {
  return (
    <div className="total-revenue-container reports-overview-card">
      <div className="reports-overview-card-title">{title}</div>
      <div className="total-revenue-content">
        <div className="total-revenue-percentage-change">
          <img src={diff > 0 ? ArrowUp : ArrowDown} />
          <div
            className="percentage-value"
            style={{ color: diff > 0 ? GREEN_COLOR : RED_COLOR }}
          >{`${diff}%`}</div>
        </div>
        <div className="total-revenue-amount">{`$${revenue}`}</div>
      </div>
    </div>
  );
}

export default function ReportOverview() {
  const axios = AxiosSingleton.axiosInstance;

  const navigate = useNavigate();

  const [campaignStats, setCampaignStats] = useState<CampaignStats | null>(null);
  const [regionStats, setRegionStats] = useState<RegionStats | null>(null);
  const [totalRevenueStats, setTotalRevenueStats] = useState<TotalRevenueStats | null>(null);
  const [adTypeStats, setAdTypeStats] = useState<AdTypeStats | null>(null);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios.get('reports/overview').then((res) => {
      const { data } = res.data;
      setCampaignStats(data.stats.campaigns);
      setRegionStats(data.stats.regions);
      setTotalRevenueStats(data.stats.total_revenue);
      setAdTypeStats(data.stats.types);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <AnghamiAdsFullPageLoader />;
  }

  return (
    <div className="reports-overview-container">
      <PageHeader title="Financial Overview" />
      {totalRevenueStats && (
        <div className="total-revenue-section">
          <RevenueCard
            title="Total Revenue in Pipeline this Year"
            diff={totalRevenueStats.year.diff}
            revenue={totalRevenueStats.year.revenue}
          />
          <RevenueCard
            title="Total Revenue in Pipeline this Month"
            diff={totalRevenueStats.month.diff}
            revenue={totalRevenueStats.month.revenue}
          />
          <RevenueCard
            title="Total Revenue in Pipeline this Quarter"
            diff={totalRevenueStats.quarter.diff}
            revenue={totalRevenueStats.quarter.revenue}
          />
        </div>
      )}
      {campaignStats && <OverviewStats stats={{ campaigns: campaignStats }} />}
      {regionStats && (
        <div className="total-revenue-section region-stats-section">
          <RegionStatusCard
            title="GCC Status this Month"
            diff={regionStats.gcc.diff}
            revenue={regionStats.gcc.revenue}
            runningCampaigns={regionStats.gcc.running}
          />
          <RegionStatusCard
            title="Levant Status this Month"
            diff={regionStats.levant.diff}
            revenue={regionStats.levant.revenue}
            runningCampaigns={regionStats.levant.running}
          />
          <RegionStatusCard
            title="NA Status this Month"
            diff={regionStats.na.diff}
            revenue={regionStats.na.revenue}
            runningCampaigns={regionStats.na.running}
          />
        </div>
      )}
      {adTypeStats && (
        <div className="total-revenue-section">
          <RevenueCard
            title="Audio Revenue this Quarter"
            diff={adTypeStats.audio.diff}
            revenue={adTypeStats.audio.revenue}
          />
          <RevenueCard
            title="Display Revenue this Quarter"
            diff={adTypeStats.display.diff}
            revenue={adTypeStats.display.revenue}
          />
          <RevenueCard
            title="Playlist Revenue this Quarter"
            diff={adTypeStats.playlist.diff}
            revenue={adTypeStats.playlist.revenue}
          />
        </div>
      )}
      <button
        type="button"
        className="generate-report-btn"
        onClick={() => {
          navigate('/reports/generate');
        }}
      >
        Generate detailed report
      </button>
    </div>
  );
}
