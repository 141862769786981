import React from 'react';
import './styles.scss';

export default function SpinningCircle() {
  return (
    <div className="spinning-circle-loading">
      <div className="spinner">
        <div className="mask">
          <div className="maskedCircle" />
        </div>
      </div>
    </div>
  );
}
