import { message as antdMessage } from 'antd';

export const message = {
  success: antdMessage.success,
  error: antdMessage.error,
  warning: antdMessage.warning,
  warn: antdMessage.warn,
  open: antdMessage.open,
  loading: antdMessage.loading
};
