import { Modal } from 'antd';
import React, { useState } from 'react';
import { AxiosSingleton } from '../../utils/axiosinstance';
import WarningModal from '../warningmodal';
import UserModal from './UserModal';
import { message } from '../../utils/custommessage';
import { AnghamiAdsFormInput } from '../core/AnghamiAdsFormInput';

export type TableColumnType = {
  title: string;
  width: string;
  dataIndex: string;
  key: string;
  fixed?: string;
  render: (value: any, data: any) => any;
};

type ResetPasswordModalProps = {
  user: any;
  resetPassword: (password: string) => void;
};

const ResetPasswordModal = ({ user, resetPassword }: ResetPasswordModalProps) => {
  const [newPassword, setNewPassword] = useState(Math.random().toString(36).slice(-8));
  return (
    <div className="reset-password-modal">
      <div className="reset-password-modal-title mb-1rem">{`Reset Password: ${user.name}`}</div>
      <AnghamiAdsFormInput
        label="New Password (Auto-generated)"
        value={newPassword}
        placeholder="Enter user's password"
        onChange={(e) => {
          setNewPassword(e.target.value);
        }}
      />
      <button
        style={{ marginTop: '1rem' }}
        type="button"
        onClick={() => {
          resetPassword(newPassword);
        }}
      >
        Save
      </button>
    </div>
  );
};

type NameCellProps = {
  name: string;
  user: any;
};

const NameCell = ({ user, name }: NameCellProps) => {
  const axios = AxiosSingleton.axiosInstance;
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  return (
    <>
      <div className="manage-users-name-cell">
        <div className="manage-users-name">{name}</div>
        <div className="manage-users-name-actions">
          <span
            className="manage-users-name-action"
            onClick={() => {
              setShowEditUserModal(true);
            }}
          >
            Edit
          </span>
          <span
            className="manage-users-name-action"
            onClick={() => {
              setShowDeleteUserModal(true);
            }}
          >
            Delete
          </span>
          <span
            className="manage-users-name-action"
            onClick={() => {
              setShowResetPasswordModal(true);
            }}
          >
            Reset Password
          </span>
        </div>
      </div>
      <Modal
        centered
        visible={showEditUserModal}
        footer={null}
        closable
        width="40rem"
        destroyOnClose
        onCancel={() => {
          setShowEditUserModal(false);
        }}
      >
        <UserModal
          mode="edit"
          user={user}
          closeModal={() => {
            setShowEditUserModal(false);
          }}
        />
      </Modal>
      <Modal
        centered
        visible={showDeleteUserModal}
        footer={null}
        closable
        width="40rem"
        destroyOnClose
        onCancel={() => {
          setShowDeleteUserModal(false);
        }}
      >
        <WarningModal
          onOk={() => {
            const params = {
              ids: [user.id]
            };

            axios
              .post('/admin/users/destroy', params)
              .then(() => {
                message.success('User deleted');
                setShowDeleteUserModal(false);
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              })
              .catch(() => {
                message.error('An error occurred. Please try again later.');
                setShowDeleteUserModal(false);
              });
          }}
          onCancel={() => {
            setShowDeleteUserModal(false);
          }}
          titleText="Delete User"
          subtitleText="Are you sure you want to proceed?"
          okButtonText="Delete"
          cancelButtonText="Cancel"
        />
      </Modal>
      <Modal
        centered
        visible={showResetPasswordModal}
        footer={null}
        closable
        width="40rem"
        destroyOnClose
        onCancel={() => {
          setShowResetPasswordModal(false);
        }}
      >
        <ResetPasswordModal
          user={user}
          resetPassword={(password) => {
            const endpoint = '/admin/users/reset-password';
            axios
              .post(endpoint, {
                ...user,
                password
              })
              .then((res) => {
                if (res.data.message === 'success') {
                  message.success('User password successfully reset!');
                }
              })
              .catch(() => {
                message.error('An error occurred. Please try again later.');
              });
          }}
        />
      </Modal>
    </>
  );
};

export const manageUsersTableColumns: TableColumnType[] = [
  {
    title: 'Name',
    width: '10rem',
    dataIndex: 'name',
    key: '0',
    render: (name: any, data: any) => <NameCell user={data} name={name} />
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: '1',
    width: '6rem',
    render: (value: any) => <span>{value}</span>
  },
  {
    title: 'Publisher',
    dataIndex: 'publisher_name',
    key: '20',
    width: '9rem',
    render: (value: any) => <span>{value}</span>
  },
  {
    title: 'Role',
    dataIndex: 'role_name',
    key: '14',
    width: '6rem',
    render: (value: any) => <span>{value}</span>
  },
  {
    title: 'Status',
    dataIndex: 'active',
    key: '3',
    width: '9rem',
    render: (value: any) => <span>{value ? 'Active' : 'Inactive'}</span>
  }
];
