export enum Messages {
  DuplicatedSuccessfully = 'Duplicated successfully',
  CopiedSuccessfully = 'Copied successfully',
  PausedSuccessfully = 'Paused successfully',
  ResumedSuccessfully = 'Resumed successfully',
  ActivatedSuccessfully = 'Activated successfully',
  DeactivatedSuccessfully = 'Deactivated successfully',
  PublishedSuccessfully = 'Campaign is now pending review',
  ArchivedSuccessfully = 'Archived successfully',
  DeletedSuccessfully = 'Deleted successfully',
  UpdatedSuccessfully = 'Your billing information were updated successfully',
  BillingInformationDeletedSuccessfully = 'Your billing information were deleted!',

  FailedDuplicated = 'Failed to duplicate',
  FailedPaused = 'Failed to paused',
  FailedResumed = 'Failed to resume',
  FailedActivated = 'Failed to activate',
  FailedDeactivated = 'Failed to deactivate',
  FailedPublished = 'Failed to publish',
  FailedArchived = 'Failed to archive',
  FailedDelete = 'Failed to delete',

  LoadingDeliveryMetrics = 'Loading delivery metrics',
  LoadedDeliveryMetrics = 'Delivery metrics loaded',
  FailedLoadingDeliveryMetrics = 'Failed to load delivery metrics',

  BudgetTooLow = 'Spend limit should be more than 100',
  CampaignSavedSuccessfully = 'Your campaign was saved successfully'
}
