import AnghamiAnalytics from '@anghami/neoanalytics';
import { Amplitude } from '@anghami/neoanalytics/dist/sdks/amplitude';
import { Raygun } from '@anghami/neoanalytics/dist/sdks/raygun';
import { GoogleAnalytics } from '@anghami/neoanalytics/dist/sdks/google-analytics';
import { IConfig } from '@anghami/neoanalytics/dist/interfaces';
import { PLATFORMS } from '@anghami/neoanalytics/dist/enums/platforms';
import env from '../components/env/env';
import packageFile from '../../package.json';

const CONFIG: IConfig = {
  project: {
    name: PLATFORMS.ADS_CLIENT,
    defaultProperties: {
      web_medium: PLATFORMS.ADS_CLIENT,
      useragent: navigator.userAgent
    },
    config: {
      version: packageFile.version
    }
  },
  amplitude: {
    key: env.amplitude_public_key
  },
  googleAnalytics: {
    key: env.googleanalytics_public_key || ''
  },
  raygun: {
    key: env.raygun_public_key || ''
  }
};
const initAnalytics = async (defaultProperties: typeof CONFIG.project.defaultProperties) => {
  CONFIG.project.defaultProperties = {
    ...CONFIG.project.defaultProperties,
    ...defaultProperties
  };

  new AnghamiAnalytics(CONFIG);

  Amplitude.getAmplitudeInstance();

  GoogleAnalytics.getGoogleAnalyticsInstance();

  Raygun.getRaygunInstance();
};

const logAmplitudeEvent = (eventName: string, eventProperties: any = {}) => {
  Amplitude.getAmplitudeInstance().then((instance) => {
    instance.logAmplitudeEvent(eventName, eventProperties);
  });
};

const logGoogleAnalyticsPageview = (url: string) => {
  // delay google event for 1s after everything loads
  setTimeout(
    () =>
      GoogleAnalytics.getGoogleAnalyticsInstance().then((instance) =>
        instance.logGoogleAnalyticsPageviewEvent(url)
      ),
    1000
  );
};

const logRaygunCustomError = (error: any, tags: string[], customData: any = {}) => {
  if (!(window as any).rg4js) return;
  (window as any).rg4js('send', {
    error: error,
    tags: ['custom-ads-error', ...tags],
    customData
  });
};

export { initAnalytics, logAmplitudeEvent, logGoogleAnalyticsPageview, logRaygunCustomError };
