import React, { useState } from 'react';
import BillingModal from '../billing-modal';
import SideMenu from '../sidemenu';
import './styles.scss';

type Props = {
  children: any;
};

type ModalContext = {
  showBillingModal: boolean;
  setShowBillingModal: (value: boolean) => void;
};

export const BillingModalContext = React.createContext<ModalContext>({
  showBillingModal: false,
  setShowBillingModal: () => null
});

export default function BasePage({ children }: Props) {
  const [showBillingModal, setShowBillingModal] = useState(false);

  return (
    <BillingModalContext.Provider
      value={{
        showBillingModal,
        setShowBillingModal
      }}
    >
      <div className="Base">
        <SideMenu />
        {showBillingModal && <BillingModal />}
        <div className="Base-content">{children}</div>
      </div>
    </BillingModalContext.Provider>
  );
}
