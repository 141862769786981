import React from 'react';
import {
  AdTypes,
  CreativesStatusTypes,
  FilterStatusTypes,
  CampaignStatusTypes,
  Tabs
} from '../../enums';
import {
  ManageNameCell,
  BudgetCell,
  ManageStatusCell,
  CountriesCell,
  AdTypeCell,
  CreativeStatusCell,
  MetricsCell,
  DeliveryRotationsCell,
  ImpressionsCell
} from '../adstablecells';
import { DataType } from './tabledatatype';

export type TableColumnType = {
  title: string;
  width: string;
  dataIndex: string;
  key: string;
  fixed?: string;
  // eslint-disable-next-line no-undef
  render: (value: any, data: DataType) => JSX.Element;
};

const renderMetricsFunc = (value: string) => <MetricsCell value={value} />;

const renderImpressions = (value: string, data: any) => {
  if (data.streams_based) {
    return <span>-</span>;
  }

  return (
    <ImpressionsCell
      targetImpressions={data.target_impressions}
      impressions={data.pacing_impressions}
      paceProgress={data.pace_progress}
    />
  );
};

export const campaignsTableColumns: TableColumnType[] = [
  {
    title: 'Campaign Name',
    width: '30rem',
    dataIndex: 'name',
    key: '0',
    fixed: 'left',
    render: (value: string, data: DataType) => (
      <ManageNameCell name={value} data={data} tabType={Tabs.Campaigns} />
    )
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: '3',
    width: '12rem',
    render: (value: CampaignStatusTypes) => <ManageStatusCell status={value} isCampaign />
  },
  {
    title: 'Created By',
    dataIndex: 'created_by',
    key: '13',
    width: '9rem',
    render: (value: string) => <span className="AdsTable-value">{value || '-'}</span>
  },
  {
    title: 'Publisher',
    dataIndex: 'publisher_name',
    key: '20',
    width: '9rem',
    render: (value: string) => <span className="AdsTable-value">{value || '-'}</span>
  },
  {
    title: 'Advertiser',
    dataIndex: 'advertiser_name',
    key: '14',
    width: '9rem',
    render: (value: string) => <span className="AdsTable-value">{value || '-'}</span>
  },
  {
    title: 'Created Date',
    dataIndex: 'created_at',
    key: '4',
    width: '9rem',
    render: (value: string) => <span className="AdsTable-value">{value.toString() || '-'}</span>
  },
  {
    title: 'Amount spent',
    dataIndex: 'budget',
    key: '1',
    width: '9rem',
    render: (_: any, data: DataType) => <BudgetCell data={data} />
  },
  {
    title: 'Impressions',
    dataIndex: 'impressions',
    key: '2',
    width: '9rem',
    render: (impressions: number) => <span>{impressions}</span>
  },
  {
    title: 'Streams',
    dataIndex: 'streams',
    key: '10',
    width: '9rem',
    render: (value: string) => <span>{value || '-'}</span>
  },
  {
    title: 'CPM',
    dataIndex: 'cpm',
    key: '11',
    width: '9rem',
    render: renderMetricsFunc
  },
  {
    title: 'Clicks',
    dataIndex: 'clicks',
    key: '16',
    width: '9rem',
    render: renderMetricsFunc
  },
  {
    title: 'CPC',
    dataIndex: 'cpc',
    key: '17',
    width: '9rem',
    render: renderMetricsFunc
  },
  {
    title: 'CTR',
    dataIndex: 'ctr',
    key: '17',
    width: '9rem',
    render: (value: string) => <span>{Number(value) ? `${value}%` : '-'}</span>
  }
];

export const adsSetsTableColumns = [
  {
    title: 'Ad Set Name',
    width: '30rem',
    dataIndex: 'name',
    key: '0',
    fixed: 'left',
    render: (name: string, data: any) => (
      <ManageNameCell name={name} data={data} tabType={Tabs.AdsSets} />
    )
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: '3',
    width: '12rem',
    render: (status: FilterStatusTypes) => <ManageStatusCell status={status} />
  },
  {
    title: 'Ad Type',
    dataIndex: 'format',
    key: '9',
    width: '9rem',
    render: (adType: AdTypes) => <AdTypeCell adType={adType} />
  },
  {
    title: 'Delivery Rotation',
    dataIndex: 'delivery_rotation',
    key: '17',
    width: '12rem',
    render: (value: number) => <DeliveryRotationsCell deliveryRotaion={value} />
  },
  {
    title: 'Countries',
    dataIndex: 'countries',
    key: '7',
    width: '20rem',
    render: (countries: string) => <CountriesCell countries={countries} />
  },
  {
    title: 'Frequencies',
    dataIndex: 'frequencies',
    key: '13',
    width: '9rem',
    render: (value: string) => <span className="AdsTable-value">{value || '-'}</span>
  },
  {
    title: 'Created Date',
    dataIndex: 'created_at',
    key: '4',
    width: '9rem',
    render: (value: string) => <span className="AdsTable-value">{value.toString() || '-'}</span>
  },
  {
    title: 'Start Date',
    dataIndex: 'start_date',
    key: '6',
    width: '9rem',
    render: (value: string) => <span className="AdsTable-value">{value || '-'}</span>
  },
  {
    title: 'End Date',
    dataIndex: 'end_date',
    key: '5',
    width: '9rem',
    render: (value: string) => <span className="AdsTable-value">{value || '-'}</span>
  },
  {
    title: 'Amount spent',
    dataIndex: 'budget',
    key: '1',
    width: '9rem',
    render: (_: any, data: DataType) => <BudgetCell data={data} />
  },
  {
    title: 'Impressions',
    dataIndex: 'impressions',
    key: '2',
    width: '9rem',
    render: renderImpressions
  },
  {
    title: 'Streams',
    dataIndex: 'streams',
    key: '2',
    width: '9rem',
    render: (streams: any) => <span>{streams}</span>
  },
  {
    title: 'CPM',
    dataIndex: 'cpm',
    key: '11',
    width: '9rem',
    render: renderMetricsFunc
  },
  {
    title: 'Reach',
    dataIndex: 'unique_impressions',
    key: '15',
    width: '9rem',
    render: renderMetricsFunc
  },
  {
    title: 'Clicks',
    dataIndex: 'clicks',
    key: '16',
    width: '9rem',
    render: renderMetricsFunc
  },
  {
    title: 'CPC',
    dataIndex: 'cpc',
    key: '17',
    width: '9rem',
    render: renderMetricsFunc
  },
  {
    title: 'CTR',
    dataIndex: 'ctr',
    key: '17',
    width: '9rem',
    render: (value: string) => <span>{Number(value) ? `${value}%` : '-'}</span>
  }
];

export const creativeTableColumns = [
  {
    title: 'Creative Name',
    width: '30rem',
    dataIndex: 'name',
    key: '0',
    fixed: 'left',
    render: (name: string, data: any) => (
      <ManageNameCell name={name} data={data} tabType={Tabs.Creatives} />
    )
  },
  {
    title: 'Status',
    dataIndex: 'active',
    key: '3',
    width: '9rem',
    render: (status: CreativesStatusTypes) => <CreativeStatusCell status={status} />
  },
  {
    title: 'Ad Type',
    dataIndex: 'format',
    key: '9',
    width: '9rem',
    render: (adType: AdTypes) => <AdTypeCell adType={adType} />
  },
  {
    title: 'CTA',
    dataIndex: 'button_cta',
    key: '17',
    width: '9rem',
    render: (value: string) => (
      <span title={value} className="AdsTable-value">
        {value || '-'}
      </span>
    )
  },
  {
    title: 'Redirect Link',
    dataIndex: 'cta_url',
    key: '13',
    width: '15rem',
    render: (link: string) => (
      <a title={link} href={link} target="_blank" rel="noreferrer">
        {link}
      </a>
    )
  },
  {
    title: 'Created Date',
    dataIndex: 'created_at',
    key: '4',
    width: '9rem',
    render: (value: string) => <span className="AdsTable-value">{value || '-'}</span>
  },
  {
    title: 'Impressions',
    dataIndex: 'impressions',
    key: '2',
    width: '9rem',
    render: renderMetricsFunc
  },
  {
    title: 'CPM',
    dataIndex: 'cpm',
    key: '11',
    width: '9rem',
    render: renderMetricsFunc
  },
  {
    title: 'Reach',
    dataIndex: 'unique_impressions',
    key: '15',
    width: '9rem',
    render: renderMetricsFunc
  },
  {
    title: 'Clicks',
    dataIndex: 'clicks',
    key: '16',
    width: '9rem',
    render: renderMetricsFunc
  },
  {
    title: 'CPC',
    dataIndex: 'cpc',
    key: '17',
    width: '9rem',
    render: renderMetricsFunc
  },
  {
    title: 'CTR',
    dataIndex: 'ctr',
    key: '17',
    width: '9rem',
    render: (value: string) => <span>{Number(value) ? `${value}%` : '-'}</span>
  }
];
