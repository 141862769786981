import React, { useEffect, useState } from 'react';
import { AxiosSingleton } from '../../utils/axiosinstance';
import PreviewComponent from '../newpreview';
import SpinningLoader from '../spinningloader';
import './styles.scss';

type Props = {
  creativeData: any;
};

export default function PreviewModal({ creativeData }: Props) {
  const axios = AxiosSingleton.axiosInstance;
  const [isLoading, setIsLoading] = useState(true);

  const formatCreativeData = (data: any) => ({
    id: 0, // used to track creatives client side
    creativeId: data.id, // used to track creatives server side
    creativeName: data.name,
    creativeType: '',
    creativeTitleEn: data.title_en,
    creativeLinkEn: data.link_en,
    creativeAudioEn: data.audio_en,
    creativeVideoEn: data.video_en,
    creativeImageEn: data.image_raw, // need to rely on image raw because API gets confused if we send the formatted version of image_en they return
    creativeButtonTextEn: data.button_cta,
    creativeStartDate: data.start_date,
    creativeEndDate: data.end_date,
    creativeThirdPartyStart: data.thirdparty_start || [''],
    creativeThirdPartyEnd: data.thirdparty_end,
    creativeThirdPartyTap: data.thirdparty_tap || [''],
    creativeThirdPartyQuartile: data.thirdparty_quartile || [''],
    creativeDaysOfWeek: data.daysofweek,
    creativeStartTime: data.start_time,
    creativeEndTime: data.end_time
  });

  const formatAdSetData = (data: any) => ({
    id: 0,
    adSetId: data.id,
    adSetName: data.name,
    adSetStartDate: data.start_date,
    adSetEndDate: data.end_date,
    adSetPriority: data.priority,
    adSetTargetImpressions: data.impressions,
    adSetPaidImpressions: '',
    adSetFreeImpressions: '',
    adSetDeliveryRotation: data.delivery_rotation,
    adSetFrequencies: data.frequencies
      ? data.frequencies.map((freq: any) => {
          const splitFreq = freq.split(':');
          return {
            count: splitFreq[0],
            duration: splitFreq[1]
          };
        })
      : [],
    adSetBudget: data.budget,
    adSetAudienceId: data.audience_id,
    adSetFeatureSupport: '',
    adSetPlaylists: '',
    adSetType: data.format,
    adSetBookingNumber: '',
    adSetBookingValue: '',
    adSetBookingValueCurrency: data.booking_value_currency,
    adSetStartTime: data.start_time,
    adSetEndTime: data.end_time,
    adSetForm: {
      name: data.form?.name || '',
      description: data.form?.description || '',
      terms: data.form?.terms || '',
      button_cta: data.form?.button_cta || '',
      image: data.form?.image || '',
      fields: data.form?.fields || {
        fullname: true,
        email: true,
        msidn: true,
        birthday: false,
        gender: false
      }
    },
    creatives: []
  });

  const capitalize = (str: string) => str[0].toUpperCase() + str.substring(1);

  const joinAndCapitalize = (arr: string[]) => {
    const capitalizedArr = arr.map((element) => capitalize(String(element)));
    return capitalizedArr.join(', ');
  };

  const [adSet, setAdSet] = useState<any>();
  const creative = formatCreativeData(creativeData);

  useEffect(() => {
    axios
      .post('/campaigns/get', {
        campaignid: creativeData.campaign_id
      })
      .then((response) => {
        const { data } = response;
        setAdSet(formatAdSetData(data.data[0]));
        setIsLoading(false);
      });
  }, []);
  return isLoading ? (
    <div className="preview-modal preview-modal-spinner-container">
      <SpinningLoader />
    </div>
  ) : (
    <div className="preview-modal">
      <div className="creative-summary">
        <div className="creative-summary-preview">
          <PreviewComponent
            creative={creative}
            adSet={adSet}
            groupCampaignObjective="reach"
            updateLeadGenForm={() => null}
            buttonText={creative.creativeButtonTextEn}
            songData={null}
          />
        </div>
        <div className="creative-summary-info">
          <div className="creative-name">{creative.creativeName}</div>
          <div className="creative-summary-element">
            <div>CTA URL</div>
            <div>{creative.creativeLinkEn}</div>
          </div>
          <div className="creative-summary-element">
            <div>FROM</div>
            <div>{`${adSet.adSetStartDate} ${creative.creativeStartTime}`}</div>
          </div>
          <div className="creative-summary-element">
            <div>TO</div>
            <div>{`${adSet.adSetEndDate} ${creative.creativeEndTime}`}</div>
          </div>
          <div className="creative-summary-element">
            <div>DAYS</div>
            <div>{joinAndCapitalize(Object.keys(creative.creativeDaysOfWeek))}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
