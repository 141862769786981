import { Button, Form, Input } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { AmplitudeEvents } from '@anghami/neoanalytics/dist/enums/events';
import AnghamiAdsBetaLogo from '../../public/assets/images/Ads-Logo-Dark.png';
import { AxiosSingleton } from '../../utils/axiosinstance';
import useAuth from '../../hooks/useAuth';
import { isEmailValid } from '../../helpers/email-validaton';
import { logAmplitudeEvent } from '../../utils/analytics';

export default function Login() {
  const axios = AxiosSingleton.axiosInstance;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [showAuthFailedMessage, setShowAuthFailedMessage] = useState(false);
  const [emailHighlighted, setEmailHighlighted] = useState(false);
  const [passwordHighlighted, setPasswordHighlighted] = useState(false);
  const { setUser, setIsLoggedIn } = useAuth();
  const [searchParams] = useSearchParams();
  const reset = searchParams.get('reset');
  const redirectUrl = searchParams.get('redirecturl');
  const navigate = useNavigate();

  useEffect(() => {
    logAmplitudeEvent(AmplitudeEvents.viewLoginScreen);
  }, []);

  const signIn = () => {
    setLoading(true);
    setShowAuthFailedMessage(false);
    setEmailError('');

    const params = {
      email,
      password
    };

    if (email.length === 0 || password.length === 0) {
      setEmailError('Please fill in the required information to proceed');
      setEmailHighlighted(email.length === 0);
      setPasswordHighlighted(password.length === 0);
      setLoading(false);
      return;
    }

    if (!isEmailValid(email)) {
      setEmailError('Email format not valid, please insert a valid email');
      setEmailHighlighted(true);
      setLoading(false);
      return;
    }

    axios
      .post('/login', params, {
        headers: {
          Authorization: ''
        }
      })
      .then((res) => {
        const { data } = res;
        setUser(data);
        setIsLoggedIn(true);
        AxiosSingleton.setAccessToken(data.access_token);
        setLoading(false);
        const url = data.verified ? '/overview' : '/verify?fromlogin=true';
        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          navigate(url);
        }

        logAmplitudeEvent(AmplitudeEvents.loginSuccessful);
      })
      .catch((err) => {
        setShowAuthFailedMessage(true);
        setLoading(false);
        logAmplitudeEvent(AmplitudeEvents.loginFail, {
          error_message: err.message
        });
      });
  };

  return (
    <div className="auth-container">
      <div className="auth-logo">
        <img src={AnghamiAdsBetaLogo} alt="Anghami Beta Ads" />
      </div>
      <div className="auth-title">Log in with your email to start advertising</div>
      {showAuthFailedMessage && (
        <div className="auth-failed-msg">These credentials do not match our records.</div>
      )}
      {emailError && <div className="auth-failed-msg">{emailError}</div>}
      {reset && <div className="auth-success-msg">Your password has been reset!</div>}
      <Form onFinish={signIn}>
        <div className={`auth-input-container ${emailHighlighted ? 'auth-input-error' : ''}`}>
          <label>Email</label>
          <Input
            name="email"
            placeholder="user@example.com"
            onChange={(e) => {
              setEmailHighlighted(false);
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className={`auth-input-container ${passwordHighlighted ? 'auth-input-error' : ''}`}>
          <label>Password</label>
          <Input
            name="password"
            placeholder="••••••••"
            type="password"
            onChange={(e) => {
              setPasswordHighlighted(false);
              setPassword(e.target.value);
            }}
          />
        </div>
        <Link to="/forgotpassword">
          <div className="auth-forgot-password">Forgot your password?</div>
        </Link>
        <div className="auth-primary-btn-container">
          <Button
            className="auth-primary-btn"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={signIn}
            disabled={loading}
          >
            Sign In
          </Button>
        </div>
      </Form>
      <div className="auth-footer">
        <div className="auth-footer-text">Don&apos;t have an account yet?</div>
        <Link to="/signup" onClick={() => logAmplitudeEvent(AmplitudeEvents.clickOnRegister)}>
          <button className="auth-secondary-btn" type="button">
            Register Here
          </button>
        </Link>
      </div>
    </div>
  );
}
