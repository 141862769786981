interface IData {
  [key: string]: any;
}

/**
 * @description checks whether an object's entries contain at least
 * one valid entry
 */
export const hasAtLeastOneValidValue = (data: IData): boolean => {
  if (data) {
    return Boolean(
      Object.keys(data).length > 0 && Object.keys(data).find((key) => key && data[key]?.length > 0)
    );
  }
  return false;
};

/**
 * @description checks whether all object's entries are valid
 */
export const hasAllValid = (data: IData): boolean => {
  if (data) {
    return Boolean(
      Object.keys(data).length > 0 &&
        Object.keys(data).filter((elt) => data[elt] && data[elt].length > 0).length ===
          Object.keys(data).length
    );
  }
  return false;
};

/**
 * @description compares between old and new data for object entries
 * @param newData
 * @param originalData
 */
export const hasSameData = (newData: IData, originalData: IData): boolean =>
  Boolean(Object.keys(newData).find((key) => originalData[key].trim() !== newData[key]));
