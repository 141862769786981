import React, { useState } from 'react';
import { LineChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Line } from 'recharts';
import { formatNumber } from '../../helpers/format-numbers';
import './styles.scss';

type Props = {
  data: any;
};
export const InsightsLineChart = ({ data }: Props) => {
  const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 });
  const [activeTooltipData, setActiveTooltipData] = useState(null);

  const calculateTooltipPosition = (e: any) => {
    let { clientX, clientY } = e;

    if (clientX + 180 >= window.innerWidth) {
      clientX -= 150;
    }

    if (clientY + 150 >= window.innerHeight) {
      clientY -= 120;
    }

    setPointerPosition({ x: clientX + 20, y: clientY + 20 });
  };

  return (
    <div className="insights-line-chart-container">
      <ResponsiveContainer>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" dy={15} />
          <YAxis tickFormatter={(value) => `${value}%`} dx={-15} />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#945cff"
            strokeWidth={3}
            dot={{
              r: 5,
              stroke: '#945cff',
              fill: '#945cff',
              strokeWidth: 3,
              onMouseEnter: (tooltipData, e) => {
                setActiveTooltipData(tooltipData);
                calculateTooltipPosition(e);
              },
              onMouseMove: (_, e) => {
                calculateTooltipPosition(e);
              },
              onMouseLeave: () => {
                setActiveTooltipData(null);
              }
            }}
          />
        </LineChart>
      </ResponsiveContainer>
      {activeTooltipData && (
        <div
          className="insights-line-chart-tooltip"
          style={{ left: pointerPosition.x, top: pointerPosition.y }}
        >
          <div className="insights-line-chart-tooltip-title">
            {activeTooltipData.title || activeTooltipData.day}
          </div>
          <span>{`percentage: ${formatNumber(activeTooltipData.payload.value)}%`}</span>
        </div>
      )}
    </div>
  );
};
