import { Col, Row, Tabs } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosSingleton } from '../../utils/axiosinstance';
import { message } from '../../utils/custommessage';
import { AnghamiAdsButton } from '../core/AnghamiAdsButton';
import { AnghamiAdsFormInput } from '../core/AnghamiAdsFormInput';
import { AnghamiAdsFormSelector } from '../core/AnghamiAdsFormSelector';
import AnghamiAdsFullPageLoader from '../core/AnghamiAdsFullPageLoader';
import AnghamiAdsPageTitle from '../core/AnghamiAdsPageTitle';
import {
  countriesOptions,
  blsTypeOptions,
  howLikelyAnswers,
  statusOptions
} from './selectorOptions';
import './styles.scss';

const isAnswerTranslated = (answer) => {
  if (answer?.en.length === 0 && answer?.ar.length === 0) {
    return true;
  }

  if (answer?.en.length > 0 && answer?.ar.length === 0) {
    return false;
  }

  if (answer?.en.length === 0 && answer?.ar.length > 0) {
    return false;
  }

  return true;
};

type Props = {
  mode: 'edit' | 'create';
};

type BrandStudiesParams = {
  id: string | undefined;
  name: string;
  source: number;
  adids: string[];
  countries: string[];
  start_date: string;
  end_date: string;
  target_impressions: number | undefined;
  question: {
    en: string;
    ar: string;
  };
  answers: {
    en: string;
    ar: string;
  }[];
  type_id: number | undefined;
  status: number;
};

type QuestionAnswerType = {
  en: string;
  ar: string;
};

type AnswerInputsProps = {
  lang: 'en' | 'ar';
  firstAnswer: QuestionAnswerType;
  setFirstAnswer: (answer: QuestionAnswerType) => void;
  secondAnswer: QuestionAnswerType;
  setSecondAnswer: (answer: QuestionAnswerType) => void;
  thirdAnswer: QuestionAnswerType;
  setThirdAnswer: (answer: QuestionAnswerType) => void;
  fourthAnswer: QuestionAnswerType;
  setFourthAnswer: (answer: QuestionAnswerType) => void;
  fifthAnswer: QuestionAnswerType;
  setFifthAnswer: (answer: QuestionAnswerType) => void;
  sixthAnswer: QuestionAnswerType;
  setSixthAnswer: (answer: QuestionAnswerType) => void;
  typeId: number | undefined;
};

const AnswerInputs = ({
  lang,
  firstAnswer,
  setFirstAnswer,
  secondAnswer,
  setSecondAnswer,
  thirdAnswer,
  setThirdAnswer,
  fourthAnswer,
  setFourthAnswer,
  fifthAnswer,
  setFifthAnswer,
  sixthAnswer,
  setSixthAnswer,
  typeId
}: AnswerInputsProps) => (
  <>
    <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <AnghamiAdsFormInput
        label="Answer 1"
        placeholder="Insert the first answer"
        value={firstAnswer[lang]}
        onChange={(e) => {
          if (e.target.value.length >= 30) {
            return;
          }
          const newFirstAnswer = {
            ...firstAnswer
          };
          newFirstAnswer[lang] = e.target.value;
          setFirstAnswer(newFirstAnswer);
        }}
      />
    </div>
    <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <AnghamiAdsFormInput
        label="Answer 2"
        placeholder="Insert the second answer"
        value={secondAnswer[lang]}
        onChange={(e) => {
          if (e.target.value.length >= 30) {
            return;
          }
          const newSecondAnswer = {
            ...secondAnswer
          };
          newSecondAnswer[lang] = e.target.value;
          setSecondAnswer(newSecondAnswer);
        }}
      />
    </div>
    <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <AnghamiAdsFormInput
        label="Answer 3"
        placeholder="Insert the third answer"
        value={thirdAnswer[lang]}
        onChange={(e) => {
          if (e.target.value.length >= 30) {
            return;
          }
          const newThirdAnswer = {
            ...thirdAnswer
          };
          newThirdAnswer[lang] = e.target.value;
          setThirdAnswer(newThirdAnswer);
        }}
      />
    </div>
    <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
      <AnghamiAdsFormInput
        label="Answer 4"
        placeholder="Insert the fourth answer"
        value={fourthAnswer[lang]}
        onChange={(e) => {
          if (e.target.value.length >= 30) {
            return;
          }
          const newFourthAnswer = {
            ...fourthAnswer
          };
          newFourthAnswer[lang] = e.target.value;
          setFourthAnswer(newFourthAnswer);
        }}
      />
    </div>
    {typeId === 3 ? (
      <>
        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <AnghamiAdsFormInput
            label="Answer 5"
            placeholder="Insert the fifth answer"
            value={fifthAnswer[lang]}
            onChange={(e) => {
              if (e.target.value.length >= 30) {
                return;
              }
              const newFifthAnswer = {
                ...fifthAnswer
              };
              newFifthAnswer[lang] = e.target.value;
              setFifthAnswer(newFifthAnswer);
            }}
          />
        </div>
        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <AnghamiAdsFormInput
            label="Answer 6"
            placeholder="Insert the sixth answer"
            value={sixthAnswer[lang]}
            onChange={(e) => {
              if (e.target.value.length >= 30) {
                return;
              }
              const newSixthAnswer = {
                ...sixthAnswer
              };
              newSixthAnswer[lang] = e.target.value;
              setSixthAnswer(newSixthAnswer);
            }}
          />
        </div>
      </>
    ) : (
      <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <AnghamiAdsFormInput
          label="Answer 5"
          disabled
          readOnly
          placeholder="Insert the fifth answer"
          value={lang === 'ar' ? 'ولا واحدة' : 'None of these'}
        />
      </div>
    )}
  </>
);

export default function BrandUpliftPage({ mode }: Props) {
  const axios = AxiosSingleton.axiosInstance;
  const navigate = useNavigate();
  const isEditMode = mode === 'edit';
  const [isLoading, setIsLoading] = useState(isEditMode);
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [ads, setAds] = useState<string[]>([]);
  const [countries, setCountries] = useState<string[]>([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [targetImpressions, setTargetImpressions] = useState<number | undefined>();
  const [question, setQuestion] = useState({
    ar: '',
    en: ''
  });
  const [firstAnswer, setFirstAnswer] = useState({
    ar: '',
    en: ''
  });
  const [secondAnswer, setSecondAnswer] = useState({
    ar: '',
    en: ''
  });
  const [thirdAnswer, setThirdAnswer] = useState({
    ar: '',
    en: ''
  });
  const [fourthAnswer, setFourthAnswer] = useState({
    ar: '',
    en: ''
  });
  const [fifthAnswer, setFifthAnswer] = useState({
    ar: '',
    en: ''
  });
  const [sixthAnswer, setSixthAnswer] = useState({
    ar: '',
    en: ''
  });

  const [typeId, setTypeId] = useState<number | undefined>();
  const [status, setStatus] = useState(0);

  const [adsOptions, setAdsOptions] = useState([]);

  const [isSubmissionLoading, setIsSubmissionLoading] = useState(false);

  const isQuestionNonEmptyAndTranslated = question?.en.length > 0 && question?.ar.length > 0;
  const isAnyAnswerNotTranslated =
    !isAnswerTranslated(firstAnswer) ||
    !isAnswerTranslated(secondAnswer) ||
    !isAnswerTranslated(thirdAnswer) ||
    !isAnswerTranslated(fourthAnswer) ||
    !isAnswerTranslated(fifthAnswer);

  const fetchAds = () => {
    axios.get('/ads/all?simple=true&src=bls').then((res) => {
      const { data } = res;
      setAdsOptions(data);
    });
  };

  useEffect(fetchAds, []);

  const saveStudy = () => {
    if (!title) {
      message.error('Please add a title.');
      return;
    }

    if (!startDate) {
      message.error('Please add a start date.');
      return;
    }

    if (!endDate) {
      message.error('Please add an end date.');
      return;
    }

    if (!targetImpressions) {
      message.error('Please add your target impressions.');
      return;
    }

    if (!isQuestionNonEmptyAndTranslated) {
      message.error('Please add a question with its translation.');
      return;
    }

    if (isAnyAnswerNotTranslated) {
      message.error('Please make sure all answers have proper translations.');
      return;
    }

    const params: BrandStudiesParams = {
      id: isEditMode ? id : '',
      name: title,
      source: 1,
      adids: ads,
      countries,
      start_date: startDate,
      end_date: endDate,
      target_impressions: targetImpressions,
      question,
      answers: [firstAnswer, secondAnswer, thirdAnswer, fourthAnswer],
      type_id: typeId,
      status
    };

    if (typeId === 3) {
      params.answers.push(fifthAnswer);
      params.answers.push(sixthAnswer);
    } else {
      params.answers.push({
        en: 'None of these',
        ar: 'ولا واحدة'
      });
    }

    const endpoint = isEditMode ? '/brandstudies/update' : '/brandstudies/store';

    setIsSubmissionLoading(true);
    axios
      .post(endpoint, params)
      .then((res) => {
        const { data } = res;

        if (data.message === 'success') {
          message.success('Brand uplift study saved!');
          navigate('/brandupliftstudies');
        }
        setIsSubmissionLoading(true);
      })
      .catch(() => {
        message.error('There was an error saving the brand uplift study. Please try again later.');
        setIsSubmissionLoading(true);
      });
  };

  useEffect(() => {
    if (mode === 'create' || !id) return;
    axios.get(`/brandstudies/get?id=${id}`).then(({ data }) => {
      const selectedCampaign = data.data[0];
      setTitle(selectedCampaign.name);
      setAds(selectedCampaign.ads.map((adId: string) => Number(adId)));
      setCountries(selectedCampaign.countries);
      setStartDate(selectedCampaign.start_date);
      setEndDate(selectedCampaign.end_date);
      setTargetImpressions(selectedCampaign.target_impressions);
      setTypeId(selectedCampaign.type_id);
      setQuestion(selectedCampaign.question?.title);
      setIsLoading(false);
      setStatus(selectedCampaign.status);
      if (selectedCampaign.options) {
        const selectedAnswers = selectedCampaign.options.map((option) => ({
          en: option.option?.en || '',
          ar: option.option?.ar || ''
        }));
        setFirstAnswer(selectedAnswers[0] || { en: '', ar: '' });
        setSecondAnswer(selectedAnswers[1] || { en: '', ar: '' });
        setThirdAnswer(selectedAnswers[2] || { en: '', ar: '' });
        setFourthAnswer(selectedAnswers[3] || { en: '', ar: '' });
        if (selectedCampaign.type_id === 3) {
          setFifthAnswer(selectedAnswers[4] || { en: '', ar: '' });
          setSixthAnswer(selectedAnswers[5] || { en: '', ar: '' });
        }
      }
    });
  }, [mode]);

  useEffect(() => {
    if (typeId === 3 && mode === 'create') {
      setFirstAnswer(howLikelyAnswers[0]);
      setSecondAnswer(howLikelyAnswers[1]);
      setThirdAnswer(howLikelyAnswers[2]);
      setFourthAnswer(howLikelyAnswers[3]);
      setFifthAnswer(howLikelyAnswers[4]);
      setSixthAnswer(howLikelyAnswers[5]);
    }
  }, [typeId]);

  if (isLoading) {
    return <AnghamiAdsFullPageLoader />;
  }

  return (
    <div className="brand-studies-modal" style={{ width: '62.5rem' }}>
      <AnghamiAdsPageTitle label={`${mode} Brand Uplift Study`} />
      <div style={{ marginTop: '1rem' }}>
        <AnghamiAdsFormInput
          label="Brand Studies Title*"
          placeholder="Give a name for your brand study"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
      </div>
      <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <AnghamiAdsFormSelector
          label="Ads"
          mode="multiple"
          placeholder="Select ads"
          value={ads}
          options={adsOptions}
          onChange={(value: string[]) => {
            setAds(value);
          }}
        />
      </div>
      <Row gutter={[16, 16]} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Col span={12}>
          <div className="campaign-form-input-container">
            <label>Start Date *</label>
            <input
              type="date"
              min={moment().format('YYYY-MM-DD')}
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
            />
          </div>
        </Col>
        <Col span={12}>
          <div className="campaign-form-input-container">
            <label>End Date *</label>
            <input
              type="date"
              min={startDate}
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
            />
          </div>
        </Col>
      </Row>
      <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <AnghamiAdsFormInput
          label="Target Impressions *"
          placeholder="Enter your target impressions"
          value={targetImpressions}
          onChange={(e) => {
            setTargetImpressions(e.target.value);
          }}
        />
      </div>
      <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <AnghamiAdsFormSelector
          label="Countries"
          mode="multiple"
          placeholder="Select countries"
          value={countries}
          options={countriesOptions}
          onChange={(value: string[]) => {
            setCountries(value);
          }}
        />
      </div>
      <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <AnghamiAdsFormSelector
          label="Type"
          placeholder="Select type"
          value={typeId}
          options={blsTypeOptions}
          onChange={(value: number) => {
            setTypeId(value);
          }}
        />
      </div>
      <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <AnghamiAdsFormSelector
          label="Status"
          placeholder="Select status"
          value={status}
          options={statusOptions}
          onChange={(value: number) => {
            setStatus(value);
          }}
        />
      </div>
      <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane tab="English" key="item-1">
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
              <AnghamiAdsFormInput
                label="Question (English)"
                placeholder="Insert your english question"
                value={question?.en}
                onChange={(e) => {
                  if (e.target.value.length >= 80) {
                    return;
                  }
                  setQuestion({
                    ...question,
                    en: e.target.value
                  });
                }}
              />
            </div>
            <AnswerInputs
              lang="en"
              firstAnswer={firstAnswer}
              setFirstAnswer={setFirstAnswer}
              secondAnswer={secondAnswer}
              setSecondAnswer={setSecondAnswer}
              thirdAnswer={thirdAnswer}
              setThirdAnswer={setThirdAnswer}
              fourthAnswer={fourthAnswer}
              setFourthAnswer={setFourthAnswer}
              fifthAnswer={fifthAnswer}
              setFifthAnswer={setFifthAnswer}
              sixthAnswer={sixthAnswer}
              setSixthAnswer={setSixthAnswer}
              typeId={typeId}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Arabic" key="item-2">
            <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
              <AnghamiAdsFormInput
                label="Question (Arabic)"
                placeholder="Insert your arabic question"
                value={question?.ar}
                onChange={(e) => {
                  setQuestion({
                    ...question,
                    ar: e.target.value
                  });
                }}
              />
            </div>
            <AnswerInputs
              lang="ar"
              firstAnswer={firstAnswer}
              setFirstAnswer={setFirstAnswer}
              secondAnswer={secondAnswer}
              setSecondAnswer={setSecondAnswer}
              thirdAnswer={thirdAnswer}
              setThirdAnswer={setThirdAnswer}
              fourthAnswer={fourthAnswer}
              setFourthAnswer={setFourthAnswer}
              fifthAnswer={fifthAnswer}
              setFifthAnswer={setFifthAnswer}
              sixthAnswer={sixthAnswer}
              setSixthAnswer={setSixthAnswer}
              typeId={typeId}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
      <AnghamiAdsButton
        style={{ marginTop: '1rem', marginBottom: '1rem' }}
        label="Save"
        onClick={() => {
          saveStudy();
        }}
        isLoading={isSubmissionLoading}
      />
    </div>
  );
}
