import React, { useEffect, useRef } from 'react';
import { Col, Row } from 'antd';
import '../fillrate/styles.scss';
import { countries } from 'country-flags-svg';
import ArrowUp from '../../public/assets/images/arrows/arrow-up.png';
import ArrowDown from '../../public/assets/images/arrows/arrow-down.png';

interface ICountryRate {
  country: string;
  total_impressions: number;
  client_impressions: number;
  fillrate: string;
  diff_fillrate: number;
}

type Props = {
  rowIndex: number;
  data: ICountryRate;
  minHeight: number;
  sendHeight: ({ index: number, height: number }) => void;
};

const countryFlagPath = 'https://flagicons.lipis.dev/flags/4x3/';

const FillRateCountry = ({ rowIndex, data, minHeight, sendHeight }: Props) => {
  const countryData = countries.find((country) => country.iso2 === data?.country);
  const countryFlagIcon = `${countryFlagPath}${data?.country.toLowerCase()}.svg`;
  const countryWrap = useRef(null);

  useEffect(() => {
    const eltHeight = Math.ceil(countryWrap?.current.clientHeight / 16);
    if (minHeight < eltHeight) {
      sendHeight({ index: rowIndex, height: eltHeight });
    }
  });

  return (
    <Col span={8} key={data.country} gutter={[32, 0]}>
      <Row
        align="middle"
        className="FillRate-country-box"
        style={{
          minHeight: `${minHeight}rem`
        }}
      >
        <Col span={4}>
          <img className="FillRate-country-img flag-img" alt={data.country} src={countryFlagIcon} />
        </Col>
        <Col span={20}>
          <div className="FillRate-country-name" ref={countryWrap}>
            {countryData?.name}
          </div>
        </Col>
      </Row>
      <Row align="middle">
        <Col>
          <div className="FillRate-rate">{data.fillrate}%</div>
        </Col>
        <Col>
          <img
            src={Number(data?.diff_fillrate) < 0 ? ArrowDown : ArrowUp}
            className="FillRate-arrow-img"
            alt=""
          />
        </Col>
        <Col>
          <div
            style={{
              color: Number(data?.diff_fillrate) < 0 ? '#D03150' : '#52D57D'
            }}
            className="FillRate-country-diff"
          >
            {Number(Math.abs(data.diff_fillrate)).toFixed(2)}
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default FillRateCountry;
