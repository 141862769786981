import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AuthPage from './components/authpage';
import BasePage from './components/basepage';
import AuthGuard from './components/auth/AuthGuard';
import Login from './components/login/Login';
import SignUp from './components/signup/SignUp';
import ForgotPassword from './components/forgotpassword/ForgotPassword';
import ResetPassword from './components/forgotpassword/ResetPassword';
import Overview from './components/overview';
import Verification from './components/verification/Verification';
import CampaignInsights from './components/campaigninsights';
import ManageCampaigns from './components/managecampaigns';
import Campaign from './components/campaign';
import CampaignHoc from './components/campaignhoc';
import Invoices from './components/invoices';
import Home from './components/home';
import ErrorPage from './components/errorpage';
import SponsoredPlaylists from './components/sponsoredplaylists';
import SponsoredPlaylistsList from './components/sponsoredplaylistslist';
import ReportOverview from './components/reportsoverview';
import ReportsGenerate from './components/reportsgenerate';
import ReportsList from './components/reportslist';
import SellThroughRate from './components/str';
import Inventory from './components/inventory';
import ContendingCampaigns from './components/contendingcampaigns';
import BrandUpliftStudies from './components/brandupliftstudies';
import CustomAudiences from './components/customaudiences';
import AudienceInsights from './components/audienceinsights';
import AudienceInsightsGuard from './components/guards/AudienceInsightsGuard';
import AudienceMatchGuard from './components/guards/AudienceMatchGuard';
import BrandStudiesGuard from './components/guards/BrandStudiesGuard';
import ClientInvoicesGuard from './components/guards/ClientInvoicesGuard';
import FinancialOverviewGuard from './components/guards/FinancialOverviewGuard';
import InventoryGuard from './components/guards/InventoryGuard';
import ManageReportsGuard from './components/guards/ManageReportsGuard';
import SellThroughRateGuard from './components/guards/SellThroughRateGuard';
import SponsoredPlaylistsGuard from './components/guards/SponsoredPlaylistsGuard';
import ManageUsers from './components/manageusers';
import ManageUsersGuard from './components/guards/ManageUsersGuard';
import Budget from './components/budget';
import BudgetsList from './components/budgetslist';
import MastheadCampaignsGuard from './components/guards/MastheadCampaignsGuard';
import MastheadCampaign from './components/mastheadcampaign';
import MastheadCampaignList from './components/mastheadcampaignlist';
import MastheadCampaignCalendar from './components/mastheadcampaigncalendar';
import MastheadCampaignsCalendarGuard from './components/guards/MastheadCampaignsCalendarGuard';
import VideoMastheadCampaignsGuard from './components/guards/VideoMastheadCampaignsGuard';
import VideoMastheadCampaign from './components/videomastheadcampaign';
import VideoMastheadCampaignList from './components/videomastheadcampaignlist';
import BrandUpliftPage from './components/brandupliftpage';
import genericImage from './public/assets/images/generic.png';
import FillRateGuard from "./components/guards/FillRateGuard";
import FillRateExport from "./components/fillratepage";

export default function AppRoutes() {
  const navigate = useNavigate();
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthGuard>
            <BasePage>
              <Home />
            </BasePage>
          </AuthGuard>
        }
      />
      <Route
        path="/login"
        element={
          <AuthPage>
            <Login />
          </AuthPage>
        }
      />
      <Route
        path="/signup"
        element={
          <AuthPage>
            <SignUp />
          </AuthPage>
        }
      />
      <Route
        path="/forgotpassword"
        element={
          <AuthPage>
            <ForgotPassword />
          </AuthPage>
        }
      />
      <Route
        path="/resetpassword"
        element={
          <AuthPage>
            <ResetPassword />
          </AuthPage>
        }
      />
      <Route
        path="/verify"
        element={
          <AuthPage>
            <Verification />
          </AuthPage>
        }
      />
      <Route
        path="/overview"
        element={
          <AuthGuard>
            <BasePage>
              <Overview />
            </BasePage>
          </AuthGuard>
        }
      />
      <Route
        path="/insights/campaign/:campaignId"
        element={
          <AuthGuard>
            <BasePage>
              <CampaignInsights insightsType="campaign" />
            </BasePage>
          </AuthGuard>
        }
      />
      <Route
        path="/insights/groupcampaign/:campaignId"
        element={
          <AuthGuard>
            <BasePage>
              <CampaignInsights insightsType="groupcampaign" />
            </BasePage>
          </AuthGuard>
        }
      />
      <Route
        path="/manage-campaigns"
        element={
          <AuthGuard>
            <BasePage>
              <CampaignHoc mode="preview">
                <ManageCampaigns />
              </CampaignHoc>
            </BasePage>
          </AuthGuard>
        }
      />
      <Route
        path="/invoices"
        element={
          <AuthGuard>
            <ClientInvoicesGuard>
              <BasePage>
                <Invoices />
              </BasePage>
            </ClientInvoicesGuard>
          </AuthGuard>
        }
      />
      <Route
        path="/campaign/create"
        element={
          <AuthGuard>
            <CampaignHoc mode="create">
              <Campaign />
            </CampaignHoc>
          </AuthGuard>
        }
      />
      <Route
        path="/campaign/edit/:id"
        element={
          <AuthGuard>
            <CampaignHoc mode="edit">
              <Campaign />
            </CampaignHoc>
          </AuthGuard>
        }
      />
      <Route
        path="/campaign/preview/:id"
        element={
          <AuthGuard>
            <CampaignHoc mode="preview">
              <Campaign />
            </CampaignHoc>
          </AuthGuard>
        }
      />
      <Route
        path="/sponsoredplaylists"
        element={
          <AuthGuard>
            <SponsoredPlaylistsGuard>
              <BasePage>
                <SponsoredPlaylistsList />
              </BasePage>
            </SponsoredPlaylistsGuard>
          </AuthGuard>
        }
      />
      <Route
        path="/sponsoredplaylists/create"
        element={
          <AuthGuard>
            <SponsoredPlaylists mode="create" />
          </AuthGuard>
        }
      />
      <Route
        path="/masthead-campaigns/list"
        element={
          <AuthGuard>
            <MastheadCampaignsGuard>
              <BasePage>
                <MastheadCampaignList />
              </BasePage>
            </MastheadCampaignsGuard>
          </AuthGuard>
        }
      />
    <Route
        path="/video-masthead-campaigns/list"
        element={
            <AuthGuard>
                <VideoMastheadCampaignsGuard>
                    <BasePage>
                        <VideoMastheadCampaignList />
                    </BasePage>
                </VideoMastheadCampaignsGuard>
            </AuthGuard>
        }
    />

    <Route
        path="/video-masthead-campaigns/create"
        element={
            <AuthGuard>
                <VideoMastheadCampaignsGuard>
                    <BasePage>
                        <VideoMastheadCampaign />
                    </BasePage>
                </VideoMastheadCampaignsGuard>
            </AuthGuard>
        }
    />


      <Route
        path="/masthead-campaigns/calendar"
        element={
          <AuthGuard>
            <MastheadCampaignsCalendarGuard>
              <BasePage>
                <MastheadCampaignCalendar />
              </BasePage>
            </MastheadCampaignsCalendarGuard>
          </AuthGuard>
        }
      />
      <Route
        path="/masthead-campaigns/create"
        element={
          <AuthGuard>
            <MastheadCampaignsGuard>
              <BasePage>
                <MastheadCampaign />
              </BasePage>
            </MastheadCampaignsGuard>
          </AuthGuard>
        }
      />
      <Route
        path="/masthead-campaigns/edit/:campaignId"
        element={
          <AuthGuard>
            <MastheadCampaignsGuard>
              <BasePage>
                <MastheadCampaign />
              </BasePage>
            </MastheadCampaignsGuard>
          </AuthGuard>
        }
      />
        <Route
            path="/video-masthead-campaigns/edit/:campaignId"
            element={
                <AuthGuard>
                    <VideoMastheadCampaignsGuard>
                        <BasePage>
                            <VideoMastheadCampaign />
                        </BasePage>
                    </VideoMastheadCampaignsGuard>
                </AuthGuard>
            }
        />
      <Route
        path="/reports/overview"
        element={
          <AuthGuard>
            <FinancialOverviewGuard>
              <BasePage>
                <ReportOverview />
              </BasePage>
            </FinancialOverviewGuard>
          </AuthGuard>
        }
      />
      <Route
        path="/reports/generate"
        element={
          <AuthGuard>
            <BasePage>
              <ReportsGenerate />
            </BasePage>
          </AuthGuard>
        }
      />
      <Route
        path="/sponsoredplaylists/edit/:id"
        element={
          <AuthGuard>
            <SponsoredPlaylists mode="edit" />
          </AuthGuard>
        }
      />
      <Route
        path="/reports/generate/:reportId"
        element={
          <AuthGuard>
            <BasePage>
              <ReportsGenerate />
            </BasePage>
          </AuthGuard>
        }
      />
      <Route
        path="/reports/list"
        element={
          <AuthGuard>
            <ManageReportsGuard>
              <BasePage>
                <ReportsList />
              </BasePage>
            </ManageReportsGuard>
          </AuthGuard>
        }
      />
      <Route
        path="/str"
        element={
          <AuthGuard>
            <SellThroughRateGuard>
              <BasePage>
                <SellThroughRate />
              </BasePage>
            </SellThroughRateGuard>
          </AuthGuard>
        }
      />
      <Route
        path="/inventory"
        element={
          <AuthGuard>
            <InventoryGuard>
              <BasePage>
                <Inventory />
              </BasePage>
            </InventoryGuard>
          </AuthGuard>
        }
      />
      <Route
        path="/contending-campaigns"
        element={
          <AuthGuard>
            <BasePage>
              <ContendingCampaigns />
            </BasePage>
          </AuthGuard>
        }
      />
      <Route
        path="/custom-audiences"
        element={
          <AuthGuard>
            <AudienceMatchGuard>
              <BasePage>
                <CustomAudiences />
              </BasePage>
            </AudienceMatchGuard>
          </AuthGuard>
        }
      />
      <Route
        path="/audience-insights"
        element={
          <AuthGuard>
            <AudienceInsightsGuard>
              <BasePage>
                <AudienceInsights />
              </BasePage>
            </AudienceInsightsGuard>
          </AuthGuard>
        }
      />
      <Route
        path="/brandupliftstudies/create"
        element={
          <AuthGuard>
            <BrandStudiesGuard>
              <BasePage>
                <BrandUpliftPage mode="create" />
              </BasePage>
            </BrandStudiesGuard>
          </AuthGuard>
        }
      />
      <Route
        path="/brandupliftstudies/edit/:id"
        element={
          <AuthGuard>
            <BrandStudiesGuard>
              <BasePage>
                <BrandUpliftPage mode="edit" />
              </BasePage>
            </BrandStudiesGuard>
          </AuthGuard>
        }
      />
      <Route
        path="/brandupliftstudies"
        element={
          <AuthGuard>
            <BrandStudiesGuard>
              <BasePage>
                <BrandUpliftStudies />
              </BasePage>
            </BrandStudiesGuard>
          </AuthGuard>
        }
      />
      <Route
        path="/manage-users"
        element={
          <AuthGuard>
            <ManageUsersGuard>
              <BasePage>
                <ManageUsers />
              </BasePage>
            </ManageUsersGuard>
          </AuthGuard>
        }
      />
      <Route
        path="/budget/create"
        element={
          <AuthGuard>
            <BasePage>
              <Budget mode="create" />
            </BasePage>
          </AuthGuard>
        }
      />
      <Route
        path="/budget/edit/:id"
        element={
          <AuthGuard>
            <BasePage>
              <Budget mode="edit" />
            </BasePage>
          </AuthGuard>
        }
      />
      <Route
        path="/budgets"
        element={
          <AuthGuard>
            <BasePage>
              <BudgetsList />
            </BasePage>
          </AuthGuard>
        }
      />
      <Route
        path="/fill-rate"
        element={
          <AuthGuard>
            <FillRateGuard>
              <BasePage>
                <FillRateExport />
              </BasePage>
            </FillRateGuard>
          </AuthGuard>
        }
      />
      <Route
        path="*"
        element={
          <AuthGuard>
            <BasePage>
              <ErrorPage
                title="Lost your way?"
                subTitle="Looks like the page you’re trying to access doesn’t exist."
                img={genericImage}
                ctaTitle="Go to Overview"
                ctaFunc={() => navigate('/overview')}
              />
            </BasePage>
          </AuthGuard>
        }
      />
    </Routes>
  );
}
