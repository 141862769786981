import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import ProfileModal from '../profilemodal';

type Props = {
  children: React.ReactChild;
};

const AuthGuard = ({ children }: Props) => {
  const { isLoggedIn, user } = useAuth();
  const { pathname, search } = window.location;

  if (!isLoggedIn) {
    if (pathname !== '/') {
      return <Navigate replace to={`/login?redirecturl=${pathname}${search}`} />;
    }
    return <Navigate replace to="/login" />;
  }

  if (isLoggedIn && !user?.verified) {
    return <Navigate replace to="/verify" />;
  }

  return (
    <>
      {isLoggedIn && !user?.publisher_id ? <ProfileModal /> : null}
      {children}
    </>
  );
};

export default AuthGuard;
