export const adRotations = [
  { id: 1, text: 'Even' },
  { id: 2, text: 'Sequential' },
  { id: 3, text: 'Weighted' }
];

export const countries = [
  { id: 'AE', text: 'United Arab Emirates' },
  { id: 'SA', text: 'Saudi Arabia' },
  { id: 'KW', text: 'Kuwait' },
  { id: 'BH', text: 'Bahrain' },
  { id: 'OM', text: 'Oman' },
  { id: 'QA', text: 'Qatar' },
  { id: 'EG', text: 'Egypt' },
  { id: 'SS', text: 'South Sudan' },
  { id: 'SD', text: 'Sudan' },
  { id: 'LB', text: 'Lebanon' },
  { id: 'JO', text: 'Jordan' },
  { id: 'PS', text: 'Palestine' },
  { id: 'SY', text: 'Syria' },
  { id: 'IQ', text: 'Iraq' },
  { id: 'DZ', text: 'Algeria' },
  { id: 'LY', text: 'Libya' },
  { id: 'MA', text: 'Morocco' },
  { id: 'TN', text: 'Tunisia' },
  { id: 'ROW', text: 'Rest of the World' }
];

export const genderOptions = [
  { id: 'male', text: 'Male' },
  { id: 'female', text: 'Female' }
];

export const musicPreferencesOptions = [
  { id: 'arabic', text: 'Arabic' },
  { id: 'intl', text: 'International' }
];

export const appLanguageOptions = [
  { id: 'en', text: 'English' },
  { id: 'ar', text: 'Arabic' },
  { id: 'fr', text: 'French' }
];

export const mediumOptions = [
  { id: 'anghami_app', text: 'Anghami App' },
  { id: 'anghami_podcast', text: 'Hakawati Podcasts Network' },
  { id: 'cenomi_mall', text: 'Cenomi Malls' },
  { id: 'anghami_home_device', text: 'Anghami at Home (Audio Assistants)' }
];

export const directClientsMediumOptions = [
  { id: 'anghami_app', text: 'Anghami App' },
];

export const platformOptions = [
  { id: 'android', text: 'Android' },
  { id: 'ios', text: 'iOS' },
  { id: 'web', text: 'Web' }
];

export const interactionOptions = [
  { id: 'all', text: 'All' },
  { id: 'view_start', text: 'Views' },
  { id: 'click', text: 'Clicks' }
];

export const operatorsOptions = [
  { id: 'or', text: 'OR' },
  { id: 'and', text: 'AND' }
];

export const frequencyCapDurations = [
  { id: 'day', text: 'day' },
  { id: 'week', text: 'week' },
  { id: 'month', text: 'month' },
  { id: 'lifetime', text: 'lifetime' }
];
