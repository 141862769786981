import moment from 'moment';
import React from 'react';
import { formatNumber } from '../../helpers/format-numbers';

export type TableColumnType = {
  title: string;
  width: string;
  dataIndex: string;
  key: string;
  fixed?: string;
  render: (value: any, data: any) => any;
};

const formatStatus = (status) => {
  switch (status) {
    case 0:
      return 'Draft';
    case 1:
      return 'Pending';
    case 2:
      return 'Running';
    case 3:
      return 'Paused';
    case 4:
      return 'Completed';
    case 5:
      return 'Scheduled';
    case -2:
      return 'Rejected';
    default:
      return 'Inactive';
  }
};

const formatPriority = (priority) => {
  switch (priority) {
    case 1:
      return 'Low';
    case 5:
      return 'Normal';
    case 10:
      return 'High';
    case 6:
      return 'Low';
    case 11:
      return 'Normal';
    case 15:
      return 'High';
    case 12:
      return 'Low';
    case 16:
      return 'Normal';
    case 20:
      return 'High';
    default:
      return priority;
  }
};

const formatDeliveryRotation = (rotation) => {
  switch (rotation) {
    case 1:
      return 'Even';
    case 2:
      return 'Sequential';
    case 3:
      return 'Weighted';
    default:
      return 'Even<';
  }
};

const renderFrequencies = (frequencies) => {
  if (frequencies === '') {
    return <span>Indefinite</span>;
  }

  return (
    <div>
      {frequencies.map((frequency) => {
        const elements = frequency.split(':');
        let count = elements[0];
        let duration = elements[1];
        if (count === 1) count = 'Once';
        else if (count === 2) count = 'Twice';
        else count += ' times';

        // Human-readable 'duration'
        if (duration === 'day') duration = 'day';
        else if (duration === 'week') duration = 'week';
        else if (duration === 'month') duration = 'month';
        else if (duration === 'lifetime') duration = 'lifetime';
        return (
          <div key={duration}>
            {count} per {duration}
          </div>
        );
      })}
    </div>
  );
};

export const contendingCampaignsTableColumns: TableColumnType[] = [
  {
    title: 'Name',
    width: '14rem',
    dataIndex: 'name',
    key: '0',
    render: (name: string) => <span>{name}</span>
  },
  {
    title: 'Impressions',
    dataIndex: 'target_impressions',
    key: '1',
    width: '14rem',
    render: (impressions: string, data) => (
      <span>
        {formatNumber(data.impressions)} out of {formatNumber(data.target_impressions)}
      </span>
    )
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: '2',
    width: '9rem',
    render: (status: string) => <span>{formatStatus(status)}</span>
  },
  {
    title: 'Date',
    dataIndex: 'start_date',
    key: '3',
    width: '13rem',
    render: (date: string, data: any) => (
      <span>
        From {moment(data.start_date).format('MMM Do YYYY')}
        <br />
        To {moment(data.end_date).format('MMM Do YYYY')}
      </span>
    )
  },
  {
    title: 'Audience',
    dataIndex: 'countries',
    key: '4',
    width: '9rem',
    render: (countries: string) => <span>{countries}</span>
  },
  {
    title: 'Priority',
    dataIndex: 'priority',
    key: '5',
    width: '7rem',
    render: (priority: string) => <span>{formatPriority(priority)}</span>
  },
  {
    title: 'Type',
    dataIndex: 'format',
    key: '6',
    width: '7rem',
    render: (format: string) => <span style={{ textTransform: 'capitalize' }}>{format}</span>
  },
  {
    title: 'Objective',
    dataIndex: 'objective',
    key: '7',
    width: '8rem',
    render: (objective: string) => <span style={{ textTransform: 'capitalize' }}>{objective}</span>
  },
  {
    title: 'Delivery Rotation',
    dataIndex: 'delivery_rotation',
    key: '8',
    width: '11rem',
    render: (rotation: string) => <span>{formatDeliveryRotation(rotation)}</span>
  },
  {
    title: 'Frequencies',
    dataIndex: 'frequencies',
    key: '9',
    width: '9rem',
    render: (frequencies: string) => renderFrequencies(frequencies)
  },
  {
    title: 'Created By',
    dataIndex: 'created_by',
    key: '10',
    width: '9rem',
    render: (creator: string) => <span>{creator}</span>
  }
];
