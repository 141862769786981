import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import { FiltersSelectTypes } from '../../enums';

type Props = {
  type: FiltersSelectTypes;
  onChange: (e: any) => void;
};

const FilterDate = ({ type, onChange }: Props) => {
  let renderedComponent;

  switch (type) {
    case FiltersSelectTypes.lessThan:
      renderedComponent = (
        <DatePicker
          className="FilterItem-filter-menu-input"
          onChange={(e) => onChange(e?.format('YYYY-MM-DD'))}
        />
      );
      break;
    case FiltersSelectTypes.greaterThan:
      renderedComponent = (
        <DatePicker
          className="FilterItem-filter-menu-input"
          onChange={(e) => onChange(e?.format('YYYY-MM-DD'))}
        />
      );
      break;
    case FiltersSelectTypes.between:
      renderedComponent = (
        <DatePicker.RangePicker
          disabledDate={(current) => {
            const today = moment();
            const diff = current.diff(today, 'days');
            return diff > 0;
          }}
          className="FilterItem-filter-menu-input"
          onChange={(rangeDate: any) =>
            onChange(
              rangeDate
                ? [rangeDate[0]?.format('YYYY-MM-DD'), rangeDate[1]?.format('YYYY-MM-DD')]
                : ''
            )
          }
        />
      );
      break;
    default:
  }

  return <>{renderedComponent}</>;
};

export default FilterDate;
