import { PlusOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useState } from 'react';
import AdsTable from '../adstable';
import { manageUsersTableColumns } from './tableColumns';
import './styles.scss';
import UserModal from './UserModal';
import { useFetchUsers } from './useFetchUsers';

const ManageUsers = () => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { tableData } = useFetchUsers();

  return (
    <div className="manage-users-container">
      <div className="manage-users-header">
        <div className="manage-users-header-actions">
          <div className="manage-users-header-heading">Users</div>
          <button
            type="button"
            className="manage-users-header-button"
            onClick={() => {
              setShowCreateModal(true);
            }}
          >
            <PlusOutlined />
            NEW USER
          </button>
        </div>
      </div>
      {tableData && (
        <AdsTable
          data={tableData}
          tableColumns={manageUsersTableColumns}
          onFetch={() => null}
          isLoading={tableData.length === 0}
          isInfiniteLoading={false}
          rowSelection={false}
        />
      )}

      <Modal
        centered
        visible={showCreateModal}
        footer={null}
        closable
        width="40rem"
        destroyOnClose
        onCancel={() => {
          setShowCreateModal(false);
        }}
      >
        <UserModal
          mode="create"
          user={null}
          closeModal={() => {
            setShowCreateModal(false);
          }}
        />
      </Modal>
    </div>
  );
};

export default ManageUsers;
