import React from 'react';
import { Typography } from 'antd';
import './styles.scss';

type Props = {
  title: string;
};

const { Title } = Typography;

const PageHeader = function ({ title }: Props) {
  return (
    <div className="PageHeader">
      <div>
        <Title level={3}>{title}</Title>
      </div>
    </div>
  );
};

export default PageHeader;
