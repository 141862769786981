import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

type Props = {
  date: any;
  setDate: (s: any) => void;
};

const MastheadCampaignDatePicker = ({ date, setDate }: Props) => (
  <DatePicker
    bordered={false}
    style={{ width: '100%', padding: '0' }}
    value={date}
    onChange={(value) => {
      setDate(value);
    }}
  />
);

export default MastheadCampaignDatePicker;
