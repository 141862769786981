export const getTargetImpressions = (
  targetImpressions: any,
  paidImpressions: any,
  freeImpressions: any,
  showFirstToggled: any
) => {
  if (showFirstToggled) {
    return null;
  }

  if (targetImpressions) {
    return targetImpressions;
  }

  return freeImpressions + paidImpressions;
};
