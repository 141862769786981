import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { AxiosSingleton } from '../../utils/axiosinstance';
import BarChart from '../charts/barchart';
import HorizontalChart from '../charts/horizontalchart';
import InsightsCard from '../insightscard';
import { InsightsLineChart } from '../insightslinechart';
import { InsightsVerticalBarChart } from '../insightsverticalbarchart';
import SpinningCircle from '../spinningcircle';
import { ageOptions, genderOptions, countriesOptions } from './selectorOptions';
import { AnghamiAdsFormSelector } from '../core/AnghamiAdsFormSelector';
import './styles.scss';

const languageInsightsTitleMap = {
  en: 'English',
  ar: 'Arabic',
  fr: 'French'
};

type TopArtist = {
  artist_id: number;
  artist_name: string;
};

export default function AudienceInsights() {
  const axios = AxiosSingleton.axiosInstance;

  const [selectedCountry, setSelectedCountry] = useState(countriesOptions[0].id);
  const [selectedAgeBracket, setSelectedAgeBracket] = useState<string[]>([]);
  const [selectedGender, setSelectedGender] = useState<string | null>(null);

  const [ageInsights, setAgeInsights] = useState([]);
  const [genderInsights, setGenderInsights] = useState([]);
  const [languageInsights, setLanguageInsights] = useState([]);
  const [platformInsights, setPlatformInsights] = useState([]);
  const [topArtists, setTopArtists] = useState<TopArtist[]>([]);
  const [genresInsights, setGenresInsights] = useState([]);
  const [playlistsInsights, setPlaylistsInsights] = useState([]);
  const [tabsInsights, setTabsInsights] = useState([]);
  const [streamsPerTimeOfDay, setStreamsPerTimeOfDay] = useState([]);

  const [isAudienceInsightsLoading, setIsAudienceInsightsLoading] = useState(false);

  const resetFilters = () => {
    setSelectedCountry(countriesOptions[0].id);
    setSelectedAgeBracket([]);
    setSelectedGender(null);
  };

  const getCommercialData = () => {
    setIsAudienceInsightsLoading(true);
    const filters = {
      country: selectedCountry,
      agebracket: selectedAgeBracket.length > 0 ? selectedAgeBracket : '',
      gender: selectedGender
    };

    axios.post('/adplanner/get/commercial-data', { filters }).then((res) => {
      setIsAudienceInsightsLoading(false);
      const { data } = res;

      const formattedAgeInsights = data.age_insights
        ? data.age_insights.map((bracket) => ({
            title: bracket.agebracket,
            percentage: Number(bracket.percentage)
          }))
        : [];
      setAgeInsights(formattedAgeInsights);

      const formattedGenderInsights = data.gender_insights
        ? data.gender_insights.map((genderInsight) => ({
            title: genderInsight.gender,
            percentage: Number(genderInsight.percentage)
          }))
        : [];
      setGenderInsights(formattedGenderInsights);

      const formattedLanguageInsights = data.language_insights
        ? data.language_insights.map((languageInsight) => ({
            title: languageInsightsTitleMap[languageInsight.language],
            percentage: Number(languageInsight.percentage)
          }))
        : [];
      setLanguageInsights(formattedLanguageInsights);

      const formattedPlatformInsights = data.platform_insights
        ? data.platform_insights.map((platformInsight) => ({
            title: platformInsight.platform,
            percentage: Number(platformInsight.percentage)
          }))
        : [];
      setPlatformInsights(formattedPlatformInsights);

      setTopArtists(data.top_artists_insights);

      const formattedPlaylistsInsights = data.top_playlists_insights.map((playlistsInsight) => ({
        title: playlistsInsight.playlist_name,
        impressions: Number(playlistsInsight.streams)
      }));
      setPlaylistsInsights(formattedPlaylistsInsights);

      const formattedGenresInsights = data.top_genres_insights.map((genresInsight) => ({
        title: genresInsight.genre,
        impressions: Number(genresInsight.streams)
      }));
      setGenresInsights(formattedGenresInsights);

      const formattedTabsInsights = data.top_tabs_insights.map((tabsInsight) => ({
        title: tabsInsight.tag_name,
        impressions: Number(tabsInsight.streams)
      }));
      setTabsInsights(formattedTabsInsights);

      const formattedStreamsPerTimeOfDay = data.hourly_streams_insights.map((stream) => ({
        name: String(stream.hour),
        value: Number(stream.percentage)
      }));
      setStreamsPerTimeOfDay(formattedStreamsPerTimeOfDay);
    });
  };

  useEffect(getCommercialData, []);

  return (
    <div className="audience-insights-container">
      <div className="audience-insights-title">Audience Insights</div>
      <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
        <Col span={8}>
          <AnghamiAdsFormSelector
            label="Filter By Country"
            placeholder="Select country or region"
            value={selectedCountry}
            options={countriesOptions}
            onChange={(value: string) => {
              setSelectedCountry(value);
            }}
          />
        </Col>
        <Col span={8}>
          <AnghamiAdsFormSelector
            label="Filter By Age"
            mode="multiple"
            placeholder="Select age bracket"
            value={selectedAgeBracket}
            options={ageOptions}
            onChange={(value: string[]) => {
              setSelectedAgeBracket(value);
            }}
          />
        </Col>
        <Col span={8}>
          <AnghamiAdsFormSelector
            label="Filter By Gender"
            placeholder="Select gender"
            value={selectedGender}
            options={genderOptions}
            onChange={(value: string) => {
              setSelectedGender(value);
            }}
          />
        </Col>
      </Row>
      <div className="controls-btns-container">
        <button type="button" className="reset-btn" onClick={resetFilters}>
          Reset
        </button>
        <button type="button" className="apply-btn" onClick={getCommercialData}>
          {isAudienceInsightsLoading ? <SpinningCircle /> : 'Apply'}
        </button>
      </div>
      <div className="audience-insights-section">
        {ageInsights.length > 0 && (
          <InsightsCard width="49%" height="32rem" title="Age Split">
            <BarChart values={ageInsights} color="#44e6b0" />
          </InsightsCard>
        )}
        {genderInsights.length > 0 && (
          <InsightsCard width="49%" height="32rem" title="Gender Split">
            <InsightsVerticalBarChart
              values={genderInsights}
              color="#ff5c5c"
              dataKey="percentage"
            />
          </InsightsCard>
        )}
      </div>
      <div className="audience-insights-section">
        <InsightsCard width="49%" height="12rem" title="App Language Split">
          <HorizontalChart values={languageInsights} color="#945cff" />
        </InsightsCard>
        <InsightsCard width="49%" height="12rem" title="Platform Split">
          <HorizontalChart values={platformInsights} color="#FFD800" />
        </InsightsCard>
      </div>
      <InsightsCard width="100%" height="23rem" title="Top Artists">
        <div className="top-artists-list">
          <div className="top-artists-list-column">
            {topArtists
              .slice(0, Math.ceil(topArtists.length / 2))
              .map((artist: TopArtist, index) => (
                <div key={artist.artist_id} className="top-artists-list-item">{`${index + 1}. ${
                  artist.artist_name
                }`}</div>
              ))}
          </div>
          <div className="top-artists-list-column">
            {topArtists.slice(-Math.ceil(topArtists.length / 2)).map((artist: TopArtist, index) => (
              <div key={artist.artist_id} className="top-artists-list-item">{`${index + 11}. ${
                artist.artist_name
              }`}</div>
            ))}
          </div>
        </div>
      </InsightsCard>
      <InsightsCard width="100%" height="32rem" title="Top Playlists">
        <InsightsVerticalBarChart
          values={playlistsInsights}
          color="#5c5cff"
          dataKey="impressions"
        />
      </InsightsCard>
      <InsightsCard width="100%" height="32rem" title="Top Genres">
        <InsightsVerticalBarChart values={genresInsights} color="#44E6B0" dataKey="impressions" />
      </InsightsCard>
      <InsightsCard width="100%" height="32rem" title="Top Tabs">
        <InsightsVerticalBarChart values={tabsInsights} color="#FF5C5C" dataKey="impressions" />
      </InsightsCard>
      <InsightsCard width="100%" height="32rem" title="Streams Per Time Of Day">
        <InsightsLineChart data={streamsPerTimeOfDay} />
      </InsightsCard>
    </div>
  );
}
