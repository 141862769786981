export enum CampaignTopLevelNavigation {
  Setup = 'setup',
  AdSets = 'adsets',
  Review = 'review'
}

export enum AdSetNavigation {
  Setup = 'setup',
  Targeting = 'targeting',
  Creative = 'creative'
}
