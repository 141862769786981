import React from 'react';
import { DataType } from '../managecampaigns/tabledatatype';
import './styles.scss';

type Props = {
  data: DataType;
};

const BudgetCell = ({ data }: Props) => {
  if (!data.budget) {
    return <span>N/A</span>;
  }

  return (
    <div className="BudgetCell">
      <div className="BudgetCell-is">${data.amount_spent || '0.0'}</div>
      <div className="BudgetCell-of">out of ${data.budget?.toFixed(2) || 0}</div>
      {typeof data.budget === 'number' ? (
        <div className="BudgetCell-slider">
          <div
            className="BudgetCell-slider-thumb"
            style={{ width: `${(data.amount_spent || 0 / data.budget) * 100 || 0}%` }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default BudgetCell;
