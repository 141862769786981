import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NeoAnalytics from '@anghami/neoanalytics';
import { userContext, Auth, User } from './auth.context';
import { PublisherRoles } from '../../enums/publisherroles';

type Props = {
  children: any;
};

export default function AuthWrapper({ children }: Props) {
  const localStorageUser = JSON.parse(window.localStorage.getItem('ads_user'));
  const localStorageToken = window.localStorage.getItem('ads_token');

  const [isLoggedIn, setIsLoggedIn] = useState(
    Boolean(localStorageUser) && localStorageToken && localStorageToken !== 'undefined'
  );
  const [user, setUser] = useState<User>(localStorageUser as unknown as User);
  const [showProfileModal, setShowProfileModal] = useState(false);

  const navigate = useNavigate();
  NeoAnalytics.setUserData({
    anid: user?.id,
    ...user
  });

  const logout = () => {
    setIsLoggedIn(false);
    setUser(null);
    window.localStorage.removeItem('ads_user');
    window.localStorage.removeItem('ads_token');
    setShowProfileModal(!showProfileModal);
    navigate('/login');
  };

  const convertPublisherTypeToPublisherRole = (type: string[], roleId: number) => {
    if (!type) {
      return PublisherRoles.Unknown;
    }

    const [publisherType, publisherSubtype] = type;

    if (publisherType === PublisherRoles.Anghami) {
      return PublisherRoles.Anghami;
    }

    if (publisherType === PublisherRoles.DirectClient) {
      return PublisherRoles.DirectClient;
    }

    if (publisherType === PublisherRoles.AnghamiAdvertiser) {
      return PublisherRoles.AnghamiAdvertiser;
    }

    if (publisherType === PublisherRoles.DMSAdvertiser) {
      return PublisherRoles.DMSAdvertiser;
    }

    if (
      publisherType === PublisherRoles.MediaPartner &&
      publisherSubtype === PublisherRoles.MediaAlgeria
    ) {
      return PublisherRoles.MediaAlgeria;
    }

    if (
      publisherType === PublisherRoles.MediaPartner &&
      publisherSubtype === PublisherRoles.DMSPublisher
    ) {
      if (roleId === 19) {
        return PublisherRoles.DMS_CRM;
      }

      if (roleId === 18) {
        return PublisherRoles.DMSTrafficker;
      }

      if (roleId === 17 || roleId === 29) {
        return PublisherRoles.DMSAdmin;
      }

      if (roleId === 14) {
        return PublisherRoles.DMSExternalCampaignResearch;
      }

      if (roleId === 12) {
        return PublisherRoles.DMSExternalCampaignReporting;
      }

      if (roleId === 21 || roleId === 30) {
        return PublisherRoles.DMSPublisher;
      }
    }

    if (publisherType === PublisherRoles.WarnerPublisher && roleId === 28) {
      return PublisherRoles.WarnerPublisher;
    }

    return PublisherRoles.Unknown;
  };

  const [role, setRole] = useState(
    convertPublisherTypeToPublisherRole(localStorageUser?.publisher_type, localStorageUser?.role_id)
  );

  const auth: Auth = {
    user,
    setUser: (data, appendOldUser = false) => {
      const userData: User = {
        // used to preserve the token and other values that does not come with complete-profile endpoint
        ...(appendOldUser ? user : {}),
        ...data?.user,
        verified: Boolean(data?.verified)
      };
      setUser(userData);
      setRole(convertPublisherTypeToPublisherRole(userData.publisher_type, userData.role_id));
      window.localStorage.setItem('ads_user', JSON.stringify(userData));
      if (data?.access_token) {
        window.localStorage.setItem('ads_token', data.access_token);
        NeoAnalytics.setUserData({
          anid: userData.id,
          ...userData
        });
      }
    },
    isLoggedIn,
    setIsLoggedIn,
    setShowProfileModal,
    logout,
    role
  };

  return <userContext.Provider value={auth}>{children}</userContext.Provider>;
}
