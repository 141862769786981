import React, { useContext, useEffect, useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { Select } from 'antd';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AudioAd from '../../public/assets/images/audioad.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VideoAd from '../../public/assets/images/videoad.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DisplayAd from '../../public/assets/images/displayad.svg';
import './styles.scss';
import { campaignContext } from '../campaignhoc/campaign.context';
import { AnghamiAdsFormInput } from '../core/AnghamiAdsFormInput';
import { AnghamiAdsFormSelector } from '../core/AnghamiAdsFormSelector';
import { IAdSet } from '../campaignhoc';
import { message } from '../../utils/custommessage';
import { Messages } from '../../enums/messages';
import { logAmplitudeEvent } from '../../utils/analytics';
import { CampaignAmplitudeEvents } from '../../enums/campaignamplitudeevents';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AudioLabImg from '../../public/assets/images/anghamilabaudioad.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CouponStar from '../../public/assets/images/coupon-star.svg';
import useAuth from '../../hooks/useAuth';
import { AxiosSingleton } from '../../utils/axiosinstance';
import { PublisherRoles } from '../../enums/publisherroles';
import { campaignTypeOptions, currencyOptions } from './selectorOptions';
import DebounceSelect from '../debounceselect';

const { Option } = Select;

type SelectorOptionType = {
  id: string | number;
  text: string | number;
};

type Props = {
  mode: string;
  adSet: IAdSet;
  index: number;
  shouldBlockAdTypeChanges: boolean;
};

const WARNER_PUBLISHER_ROLE_ID = 28;

export default function AdSetSetup({ mode, adSet, index, shouldBlockAdTypeChanges }: Props) {
  const axios = AxiosSingleton.axiosInstance;
  const { updateAdSetValue, navigationData, setNavigationData, groupCampaignObjective } =
    useContext(campaignContext);
  const { user, role } = useAuth();
  const showCoupon = Boolean(user?.billing.topup_coupon) && index === 0;
  const [showCouponBanner, setShowCouponBanner] = useState(true);
  const [prioritiesOptions, setPrioritiesOptions] = useState<SelectorOptionType[]>([]);

  const isAnghamiOrDms =
    role === PublisherRoles.DMSAdmin ||
    role === PublisherRoles.DMSPublisher ||
    role === PublisherRoles.DMSTrafficker ||
    role === PublisherRoles.Anghami ||
    role === PublisherRoles.AnghamiAdvertiser;

  const fetchPlaylists = (query) =>
    axios
      .get('/sponsored-playlists/search', {
        params: {
          q: query,
          search_type: 'playlists'
        }
      })
      .then((res) => {
        const { data } = res;
        let results;
        if (query.toString().indexOf('anghami.com/playlist/') !== -1 || Number(query)) {
          let id = query.split('/');
          id = id[id.length - 1];
          // eslint-disable-next-line prefer-destructuring
          id = id.split('?')[0];
          id = Number(id);
          results = data.unshift({
            _value: `Load from [${query}]`,
            _attributes: {
              type: 'playlist',
              id
            }
          });
        }

        results = data.reduce((result, item) => {
          // eslint-disable-next-line no-underscore-dangle
          if (item._attributes.type === 'playlist') {
            // eslint-disable-next-line no-underscore-dangle
            result.push({ id: item._attributes.id, text: item._value });
          }
          return result;
        }, []);

        return results.map((result) => ({
          label: result.text,
          value: result.id
        }));
      });

  useEffect(() => {
    if (user?.role_id === WARNER_PUBLISHER_ROLE_ID) {
      updateAdSetValue({
        id: adSet.id,
        key: 'adSetType',
        value: 'song'
      });
    }
  }, []);

  useEffect(() => {
    axios.get('/adplanner/priorities').then((res) => {
      const { data } = res;
      setPrioritiesOptions(data.priorities);
      if (!adSet.adSetPriority && data.priorities[1]) {
        updateAdSetValue({
          id: adSet.id,
          key: 'adSetPriority',
          value: data.priorities[1].id
        });
      }
    });
  }, []);

  const redirectToTargeting = () => {
    const isBudgetTooLow =
      !adSet.adSetBudget || adSet.adSetBudget < 100 || /[^\d]/.test(String(adSet.adSetBudget));
    if (isBudgetTooLow && !isAnghamiOrDms && user?.role_id !== WARNER_PUBLISHER_ROLE_ID) {
      message.error(Messages.BudgetTooLow);
      return;
    }

    if (
      !adSet.adSetName ||
      (!adSet.adSetPriority && user?.role_id !== WARNER_PUBLISHER_ROLE_ID) ||
      !adSet.adSetType
    ) {
      return;
    }

    logAmplitudeEvent(CampaignAmplitudeEvents.CreateCampaignProceed, {
      new: window.location.href.indexOf('create') > -1 ? 1 : 0,
      campaign_objective: groupCampaignObjective,
      campaign_type: adSet.adSetType,
      step: 'targeting',
      objectid: adSet.adSetId
    });

    setNavigationData({
      ...navigationData,
      navigationAdSetSublevel: 'targeting'
    });
  };

  const addCouponToBudget = (budget) => {
    if (budget < 250) {
      return budget;
    }

    return budget + 250;
  };

  return (
    <div className="campaign-container ad-set-setup-container">
      <div className="campaign-container-header">
        <div className="campaign-container-header-title">
          {mode === 'create' ? 'Create' : 'Edit'} Your Ad Set
        </div>
        <button
          className={`campaign-container-header-btn ${
            adSet.adSetName &&
            (adSet.adSetPriority || user?.role_id === WARNER_PUBLISHER_ROLE_ID) &&
            adSet.adSetType &&
            (adSet.adSetBudget || isAnghamiOrDms || user?.role_id === WARNER_PUBLISHER_ROLE_ID)
              ? ''
              : 'disabled'
          }`}
          type="button"
          onClick={redirectToTargeting}
        >
          Next
        </button>
      </div>
      <div className={mode === 'preview' ? 'campaign-form-preview-mask' : ''}>
        <div className="flex-inputs-container">
          <AnghamiAdsFormInput
            label="Ad Set Name *"
            type="text"
            width="30.4rem"
            placeholder="Choose a name for your ad"
            value={adSet.adSetName}
            onChange={(e) => {
              updateAdSetValue({
                id: adSet.id,
                key: 'adSetName',
                value: e.target.value
              });
            }}
          />
        </div>
        <div className="campaign-section-title">Select Ad Type</div>
        <div className="ad-type-container">
          {user?.role_id !== WARNER_PUBLISHER_ROLE_ID && (
            <>
              <div
                style={{ width: isAnghamiOrDms ? '23%' : '31%' }}
                className={`ad-type ${adSet.adSetType === 'audio' ? 'selected' : ''} ${
                  shouldBlockAdTypeChanges ? 'blocked-ad-type' : ''
                }`}
                onClick={() => {
                  if (shouldBlockAdTypeChanges) {
                    return;
                  }

                  updateAdSetValue({
                    id: adSet.id,
                    key: 'adSetType',
                    value: 'audio'
                  });
                  logAmplitudeEvent(CampaignAmplitudeEvents.SelectCampaignType, {
                    type: 'audio'
                  });
                }}
              >
                <img src={AudioAd} alt="Audio Ad" />
                <div>Audio Ad</div>
                <div className="ad-type-check">
                  <CheckOutlined />
                </div>
              </div>
              <div
                style={{ width: isAnghamiOrDms ? '23%' : '31%' }}
                className={`ad-type ${adSet.adSetType === 'video' ? 'selected' : ''} ${
                  shouldBlockAdTypeChanges ? 'blocked-ad-type' : ''
                }`}
                onClick={() => {
                  if (shouldBlockAdTypeChanges) {
                    return;
                  }

                  updateAdSetValue({
                    id: adSet.id,
                    key: 'adSetType',
                    value: 'video'
                  });
                  logAmplitudeEvent(CampaignAmplitudeEvents.SelectCampaignType, {
                    type: 'video'
                  });
                }}
              >
                <img src={VideoAd} alt="Video Ad" />
                <div>Video Ad</div>
                <div className="ad-type-check">
                  <CheckOutlined />
                </div>
              </div>
              <div
                style={{ width: isAnghamiOrDms ? '23%' : '31%' }}
                className={`ad-type ${adSet.adSetType === 'display' ? 'selected' : ''} ${
                  shouldBlockAdTypeChanges ? 'blocked-ad-type' : ''
                }`}
                onClick={() => {
                  if (shouldBlockAdTypeChanges) {
                    return;
                  }

                  updateAdSetValue({
                    id: adSet.id,
                    key: 'adSetType',
                    value: 'display'
                  });
                  logAmplitudeEvent(CampaignAmplitudeEvents.SelectCampaignType, {
                    type: 'display'
                  });
                }}
              >
                <img src={DisplayAd} alt="Display Ad" />
                <div>Display Ad</div>
                <div className="ad-type-check">
                  <CheckOutlined />
                </div>
              </div>
            </>
          )}
          {(role === PublisherRoles.Anghami || user?.role_id === WARNER_PUBLISHER_ROLE_ID) && (
            <div
              style={{ width: isAnghamiOrDms ? '23%' : '31%' }}
              className={`ad-type ${adSet.adSetType === 'song' ? 'selected' : ''} ${
                shouldBlockAdTypeChanges ? 'blocked-ad-type' : ''
              }`}
              onClick={() => {
                if (shouldBlockAdTypeChanges) {
                  return;
                }

                updateAdSetValue({
                  id: adSet.id,
                  key: 'adSetType',
                  value: 'song'
                });
                logAmplitudeEvent(CampaignAmplitudeEvents.SelectCampaignType, {
                  type: 'song'
                });
              }}
            >
              <img src={AudioAd} alt="Song Ad" />
              <div>Song Ad</div>
              <div className="ad-type-check">
                <CheckOutlined />
              </div>
            </div>
          )}
        </div>
        {adSet.adSetType === 'audio' && role === PublisherRoles.DirectClient && (
          <>
            <div className="campaign-section-title">Want An Audio Ad?</div>
            <div className="request-audio-ad-container">
              <img src={AudioLabImg} />
              <div>
                <div className="request-ad-title">
                  Our Anghami Ad Lab offers you Audio Ad service. From script to audio recording and
                  editing.
                </div>
                <div className="request-ad-subtitle">You can always upload your own audio ad.</div>
              </div>
              <button
                type="button"
                onClick={() => {
                  window.open('https://forms.gle/7EqPHLZNHuZuLGDs8');
                }}
              >
                Request an audio ad
              </button>
            </div>
          </>
        )}
        {!isAnghamiOrDms && user?.role_id !== WARNER_PUBLISHER_ROLE_ID && (
          <>
            <div className="campaign-section-title">Budget</div>
            {showCoupon && showCouponBanner && (
              <div className="coupon-banner">
                <img src={CouponStar} className="coupon-star" />
                <div className="coupon-banner-text">
                  Spend minimum $ 250 and get $ 250 for FREE, ONE TIME ONLY
                </div>
                <div
                  className="coupon-banner-close"
                  onClick={() => {
                    setShowCouponBanner(false);
                  }}
                >
                  x
                </div>
              </div>
            )}
            <div>You will be charged in AED. $1 = 3.67 AED</div>
            <div className="spend-limit-section">
              <div
                className="campaign-form-input-container spend-limit-option"
                onClick={() => {
                  updateAdSetValue({
                    id: adSet.id,
                    key: 'adSetBudget',
                    value: '100'
                  });
                }}
              >
                $100
                {adSet.adSetBudget === '100' && (
                  <div className="spend-limit-check">
                    <CheckOutlined />
                  </div>
                )}
              </div>
              <div
                className="campaign-form-input-container spend-limit-option"
                onClick={() => {
                  updateAdSetValue({
                    id: adSet.id,
                    key: 'adSetBudget',
                    value: '250'
                  });
                }}
              >
                {showCoupon && <img src={CouponStar} className="coupon-star" />}
                $250
                {adSet.adSetBudget === '250' && (
                  <div className="spend-limit-check">
                    <CheckOutlined />
                  </div>
                )}
              </div>
              <div
                className="campaign-form-input-container spend-limit-option"
                onClick={() => {
                  updateAdSetValue({
                    id: adSet.id,
                    key: 'adSetBudget',
                    value: '500'
                  });
                }}
              >
                {showCoupon && <img src={CouponStar} className="coupon-star" />}
                $500
                {adSet.adSetBudget === '500' && (
                  <div className="spend-limit-check">
                    <CheckOutlined />
                  </div>
                )}
              </div>
              <div className="campaign-form-input-container create-ad-set-form-input spend-limit">
                {adSet.adSetBudget ? '$' : ''}
                <input
                  placeholder="Enter your custom amount..."
                  value={adSet.adSetBudget}
                  onChange={(e) => {
                    updateAdSetValue({
                      id: adSet.id,
                      key: 'adSetBudget',
                      value: e.target.value
                    });
                  }}
                />
              </div>
            </div>
          </>
        )}
        {isAnghamiOrDms && (
          <div>
            <div className="campaign-section-title">Campaign Setup</div>
            <div className="flex-inputs-container">
              {prioritiesOptions.length > 0 && user?.role_id !== WARNER_PUBLISHER_ROLE_ID && (
                <AnghamiAdsFormSelector
                  label="Campaign Priority"
                  placeholder="Select priority"
                  width="49%"
                  value={adSet.adSetPriority}
                  options={prioritiesOptions}
                  onChange={(value: any) => {
                    updateAdSetValue({
                      id: adSet.id,
                      key: 'adSetPriority',
                      value
                    });
                  }}
                />
              )}
              <AnghamiAdsFormSelector
                label="Campaign Type"
                placeholder="Select campaign type"
                width="49%"
                value={adSet.adSetAdType}
                options={campaignTypeOptions}
                onChange={(value: any) => {
                  updateAdSetValue({
                    id: adSet.id,
                    key: 'adSetAdType',
                    value
                  });
                }}
              />
            </div>
            {adSet.adSetAdType === 'sponsorship' && (
              <div className="mt-1rem">
                <div className="campaign-form-input-container mb-1rem">
                  <label>Playlists</label>
                  <DebounceSelect
                    showSearch
                    value={adSet.adSetPlaylists}
                    placeholder="Select playlists"
                    mode="multiple"
                    fetchOptions={fetchPlaylists}
                    onChange={(newValue) => {
                      updateAdSetValue({
                        id: adSet.id,
                        key: 'adSetPlaylists',
                        value: newValue
                      });
                    }}
                    style={{
                      width: '100%'
                    }}
                  />
                </div>
              </div>
            )}
            <div className="campaign-section-title">Booking Order</div>
            <div className="flex-inputs-container">
              <AnghamiAdsFormInput
                label="Booking Order Number"
                type="text"
                placeholder="Enter your booking order number"
                width="49%"
                value={adSet.adSetBookingNumber}
                onChange={(e) => {
                  updateAdSetValue({
                    id: adSet.id,
                    key: 'adSetBookingNumber',
                    value: e.target.value
                  });
                }}
              />
              <div className="campaign-form-input-container">
                <label>Booking Order Value</label>
                <div className="booking-order-value-input">
                  <Select
                    style={{ width: '100%' }}
                    bordered={false}
                    value={adSet.adSetBookingValueCurrency}
                    onChange={(value: any) => {
                      updateAdSetValue({
                        id: adSet.id,
                        key: 'adSetBookingValueCurrency',
                        value
                      });
                    }}
                    filterOption={(input, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {currencyOptions.map((option: SelectorOptionType) => (
                      <Option value={option.id} key={option.id}>
                        {option.text}
                      </Option>
                    ))}
                  </Select>
                  <input
                    type="text"
                    placeholder="Enter your booking order value"
                    value={adSet.adSetBookingValue}
                    onChange={(e) => {
                      updateAdSetValue({
                        id: adSet.id,
                        key: 'adSetBookingValue',
                        value: e.target.value
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {showCoupon && (
          <>
            <div className="coupon-explanation-container">
              <img src={CouponStar} />
              <div>
                Spend minimum $ 250 and get $ 250 for{' '}
                <span className="coupon-explanation-blue coupon-explanation-bold">FREE, </span>
                <span className="coupon-explanation-bold">ONE TIME ONLY</span>
              </div>
            </div>
            <div className="billing-section">
              <div className="billing-info">
                <div className="billing-title">Billing</div>
                <div className="billing-info-operand">
                  <div className="billing-info-operand-title">Budget</div>
                  <div className="billing-info-operand-value">{`USD ${adSet.adSetBudget}`}</div>
                </div>
                <div className="billing-info-operand">
                  <div className="billing-info-operand-title">Promocode value</div>
                  <div className="billing-info-operand-value">
                    USD {adSet.adSetBudget < 250 ? 0 : 250}
                  </div>
                </div>
                <div className="billing-info-total-separator" />
                <div className="billing-info-operand">
                  <div className="billing-info-operand-title">Total</div>
                  <div className="billing-info-operand-value">
                    {`USD ${addCouponToBudget(Number(adSet.adSetBudget))}`}
                  </div>
                </div>
                <div className="billing-info-operand">
                  <div className="billing-info-operand-title" />
                  <div className="billing-info-operand-value">
                    {`AED ${addCouponToBudget(Number(adSet.adSetBudget)) * 3.67}`}
                  </div>
                </div>
              </div>
              <div className="billing-notes">
                <div className="campaign-section-title">NB</div>
                ** The promocode you have will be applied on your first adset <br />
                ** You will be billed on X day
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
