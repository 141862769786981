import { Button, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import AnghamiAdsBetaLogo from '../../public/assets/images/Ads-Logo-Dark.png';
import './index.scss';
import { countries, countryCodes } from '../../utils/constants';
import useAuth from '../../hooks/useAuth';
import { AxiosSingleton } from '../../utils/axiosinstance';
import { UserResponse } from '../auth/auth.context';

const { Option } = Select;

type SelectorOptionType = {
  id: string | number;
  text: string | number;
};

type InputState = {
  value: string;
  error: string;
};

type FormState = {
  name: InputState;
  industry: InputState;
  country_code: InputState;
  phone: InputState;
  country: InputState;
  publisher_name: InputState;
};

const INPUT_MAX_LENGTH = 35;

export default function ProfileModal() {
  const axios = AxiosSingleton.axiosInstance;
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formState, setFormState] = useState<FormState>({
    name: {
      value: '',
      error: ''
    },
    country_code: {
      value: '',
      error: ''
    },
    publisher_name: {
      value: '',
      error: ''
    },
    country: {
      value: '',
      error: ''
    },
    industry: {
      value: '',
      error: ''
    },
    phone: {
      value: '',
      error: ''
    }
  });

  const [industries, setIndustries] = useState([]);
  const { logout, setUser } = useAuth();

  useEffect(() => {
    axios.get('/adplanner/industries').then((res) => {
      const { data } = res;
      setIndustries(data);
    });
  }, []);

  const handleSubmit = () => {
    setErrorMessage('');
    let newFormState = formState;

    const params = {
      name: formState.name.value,
      industry: industries.find((item) => item.text === formState.industry.value)?.id?.toString(), // industry param should be an id
      country: countries.find((item) => item.text === formState.country.value)?.id, // country param should be an id
      phone: formState.country_code.value + formState.phone.value,
      publisher_name: formState.publisher_name.value,
      country_code: formState.country_code.value
    };

    if (!params.name) {
      newFormState = {
        ...newFormState,
        name: {
          error: 'Your Full Name is required',
          value: params.name
        }
      };
    }

    if (!params.publisher_name) {
      newFormState = {
        ...newFormState,
        publisher_name: {
          error: 'Your Brand is required',
          value: params.publisher_name
        }
      };
    }

    if (!params.country) {
      newFormState = {
        ...newFormState,
        country: {
          error: 'Your Country is required',
          value: params.country
        }
      };
    }

    if (!params.country_code) {
      newFormState = {
        ...newFormState,
        country_code: {
          error: 'Your Country Code is required',
          value: params.country_code
        }
      };
    }

    if (!params.industry) {
      newFormState = {
        ...newFormState,
        industry: {
          error: 'Your Industry is required',
          value: params.industry
        }
      };
    }

    if (!formState.phone.value) {
      newFormState = {
        ...newFormState,
        phone: {
          error: 'Your Phone Number is required',
          value: formState.phone.value
        }
      };
    }

    setFormState(newFormState);

    // if there is an error the new state will be changed
    if (newFormState !== formState) {
      const firstErrorMessage = Object.values(newFormState).find((item) => item.error).error;
      setErrorMessage(firstErrorMessage);
      return;
    }

    setLoading(true);

    axios
      .post<UserResponse>('/complete-registration', params)
      .then((res) => {
        const { data } = res;
        setUser(
          {
            ...data,
            verified: 1
          },
          true
        );
        setLoading(false);
        setIsModalVisible(false);
      })
      .catch(() => {
        setErrorMessage('Something went wrong, please try again later');
        setLoading(false);
      });
  };

  const handleInputChange = (value: string, inputName: string) => {
    setErrorMessage('');
    setFormState((prevState) => ({
      ...prevState,
      [inputName]: {
        value,
        error: ''
      }
    }));
  };

  const autoAssignCountryCode = (countryName: string) => {
    const matchedCountry = countryCodes.find((c) => c.name === countryName);
    handleInputChange(matchedCountry.text, 'country_code');
  };

  return (
    <>
      <Modal visible={isModalVisible} closable={false} maskClosable={false} footer={null}>
        <div className="profile-modal-container">
          <div className="anghami-ads-logo">
            <img src={AnghamiAdsBetaLogo} alt="Anghami Beta Ads" />
          </div>
          <div className="profile-modal-title">Complete your profile to start advertising</div>
          {errorMessage && <div className="auth-failed-msg">{errorMessage}</div>}
          <div>
            <form autoComplete="off">
              <div
                className={`campaign-form-input-container ${
                  formState.name.error ? 'input-error' : ''
                }`}
              >
                <div className="label-container">
                  <label>Full Name</label>
                  <label className="asterisk">*</label>
                </div>
                <input
                  autoComplete="off"
                  placeholder="Full Name"
                  maxLength={INPUT_MAX_LENGTH}
                  value={formState.name.value}
                  onChange={(e) => handleInputChange(e.target.value, 'name')}
                />
              </div>

              <div
                className={`campaign-form-input-container ${
                  formState.publisher_name.error ? 'input-error' : ''
                }`}
              >
                <div className="label-container">
                  <label>Brand Name</label>
                  <label className="asterisk">*</label>
                </div>
                <input
                  placeholder="Brand Name"
                  maxLength={INPUT_MAX_LENGTH}
                  value={formState.publisher_name.value}
                  onChange={(e) => handleInputChange(e.target.value, 'publisher_name')}
                />
              </div>

              <div
                className={`campaign-form-input-container ${
                  formState.country.error ? 'input-error' : ''
                }`}
              >
                <div className="label-container">
                  <label>Country</label>
                  <label className="asterisk">*</label>
                </div>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  bordered={false}
                  placeholder="Select a country"
                  onChange={(e) => {
                    handleInputChange(e.toString(), 'country');
                    autoAssignCountryCode(e.toString());
                  }}
                  value={formState.country.value}
                >
                  {countries.map((country) => (
                    <Option value={country.text} key={country.id}>
                      {country.text}
                    </Option>
                  ))}
                </Select>
              </div>

              <div className="phone-form-input-container">
                <div
                  className={`campaign-form-input-container country-code ${
                    formState.country_code.error ? 'input-error' : ''
                  }`}
                >
                  <div className="label-container">
                    <label>Country Code</label>
                    <label className="asterisk">*</label>
                  </div>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    bordered={false}
                    placeholder="Country Code"
                    onChange={(e) => handleInputChange(e.toString(), 'country_code')}
                    value={formState.country_code.value}
                  >
                    {countryCodes.map((country) => (
                      <Option value={country.text} key={country.text}>
                        {country.text}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div
                  className={`campaign-form-input-container phone-number ${
                    formState.phone.error ? 'input-error' : ''
                  }`}
                >
                  <div className="label-container">
                    <label>Phone Number</label>
                    <label className="asterisk">*</label>
                  </div>
                  <input
                    value={formState.phone.value}
                    maxLength={INPUT_MAX_LENGTH}
                    type="tel"
                    onChange={(e) => {
                      const { value } = e.target;
                      if (isNaN(Number(value)) || value.includes(' ')) return;
                      handleInputChange(e.target.value, 'phone');
                    }}
                  />
                </div>
              </div>

              <div
                className={`campaign-form-input-container ${
                  formState.industry.error ? 'input-error' : ''
                }`}
              >
                <div className="label-container">
                  <label>Industry</label>
                  <label className="asterisk">*</label>
                </div>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  bordered={false}
                  placeholder="Select an industry"
                  onChange={(e) => handleInputChange(e.toString(), 'industry')}
                  value={formState.industry.value}
                >
                  {industries.map((industry: SelectorOptionType) => (
                    <Option value={industry.text} key={industry.id}>
                      {industry.text}
                    </Option>
                  ))}
                </Select>
              </div>

              <Button
                className="save-profile-btn"
                type="primary"
                loading={loading}
                onClick={handleSubmit}
                disabled={loading}
              >
                Save Changes
              </Button>
            </form>
          </div>
          <div className="profile-modal-footer">
            <div className="profile-modal-footer-text">Have another account?</div>
            <button type="button" className="profile-modal-footer-btn" onClick={logout}>
              Sign Out
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
