import React from 'react';
import './styles.scss';
import FilterSelect from '../filterselect';
import FilterItem from '../filteritem';
import { IFilter } from '../managecampaigns/filtersSchema';

type Props = {
  selectedFiltersValues: { [filterId: string]: IFilter };
  setSelectedFiltersValues: (filtersValue: { [filterId: string]: IFilter }) => void;
  filters: IFilter[];
  reloadTable: () => void;
};

const ActiveFilters = ({
  filters,
  selectedFiltersValues,
  setSelectedFiltersValues,
  reloadTable
}: Props) => (
  <div className="ActiveFilters">
    <span className="ActiveFilters-title">Filters </span>
    {Object.keys(selectedFiltersValues)
      .filter((filter) => selectedFiltersValues[filter].title)
      .map((filterId) => (
        <FilterItem
          key={filterId}
          filter={selectedFiltersValues[filterId]}
          onValueChange={(e: IFilter) => {
            setSelectedFiltersValues({ ...selectedFiltersValues, [filterId]: e });
            reloadTable();
          }}
          onFilterRemoved={(inFilterId) => {
            const clonedState = { ...selectedFiltersValues };
            delete clonedState[inFilterId];
            setSelectedFiltersValues(clonedState);
            reloadTable();
          }}
        />
      ))}

    <FilterSelect
      onFilterSelect={(filter) =>
        setSelectedFiltersValues({ ...selectedFiltersValues, [filter.id]: filter })
      }
      filters={filters.filter((filter) => !Object.keys(selectedFiltersValues).includes(filter.id))}
    />
  </div>
);

export default ActiveFilters;
