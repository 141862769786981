import React from 'react';
import { InvoicesStatusTypes } from '../../enums';
import { InvoiceNameCell, InvoiceStatusCell, PublisherCell } from '../adstablecells';
import { DataType } from './tabledatatype';

export type TableColumnType = {
  title: string;
  width: string;
  dataIndex: string;
  key: string;
  fixed?: string;
  render: (value: any, data: DataType) => JSX.Element;
};

export const invoicesTableColumns: TableColumnType[] = [
  {
    title: 'Invoice Number',
    width: '10rem',
    dataIndex: 'name',
    key: '0',
    fixed: 'left',
    render: (value: string, data: DataType) => <InvoiceNameCell data={data} name={value} />
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: '1',
    width: '6rem',
    render: (amount: string) => <span>{amount}</span>
  },
  {
    title: 'Publisher',
    dataIndex: 'publisher_name',
    key: '20',
    width: '9rem',
    render: (value: string, data: DataType) => <PublisherCell name={value} data={data} />
  },
  {
    title: 'Payment ID',
    dataIndex: 'payment_id',
    key: '14',
    width: '6rem',
    render: (value: string) => <span className="AdsTable-value">{value || '-'}</span>
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: '3',
    width: '9rem',
    render: (value: InvoicesStatusTypes) => <InvoiceStatusCell status={value} />
  },
  {
    title: 'Date',
    dataIndex: 'created_at',
    key: '4',
    width: '9rem',
    render: (value: string) => <span className="AdsTable-value">{value.toString() || '-'}</span>
  }
];
