import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router';
import './styles.scss';
import { DatePicker, Select, Row, Col } from 'antd';
import { AxiosSingleton } from '../../utils/axiosinstance';
import PageHeader from '../pageheader';
import SpinningCircle from '../spinningcircle';
import { message } from '../../utils/custommessage';
import { AnghamiAdsFormInput } from '../core/AnghamiAdsFormInput';
import { AnghamiAdsFormSelector } from '../core/AnghamiAdsFormSelector';
import {
  reportTypeOptions,
  reportDimensionsOptions,
  reportDateIdentifiers,
  reportDateTypes,
  reportScheduleTypes,
  reportScheduleDaysOfWeek
} from './selectorOptions';

const { Option } = Select;

type SelectorOptionType = {
  id: string | number;
  text: string | number;
};

type ReportParams = {
  id: string | undefined;
  name: string;
  type: string;
  dimension: ReportDimension | undefined;
  dimension_id: string | undefined;
  dimension_value: string | undefined;
  date_identifier: string;
  date_count: number;
  date_type: string;
  start_date: string;
  end_date: string;
  schedule: boolean;
  schedule_type: string;
  schedule_on: string;
  schedule_at: string;
  schedule_emails: string[];
};

type ReportDimension = {
  id: string;
  text: string;
  path: string;
  options: {
    id: string;
    text: string;
  }[];
};

export default function ReportsGenerate() {
  const axios = AxiosSingleton.axiosInstance;

  const { reportId } = useParams();

  const [reportName, setReportName] = useState('');
  const [selectedReportType, setSelectedReportType] = useState(reportTypeOptions[0].id);
  const [selectedReportDimension, setSelectedReportDimension] = useState<
    ReportDimension | undefined
  >();
  const [reportDimensionSuboptions, setReportDimensionSuboptions] = useState([]);
  const [selectedDimensionSuboption, setSelectedDimensionSuboption] = useState<
    string | undefined
  >();
  const [selectedReportDatesIdentifier, setSelectedReportDatesIdentifier] = useState('previous');
  const [reportDatesDayCount, setReportDatesDayCount] = useState(15);
  const [selectedReportDateType, setSelectedReportDateType] = useState(reportDateTypes[0].id);
  const [disableGenerateButton, setDisableGenerateButton] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(15, 'day'));
  const [endDate, setEndDate] = useState(moment());

  const [dateRange, setDateRange] = useState<[moment.Moment, moment.Moment]>([startDate, endDate]);

  const [isReportSchedulingEnabled, setIsReportSchedulingEnabled] = useState(false);
  const [scheduleType, setScheduleType] = useState(reportScheduleTypes[0].id);
  const [scheduleAt, setScheduleAt] = useState('');
  const [scheduleOn, setScheduleOn] = useState('');
  const [receiverEmails, setReceiverEmails] = useState([]);

  const getDimensionOptions = (dimension) => {
    if (!dimension.path) {
      setReportDimensionSuboptions(dimension.options);
      return;
    }

    axios.get(dimension.path).then((res) => {
      const { data } = res;
      const isResponseProperlyFormatted = Boolean(data[0].text);
      const newReportDimensionSuboptions = isResponseProperlyFormatted
        ? data
        : data.map((item) => ({
            id: item.id,
            text: item.name
          }));
      setReportDimensionSuboptions(newReportDimensionSuboptions);
    });
  };

  useEffect(() => {
    if (reportId) {
      axios.get(`/report/get/${reportId}`).then((res) => {
        const { data } = res;
        const dimension = reportDimensionsOptions.find((el) => data.dimension_id === el.id);
        setReportName(data.name);
        setSelectedReportType(data.type);
        setSelectedReportDimension(dimension);
        setSelectedDimensionSuboption(data.dimension_value.split(','));
        getDimensionOptions(dimension);
        setSelectedReportDatesIdentifier(data.date_identifier);
        setReportDatesDayCount(data.date_count);
        setSelectedReportDateType(data.date_type);
        setStartDate(moment(data.start_date));
        setEndDate(moment(data.end_date));
        setIsReportSchedulingEnabled(Boolean(data.schedule));
        setScheduleType(data.schedule_type);
        setScheduleAt(data.schedule_at);
        setScheduleOn(data.schedule_on);
        setReceiverEmails(data.schedule_emails?.split(','));
      });
    }
  }, []);

  const generateReport = (id) => {
    axios
      .post(
        `/report/generate/${id}`,
        {},
        {
          responseType: 'arraybuffer'
        }
      )
      .then((res) => {
        const { data } = res;

        const decoder = new TextDecoder('utf-8');
        const json = decoder.decode(res.data);
        let jsonDecoded;
        try {
          jsonDecoded = JSON.parse(json);
        } catch (e) {
          jsonDecoded = {};
        }
        if (jsonDecoded && jsonDecoded.dispatching) {
          setTimeout(() => {
            generateReport(jsonDecoded.id);
          }, 3000);
          return;
        }

        const buffer = new Uint8Array(data);
        const blob = new Blob([buffer], { type: 'application/vnd.ms-excel' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const rand = Math.floor(Math.random() * 9999 + 1000);
        link.download = `${reportName.replace(' ', '_')}_${rand}.xlsx`;
        link.click();
        setDisableGenerateButton(false);
      });
  };

  const generateReportClickHandler = () => {
    if (disableGenerateButton) {
      return;
    }

    const params: ReportParams = {
      id: reportId,
      name: reportName,
      type: selectedReportType,
      dimension: selectedReportDimension,
      dimension_id: selectedReportDimension?.id,
      dimension_value: selectedDimensionSuboption,
      date_identifier: selectedReportDatesIdentifier,
      date_count: reportDatesDayCount,
      date_type: selectedReportDateType,
      start_date: startDate.format('YYYY-MM-DD'),
      end_date: endDate.format('YYYY-MM-DD'),
      schedule: isReportSchedulingEnabled,
      schedule_type: isReportSchedulingEnabled ? scheduleType : 'weekly',
      schedule_on: isReportSchedulingEnabled ? scheduleOn : '',
      schedule_at: isReportSchedulingEnabled ? scheduleAt : '',
      schedule_emails: isReportSchedulingEnabled ? receiverEmails : []
    };

    setDisableGenerateButton(true);

    axios
      .post(reportId ? '/report/edit' : '/report/create', params)
      .then((res) => {
        const { data } = res;

        if (data.error) {
          message.error(data.error);
          setDisableGenerateButton(false);
          return;
        }

        if (data.message) {
          message.success(data.message);
          setDisableGenerateButton(false);
          return;
        }

        setTimeout(() => {
          generateReport(data.id);
        });
      })
      .catch(() => {
        setDisableGenerateButton(false);
        message.error('Something went wrong.');
      });
  };

  const renderReportDates = () => {
    if (selectedReportDatesIdentifier === 'previous') {
      return (
        <>
          <input
            type="number"
            value={reportDatesDayCount}
            onChange={(e) => {
              setReportDatesDayCount(Number(e.target.value));
            }}
          />
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select report dimensions"
            value={selectedReportDateType}
            onChange={(value: any) => {
              setSelectedReportDateType(value);
            }}
            bordered={false}
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {reportDateTypes.map((type: SelectorOptionType) => (
              <Option value={type.id} key={type.id}>
                {type.text}
              </Option>
            ))}
          </Select>
        </>
      );
    }

    return (
      <>
        <DatePicker.RangePicker
          showNow
          clearIcon={null}
          format="YYYY-MM-DD"
          className="CampaignInsights-summary-dateselector-input"
          value={dateRange}
          onChange={(range: [moment.Moment, moment.Moment]) => {
            const [selectedStartDate, selectedEndDate] = range;
            setStartDate(selectedStartDate);
            setEndDate(selectedEndDate);
            setDateRange(range);
          }}
        />
      </>
    );
  };

  return (
    <div className="reports-generate-container">
      <PageHeader title="Generate" />
      <div className="campaign-section-title">Report Details</div>
      <Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
        <Col span={12}>
          <AnghamiAdsFormInput
            label="Report Name"
            placeholder="Report Name"
            value={reportName}
            onChange={(e) => {
              setReportName(e.target.value);
            }}
          />
        </Col>
        <Col span={12}>
          <AnghamiAdsFormSelector
            label="Report Type"
            placeholder="Select report type"
            options={reportTypeOptions}
            value={selectedReportType}
            onChange={(value: any) => {
              setSelectedReportType(value);
            }}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Col span={12}>
          <AnghamiAdsFormSelector
            label="Report Dimensions"
            placeholder="Select report dimensions"
            options={reportDimensionsOptions}
            value={selectedReportDimension?.id}
            onChange={(value: any) => {
              const dimension = reportDimensionsOptions.find((element) => element.id === value);
              setSelectedReportDimension(dimension);
              getDimensionOptions(dimension);
            }}
          />
        </Col>
        {reportDimensionSuboptions.length > 0 && selectedReportDimension && (
          <Col span={12}>
            <AnghamiAdsFormSelector
              label={selectedReportDimension.text}
              placeholder="Select report dimension"
              showSearch
              mode="multiple"
              options={reportDimensionSuboptions}
              value={selectedDimensionSuboption}
              onChange={(value: any) => {
                setSelectedDimensionSuboption(value);
              }}
            />
          </Col>
        )}
      </Row>
      <div className="reports-generate-section">
        <div className="campaign-form-input-container">
          <label>Report Dates</label>
          <div className="report-dates-inputs-container">
            <Select
              style={{ width: '100%' }}
              value={selectedReportDatesIdentifier}
              onChange={(value: any) => {
                setSelectedReportDatesIdentifier(value);
              }}
              bordered={false}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {reportDateIdentifiers.map((indentifier: SelectorOptionType) => (
                <Option value={indentifier.id} key={indentifier.id}>
                  {indentifier.text}
                </Option>
              ))}
            </Select>
            {renderReportDates()}
          </div>
        </div>
      </div>
      <div className="schedule-checkbox-input-container">
        <input
          type="checkbox"
          checked={isReportSchedulingEnabled}
          onChange={(e) => {
            setIsReportSchedulingEnabled(Boolean(e.target.checked));
          }}
        />
        Schedule Report
      </div>
      <div className="reports-generate-section">
        <div
          className={`campaign-form-input-container ${isReportSchedulingEnabled ? '' : 'disabled'}`}
        >
          <label>Schedule Report</label>
          <div className="report-dates-inputs-container">
            <Select
              style={{ width: '100%' }}
              value={scheduleType}
              onChange={(value: any) => {
                setScheduleType(value);
              }}
              bordered={false}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {reportScheduleTypes.map((indentifier: SelectorOptionType) => (
                <Option value={indentifier.id} key={indentifier.id}>
                  {indentifier.text}
                </Option>
              ))}
            </Select>
            {scheduleType === 'weekly' && (
              <Select
                style={{ width: '100%' }}
                value={scheduleOn}
                onChange={(value: any) => {
                  setScheduleOn(value);
                }}
                bordered={false}
                filterOption={(input, option: any) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {reportScheduleDaysOfWeek.map((indentifier: SelectorOptionType) => (
                  <Option value={indentifier.id} key={indentifier.id}>
                    {indentifier.text}
                  </Option>
                ))}
              </Select>
            )}
            {scheduleType === 'monthly' && (
              <input
                type="number"
                placeholder="Day of month"
                value={scheduleOn}
                onChange={(e) => {
                  setScheduleOn(String(e.target.value));
                }}
              />
            )}
            <input
              type="time"
              value={scheduleAt}
              onChange={(e) => {
                setScheduleAt(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          className={`campaign-form-input-container ${isReportSchedulingEnabled ? '' : 'disabled'}`}
        >
          <label>Receiver Emails</label>
          <Select
            placeholder="Enter receiver emails"
            mode="tags"
            style={{ width: '100%' }}
            value={receiverEmails}
            onChange={(value) => {
              setReceiverEmails(value);
            }}
            tokenSeparators={[',']}
            dropdownStyle={{ display: 'none' }}
          />
        </div>
      </div>
      <div>
        <button
          className={`generate-report-btn ${disableGenerateButton ? 'disabled' : ''}`}
          type="button"
          onClick={generateReportClickHandler}
        >
          {disableGenerateButton ? <SpinningCircle /> : 'Generate report'}
        </button>
      </div>
    </div>
  );
}
