import React from 'react';
import { AxiosSingleton } from '../../utils/axiosinstance';
import { message } from '../../utils/custommessage';
import './styles.scss';

type DataType = {
  amount: string;
  created_at: string;
  email: string;
  id: number;
  name: string;
  payment_id: string;
  publisher_id: number;
  publisher_name: string;
  key: number;
  status: number;
};

export type TableColumnType = {
  title: string;
  width: string;
  dataIndex: string;
  key: string;
  fixed?: string;
  // eslint-disable-next-line no-undef
  render: (value: any, data: DataType) => JSX.Element;
};

type ReportControlsProps = {
  report: any;
};

const ReportControls = ({ report }: ReportControlsProps) => {
  const axios = AxiosSingleton.axiosInstance;

  const edit = (reportId) => {
    window.open(`/reports/generate/${reportId}`);
  };

  const activate = (reportId) => {
    axios
      .post('/report/activate', {
        report_id: reportId
      })
      .then(() => {
        message.success('Report successfully activated.');
      });
  };

  const destroy = (reportId) => {
    axios
      .post('/report/destroy', {
        report_id: reportId
      })
      .then(() => {
        message.success('Report successfully destroyed.');
      });
  };

  const regenerate = (reportId, reportName) => {
    axios
      .post(
        `/report/generate/${reportId}`,
        {},
        {
          responseType: 'arraybuffer'
        }
      )
      .then((res) => {
        const { data } = res;

        const decoder = new TextDecoder('utf-8');
        const json = decoder.decode(res.data);
        let jsonDecoded;
        try {
          jsonDecoded = JSON.parse(json);
        } catch (e) {
          jsonDecoded = {};
        }
        if (jsonDecoded && jsonDecoded.dispatching) {
          setTimeout(() => {
            regenerate(jsonDecoded.id, reportName);
          }, 3000);
          return;
        }

        const buffer = new Uint8Array(data);
        const blob = new Blob([buffer], { type: 'application/vnd.ms-excel' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const rand = Math.floor(Math.random() * 9999 + 1000);
        link.download = `${reportName.replace(' ', '_')}_${rand}.xlsx`;
        link.click();
      });
  };

  return (
    <div className="report-name-cell">
      <div className="report-name">{report.name}</div>
      <div className="report-name-actions">
        <span
          className="report-name-action"
          onClick={() => {
            edit(report.id);
          }}
        >
          Edit
        </span>
        <span
          className="report-name-action"
          onClick={() => {
            activate(report.id);
          }}
        >
          Activate
        </span>
        <span
          className="report-name-action"
          onClick={() => {
            destroy(report.id);
          }}
        >
          Delete
        </span>
        <span
          className="report-name-action"
          onClick={() => {
            regenerate(report.id, report.name);
          }}
        >
          Regenerate
        </span>
      </div>
    </div>
  );
};

export const reportListTableColumns: TableColumnType[] = [
  {
    title: 'Name',
    width: '12rem',
    dataIndex: 'reportCopy',
    key: '0',
    fixed: 'left',
    render: (report: any) => <ReportControls report={report} />
  },
  {
    title: 'Report Dimension',
    dataIndex: 'dimension',
    key: '1',
    width: '9rem',
    render: (dimension: string) => <span>{dimension}</span>
  },
  {
    title: 'Dimension Values',
    dataIndex: 'dimension_value',
    key: '2',
    width: '10rem',
    render: (values: string[]) => <span>{values?.join(', ')}</span>
  },
  {
    title: 'Report Dates',
    dataIndex: 'report_dates',
    key: '3',
    width: '12rem',
    render: (dates: string) => <span>{dates}</span>
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: '4',
    width: '6rem',
    render: (type: string) => <span>{type}</span>
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: '5',
    width: '8rem',
    render: (status: string) => <span>{status ? '-' : 'Deactivated'}</span>
  },
  {
    title: 'Schedule Info',
    dataIndex: 'schedule_info',
    key: '6',
    width: '9rem',
    render: (dimension: string) => <span>{dimension}</span>
  },
  {
    title: 'Report Receivers',
    dataIndex: 'schedule_emails',
    key: '7',
    width: '9rem',
    render: (scheduleEmails: string[]) => (
      <span>{scheduleEmails ? scheduleEmails.join(', ') : '-'}</span>
    )
  },
  {
    title: 'Created By',
    dataIndex: 'user',
    key: '8',
    width: '8rem',
    render: (user: any) => <span>{user.email}</span>
  }
];
