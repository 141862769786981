import React, { useState } from 'react';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { colorDarkenLighten } from '../../helpers/color-darken-lighten';
import { formatNumber } from '../../helpers/format-numbers';
import { truncateNumber } from '../../helpers/truncate-number';
import './styles.scss';

type Value = {
  title: string;
  percentage: string;
};

type Props = {
  values: Value[];
  color: string;
  dataKey: string;
};
export const InsightsVerticalBarChart = ({ values, color, dataKey }: Props) => {
  const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 });
  const [activeTooltipData, setActiveTooltipData] = useState(null);

  const calculateTooltipPosition = (e: any) => {
    let { clientX, clientY } = e;

    if (clientX + 180 >= window.innerWidth) {
      clientX -= 150;
    }

    if (clientY + 150 >= window.innerHeight) {
      clientY -= 120;
    }

    setPointerPosition({ x: clientX + 20, y: clientY + 20 });
  };

  const tickFormatter = (value) =>
    dataKey === 'percentage' ? `${value}%` : String(truncateNumber(value));
  return (
    <div
      className={`insights-vertical-bar-chart-container ${
        values.length > 7 ? 'scrollabled-insights-vertical-bar-chart' : ''
      }`}
    >
      <ResponsiveContainer
        width={`${(values.length > 7 ? values.length / 7 : 1) * 100}%`}
        height="100%"
        debounce={200}
      >
        <BarChart data={values}>
          <CartesianGrid vertical={null} />
          <XAxis dataKey="title" tick={{ fill: '#000', fontSize: '1rem' }} dy={15} />
          <YAxis tick={{ fill: '#000', fontSize: '1rem' }} tickFormatter={tickFormatter} dx={-15} />
          <Bar dataKey={dataKey} barSize={100} radius={[12, 12, 0, 0]}>
            {values.map((data: any, index: number) => (
              <Cell
                onMouseEnter={(e) => {
                  calculateTooltipPosition(e);
                  setActiveTooltipData(data);
                }}
                onMouseLeave={() => setActiveTooltipData(null)}
                onMouseMove={calculateTooltipPosition}
                // eslint-disable-next-line react/no-array-index-key
                key={`cell-${index}`}
                fill={colorDarkenLighten(color, index, values.length)}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      {activeTooltipData && (
        <div
          className="insights-vertical-bar-chart-tooltip"
          style={{ left: pointerPosition.x, top: pointerPosition.y }}
        >
          <div className="insights-vertical-bar-chart-tooltip-title">
            {activeTooltipData.title || activeTooltipData.day}
          </div>
          <span>
            {activeTooltipData.percentage
              ? `percentage: ${activeTooltipData.percentage}%`
              : `impressions: ${formatNumber(activeTooltipData.impressions)}`}
          </span>
        </div>
      )}
    </div>
  );
};
