import React from 'react';
import { Navigate } from 'react-router-dom';
import { PublisherRoles } from '../../enums/publisherroles';
import useAuth from '../../hooks/useAuth';

type Props = {
  children: React.ReactChild;
};

const AudienceInsightsGuard = ({ children }: Props) => {
  const { role } = useAuth();
  const canViewPage =
    role === PublisherRoles.Anghami ||
    role === PublisherRoles.DMSPublisher ||
    role === PublisherRoles.DMSAdmin ||
    role === PublisherRoles.DMSTrafficker ||
    role === PublisherRoles.DMS_CRM;

  if (canViewPage) {
    return <>{children}</>;
  }

  return <Navigate replace to="/overview" />;
};

export default AudienceInsightsGuard;
