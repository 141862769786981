import React, { useState, useEffect, createContext, useContext } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AxiosSingleton } from '../../utils/axiosinstance';
import AdsTableHeader from '../adstableheader';
import ActiveFilters from '../activefilters';
import AdsTab from '../adstab';
import { adsSetsTableColumns, campaignsTableColumns, creativeTableColumns } from './tableColumns';
import environment from '../env/env';
import { filters, FilterTypes, FilterValue, IFilter } from './filtersSchema';
import { FiltersSelectTypes, FilterStatusTypes, Tabs } from '../../enums';
import './styles.scss';
import { DataType, TableData } from './tabledatatype';
import { message } from '../../utils/custommessage';
import { Messages } from '../../enums/messages';
import SpinningLoader from '../spinningloader';
import ErrorPage, { ErrorPageProps } from '../errorpage';
import invoiceImg from '../../public/assets/images/invoice.png';
import { logAmplitudeEvent } from '../../utils/analytics';
import { CampaignAmplitudeEvents } from '../../enums/campaignamplitudeevents';
import useAuth from '../../hooks/useAuth';
import { PublisherRoles } from '../../enums/publisherroles';
import { campaignContext } from '../campaignhoc/campaign.context';

export interface ITableContext {
  reload: () => void;
}

// used to pass the reload method to the table cells
export const tableContext = createContext<ITableContext>({
  reload: () => null
});

// to cancel requests
let controller: AbortController;
let controllerMetrics: AbortController;

// used to cache the the delivery metrics
let deliveryMetricsMap: { [x: number]: DataType } = {};

const TABLE_LIMIT = 20;

const ManageCampaigns = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParam] = useSearchParams();
  const [currentTab, setCurrentTab] = useState((searchParams.get('tab') as Tabs) || Tabs.Campaigns);
  const [selectedCampaigns, setSelectedCampaigns] = useState<number[]>([]);
  const [selectedAdSets, setSelectedAdSets] = useState<number[]>([]);
  const [selectedCreatives, setSelectedCreatives] = useState<number[]>([]);
  const [tableData, setTableData] = useState<TableData>({ data: [], meta: {} });
  const [tableRangeDate, setTableRangeDate] = useState([moment().subtract(7, 'd'), moment()]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsloading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [isInfiniteLoading, setIsInfiniteLoading] = useState(false);
  const [initalPageLoading, setInitalPageLoading] = useState(true);
  const [error, setError] = useState<string>('');
  const [refreshKey, setRefreshKey] = useState(0);
  const [searchRefreshKey, setSearchRefreshKey] = useState(0);
  const [selectedFiltersValues, setSelectedFiltersValues] = useState<{
    [filterId: string]: IFilter;
  }>({});
  const [extendedFilters, setExtendedFilters] = useState<IFilter[]>(filters);
  const [errorPageProps, setErrorProp] = useState<ErrorPageProps>();

  const { role, user } = useAuth();
  const [firstCall, setFirstCall] = useState(true);

  const canCreateCampaigns =
    role === PublisherRoles.DirectClient ||
    role === PublisherRoles.Anghami ||
    role === PublisherRoles.DMSPublisher ||
    role === PublisherRoles.DMSAdmin ||
    role === PublisherRoles.DMSTrafficker;

  const isUserDMS =
    role === PublisherRoles.DMSAdmin ||
    role === PublisherRoles.DMSPublisher ||
    role === PublisherRoles.DMSAdvertiser ||
    role === PublisherRoles.DMSTrafficker ||
    role === PublisherRoles.DMS_CRM;
  const [shouldBlockEditing, setShouldBlockEditing] = useState(false);

  const [publisherOptions, setPublisherOptions] = useState<any>();

  const isWeirdDMSCommercialCase =
    user?.role_id === 20 || user?.role_id === 22 || user?.role_id === 28; // for these three user role_ids we avoid filtering by their publisher id, because their publisher id is wrong
  const { specialPublishers } = useContext(campaignContext);
  const loadPaginatedTableData = ({ isCurrentTabChanged }: { isCurrentTabChanged: boolean }) => {
    // to check and cancel the old request when switching tabs before the old one finishs
    // https://axios-http.com/docs/cancellation
    if (controller) {
      controller.abort();
    }
    controller = new AbortController();

    setIsloading(true);
    setError('');

    let params = {};
    let endpoint = '';
    let filtersJSON: { [filterId: string]: FilterValue | undefined } = {};

    if (firstCall) {
      filtersJSON = {
        publisher: {
          operator: 'is',
          value: isWeirdDMSCommercialCase ? [] : [Number(user?.publisher_id)] // for these three user role_ids we avoid filtering by their publisher id, because their publisher id is wrong
        }
      };
      setFirstCall(false);
    }

    Object.values(selectedFiltersValues).forEach((filter) => {
      filtersJSON[filter.id] = filter.value;
    });

    switch (currentTab) {
      case Tabs.Campaigns:
        if (selectedAdSets.length > 0) {
          filtersJSON.campaign_id = {
            value: selectedAdSets,
            operator: 'is'
          };
        }

        if (selectedCreatives.length > 0) {
          filtersJSON.ad_id = {
            value: selectedCreatives,
            operator: 'is'
          };
        }

        params = {
          page,
          limit: TABLE_LIMIT,
          filters: filtersJSON,
          currentTab
        };
        endpoint = '/group-campaigns/all';
        break;

      case Tabs.AdsSets:
        if (selectedCreatives.length > 0) {
          filtersJSON.ad_id = {
            value: selectedCreatives,
            operator: 'is'
          };
        }

        params = {
          page,
          limit: TABLE_LIMIT,
          group_campaign_id: selectedCampaigns.length
            ? `[${selectedCampaigns.join(',')}]`
            : undefined,
          filters: filtersJSON,
          currentTab
        };
        endpoint = '/campaigns/all';
        break;

      case Tabs.Creatives:
        params = {
          page,
          limit: TABLE_LIMIT,
          group_campaign_id: selectedCampaigns.length
            ? `[${selectedCampaigns.join(',')}]`
            : undefined,
          campaign_id: selectedAdSets.length ? `[${selectedAdSets.join(',')}]` : undefined,
          filters: filtersJSON,
          currentTab
        };
        endpoint = '/ads/all';
        break;

      default:
    }

    AxiosSingleton.axiosInstance
      .get<TableData>(environment.getBaseAPIUrl() + endpoint, {
        params,
        signal: controller.signal
      })
      .then((response) => {
        // to assign a key for each table rows
        response.data.data = response.data.data.map((e) => {
          e.key = e.id;
          return e;
        });

        setTableData(() => {
          const extendedTableData = response.data.data.map((item) => ({
            ...item,
            ...deliveryMetricsMap[item.id]
          }));

          const newTableData = {
            ...response.data,
            data: isCurrentTabChanged ? extendedTableData : tableData.data.concat(extendedTableData)
          };

          if (newTableData.data.length === 0 && Object.keys(filtersJSON).length !== 0) {
            setErrorProp({
              title: `No ${currentTab}s Found`,
              subTitle: 'Please check the search query or try differente filter',
              img: invoiceImg,
              ctaTitle: 'Clear filters',
              ctaFunc: () => {
                setSelectedFiltersValues({});
                setIsTableLoading(true);
                setRefreshKey(refreshKey + 1);
                setErrorProp(undefined);
              },
              marginTop: '-10rem'
            });
          } else if (
            newTableData.data.length === 0 &&
            Object.keys(filtersJSON).length === 0 &&
            canCreateCampaigns
          ) {
            setErrorProp({
              title: `No ${currentTab}s Found`,
              img: invoiceImg,
              ctaTitle: 'Create New Campaigns',
              ctaFunc: () => {
                navigate('/campaign/create');
              },
              marginTop: '-10rem'
            });
          } else {
            setErrorProp(undefined);
          }
          newTableData.currentTab = currentTab
          return newTableData;
        });

        setIsloading(false);
        setIsTableLoading(false);
        setIsInfiniteLoading(false);
        setInitalPageLoading(false);

        // to re-render the action button on update
        setSelectedAdSets([...selectedAdSets]);
        setSelectedCampaigns([...selectedCampaigns]);
        setSelectedCreatives([...selectedCreatives]);
      })
      .catch((e) => {
        if (e.message === 'canceled') return; // if canceled we dont change the loading state
        setError(JSON.stringify(e.response?.data));
        setIsloading(false);
        setIsTableLoading(false);
        setIsInfiniteLoading(false);
        setInitalPageLoading(false);
      });
  };

  const checkIfUneditableItemWasSelected = (selectedIds: any) => {
    if (!isUserDMS) {
      // no need to run the check if user is not DMS since API doesn't return inaccessible campaigns for non DMS roles
      // the purpose of this is to allow DMS users to view Anghami and commercial campaigns without allowing them to edit those campaigns
      return;
    }

    const tableDataFilteredByIds = tableData.data.filter((item) =>
      selectedIds.includes(Number(item.id))
    );

    let isUneditableItemSelected = false;

    tableDataFilteredByIds.forEach((item: any) => {
      if (item.publisher_id === 1 || item.publisher_id === 2) {
        isUneditableItemSelected = true;
      }
    });

    setShouldBlockEditing(isUneditableItemSelected);
  };

  // to load the main table data, to retrigger manually call setRefreshKey(refreshKey + 1);
  useEffect(() => {
    loadPaginatedTableData({ isCurrentTabChanged: false });
  }, [refreshKey]);

  useEffect(() => {
    loadPaginatedTableData({ isCurrentTabChanged: true });
  }, [currentTab, searchRefreshKey]);

  // to load the delivery metrics for loaded table items will rerigger on date change or tableData change
  useEffect(() => {
    if (currentTab != tableData.currentTab) return;
    if (tableData.data.length === 0) return;
    // to check and cancel the old request when switching tabs before the old one finishs
    // https://axios-http.com/docs/cancellation
    if (controllerMetrics) {
      controllerMetrics.abort();
    }

    controllerMetrics = new AbortController();

    let params = {};
    let endpoint = '';

    const ids = tableData.data.length
      ? tableData.data
          .filter(
            (item) =>
              item.status === FilterStatusTypes.completed || // to ignore other status
              item.status === FilterStatusTypes.running ||
              item.status === FilterStatusTypes.paused ||
              (currentTab === Tabs.Campaigns && item.status === 1) ||
              typeof item.active === 'number'
          ) // for active and not active
          .filter((item) => !deliveryMetricsMap[item?.id])
          .map((item) => item.id)
      : [];

    // default to N/A for not fetched metrics
    const notFetchedItems = tableData.data.length
      ? (tableData.data
          .filter(
            (item) =>
              item.status === FilterStatusTypes.draft ||
              (currentTab === Tabs.AdsSets && item.status === FilterStatusTypes.pending) ||
              item.status === FilterStatusTypes.incomplete
          )
          .filter((item) => !deliveryMetricsMap[item?.id])
          .map((item) => ({
            [item.id]: {
              amount_spent: null,
              clicks: null,
              cpc: null,
              cpm: null,
              ctr: null,
              frequency: null,
              impressions: null,
              unique_clicks: null,
              unique_ctr: null,
              unique_impressions: null
            }
          }))
          .reduce((acc, cur) => Object.assign(acc, cur), {}) as DataType)
      : {};

    if (ids.length === 0) {
      setTableData((prevState) => {
        deliveryMetricsMap = {
          ...deliveryMetricsMap,
          ...notFetchedItems
        };

        const extendedTableData = prevState.data.map((item) => ({
          ...item,
          ...deliveryMetricsMap[item.id]
        }));

        return {
          ...tableData,
          data: extendedTableData
        };
      });

      setIsloading(false);
      setIsTableLoading(false);
      return;
    }

    switch (currentTab) {
      case Tabs.Campaigns:
        params = {
          groupcampaignid: ids,
          type: 'insights_range',
          from: tableRangeDate[0].format('YYYY-MM-DD'),
          to: tableRangeDate[1].format('YYYY-MM-DD'),
          with_budget: true
        };
        endpoint = '/analytics/group-impressions';
        break;

      case Tabs.AdsSets:
        params = {
          campaignid: ids,
          type: 'insights_range',
          from: tableRangeDate[0].format('YYYY-MM-DD'),
          to: tableRangeDate[1].format('YYYY-MM-DD'),
          with_budget: true
        };
        endpoint = '/analytics/impressions';
        break;

      case Tabs.Creatives:
        params = {
          adid: ids,
          type: 'insights_range',
          from: tableRangeDate[0].format('YYYY-MM-DD'),
          to: tableRangeDate[1].format('YYYY-MM-DD'),
          with_budget: true
        };
        endpoint = '/analytics/ad-impressions';
        break;

      default:
    }

    if (!endpoint) return;

    setIsloading(true);

    AxiosSingleton.axiosInstance
      .post(environment.getBaseAPIUrl() + endpoint, params, {
        signal: controllerMetrics.signal
      })
      .then((response) => {
        const { data } = response;

        deliveryMetricsMap = {
          ...deliveryMetricsMap,
          ...notFetchedItems,
          ...data
        };

        setTableData((prevState) => {
          const extendedTableData = prevState.data.map((item) => ({
            ...item,
            ...deliveryMetricsMap[item.id]
          }));

          return {
            ...tableData,
            data: extendedTableData
          };
        });

        setIsloading(false);
        setIsTableLoading(false);
      })
      .catch((e) => {
        if (e.message === 'canceled') return; // if canceled we dont change the loading state

        message.error({
          content: Messages.FailedLoadingDeliveryMetrics,
          duration: 1
        });

        setIsloading(false);
        setIsTableLoading(false);
      });
  }, [currentTab, tableData.data.length, tableData.currentTab, tableRangeDate]);

  // to lazy load publishers and advertisers runs one time on component mount
  // TODO: refactor into async await
  useEffect(() => {
    AxiosSingleton.axiosInstance
      .get(`${environment.getBaseAPIUrl()}/adplanner/filters`)
      .then((e) => {
        let clonedFilters = [...filters];
        clonedFilters.forEach((element) => {
          if (element.id === 'publisher') {
            element.options = e.data.publishers;
            setPublisherOptions(element.options);
          } else if (element.id === 'advertiser') {
            element.options = e.data.advertisers;
          }
        });

        // remove created by filter and early exit if user is not an anghami user or a dms admin
        if (role !== PublisherRoles.Anghami && role !== PublisherRoles.DMSAdmin) {
          setExtendedFilters(clonedFilters.filter((filter) => filter.id !== 'user_id'));
          return;
        }

        AxiosSingleton.axiosInstance
          .get(`${environment.getBaseAPIUrl()}/admin/users/get`, {
            params: {
              publisher_id: [user?.publisher_id]
            }
          })
          .then((res) => {
            const { data } = res;
            clonedFilters = clonedFilters.map((filter) => {
              if (filter.id === 'user_id') {
                filter.options = data.users.map((el: any) => ({
                  ...el,
                  text: el.name
                }));
              }
              return filter;
            });
            setExtendedFilters(clonedFilters);
          });
      })
      .catch((e) => console.log(e));
  }, []);

  // NEED TO REVIEW THIS LOGIC
  useEffect(() => {
    if (isWeirdDMSCommercialCase) return; // for these three user role_ids we avoid filtering by their publisher id, because their publisher id is wrong
    if (!publisherOptions) return;
    let filteredPublisherIds = [Number(user?.publisher_id)];
    filteredPublisherIds.push(
      ...specialPublishers.map(function (item) {
        return Number(item.id);
      })
    );
    // filter unique
    filteredPublisherIds = filteredPublisherIds.filter((v, i, a) => a.indexOf(v) === i);
    setSelectedFiltersValues({
      ...selectedFiltersValues,
      publisher: {
        default: publisherOptions.find((element: any) => element.id === user?.publisher_id),
        defaultSelect: FiltersSelectTypes.is,
        hideSearch: false,
        hideSelect: false,
        id: 'publisher',
        lazyLoad: true,
        select: [FiltersSelectTypes.is, FiltersSelectTypes.isNot],
        title: 'Publisher',
        type: FilterTypes.multiselect,
        options: publisherOptions,
        value: {
          operator: 'is',
          value: filteredPublisherIds
        },
        initiallyHideDropdown: true
      }
    });
    setPage(1);
  }, [publisherOptions]);

  useEffect(() => {
    if (
      selectedFiltersValues?.publisher?.value?.operator === 'is' &&
      selectedFiltersValues?.publisher?.value?.value
    ) {
      AxiosSingleton.axiosInstance
        .get(`${environment.getBaseAPIUrl()}/admin/users/get`, {
          params: {
            publisher_id: selectedFiltersValues?.publisher?.value?.value
          }
        })
        .then((res) => {
          const { data } = res;
          const clonedFilters = extendedFilters.map((filter) => {
            if (filter.id === 'user_id') {
              filter.options = data.users.map((el: any) => ({
                ...el,
                text: el.name
              }));
            }
            return filter;
          });
          setExtendedFilters(clonedFilters);
        });
    }
  }, [selectedFiltersValues.publisher]);

  // NEED TO REVIEW THIS LOGIC
  useEffect(() => {
    if (selectedFiltersValues.publisher?.initiallyHideDropdown) {
      setSelectedFiltersValues({
        ...selectedFiltersValues,
        publisher: {
          ...selectedFiltersValues.publisher,
          initiallyHideDropdown: false
        }
      });
      setPage(1);
    }
  }, [selectedFiltersValues.publisher]);

  // To scroll back to the start of the table
  useEffect(() => {
    document.querySelector('.ant-table-body')?.scrollTo({
      top: 0
    });
  }, [selectedFiltersValues]);

  function handleOnPagination(e: 'prev' | 'next' | 'reload') {
    switch (e) {
      case 'prev':
        if (tableData.meta.total !== tableData.data.length) {
          setPage(page - 1);
          setRefreshKey(refreshKey + 1);
        }
        break;
      case 'next':
        if (tableData.meta.total !== tableData.data.length) {
          setPage(page + 1);
          setIsInfiniteLoading(true);
          setRefreshKey(refreshKey + 1);
        }
        break;
      case 'reload':
        setRefreshKey(refreshKey + 1);
        break;

      default:
    }
  }

  let renderedTab = null;

  switch (currentTab) {
    case Tabs.Campaigns:
      renderedTab = tableData && (
        <AdsTab
          onPagination={handleOnPagination}
          data={tableData.data}
          paginationMeta={tableData.meta}
          onRowSelect={(selectedValues) => {
            setSelectedCampaigns(selectedValues);
            checkIfUneditableItemWasSelected(selectedValues);
          }}
          selectedRows={selectedCampaigns}
          tabType={currentTab}
          tableColumns={campaignsTableColumns}
          isLoading={isLoading}
          isTableLoading={isTableLoading}
          isInfiniteLoading={isInfiniteLoading}
          shouldBlockEditing={shouldBlockEditing}
        />
      );
      break;

    case Tabs.AdsSets:
      renderedTab = tableData && (
        <AdsTab
          onPagination={handleOnPagination}
          data={tableData.data}
          paginationMeta={tableData.meta}
          onRowSelect={(selectedValues) => {
            setSelectedAdSets(selectedValues);
            checkIfUneditableItemWasSelected(selectedValues);
          }}
          selectedRows={selectedAdSets}
          tabType={currentTab}
          tableColumns={adsSetsTableColumns}
          isLoading={isLoading}
          isTableLoading={isTableLoading}
          isInfiniteLoading={isInfiniteLoading}
          shouldBlockEditing={shouldBlockEditing}
        />
      );
      break;

    case Tabs.Creatives:
      renderedTab = tableData && (
        <AdsTab
          onPagination={handleOnPagination}
          data={tableData.data}
          paginationMeta={tableData.meta}
          onRowSelect={(selectedValues) => {
            setSelectedCreatives(selectedValues);
            checkIfUneditableItemWasSelected(selectedValues);
          }}
          selectedRows={selectedCreatives}
          tabType={currentTab}
          tableColumns={creativeTableColumns}
          isLoading={isLoading}
          isTableLoading={isTableLoading}
          isInfiniteLoading={isInfiniteLoading}
          shouldBlockEditing={shouldBlockEditing}
        />
      );
      break;

    default:
      renderedTab = <p>Tab not found</p>;
  }

  return (
    <tableContext.Provider
      value={{
        reload: () => setRefreshKey(refreshKey + 1)
      }}
    >
      <div className="ManageCampaigns">
        <div className="ManageCampaigns-header">
          <div className="ManageCampaigns-header-actions">
            <div className="ManageCampaigns-header-heading">Your Campaigns</div>
            {canCreateCampaigns && (
              <button
                className="ManageCampaigns-button"
                type="button"
                onClick={() => {
                  logAmplitudeEvent(CampaignAmplitudeEvents.ClickButton, {
                    button_text: 'Create campaign',
                    page_url: window.location.href
                  });
                  window.open('/campaign/create');
                }}
              >
                <PlusOutlined />
                CREATE CAMPAIGN
              </button>
            )}
            <div className="ManageCampaigns-header-fill" />
            <span className="ManageCampaigns-header-text">Date</span>
            <DatePicker.RangePicker
              showNow
              format="YYYY-MM-DD"
              className="ManageCampaigns-header-date"
              clearIcon={null}
              defaultValue={tableRangeDate as any}
              onChange={(range) => {
                setIsTableLoading(true);
                deliveryMetricsMap = {};
                setTableRangeDate(range as any);
              }}
            />
          </div>
        </div>
        <ActiveFilters
          filters={extendedFilters}
          selectedFiltersValues={selectedFiltersValues}
          setSelectedFiltersValues={(e) => {
            setSelectedFiltersValues(e);
            setPage(1);
          }}
          reloadTable={() => setSearchRefreshKey(searchRefreshKey + 1)}
        />

        <AdsTableHeader
          currentActiveTab={currentTab}
          onTabChange={(e) => {
            if (e === currentTab) return;
            setIsTableLoading(true);
            setCurrentTab(e);
            setSearchParam({ tab: e }, { replace: true });
            setPage(1);
          }}
          selectedCampaignIDs={selectedCampaigns}
          clearSelectedCampaignsIDs={() => {
            setSelectedCampaigns([]);
            setTableData({currentTab: "", data: [], links: {}, meta: {}})
            setRefreshKey(refreshKey + 1);
          }}
          selectedAdSetsIDs={selectedAdSets}
          clearSelectedAdSetsIDs={() => {
            setSelectedAdSets([]);
            setTableData({currentTab: "", data: [], links: {}, meta: {}})
            setRefreshKey(refreshKey + 1);
          }}
          selectedCreativesIDs={selectedCreatives}
          clearSelectedCreativesIDs={() => {
            setSelectedCreatives([]);
            setTableData({currentTab: "", data: [], links: {}, meta: {}})
            setRefreshKey(refreshKey + 1);
          }}
          onInputChange={(e) => {
            setSelectedFiltersValues({
              ...selectedFiltersValues,
              // reset to search filter queries
              groupcampaign_name: {},
              campaign_name: {},
              ad_name: {},
              [e.select]: {
                id: e.select,
                value: {
                  operator: 'is',
                  value: e.input
                }
              }
            } as any);
            setPage(1);
            setSearchRefreshKey(searchRefreshKey + 1);
          }}
        />

        {initalPageLoading ? (
          <SpinningLoader />
        ) : errorPageProps ? (
          <ErrorPage {...errorPageProps} />
        ) : error ? (
          <ErrorPage
            title="Server Error"
            subTitle={error}
            img={invoiceImg}
            ctaTitle="Go to Overview"
            ctaFunc={() => navigate('/overview')}
            marginTop="-10rem"
          />
        ) : (
          renderedTab
        )}
      </div>
    </tableContext.Provider>
  );
};

export default ManageCampaigns;
