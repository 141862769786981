import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { AxiosSingleton } from '../../utils/axiosinstance';
import DebounceSelect from '../debounceselect';

type Props = {
  subBudgetTypeOptions: { id: string | number; text: string }[];
  statusOptions: { id: string | number; text: string }[];
  addSubBudget: (params: any) => void;
  editSubBudget: (params: any) => void;
  currency: string;
  selectedSubBudgetForEditing?: any;
};

export const SubBudget = ({
  subBudgetTypeOptions,
  statusOptions,
  addSubBudget,
  currency,
  selectedSubBudgetForEditing,
  editSubBudget
}: Props) => {
  const axios = AxiosSingleton.axiosInstance;
  const [subBudgetType, setSubBudgetType] = useState();
  const [subBudgetCampaignId, setSubBudgetCampaignId] = useState('');
  const [subBudgetText, setSubBudgetText] = useState('');
  const [subBudgetAmount, setSubBudgetAmount] = useState('');
  const [subBudgetStatus, setSubBudgetStatus] = useState();

  const isEditMode = Boolean(selectedSubBudgetForEditing);

  const fetchCampaigns = (query) =>
    axios
      .get('/campaigns/all', {
        params: {
          simple: 1,
          search: query
        }
      })
      .then(({ data }) =>
        data.map((result) => ({
          label: result.text,
          value: result.id
        }))
      );

  useEffect(() => {
    if (selectedSubBudgetForEditing) {
      const { amount, campaign, status, text, type } = selectedSubBudgetForEditing;

      setSubBudgetType(type);
      setSubBudgetCampaignId(campaign);
      setSubBudgetText(text);
      setSubBudgetAmount(amount);
      setSubBudgetStatus(status);
    }
  }, [selectedSubBudgetForEditing]);

  return (
    <div className="subbudget-container">
      <div className="subbudget-title">Sub-Budget</div>
      <div className="campaign-form-input-container subbudget-input">
        <label>Type</label>
        <Select
          showSearch
          style={{ width: '100%' }}
          bordered={false}
          placeholder="Select type"
          value={subBudgetType}
          onChange={(value: any) => {
            setSubBudgetType(value);
          }}
          filterOption={(input, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {subBudgetTypeOptions.map((option: any) => (
            <Select.Option value={option?.id} key={option?.text}>
              {option?.text}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className="campaign-form-input-container subbudget-input">
        <label>Campaign</label>
        <DebounceSelect
          showSearch
          mode="tags"
          value={subBudgetCampaignId ? [subBudgetCampaignId] : []}
          placeholder="Select campaign"
          fetchOptions={fetchCampaigns}
          onChange={(newValue) => {
            const selectedSubBudgetCampaignId = newValue.pop();
            setSubBudgetCampaignId(selectedSubBudgetCampaignId);
          }}
          style={{
            width: '100%'
          }}
        />
      </div>
      <div className="campaign-form-input-container subbudget-input">
        <label>Text</label>
        <input
          type="text"
          placeholder="Sub-budget description"
          value={subBudgetText}
          onChange={(e) => {
            setSubBudgetText(e.target.value);
          }}
        />
      </div>
      <div className="campaign-form-input-container subbudget-input">
        <label>Amount</label>
        <div className="subbudget-amount-input">
          <span>{currency}</span>
          <input
            type="text"
            placeholder="Insert amount"
            value={subBudgetAmount}
            onChange={(e) => {
              setSubBudgetAmount(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="campaign-form-input-container subbudget-input">
        <label>Status</label>
        <Select
          showSearch
          style={{ width: '100%' }}
          bordered={false}
          placeholder="Select type"
          value={subBudgetStatus}
          onChange={(value: any) => {
            if (value === -1) return;
            setSubBudgetStatus(value);
          }}
          filterOption={(input, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {statusOptions.map((option: any) => (
            <Select.Option disabled={option.id === -1} value={option?.id} key={option?.text}>
              {option?.text}
            </Select.Option>
          ))}
        </Select>
      </div>
      <button
        type="button"
        className="subbudget-btn"
        onClick={() => {
          if (isEditMode) {
            editSubBudget({
              type: subBudgetType,
              campaign: subBudgetCampaignId,
              text: subBudgetText,
              amount: subBudgetAmount,
              status: subBudgetStatus
            });
            return;
          }
          addSubBudget({
            type: subBudgetType,
            campaign: subBudgetCampaignId,
            text: subBudgetText,
            amount: subBudgetAmount,
            status: subBudgetStatus
          });
        }}
      >
        {isEditMode ? 'Edit' : 'Add'}
      </button>
    </div>
  );
};
