import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CampaignSetup from '../campaignsetup';
import CampaignSidebar from '../campaignsidebar';
import { AxiosSingleton } from '../../utils/axiosinstance';
import './styles.scss';
import AdSet from '../adset';
import CampaignReview from '../campaignreview';
import { campaignContext } from '../campaignhoc/campaign.context';
import { message } from '../../utils/custommessage';
import { CampaignTopLevelNavigation, AdSetNavigation } from '../../enums/campaignnavigation';
import { logAmplitudeEvent } from '../../utils/analytics';
import { CampaignAmplitudeEvents } from '../../enums/campaignamplitudeevents';
import useAuth from '../../hooks/useAuth';

type GroupCampaignsParams = {
  id?: any;
  name: string;
  objective: string;
  advertiser_id: string;
  competitor_id: string;
  agency_id: string;
  industry_id: string;
  publisher_id?: string;
  progress: number;
};

export default function Campaign() {
  const axios = AxiosSingleton.axiosInstance;

  const {
    mode,
    groupCampaignId,
    setGroupCampaignId,
    groupCampaignName,
    groupCampaignObjective,
    advertiserId,
    competitorId,
    agencyId,
    industryId,
    publisherId,
    adSets,
    getGroupCampaignDataAsync,
    navigationData,
    setNavigationData,
    specialPublishers
  } = useContext(campaignContext);

  const { id } = useParams();

  const { user } = useAuth();
  const shouldAllowCreateCampaignAccess =
    user?.publisher_type &&
    user?.publisher_type[0] !== 'anghami_advertiser' &&
    user?.publisher_type[0] !== 'dms_advertiser';
  const navigate = useNavigate();

  const [isGroupCampaignLoading, setIsGroupCampaignLoading] = useState(false);

  const submitCampaignSetup = () => {
    if (mode === 'preview') {
      setNavigationData({
        navigationTopLevel: CampaignTopLevelNavigation.AdSets,
        navigationAdSetActiveId: adSets[0].id,
        navigationAdSetSublevel: AdSetNavigation.Setup
      });
      return;
    }
    const endpoint = `/group-campaigns/${groupCampaignId ? 'update' : 'store'}`;

    const params: GroupCampaignsParams = {
      id: groupCampaignId,
      name: groupCampaignName,
      objective: groupCampaignObjective,
      advertiser_id: advertiserId,
      competitor_id: competitorId.join(','),
      agency_id: agencyId,
      industry_id: industryId,
      publisher_id: specialPublishers.find((p) => p.id === publisherId.toString())
        ? publisherId
        : undefined,
      progress: 1
    };

    setIsGroupCampaignLoading(true);

    axios
      .post(endpoint, params)
      .then((res) => {
        const { data } = res;
        setGroupCampaignId(data);

        logAmplitudeEvent(CampaignAmplitudeEvents.CreateCampaignProceed, {
          new: window.location.href.indexOf('create') > -1 ? 1 : 0,
          campaign_objective: groupCampaignObjective,
          campaign_type: adSets[0].adSetType,
          step: 'setup',
          objectid: adSets[0].adSetId
        });
        setNavigationData({
          navigationTopLevel: CampaignTopLevelNavigation.AdSets,
          navigationAdSetActiveId: adSets[0].id,
          navigationAdSetSublevel: AdSetNavigation.Setup
        });
        setIsGroupCampaignLoading(false);
      })
      .catch((err) => {
        message.error(err.response.data.message);
        setIsGroupCampaignLoading(false);
      });
  };

  useEffect(() => {
    if (id) {
      setGroupCampaignId(id);
    }
  }, [id]);

  useEffect(() => {
    if (mode !== 'preview' && !shouldAllowCreateCampaignAccess) {
      navigate('/manage-campaigns');
    }

    if (mode !== 'create') {
      getGroupCampaignDataAsync({ id });
    }
  }, []);

  return (
    <div className="campaign">
      <div className="sidebar-container">
        <CampaignSidebar ads={adSets} />
      </div>
      <div className="pages-container">
        <div
          className={
            navigationData.navigationTopLevel === CampaignTopLevelNavigation.Setup
              ? 'visible'
              : 'invisible'
          }
        >
          <CampaignSetup
            submitCampaignSetup={submitCampaignSetup}
            mode={mode}
            isGroupCampaignLoading={isGroupCampaignLoading}
          />
        </div>
        <div
          className={
            navigationData.navigationTopLevel === CampaignTopLevelNavigation.AdSets
              ? 'visible'
              : 'invisible'
          }
        >
          {adSets.map((adSet, index) => (
            <div
              className={
                navigationData.navigationAdSetActiveId === adSet.id ? 'visible' : 'invisible'
              }
              key={adSet.id}
            >
              <AdSet mode={mode} adSet={adSet} index={index} />
            </div>
          ))}
        </div>
        <div
          className={
            navigationData.navigationTopLevel === CampaignTopLevelNavigation.Review
              ? 'visible'
              : 'invisible'
          }
        >
          <CampaignReview adSets={adSets} />
        </div>
      </div>
    </div>
  );
}
