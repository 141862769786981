import React, { useEffect, useState, Suspense, lazy, useContext } from 'react';
import { Col, Row } from 'antd';
import { AmplitudeEvents } from '@anghami/neoanalytics/dist/enums/events';
import PageHeader from '../pageheader/index';
import useAuth from '../../hooks/useAuth';
import { AxiosSingleton } from '../../utils/axiosinstance';
import RewardImg from '../../public/assets/images/reward.png';
import './styles.scss';
import { logAmplitudeEvent } from '../../utils/analytics';
import AnghamiAdsFullPageLoader from '../core/AnghamiAdsFullPageLoader';
import { BillingModalContext } from '../basepage';
import OverviewCampaigns from '../overview-campaigns';
import OverviewStats from '../overview-stats';
import OverviewImpressions from '../overview-impressions';
import OverviewEmpty from '../overview-empty';

const OverviewBudget = lazy(() => import('../overview-budget'));
const BillingModal = lazy(() => import('../billing-modal'));

interface IFillRate {
  total_last7days: number;
  total_last14days: number;
  top_last7days: any;
  top_last14days: any;
  diff_last7days: number;
}

export interface IImpressions {
  previous_month: string;
  yesterday: string;
  fillrate: IFillRate;
}
export interface IStats {
  campaigns: {
    pending: number;
    running: number;
    paused: number;
    completed: number;
  };
  impressions: IImpressions;
}

interface ICoupon {
  title: string;
  subtitle: string;
}

const Overview = () => {
  const axios = AxiosSingleton.axiosInstance;
  const { user } = useAuth();
  const { showBillingModal, setShowBillingModal } = useContext(BillingModalContext);
  const [stats, setStats] = useState<IStats | null>(null);
  const [campaigns, setCampaigns] = useState(null);
  const [billings, setBillings] = useState([]);
  const [coupon, setCoupon] = useState<ICoupon | null>(null);
  const [payments, setPayments] = useState(null);
  const [showEmpty, setShowEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    axios.get('/overview').then((res: any) => {
      const { data } = res;
      if (data?.data) {
        setBillings(data.data.billing || []);
        setCoupon(data.data.coupon || null);
        if (Number(data.data.number_of_campaigns) === 0) {
          setShowEmpty(true);
        } else {
          setStats(data.data.stats || null);
          setPayments(data.data.budget || null);
          setCampaigns(data.data.campaigns || null);
        }
        setIsLoading(false);
      }
    });
  };

  const openBilling = () => {
    logAmplitudeEvent(AmplitudeEvents.clickButton, {
      button_text: 'update billing info',
      page_url: window.location.href
    });
    setShowBillingModal(true);
  };

  const renderCouponBanner = () => (
    <Row>
      <Col span={24}>
        <div className="Overview-grid flexbox rows col-no-padding">
          <img src={RewardImg} className="reward-img" alt="" />
          <div className="flexbox colls start">
            <div className="reward-title">{coupon?.title}</div>
            <div className="reward-subtitle">{coupon?.subtitle}</div>
          </div>
        </div>
      </Col>
    </Row>
  );

  const renderBillingUpdates = () => (
    <>
      {billings.map((billing: any) => (
        <Row align="middle" justify="end" key={billing?.text}>
          <div className="Overview-grid flexbox rows billing">
            <Col>{billing.text && <div className="billing-text">{billing.text}</div>}</Col>
            <Col>
              {billing.cta && (
                <button
                  type="button"
                  className="Overview-grid-button"
                  onClick={() => {
                    openBilling();
                  }}
                >
                  {billing.cta}
                </button>
              )}
            </Col>
          </div>
        </Row>
      ))}
    </>
  );

  useEffect(() => {
    fetchData();
  }, [user]);

  return (
    <div className="Overview">
      {isLoading ? (
        <AnghamiAdsFullPageLoader />
      ) : (
        <>
          <Row align="top" gutter={[0, 12]}>
            <Col span={12}>
              <PageHeader title="Overview" />
            </Col>
            <Col span={12}>{billings?.length > 0 && renderBillingUpdates()}</Col>
          </Row>
          {coupon && renderCouponBanner()}
          {showEmpty ? (
            <OverviewEmpty />
          ) : (
            <>
              {campaigns && <OverviewCampaigns campaigns={campaigns} />}
              <Suspense fallback={<></>}>
                {payments && <OverviewBudget payments={payments} />}
              </Suspense>
              {stats && <OverviewStats stats={stats} />}
              {stats?.impressions && <OverviewImpressions impressions={stats?.impressions} />}
              <Suspense fallback={<></>}>{showBillingModal && <BillingModal />}</Suspense>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Overview;
