export enum CampaignAmplitudeEvents {
  ClickButton = 'Click button',
  ViewCreateCampaignPage = 'View create campaign page',
  SelectCampaignObjective = 'Select campaign objective',
  SelectCampaignType = 'Select campaign type',
  CreateCampaignProceed = 'Create campaign proceed',
  AddCreative = 'Add creative',
  SubmitCampaign = 'Submit campaign',
  SubmitAdSet = 'Submit ad set',
  SaveDraftCampaign = 'Save draft campaign'
}
