import React, { useContext, useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useTus } from 'use-tus';
import useAuth from '../../hooks/useAuth';
import { campaignContext } from '../campaignhoc/campaign.context';
import UploadBox from '../uploadbox';
import { IAdSet, ICreative } from '../campaignhoc';
import environment from '../env/env';
import { UploadBoxStates } from '../../enums/uploadboxstates';
import { AxiosSingleton } from '../../utils/axiosinstance';
import './styles.scss';

type Props = {
  uploadType: 'video' | 'audio' | 'image';
  isLeadGen: boolean;
  adSet: IAdSet;
  creative: ICreative;
};

type UploadBoxStateType = 'preupload' | 'upload' | 'uploaded' | 'uploadfailed' | 'invalidfile';

const typeToKeyMap = {
  image: 'creativeImageEn',
  audio: 'creativeAudioEn',
  video: 'creativeVideoEn'
};

const fileTypesMap = {
  image: ['JPEG', 'PNG', 'JPG'],
  audio: ['MP3', 'WAV'],
  video: ['MP4']
};

export default function CreativeUpload({ uploadType, isLeadGen, adSet, creative }: Props) {
  const typeToRequirementsMap = {
    image: `* For better results, please make sure that your creative size is 600x600, 600x500${
      adSet.adSetType === 'display' ? ', or 320x480.' : '.'
    }`,
    audio: '* Duration should not exceed 30 seconds.',
    video: '* Size should be 120x1280, 640x360, or 1280x720. Duration should not exceed 30 seconds.'
  };

  const { upload, setUpload, isSuccess } = useTus();

  const axios = AxiosSingleton.axiosInstance;

  const { updateCreativeValue, updateAdSetValue } = useContext(campaignContext);
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [metadataFileName, setMetadataFileName] = useState('');

  const [uploadBoxState, setUploadBoxState] = useState<UploadBoxStateType>(
    UploadBoxStates.PreUpload
  );
  const [isFileValid, setIsFileValid] = useState(null);

  useEffect(() => {
    const isUploaded =
      (uploadType === 'image' && creative.creativeImageEn) ||
      (uploadType === 'video' && creative.creativeVideoEn) ||
      (uploadType === 'audio' && creative.creativeAudioEn);

    if (isUploaded) {
      setUploadBoxState(UploadBoxStates.Uploaded);
    }
  }, [creative.creativeAudioEn, creative.creativeVideoEn, creative.creativeImageEn]);

  const checkFileRequirements = async () => {
    if (uploadType === 'video') {
      setIsFileValid(true);
      return;
    }

    const params = {
      type: adSet.adSetType
    };
    params[uploadType] = environment.ads_cdn_url + metadataFileName;

    const endpoint = uploadType === 'audio' ? '/adplanner/checkaudio' : '/adplanner/checkbanner';

    const { data } = await axios.get(endpoint, {
      params
    });

    setIsFileValid(data.message === 'ok' || data.message === 'warning');
  };

  const handleSetUpload = (file) => {
    if (!file) {
      return;
    }

    setIsLoading(true);
    setUploadBoxState(UploadBoxStates.Uploading);

    const name = `${String(Math.floor(Math.random() * 9999 + 1000))}_${file.name
      .replace(/[^A-Za-z0-9\-._]/g, '')
      .toLowerCase()}`;
    const filename = `${user.publisher_id
      .toString()
      .replace(/[^A-Za-z0-9\-._]/g, '')
      .toLowerCase()}/${name}`;

    setMetadataFileName(filename);
    setIsFileValid(null);

    setUpload(file, {
      endpoint: 'https://tusk.anghami.com/files/',
      retryDelays: [0, 1000, 3000, 5000],
      metadata: {
        name,
        filename,
        filetype: file.type,
        token: 'C5E386CD1A2A2F1DD93841AD811F7',
        bucket: 'anghami.newads'
      },
      removeFingerprintOnSuccess: true
    });
  };

  useEffect(() => {
    if (!upload) {
      return;
    }

    upload.start();
  }, [upload]);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    checkFileRequirements();

    setIsLoading(false);
  }, [isSuccess]);

  useEffect(() => {
    if (isFileValid === null || isLoading) {
      return;
    }
    if (isFileValid === false) {
      setUploadBoxState(UploadBoxStates.InvalidFile);
      return;
    }

    setUploadBoxState(UploadBoxStates.Uploaded);

    if (isLeadGen) {
      updateAdSetValue({
        id: adSet.id,
        key: 'adSetForm',
        value: {
          ...adSet.adSetForm,
          image: `${environment.ads_cdn_url + metadataFileName}`
        }
      });

      return;
    }

    updateCreativeValue({
      adSetId: adSet.id,
      creativeId: creative.id,
      key: typeToKeyMap[uploadType],
      value: `${environment.ads_cdn_url + metadataFileName}`
    });
  }, [isFileValid, isLoading]);

  const clearCreativeValue = () => {
    if (isLeadGen) {
      updateAdSetValue({
        id: adSet.id,
        key: 'adSetForm',
        value: {
          ...adSet.adSetForm,
          image: ''
        }
      });

      setUploadBoxState(UploadBoxStates.PreUpload);
      return;
    }

    updateCreativeValue({
      adSetId: adSet.id,
      creativeId: creative.id,
      key: typeToKeyMap[uploadType],
      value: ''
    });

    setUploadBoxState(UploadBoxStates.PreUpload);
  };

  return (
    <div className="file-uploader-container">
      <div>
        <FileUploader
          handleChange={handleSetUpload}
          name="file"
          types={fileTypesMap[uploadType]}
          disabled={uploadBoxState === UploadBoxStates.Uploaded}
        >
          <UploadBox
            type={uploadType}
            uploadedFilename={creative[typeToKeyMap[uploadType]]}
            state={uploadBoxState}
            clearUploadValue={clearCreativeValue}
          />
        </FileUploader>
      </div>
      <div
        className={`upload-requirements ${
          uploadBoxState === UploadBoxStates.InvalidFile ? 'invalid-file' : ''
        }`}
      >
        {typeToRequirementsMap[uploadType]}
      </div>
    </div>
  );
}
