import React, { useState } from 'react';
import { XAxis, YAxis, CartesianGrid, ResponsiveContainer, BarChart, Bar, Cell } from 'recharts';
import SpinningLoader from '../spinningloader';
import { formatNumber } from '../../helpers/format-numbers';
import { InsightsNoData } from './insightsnodata';
import { colorDarkenLighten } from '../../helpers/color-darken-lighten';

type Props = {
  rangeDate: any[];
  isLoading: boolean;
  country: {
    percentage: number;
    title: string;
  }[];
};

export const InsightsPerCountryChart = ({ isLoading, country, rangeDate }: Props) => {
  const [activeTooltipData, setActiveTooltipData] = useState(null);
  const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 });

  const calculateTooltipPosition = (e: any) => {
    let { clientX, clientY } = e;

    if (clientX + 180 >= window.innerWidth) {
      clientX -= 150;
    }

    if (clientY + 150 >= window.innerHeight) {
      clientY -= 120;
    }

    setPointerPosition({ x: clientX + 20, y: clientY + 20 });
  };

  return (
    <>
      <div className="CampaignInsights-chart fixed-full-height">
        <div className="CampaignInsights-chart-header">IMPRESSIONS PER COUNTRY</div>
        {!isLoading && rangeDate ? (
          <div className="CampaignInsights-chart-date">
            From {rangeDate[0]?.format('YYYY-MM-DD')} to {rangeDate[1]?.format('YYYY-MM-DD')}
          </div>
        ) : null}

        {!isLoading ? (
          Array.isArray(country) && country.length > 0 ? (
            <div className="CampaignInsights-chart-vscroll">
              <ResponsiveContainer
                width={`${(country.length > 7 ? country.length / 7 : 1) * 100}%`}
                height="100%"
                debounce={200}
              >
                <BarChart data={country}>
                  <CartesianGrid vertical={null} />
                  <XAxis dataKey="title" tick={{ fill: '#000', fontSize: '1rem' }} dy={15} />
                  <YAxis
                    tick={{ fill: '#000', fontSize: '1rem' }}
                    tickFormatter={(value) => `${value}%`}
                    dx={-15}
                  />
                  <Bar dataKey="percentage" barSize={100} radius={[12, 12, 0, 0]} minPointSize={10}>
                    {country.map((data: any, index: number) => (
                      <Cell
                        onMouseEnter={(e) => {
                          calculateTooltipPosition(e);
                          setActiveTooltipData(data);
                        }}
                        onMouseLeave={() => setActiveTooltipData(null)}
                        onMouseMove={calculateTooltipPosition}
                        key={`cell-${index}`}
                        fill={colorDarkenLighten('#5c5cff', index, country.length)}
                      />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <InsightsNoData />
          )
        ) : (
          <SpinningLoader />
        )}
      </div>

      {activeTooltipData ? (
        <div
          className="BarChart-tooltip"
          style={{ left: pointerPosition.x, top: pointerPosition.y }}
        >
          <div className="HorizontalChart-tooltip-title">
            {activeTooltipData.title || activeTooltipData.payload.day}
          </div>
          <span>
            {activeTooltipData.percentage
              ? `percentage: ${activeTooltipData.percentage}%`
              : `impressions: ${formatNumber(activeTooltipData.payload.impressions)}`}
          </span>
        </div>
      ) : null}
    </>
  );
};
