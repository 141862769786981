import React from 'react';
import { Navigate } from 'react-router-dom';
import { PublisherRoles } from '../../enums/publisherroles';
import useAuth from '../../hooks/useAuth';

type Props = {
  children: React.ReactChild;
};

const ManageUsersGuard = ({ children }: Props) => {
  const { user, role } = useAuth();
  const isDirectClientAdmin =
    user.publisher_type.length > 1 && user.publisher_type[1] === 'direct_client_admin';
  const canViewPage =
    role === PublisherRoles.Anghami ||
    role === PublisherRoles.DMSAdmin ||
    role === PublisherRoles.DMSPublisher ||
    (role === PublisherRoles.DirectClient && isDirectClientAdmin);

  if (canViewPage) {
    return <>{children}</>;
  }

  return <Navigate replace to="/overview" />;
};

export default ManageUsersGuard;
