import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  SponsoredPlaylistNavigation,
  SponsoredPlaylistNavigationType
} from '../../enums/sponsoredplaylistsnavigation';
import { AxiosSingleton } from '../../utils/axiosinstance';
import DebounceSelect from '../debounceselect';

const { Option } = Select;

type SelectorOptionType = {
  id: string | number;
  text: string | number;
};

type Props = {
  mode: 'create' | 'edit';
  brandId: number | string | undefined;
  setBrandId: (id: any) => void;
  advertiserId: number | string | undefined;
  setAdvertiserId: (id: any) => void;
  playlistTitleEn: string;
  setPlaylistTitleEn: (title: string) => void;
  playlistTitleAr: string;
  setPlaylistTitleAr: (title: string) => void;
  setNavigationPage: (navigationPage: SponsoredPlaylistNavigationType) => void;
};

export default function SponsoredPlaylistsInfo({
  mode,
  brandId,
  setBrandId,
  advertiserId,
  setAdvertiserId,
  playlistTitleEn,
  setPlaylistTitleEn,
  playlistTitleAr,
  setPlaylistTitleAr,
  setNavigationPage
}: Props) {
  const axios = AxiosSingleton.axiosInstance;
  const [advertisersOptions, setAdvertisersOptions] = useState([]);

  const fetchBrands = (query) =>
    axios
      .get('/sponsored-playlists/search', {
        params: {
          q: query,
          search_type: 'brands'
        }
      })
      .then((res) => {
        const { data } = res;
        if (query) {
          data.brands.unshift({
            brandid: query,
            brandname: query
          });
        }
        return data.brands
          .reduce((result, item) => {
            result.push({ id: item.brandid, text: item.brandname });
            return result;
          }, [])
          .map((result) => ({
            label: result.text,
            value: result.id
          }));
      });

  useEffect(() => {
    axios.get('/adplanner/advertisers').then((res) => {
      const { data } = res;
      setAdvertisersOptions(data);
    });
  }, []);
  return (
    <div className="campaign-container">
      <div className="campaign-container-header">
        {mode === 'create' && (
          <div className="campaign-container-header-title">Setup Your New Campaign</div>
        )}
        {mode === 'edit' && (
          <div className="campaign-container-header-title">Edit Your Campaign</div>
        )}
        <button
          className={`campaign-container-header-btn ${
            advertiserId && playlistTitleEn && playlistTitleAr ? '' : 'disabled'
          }`}
          type="button"
          onClick={() => {
            if (!advertiserId || !playlistTitleEn || !playlistTitleAr) {
              return;
            }
            setNavigationPage(SponsoredPlaylistNavigation.Review);
          }}
        >
          Next
        </button>
      </div>
      <div className="campaign-section-title">Promotion Info</div>
      <div className="campaign-form-input-container mb-1rem">
        <label>Advertiser *</label>
        <Select
          showSearch
          style={{ width: '100%' }}
          bordered={false}
          placeholder="Select advertiser"
          value={advertiserId}
          onChange={(value: any) => {
            setAdvertiserId(value);
          }}
          filterOption={(input, option: any) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {advertisersOptions.map((advertiser: SelectorOptionType) => (
            <Option value={advertiser.id} key={advertiser.id}>
              {advertiser.text}
            </Option>
          ))}
        </Select>
      </div>
      <div className="campaign-form-input-container mb-1rem">
        <label>Brand</label>
        <DebounceSelect
          showSearch
          value={brandId}
          placeholder="Select brand"
          fetchOptions={fetchBrands}
          onChange={(newValue) => {
            setBrandId(newValue);
          }}
          style={{
            width: '100%'
          }}
        />
      </div>
      <div className="flex-input-container">
        <div className="campaign-form-input-container mb-1rem">
          <label>Playlist Title *</label>
          <input
            type="text"
            placeholder="Add playlist title"
            value={playlistTitleEn}
            onChange={(e) => {
              setPlaylistTitleEn(e.target.value);
            }}
          />
        </div>
        <div className="campaign-form-input-container mb-1rem">
          <label>Arabic Playlist Title *</label>
          <input
            type="text"
            placeholder="Add arabic playlist title"
            value={playlistTitleAr}
            onChange={(e) => {
              setPlaylistTitleAr(e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
}
