import React, { useState } from 'react';
import { XAxis, YAxis, CartesianGrid, ResponsiveContainer, BarChart, Bar, Cell } from 'recharts';
import SpinningLoader from '../spinningloader';
import { formatNumber } from '../../helpers/format-numbers';
import { colorDarkenLighten } from '../../helpers/color-darken-lighten';
import { InsightsNoData } from './insightsnodata';

type Props = {
  rangeDate: any[];
  isLoading: boolean;
  gender: {
    percentage: number;
    title: string;
  }[];
};

export const InsightsPerGenderChart = ({ isLoading, gender, rangeDate }: Props) => {
  const [activeTooltipData, setActiveTooltipData] = useState(null);
  const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 });

  const calculateTooltipPosition = (e: any) => {
    let { clientX, clientY } = e;

    if (clientX + 180 >= window.innerWidth) {
      clientX -= 150;
    }

    if (clientY + 150 >= window.innerHeight) {
      clientY -= 120;
    }

    setPointerPosition({ x: clientX + 20, y: clientY + 20 });
  };

  return (
    <>
      <div className="CampaignInsights-chart fixed-full-height">
        <div className="CampaignInsights-chart-header">IMPRESSIONS PER GENDER</div>
        {!isLoading && rangeDate ? (
          <div className="CampaignInsights-chart-date">
            From {rangeDate[0]?.format('YYYY-MM-DD')} to {rangeDate[1]?.format('YYYY-MM-DD')}
          </div>
        ) : null}

        {!isLoading ? (
          Array.isArray(gender) && gender.length > 0 ? (
            <ResponsiveContainer width="100%" height="100%" debounce={200}>
              <BarChart data={gender}>
                <CartesianGrid vertical={null} />
                <XAxis dataKey="title" tick={{ fill: '#000', fontSize: '1rem' }} dy={15} />
                <YAxis
                  tick={{ fill: '#000', fontSize: '1rem' }}
                  tickFormatter={(value) => `${value}%`}
                  dx={-15}
                />
                <Bar dataKey="percentage" barSize={100} radius={[12, 12, 0, 0]}>
                  {gender.map((data: any, index: number) => (
                    <Cell
                      onMouseEnter={(e) => {
                        calculateTooltipPosition(e);
                        setActiveTooltipData(data);
                      }}
                      onMouseLeave={() => setActiveTooltipData(null)}
                      onMouseMove={calculateTooltipPosition}
                      key={`cell-${index}`}
                      fill={colorDarkenLighten('#ff5c5c', index, gender.length)}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <InsightsNoData />
          )
        ) : (
          <SpinningLoader />
        )}
      </div>

      {activeTooltipData ? (
        <div
          className="BarChart-tooltip"
          style={{ left: pointerPosition.x, top: pointerPosition.y }}
        >
          <div className="HorizontalChart-tooltip-title">
            {activeTooltipData.title || activeTooltipData.payload.day}
          </div>
          <span>
            {activeTooltipData.percentage
              ? `percentage: ${activeTooltipData.percentage}%`
              : `impressions: ${formatNumber(activeTooltipData.payload.impressions)}`}
          </span>
        </div>
      ) : null}
    </>
  );
};
