import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import AdsTable from '../adstable';
import { sponsoredPlaylistsTableColumn } from './tableColumns';
import { AxiosSingleton } from '../../utils/axiosinstance';
import './styles.scss';
import useAuth from '../../hooks/useAuth';
import { PublisherRoles } from '../../enums/publisherroles';
import FilterItem from '../filteritem';
import { FilterTypes, IFilter } from '../managecampaigns/filtersSchema';
import { FiltersSelectTypes } from '../../enums';

const statusFilter = {
  id: 'status',
  title: 'Status',
  type: FilterTypes.multiselect,
  default: '',
  options: [
    {
      id: 0,
      text: 'Pending'
    },
    {
      id: 1,
      text: 'Running'
    },
    {
      id: 2,
      text: 'Stopped'
    },
    {
      id: 3,
      text: 'Ended'
    },
    {
      id: -1,
      text: 'Rejected'
    }
  ],
  select: [FiltersSelectTypes.is, FiltersSelectTypes.isNot],
  defaultSelect: FiltersSelectTypes.is,
  hideSelect: false,
  hideSearch: false,
  width: 21.875,
  initiallyHideDropdown: true
};

export default function SponsoredPlaylistsList() {
  const axios = AxiosSingleton.axiosInstance;
  const [tableMeta, setTableMeta] = useState<any>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [isInfiniteLoading, setIsInfiniteLoading] = useState(false);

  const [selectedTableKeys, setSelectedTableKeys] = useState<any[]>([]);

  const { role } = useAuth();

  const canCreateSponsoredPlaylists =
    role === PublisherRoles.Anghami ||
    role === PublisherRoles.DMSPublisher ||
    role === PublisherRoles.DMSAdmin ||
    role === PublisherRoles.DMSTrafficker;

  const [selectedFilter, setSelectedFilter] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState('');
  const [tableDataFilteredBySearchTerm, setTableDataFilteredBySearchTerm] = useState<any[]>([]);

  useEffect(() => {
    axios
      .get('/sponsored-playlists/list', {
        params: {
          offset: page,
          filter: selectedFilter
        }
      })
      .then((res) => {
        const { data } = res;
        setTableMeta(data);
        const updatedTableData = [...tableData, ...data.promotions];
        setTableData(
          updatedTableData.map((el) => ({
            ...el,
            key: el.id
          }))
        );
        setIsInfiniteLoading(false);
      });
  }, [page]);

  useEffect(() => {
    setIsInfiniteLoading(true);
    axios
      .get('/sponsored-playlists/list', {
        params: {
          offset: page,
          filter: selectedFilter
        }
      })
      .then((res) => {
        const { data } = res;
        setTableMeta(data);
        const updatedTableData = data.promotions;
        setTableData(
          updatedTableData.map((el) => ({
            ...el,
            key: el.id
          }))
        );
        setIsInfiniteLoading(false);
      });
  }, [selectedFilter]);

  useEffect(() => {
    const updatedTableData = tableData.filter((el) =>
      el.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setTableDataFilteredBySearchTerm(updatedTableData);
  }, [searchTerm]);

  return (
    <div className="sponsored-playlists-list-container">
      <div className="sponsored-playlists-list-header">
        <div className="sponsored-playlists-list-header-actions">
          <div className="sponsored-playlists-list-header-heading">Sponsored Playlists</div>
          {canCreateSponsoredPlaylists && (
            <>
              <button
                type="button"
                className="sponsored-playlists-list-header-button create-btn"
                onClick={() => {
                  window.open('/sponsoredplaylists/create');
                }}
              >
                <PlusOutlined />
                NEW SPONSORED PLAYLIST
              </button>
              <button
                type="button"
                className={`sponsored-playlists-list-header-button ${
                  selectedTableKeys.length !== 1 ? 'disabled' : ''
                }`}
                onClick={() => {
                  window.open(`/sponsoredplaylists/edit/${selectedTableKeys[0]}`);
                }}
              >
                EDIT
              </button>
            </>
          )}
        </div>
      </div>
      <div className="search-filter-section">
        <div className="filter-section">
          <div className="filter-title">Filter</div>
          <div>
            <FilterItem
              filter={statusFilter}
              onValueChange={(filter: IFilter) => {
                setSelectedFilter({
                  ...filter.value
                });
              }}
              onFilterRemoved={() => {
                setSelectedFilter({});
              }}
            />
          </div>
        </div>
        <div className="search-section">
          <input
            placeholder="Search..."
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </div>
      </div>
      <div>
        {tableData ? (
          <AdsTable
            data={searchTerm ? tableDataFilteredBySearchTerm : tableData}
            tableColumns={sponsoredPlaylistsTableColumn}
            onFetch={() => {
              if (tableData.length >= Number(tableMeta.count)) return;
              setIsInfiniteLoading(true);
              setPage(page + 1);
            }}
            isLoading={tableData.length === 0}
            isInfiniteLoading={isInfiniteLoading}
            rowSelection
            selectedIDs={selectedTableKeys}
            onChange={(selectedKeys) => {
              setSelectedTableKeys(selectedKeys);
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
