import React, { useState } from 'react';
import { Input, Select } from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import './styles.scss';
import { Tabs } from '../../enums';
import { checkPlural } from '../../helpers/check-plural';
import campaignImg from '../../public/assets/images/campaign.svg';
import adSetImg from '../../public/assets/images/adset.svg';
import creativeImg from '../../public/assets/images/creative.svg';

type Props = {
  currentActiveTab: Tabs;
  onTabChange: (tabId: Tabs) => void;
  onInputChange: (queryObject: { select: string; input: string }) => void;
  selectedCampaignIDs: number[];
  clearSelectedCampaignsIDs: () => void;
  selectedAdSetsIDs: number[];
  clearSelectedAdSetsIDs: () => void;
  selectedCreativesIDs: number[];
  clearSelectedCreativesIDs: () => void;
};

let inputTimeout: ReturnType<typeof setTimeout>;

const AdsTableHeader = ({
  currentActiveTab = Tabs.Campaigns,
  onTabChange = () => null,
  onInputChange = () => null,
  selectedCampaignIDs,
  clearSelectedCampaignsIDs = () => null,
  selectedAdSetsIDs,
  clearSelectedAdSetsIDs = () => null,
  selectedCreativesIDs,
  clearSelectedCreativesIDs = () => null
}: Props) => {
  const [tableSearchQuery, setTableSearchQuery] = useState('');
  const [selectSearchTypeValue, setSelectSearchTypeValue] = useState('groupcampaign_name');

  return (
    <div className="AdsTableHeader">
      <div className="AdsTableHeader-tabs">
        <div
          className={`AdsTableHeader-tabs-item ${currentActiveTab === Tabs.Campaigns && 'active'}`}
          onClick={() => onTabChange(Tabs.Campaigns)}
        >
          <div className="AdsTableHeader-tabs-item-heading">
            <img src={campaignImg} />
            <span className="AdsTableHeader-tabs-item-heading-title">Campaigns</span>
            {selectedCampaignIDs.length ? (
              <div className="AdsTableHeader-tabs-item-heading-selected">
                <span className="AdsTableHeader-tabs-item-heading-selected-text">
                  {selectedCampaignIDs.length} Selected
                </span>
                <CloseOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                    clearSelectedCampaignsIDs();
                  }}
                />
              </div>
            ) : null}
          </div>
          {selectedAdSetsIDs.length ? (
            <div className="AdsTableHeader-tabs-item-bottom">
              <span className="AdsTableHeader-tabs-item-bottom-text">
                For {selectedAdSetsIDs.length} ad set
                {checkPlural(selectedAdSetsIDs)}
              </span>
            </div>
          ) : selectedCreativesIDs.length ? (
            <div className="AdsTableHeader-tabs-item-bottom">
              <span className="AdsTableHeader-tabs-item-bottom-text">
                For {selectedCreativesIDs.length} creatives
                {checkPlural(selectedCreativesIDs)}
              </span>
            </div>
          ) : null}
        </div>

        <div
          className={`AdsTableHeader-tabs-item ${currentActiveTab === Tabs.AdsSets && 'active'}`}
          onClick={() => onTabChange(Tabs.AdsSets)}
        >
          <div className="AdsTableHeader-tabs-item-heading">
            <img src={adSetImg} />
            <span className="AdsTableHeader-tabs-item-heading-title">Ad Sets</span>
            {selectedAdSetsIDs.length ? (
              <div className="AdsTableHeader-tabs-item-heading-selected">
                <span className="AdsTableHeader-tabs-item-heading-selected-text">
                  {selectedAdSetsIDs.length} Selected
                </span>
                <CloseOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                    clearSelectedAdSetsIDs();
                  }}
                />
              </div>
            ) : null}
          </div>
          {selectedCreativesIDs.length ? (
            <div className="AdsTableHeader-tabs-item-bottom">
              <span className="AdsTableHeader-tabs-item-bottom-text">
                For {selectedCreativesIDs.length} creatives
                {checkPlural(selectedCreativesIDs)}
              </span>
            </div>
          ) : selectedCampaignIDs.length ? (
            <div className="AdsTableHeader-tabs-item-bottom">
              <span className="AdsTableHeader-tabs-item-bottom-text">
                For {selectedCampaignIDs.length} campaign
                {checkPlural(selectedCampaignIDs)}
              </span>
            </div>
          ) : null}
        </div>

        <div
          className={`AdsTableHeader-tabs-item ${currentActiveTab === Tabs.Creatives && 'active'}`}
          onClick={() => onTabChange(Tabs.Creatives)}
        >
          <div className="AdsTableHeader-tabs-item-heading">
            <img src={creativeImg} />
            <span className="AdsTableHeader-tabs-item-heading-title">Creative</span>
            {selectedCreativesIDs.length ? (
              <div className="AdsTableHeader-tabs-item-heading-selected">
                <span className="AdsTableHeader-tabs-item-heading-selected-text">
                  {selectedCreativesIDs.length} Selected
                </span>
                <CloseOutlined
                  onClick={(e) => {
                    e.stopPropagation();
                    clearSelectedCreativesIDs();
                  }}
                />
              </div>
            ) : null}
          </div>
          {selectedAdSetsIDs.length ? (
            <div className="AdsTableHeader-tabs-item-bottom">
              <span className="AdsTableHeader-tabs-item-bottom-text">
                For {selectedAdSetsIDs.length} ad set
                {checkPlural(selectedAdSetsIDs)}
              </span>
            </div>
          ) : selectedCampaignIDs.length ? (
            <div className="AdsTableHeader-tabs-item-bottom">
              <span className="AdsTableHeader-tabs-item-bottom-text">
                For {selectedCampaignIDs.length} campaign
                {checkPlural(selectedCampaignIDs)}
              </span>
            </div>
          ) : null}
        </div>
      </div>

      <div className="AdsTableHeader-divider" />

      <div className="AdsTableHeader-search">
        <div className="AdsTableHeader-search-container">
          <Select
            bordered={false}
            value={selectSearchTypeValue}
            style={{ width: '7.5rem' }}
            onChange={(e) => {
              setSelectSearchTypeValue(e);
              if (!tableSearchQuery) return;
              onInputChange({
                select: e,
                input: tableSearchQuery
              });
            }}
            className="AdsTableHeader-search-container-select"
          >
            <Select.Option value="groupcampaign_name">Campaign Name</Select.Option>
            <Select.Option value="campaign_name">Ad Set Name</Select.Option>
            <Select.Option value="ad_name">Creative Name</Select.Option>
          </Select>
          <div className="AdsTableHeader-search-container-search">
            <Input
              placeholder="Search..."
              size="large"
              bordered={false}
              value={tableSearchQuery}
              onChange={(e) => {
                setTableSearchQuery(e.target.value);
                clearTimeout(inputTimeout);
                inputTimeout = setTimeout(() => {
                  onInputChange({
                    select: selectSearchTypeValue,
                    input: e.target.value
                  });
                }, 300);
              }}
            />
            <SearchOutlined />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdsTableHeader;
