import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableData } from './tabledatatype';
import { AxiosSingleton } from '../../utils/axiosinstance';
import { invoicesTableColumns } from './tableColumns';
import environment from '../env/env';
import SpinningLoader from '../spinningloader';
import AdsTable from '../adstable';
import ErrorPage from '../errorpage';
import invoiceImg from '../../public/assets/images/invoice.png';
import './styles.scss';

const Invoices = () => {
  const axios = AxiosSingleton.axiosInstance;
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<TableData>({ data: [], meta: {} });
  const [page, setPage] = useState(1);
  const [isInfiniteLoading, setIsInfiniteLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get<TableData>(`${environment.getBaseAPIUrl()}/billing/invoices`, {
        params: {
          page
        }
      })
      .then((response) => {
        // to assign a key for each table rows
        response.data.data = response.data.data.map((e) => {
          e.key = e.id;
          return e;
        });

        setIsLoading(false);
        setIsInfiniteLoading(false);
        setTableData((prevState) => ({
          ...response.data,
          data: [...prevState.data, ...response.data.data]
        }));
      })
      .catch(() => {
        setIsLoading(false);
        setIsInfiniteLoading(false);
      });
  }, [page]);

  return (
    <div className="Invoices">
      <div className="Invoices-header">
        <div className="Invoices-header-heading">My Invoices</div>
      </div>

      {tableData.data.length ? (
        <span className="Invoices-pagination">
          <div className="Invoices-pagination-section">
            <span className="Invoices-pagination-section-text">
              {' '}
              {tableData.data.length} of {tableData.meta.total || 0}{' '}
            </span>
          </div>
        </span>
      ) : null}

      {isLoading ? (
        <SpinningLoader />
      ) : tableData.data.length === 0 ? (
        <ErrorPage
          title="Looks like you don’t have an invoice yet"
          img={invoiceImg}
          ctaTitle="Go back"
          ctaFunc={() => {
            navigate(-1);
          }}
        />
      ) : (
        <AdsTable
          rowSelection={null}
          data={tableData.data}
          tableColumns={invoicesTableColumns}
          onFetch={() => {
            if (tableData.data.length >= Number(tableData.meta.total)) return;
            setIsInfiniteLoading(true);
            setPage(page + 1);
          }}
          isLoading={false}
          isInfiniteLoading={isInfiniteLoading}
        />
      )}
    </div>
  );
};

export default Invoices;
