import React from 'react';
import './styles.scss';
import SpinningLoader from '../../spinningloader';

type Props = {
  isOverlay?: boolean;
};

const AnghamiAdsFullPageLoader = ({ isOverlay = false }: Props) => (
  <div className={`anghami-ads-fullpage-loader ${isOverlay ? 'is-overlay' : ''}`}>
    <div>
      <SpinningLoader />
    </div>
  </div>
);

export default AnghamiAdsFullPageLoader;
