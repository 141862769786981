import React, { ReactElement } from 'react';
import { SyncOutlined, CloseOutlined } from '@ant-design/icons';
import { ButtonsStatus } from './index';

type Props = {
  status: ButtonsStatus;
  title: string;
  onClick: () => void;
  defaultIcon: ReactElement;
};

export const Adstablebtn = ({ status, title, defaultIcon, onClick = () => null }: Props) => {
  let renderedStatus = null;

  switch (status) {
    case ButtonsStatus.loading:
      renderedStatus = <SyncOutlined spin />;
      break;

    case ButtonsStatus.error:
      renderedStatus = <CloseOutlined />;
      break;

    default:
      renderedStatus = defaultIcon;
  }

  return status === ButtonsStatus.hidden ? null : (
    <button
      type="button"
      className={`AdsTableButtons-button ${status}`}
      disabled={status === ButtonsStatus.disabled || status === ButtonsStatus.loading}
      onClick={onClick}
    >
      {renderedStatus}
      <span className="AdsTableButtons-button-title">{title}</span>
    </button>
  );
};
