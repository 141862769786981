import React from 'react';
import { Tabs } from 'antd';
import './styles.scss';

const { TabPane } = Tabs;

type Props = {
  impressionsByCountry: any[];
  campaignType: string;
};

const COUNTRY_FLAG_URL = 'https://flagicons.lipis.dev/flags/4x3/';

export default function InventoryTabbedSection({ impressionsByCountry , campaignType }: Props) {
  const countryTab = (country) => (
    <div className="country-tab">
      {country} <img src={`${COUNTRY_FLAG_URL + country.toLowerCase()}.svg`} />
    </div>
  );
  return (
    <div className="inventory-tabbed-section">
      <Tabs defaultActiveKey="1">
        {impressionsByCountry.map((country, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TabPane
            tab={country.country === 'ALL' ? 'Selected Countries' : countryTab(country.country)}
            key={index}
          >
            <div className="tabbed-section-header-text">
              MAXIMUM OF <b>{country.available_impressions}</b> IMPRESSIONS ARE AVAILABLE FOR THE
              SELECTED TARGETING CRITERIA
            </div>
            <div>
              <b>{country.available_impressions}</b> Available Impressions
            </div>
            <div>
              <b>{country.unavailable_impressions}</b> Unavailable Impressions
            </div>
              {campaignType == 'audio' ? (
                  <>
                      <div>
                          <b>{country.background_impressions}</b> Total Background Impressions
                      </div>
                      <div>
                          <b>{country.foreground_impressions}</b> Total Foreground Impressions
                      </div>
                  </>
              ) : (
                  <div>
                      <b>{country.total_impressions}</b> Total Foreground Impressions
                  </div>
              )}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
}
