import { Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AmplitudeEvents } from '@anghami/neoanalytics/dist/enums/events';
import AnghamiAdsBetaLogo from '../../public/assets/images/Ads-Logo-Dark.png';
import { isEmailValid } from '../../helpers/email-validaton';
import { AxiosSingleton } from '../../utils/axiosinstance';
import { logAmplitudeEvent } from '../../utils/analytics';

export default function ForgotPassword() {
  const axios = AxiosSingleton.axiosInstance;
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    logAmplitudeEvent(AmplitudeEvents.viewForgotPasswordPage);
  }, []);

  const submit = () => {
    setLoading(true);

    const params = {
      email
    };

    if (!isEmailValid(email)) {
      setLoading(false);
      setErrorMessage('Email is not valid');
      return;
    }

    logAmplitudeEvent(AmplitudeEvents.clickOnResetPassword);

    axios
      .post('/password/email', params)
      .then(() => {
        setLoading(false);
        setSuccessMessage('We have emailed your password reset link!');
      })
      .catch(() => {
        setLoading(false);
        setErrorMessage('Something went wrong, please try again');
      });
  };
  return (
    <div className="auth-container">
      <div className="auth-logo">
        <img src={AnghamiAdsBetaLogo} alt="Anghami Beta Ads" />
      </div>
      <div className="auth-title">Reset your account password</div>
      {errorMessage && <div className="auth-failed-msg">{errorMessage}</div>}
      {successMessage && <div className="auth-success-msg">{successMessage}</div>}
      <Form onFinish={submit}>
        <div className="auth-input-container">
          <label>Email</label>
          <Input
            placeholder="user@example.com"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="auth-primary-btn-container">
          <Button
            className="auth-primary-btn"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={submit}
            disabled={loading}
          >
            Reset Password
          </Button>
        </div>
      </Form>
      <div className="auth-footer">
        <div className="auth-footer-text">Already have an account?</div>
        <Link to="/login">
          <button className="auth-secondary-btn" type="button">
            Sign In
          </button>
        </Link>
      </div>
    </div>
  );
}
