import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import { AxiosSingleton } from '../../utils/axiosinstance';

export type TableColumnType = {
  title: string;
  width: string;
  dataIndex: string;
  key: string;
  fixed?: string;
  render: (value: any, data: any) => any;
};

const formatStatus = (status) => {
  switch (status) {
    case 0:
      return 'Inactive';
    case 1:
      return 'Running';
    default:
      return 'Inactive';
  }
};

const formatSource = (source) => {
  if (source === 2) {
    return 'Triton Ads';
  }
  return 'Native Ads';
};

type NameCellProps = {
  name: string;
  campaign: any;
};

const NameCell = ({ name, campaign }: NameCellProps) => {
  const axios = AxiosSingleton.axiosInstance;
  const navigate = useNavigate();
  const downloadInsights = () => {
    axios
      .get('/brandstudies/export', {
        params: {
          bls_id: campaign.id
        },
        responseType: 'arraybuffer'
      })
      .then(({ data }) => {
        const buffer = new Uint8Array(data);
        const blob = new Blob([buffer], { type: 'application/vnd.ms-excel' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const rand = Math.floor(Math.random() * 9999 + 1000);
        link.download = `${campaign.name.replace(' ', '_')}_${rand}.xlsx`;
        link.click();
      });
  };

  return (
    <div className="bls-name-cell">
      <div className="bls-name">{name}</div>
      <div className="bls-name-actions">
        <span
          className="bls-name-action"
          onClick={() => navigate(`/brandupliftstudies/edit/${campaign.id}`)}
        >
          Edit
        </span>
        <span className="bls-name-action" onClick={downloadInsights}>
          Insights
        </span>
      </div>
    </div>
  );
};

export const brandStudiesTableColumns: TableColumnType[] = [
  {
    title: 'ID',
    width: '4rem',
    dataIndex: 'id',
    key: '0',
    render: (id: string) => <span>{id}</span>
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: '1',
    width: '14rem',
    render: (name: string, campaign: any) => <NameCell name={name} campaign={campaign} />
  },
  {
    title: 'Source',
    dataIndex: 'source',
    key: '2',
    width: '8rem',
    render: (source: number) => <span>{formatSource(Number(source))}</span>
  },
  {
    title: 'Ads',
    dataIndex: 'ads',
    key: '3',
    width: '6rem',
    render: (ads: string) => <span>{ads}</span>
  },
  {
    title: 'Exposed Link',
    dataIndex: 'exposedlinken',
    key: '4',
    width: '9rem',
    render: (exposedLink: string) => <span>{exposedLink}</span>
  },
  {
    title: 'Exposed Link (Arabic)',
    dataIndex: 'exposedlinkar',
    key: '5',
    width: '13rem',
    render: (exposedLink: string) => <span>{exposedLink}</span>
  },
  {
    title: 'Not Exposed Link',
    dataIndex: 'notexposedlinken',
    key: '6',
    width: '11rem',
    render: (exposedLink: string) => <span>{exposedLink}</span>
  },
  {
    title: 'Not Exposed Link (Arabic)',
    dataIndex: 'notexposedlinkar',
    key: '7',
    width: '15rem',
    render: (exposedLink: string) => <span>{exposedLink}</span>
  },
  {
    title: 'Updated By',
    dataIndex: 'updated_by',
    key: '8',
    width: '9rem',
    render: (value: string) => <span>{value}</span>
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: '9',
    width: '9rem',
    render: (value: string) => <span>{formatStatus(value)}</span>
  }
];
