import { CloseOutlined, LeftOutlined } from '@ant-design/icons';
import { Row, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import GaugeChart from 'react-gauge-chart';
import { CampaignAmplitudeEvents } from '../../enums/campaignamplitudeevents';
import { PublisherRoles } from '../../enums/publisherroles';
import useAuth from '../../hooks/useAuth';
import { logAmplitudeEvent } from '../../utils/analytics';
import { AxiosSingleton } from '../../utils/axiosinstance';
import { AnghamiAdsFormInput } from '../core/AnghamiAdsFormInput';
import { AnghamiAdsFormSelector } from '../core/AnghamiAdsFormSelector';
import { IAdSet } from '../campaignhoc';
import { campaignContext } from '../campaignhoc/campaign.context';
import SpinningCircle from '../spinningcircle';
import ThreeDotsLoader from '../threedotsloader';
import {
  adRotations,
  countries,
  genderOptions,
  musicPreferencesOptions,
  appLanguageOptions,
  platformOptions,
  interactionOptions,
  operatorsOptions,
  frequencyCapDurations,
  mediumOptions,
  directClientsMediumOptions
} from './selectorOptions';
import './styles.scss';
import { getTargetImpressions } from '../../helpers/campaign-form-helper';

const { Option } = Select;

const WARNER_PUBLISHER_ROLE_ID = 28;

type SelectorOptionType = {
  id: string | number;
  text: string | number;
};

type MobileOperatorType = SelectorOptionType & { country: string };

type Props = {
  adSet: IAdSet;
  saveAudienceAndTargetingProgress: () => void;
  isTargetingLoading: boolean;
};

export default function AdSetTargeting({
  adSet,
  saveAudienceAndTargetingProgress,
  isTargetingLoading
}: Props) {
  const axios = AxiosSingleton.axiosInstance;

  const { mode, updateAdSetValue, navigationData, setNavigationData, groupCampaignObjective } =
    useContext(campaignContext);

  const { role, user } = useAuth();

  const isDirectClient = role === PublisherRoles.DirectClient;

  const shouldShowTakeoverToggle =
    (user?.publisher_id === 2 || user?.publisher_id === 1) &&
    (adSet.adSetType === 'audio' || adSet.adSetType === 'video');

  const shouldShowFrequencyCap = adSet.adSetFrequencies.length > 0 || adSet.adSetType === 'song';
  const [showFrequencyCap, setShowFrequencyCap] = useState(shouldShowFrequencyCap);
  const [allCities, setAllCities] = useState(null);
  const [citiesOptionsByCountry, setCitiesOptionsByCountries] = useState<SelectorOptionType[]>([]);
  const [campaignOptions, setCampaignOptions] = useState<SelectorOptionType[]>([]);
  const [isReachLoading, setIsReachLoading] = useState(false);
  const [allMobileOperators, setAllMobileOperators] = useState<MobileOperatorType[]>([]);
  const [operatorsOptionsByCountry, setOperatorsOptionsByCountry] = useState<MobileOperatorType[]>(
    []
  );
  const [warnerImpressionOptions, setWarnerImpressionOptions] = useState([]);

  const [reachRange, setReachRange] = useState({
    min: '',
    max: ''
  });
  const [frequency, setFrequency] = useState<number | string>(0);
  const [gaugeValue, setGaugeValue] = useState(0);
  const [showDeliveryConfirmation, setShowDeliveryConfirmation] = useState(false);
  const [totalImpressions, setTotalImpressions] = useState(0);
  const [totalStreams, setTotalStreams] = useState(0);
  const [estimatedImpressionsRange, setEstimatedImpressionsRange] = useState({
    min: '',
    max: ''
  });

  useEffect(() => {
    if (adSet.adSetType === 'song' && adSet.adSetFrequencies?.length === 0) {
      updateAdSetValue({
        id: adSet.id,
        key: 'adSetFrequencies',
        value: [{ count: 1, duration: 'day' }]
      });
    }
  }, [adSet.adSetType]);

  useEffect(() => {
    let availableCities: SelectorOptionType[] = [];
    adSet.audienceCountries.map((country: string) => {
      if (allCities) {
        availableCities = availableCities.concat(allCities[country]);
      }
      return null;
    });

    setCitiesOptionsByCountries(availableCities.filter((city) => city));
  }, [allCities]);

  useEffect(() => {
    axios.get('/audiences/mobileoperators').then((res) => {
      setAllMobileOperators(res.data);
      setOperatorsOptionsByCountry(
        res.data.filter((operator) => adSet.audienceCountries.indexOf(operator.country) > -1)
      );
    });
  }, []);

  useEffect(() => {
    axios
      .get('/inventory/getPossibleImpressions', {
        params: {
          start_date: adSet.adSetStartDate,
          end_date: adSet.adSetEndDate
        }
      })
      .then(({ data }) => {
        if (data.impressions) {
          setWarnerImpressionOptions(data.impressions);
        }
      });
  }, [adSet.adSetStartDate, adSet.adSetEndDate]);

  const kFormatter = (num) => {
    if (num >= 1000000) {
      return `${Math.floor(num / 100000) / 10.0}M`;
    }

    if (num > 1000) {
      return `${Math.floor(num / 100) / 10.0}K`;
    }

    return String(num);
  };

  // TODO: find a way to simplify this function
  const getReach = () => {
    if (!adSet.adSetStartDate || !adSet.adSetEndDate || adSet.audienceCountries.length === 0) {
      return;
    }
    setIsReachLoading(true);
    setShowDeliveryConfirmation(false);
    axios
      .get('/adplanner/reach', {
        params: {
          campaign_id: adSet.adSetId,
          start_date: adSet.adSetStartDate,
          end_date: adSet.adSetEndDate,
          countries: adSet.audienceCountries,
          cities: adSet.audienceCities,
          mobile_operators: adSet.audienceMobileOperators,
          gender: adSet.audienceGender.join(','),
          age_from: adSet.audienceMinAge,
          age_to: adSet.audienceMaxAge,
          music_preference: adSet.audienceMusicPreference.join(','),
          platform: adSet.audienceMedium === mediumOptions[0].id ? adSet.audiencePlatforms : '',
          app_language: adSet.audienceAppLanguage,
          target_impressions: getTargetImpressions(
            adSet.adSetTargetImpressions,
            adSet.adSetPaidImpressions,
            adSet.adSetFreeImpressions,
            adSet.adSetShowFirstToggled
          ),
          paid_impressions: adSet.adSetPaidImpressions,
          free_impressions: adSet.adSetFreeImpressions,
          streams_based: adSet.adSetToggleTargetStreams,
          target_streams: adSet.adSetShowFirstToggled ? 0 : adSet.adSetTargetStreams,
          budget: role === PublisherRoles.DirectClient ? adSet.adSetBudget : null,
          show_first: adSet.adSetShowFirstToggled,
          ispaid: role === PublisherRoles.DirectClient ? 1 : 0,
          frequency_cap: adSet.adSetFrequencies.reduce((acc, element) => {
            acc[element.duration] = element.count;
            return acc;
          }, {}),
          retargeting: adSet.audienceRetargeting,
          additional_targeting: false,
          ad_type: adSet.adSetType,
          interests: null,
          genres: null,
          real_time: null
        }
      })
      .then((res) => {
        setIsReachLoading(false);
        const { data } = res;

        if (data.dispatching) {
          setTimeout(() => {
            getReach();
          }, 3000);
          return;
        }

        if (data.reach_max && data.reach_min) {
          setReachRange({
            max: data.reach_max,
            min: data.reach_min
          });
        }

        if (typeof data.available_impressions !== 'undefined') {
          setShowDeliveryConfirmation(true);
        }

        if (data.available_impressions) {
          setTotalImpressions(data.available_impressions);
        }

        if (data.streams) {
          setTotalStreams(data.streams);
        }

        if (data.reach && data.impressions) {
          if (parseInt(data.reach, 10) > 0) {
            const freq = parseInt(res.data.impressions, 10) / parseInt(res.data.reach, 10);
            setFrequency(
              new Intl.NumberFormat('en-US', {
                maximumFractionDigits: 1
              }).format(freq)
            );
          } else {
            setFrequency('-');
          }
        } else {
          setFrequency('-');
        }

        if (data.estimated_impressions_min && data.estimated_impressions_max) {
          setEstimatedImpressionsRange({
            max: data.estimated_impressions_max,
            min: data.estimated_impressions_min
          });
        }

        if (data.audience_size) {
          let adjustedGaugeValue = 100 - data.audience_size;

          if (adjustedGaugeValue > 100) {
            adjustedGaugeValue = 100;
          }

          if (adjustedGaugeValue < 0) {
            adjustedGaugeValue = 0;
          }

          setGaugeValue(adjustedGaugeValue);
        }
      })
      .catch(() => {
        setIsReachLoading(false);
      });
  };

  useEffect(() => {
    axios.get('/adplanner/cities').then((res) => {
      setAllCities(res.data);
    });
    axios.get('/campaigns/all?simple=1&retargeting=1').then((res) => {
      setCampaignOptions(res.data);
    });
  }, [axios]);

  useEffect(() => {
    if (adSet.adSetId) {
      getReach();
    }
  }, []);

  const isAlexaInPlatform = adSet.audiencePlatforms.some(val => {
    if (val.toLowerCase() === 'alexa') {
      return true;
    }
    return false;
  });

  const renderTargetImpressions = () => {
    if (adSet.adSetAdType === 'sponsorship' || role === PublisherRoles.DirectClient) {
      return;
    }

    if (user?.role_id === WARNER_PUBLISHER_ROLE_ID) {
      return (
        <div className="campaign-form-input-container mb-1rem">
          <label>Impressions</label>
          <Select
            showSearch
            style={{ width: '100%' }}
            bordered={false}
            placeholder="Select impressions"
            value={adSet.adSetTargetImpressions}
            onChange={(value: any) => {
              updateAdSetValue({
                id: adSet.id,
                key: 'adSetTargetImpressions',
                value
              });
              getReach();
            }}
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {warnerImpressionOptions.map((option: any) => (
              <Option value={option} key={option}>
                {option}
              </Option>
            ))}
          </Select>
        </div>
      );
    }

    if (adSet.adSetType === 'song') {
      return (
        <div className="mb-1rem">
          <AnghamiAdsFormInput
            label="Target Streams"
            type="text"
            placeholder="Set your target streams"
            value={adSet.adSetTargetImpressions}
            onChange={(e) => {
              updateAdSetValue({
                id: adSet.id,
                key: 'adSetTargetImpressions',
                value: e.target.value
              });
              getReach();
            }}
          />
        </div>
      );
    }
    if (
      adSet.adSetToggleTargetStreams &&
      (user?.publisher_id === 1 || user?.publisher_id === 2 || user?.publisher_id === 4)
    ) {
      return (
        <div className="mb-1rem">
          <AnghamiAdsFormInput
            label="Target Streams"
            type="text"
            placeholder="Set your target streams"
            value={adSet.adSetTargetStreams}
            onChange={(e) => {
              updateAdSetValue({
                id: adSet.id,
                key: 'adSetTargetStreams',
                value: e.target.value
              });
              getReach();
            }}
          />
        </div>
      );
    }

    if (user?.publisher_id === 1 || user?.publisher_id === 2) {
      return (
        <div className="mb-1rem">
          <AnghamiAdsFormInput
            label="Target Impressions"
            type="text"
            placeholder="Set your target impressions"
            value={adSet.adSetTargetImpressions}
            onChange={(e) => {
              updateAdSetValue({
                id: adSet.id,
                key: 'adSetTargetImpressions',
                value: e.target.value
              });
              getReach();
            }}
          />
        </div>
      );
    }

    if (user?.publisher_id === 4) {
      return (
        <div className="custom-targeting-section mb-1rem">
          <AnghamiAdsFormInput
            label="Paid Impressions"
            type="number"
            placeholder="Set your paid target impressions"
            width="49%"
            value={adSet.adSetPaidImpressions}
            onChange={(e) => {
              updateAdSetValue({
                id: adSet.id,
                key: 'adSetPaidImpressions',
                value: Number(e.target.value)
              });
              getReach();
            }}
          />
          <AnghamiAdsFormInput
            label="Free Impressions"
            type="number"
            placeholder="Set your free target impressions"
            width="49%"
            value={adSet.adSetFreeImpressions}
            onChange={(e) => {
              updateAdSetValue({
                id: adSet.id,
                key: 'adSetFreeImpressions',
                value: Number(e.target.value)
              });
              getReach();
            }}
          />
        </div>
      );
    }
  };

  return (
    <div className="campaign-container ad-set-targeting-container">
      <div className="campaign-container-header">
        <button
          type="button"
          className="campaign-container-header-back-btn"
          onClick={() => {
            logAmplitudeEvent(CampaignAmplitudeEvents.CreateCampaignProceed, {
              new: window.location.href.indexOf('create') > -1 ? 1 : 0,
              campaign_objective: groupCampaignObjective,
              campaign_type: adSet.adSetType,
              step: 'setup',
              objectid: adSet.adSetId
            });

            setNavigationData({
              ...navigationData,
              navigationAdSetSublevel: 'setup'
            });
          }}
        >
          <LeftOutlined />
        </button>
        <div className="campaign-container-header-title">Target Your Ad Set</div>
        <button
          className={`campaign-container-header-btn ${
            !adSet.adSetStartDate ||
            !adSet.adSetEndDate ||
            adSet.audienceCountries.length === 0 ||
            isReachLoading
              ? 'disabled'
              : ''
          }`}
          type="button"
          onClick={() => {
            if (
              !adSet.adSetStartDate ||
              !adSet.adSetEndDate ||
              adSet.audienceCountries.length === 0 ||
              isTargetingLoading ||
              isReachLoading
            ) {
              return;
            }

            if (gaugeValue <= 30) {
              //TODO: use/create our own confirmation dialog
              if (
                showDeliveryConfirmation &&
                !window.confirm(
                  'Your target impressions are higher than available impressions in the selected countries, do you want to proceed?'
                )
              ) {
                return;
              }
            }

            saveAudienceAndTargetingProgress();
          }}
        >
          {isTargetingLoading ? <SpinningCircle /> : 'Next'}
        </button>
      </div>
      <div className={`${mode === 'preview' ? 'campaign-form-preview-mask' : ''} targeting-body`}>
        <div className="not-reach">
          <div className="campaign-section-title">Schedule & Delivery Optimization</div>
          <div className="campaign-date-time-section-container">
            <div className="campaign-form-input-container">
              <label>Start Date</label>
              <input
                type="date"
                min={moment().format('YYYY-MM-DD')}
                value={adSet.adSetStartDate}
                onChange={(e) => {
                  updateAdSetValue({
                    id: adSet.id,
                    key: 'adSetStartDate',
                    value: e.target.value
                  });
                  getReach();
                }}
              />
            </div>
            <div className="campaign-form-input-container">
              <label>Start Time</label>
              <input
                type="time"
                value={adSet.adSetStartTime}
                onChange={(e) => {
                  updateAdSetValue({
                    id: adSet.id,
                    key: 'adSetStartTime',
                    value: e.target.value
                  });
                  getReach();
                }}
              />
            </div>
          </div>
          <div className="campaign-date-time-section-container">
            <div className="campaign-form-input-container">
              <label>End Date</label>
              <input
                type="date"
                min={adSet.adSetStartDate}
                value={adSet.adSetEndDate}
                onChange={(e) => {
                  updateAdSetValue({
                    id: adSet.id,
                    key: 'adSetEndDate',
                    value: e.target.value
                  });
                  getReach();
                }}
              />
            </div>
            <div className="campaign-form-input-container">
              <label>End Time</label>
              <input
                type="time"
                value={adSet.adSetEndTime}
                onChange={(e) => {
                  updateAdSetValue({
                    id: adSet.id,
                    key: 'adSetEndTime',
                    value: e.target.value
                  });
                  getReach();
                }}
              />
            </div>
          </div>
          <div className="mb-1rem">
            <AnghamiAdsFormSelector
              label="Ad Rotation"
              placeholder="Select ad rotation"
              value={adSet.adSetDeliveryRotation}
              options={adRotations}
              onChange={(value: any) => {
                updateAdSetValue({
                  id: adSet.id,
                  key: 'adSetDeliveryRotation',
                  value
                });
                getReach();
              }}
            />
          </div>
          <div>
            {!isDirectClient &&
              adSet.adSetAdType === 'standard' &&
              adSet.adSetType !== 'song' &&
              adSet.adSetType !== 'display' && (
                <div
                  className={`frequency-cap-checkbox-container ${
                    adSet.adSetShowFirstToggled ? 'disabled-input' : ''
                  }`}
                >
                  <input
                    type="checkbox"
                    className="frequency-cap-checkbox"
                    checked={adSet.adSetToggleTargetStreams}
                    onChange={(e) => {
                      updateAdSetValue({
                        id: adSet.id,
                        key: 'adSetToggleTargetStreams',
                        value: e.target.checked
                      });
                      getReach();
                    }}
                  />{' '}
                  Target by streams
                </div>
              )}
            <div className={adSet.adSetShowFirstToggled ? 'disabled-input' : ''}>
              {renderTargetImpressions()}
            </div>
            {shouldShowTakeoverToggle && (
              <div className="campaign-form-input-container mb-1rem">
                <label>{adSet.adSetType} Takeover</label>
                <Row justify="center" align="top" wrap={false}>
                  <div
                    style={{
                      width: '2rem',
                      marginTop: '0.4rem',
                      marginRight: '0.5rem'
                    }}
                  >
                    <input
                      type="checkbox"
                      className="frequency-cap-checkbox"
                      checked={adSet.adSetShowFirstToggled}
                      onChange={(e) => {
                        updateAdSetValue({
                          id: adSet.id,
                          key: 'adSetShowFirstToggled',
                          value: e.target.checked
                        });

                        if (e.target.checked) {
                          setShowFrequencyCap(true);

                          updateAdSetValue({
                            id: adSet.id,
                            key: 'adSetFrequencies',
                            value: [{ count: 1, duration: 'day' }]
                          });

                          updateAdSetValue({
                            id: adSet.id,
                            key: 'adSetToggleTargetStreams',
                            value: false
                          });
                        } else {
                          setShowFrequencyCap(false);
                          updateAdSetValue({
                            id: adSet.id,
                            key: 'adSetFrequencies',
                            value: []
                          });
                        }
                      }}
                    />
                  </div>{' '}
                  By enabling takeover, this ad will run as the first ad of the day and the
                  frequency cap is by default set to 1 time / day.
                </Row>
              </div>
            )}
          </div>
          {adSet.adSetType !== 'song' && (
            <div
              className={`frequency-cap-checkbox-container ${
                adSet.adSetShowFirstToggled ? 'disabled-input' : ''
              }`}
            >
              <input
                type="checkbox"
                className="frequency-cap-checkbox"
                checked={showFrequencyCap && adSet.adSetFrequencies.length > 0}
                onChange={(e) => {
                  setShowFrequencyCap(e.target.checked);
                  getReach();

                  if (e.target.checked && adSet.adSetFrequencies.length === 0) {
                    updateAdSetValue({
                      id: adSet.id,
                      key: 'adSetFrequencies',
                      value: [{ count: 1, duration: 'day' }]
                    });
                  } else if (!e.target.checked) {
                    updateAdSetValue({
                      id: adSet.id,
                      key: 'adSetFrequencies',
                      value: []
                    });
                  }
                }}
              />{' '}
              Set frequency cap
            </div>
          )}
          {showFrequencyCap && (
            <div
              className={`frequency-cap-section-container ${
                adSet.adSetShowFirstToggled ? 'disabled-input' : ''
              }`}
            >
              {adSet.adSetFrequencies.map((frequencyObj, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="frequency-cap-section" key={index}>
                  <div className="campaign-form-input-container mb-1rem">
                    <label>Frequency Cap</label>
                    <div className="frequency-cap-inputs">
                      <div className="impression-per-days">
                        <input
                          type="text"
                          value={frequencyObj.count}
                          size={String(frequencyObj.count).length || 1}
                          onChange={(e) => {
                            const newFrequencyCap = [...adSet.adSetFrequencies];
                            newFrequencyCap[index].count = e.target.value;
                            updateAdSetValue({
                              id: adSet.id,
                              key: 'adSetFrequencies',
                              value: newFrequencyCap
                            });
                          }}
                        />
                        <span>impressions per </span>
                      </div>
                      <div className="frequency-cap-durations">
                        <Select
                          showSearch
                          size="small"
                          style={{ width: '100%' }}
                          bordered={false}
                          placeholder="Add value here"
                          value={frequencyObj.duration}
                          onChange={(value: any) => {
                            const newFrequencyCap = [...adSet.adSetFrequencies];
                            newFrequencyCap[index].duration = value;
                            updateAdSetValue({
                              id: adSet.id,
                              key: 'adSetFrequencies',
                              value: newFrequencyCap
                            });
                          }}
                          filterOption={(input, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {frequencyCapDurations.map((duration: SelectorOptionType) => (
                            <Option value={duration.id} key={duration.id}>
                              {duration.text}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                  {adSet.adSetFrequencies.length > 1 && (
                    <div
                      className="remove-frequency-cap"
                      onClick={() => {
                        const newFrequencyCap = [...adSet.adSetFrequencies];
                        newFrequencyCap.splice(index, 1);
                        updateAdSetValue({
                          id: adSet.id,
                          key: 'adSetFrequencies',
                          value: newFrequencyCap
                        });
                      }}
                    >
                      <CloseOutlined />
                    </div>
                  )}
                </div>
              ))}
              <div
                className="add-frequency-cap"
                onClick={() => {
                  const newFrequencyCap = [...adSet.adSetFrequencies];
                  newFrequencyCap.push({ count: 1, duration: 'day' });
                  updateAdSetValue({
                    id: adSet.id,
                    key: 'adSetFrequencies',
                    value: newFrequencyCap
                  });
                }}
              >
                + ADD FREQUENCY CAP
              </div>
            </div>
          )}
          <div className="campaign-section-title">Target Your Audience</div>
          <div className="targeting-selectors-container">
            <div className="targeting-selector selected">Custom Targeting</div>
          </div>
          <div>
            <div className="custom-targeting-subtitle">
              Choose who should see your ads. Keep in mind that limiting your audience could lower
              your reach and impact your campaign’s performance.
            </div>
            <div>
              <div className="mb-1rem">
                <AnghamiAdsFormSelector
                  label="Countries *"
                  mode="multiple"
                  placeholder="Select one or more countries"
                  options={countries}
                  value={adSet.audienceCountries}
                  onChange={(value: any) => {
                    let availableCities: SelectorOptionType[] = [];
                    value.map((country: string) => {
                      if (allCities) {
                        availableCities = availableCities.concat(allCities[country]);
                      }
                      return null;
                    });

                    setOperatorsOptionsByCountry(
                      allMobileOperators.filter(
                        (operator: MobileOperatorType) => value.indexOf(operator.country) > -1
                      )
                    );
                    setCitiesOptionsByCountries(availableCities.filter((city) => city));

                    updateAdSetValue({
                      id: adSet.id,
                      key: 'audienceCountries',
                      value: value.includes('all') ? ['all'] : value
                    });
                    getReach();
                  }}
                />
              </div>
            </div>
            <div>
              {!isDirectClient && (
                <div className="mb-1rem">
                  <AnghamiAdsFormSelector
                    label="Cities"
                    mode="multiple"
                    placeholder="Select one or more city"
                    options={citiesOptionsByCountry}
                    value={adSet.audienceCities}
                    onChange={(value: any) => {
                      updateAdSetValue({
                        id: adSet.id,
                        key: 'audienceCities',
                        value
                      });
                      getReach();
                    }}
                  />
                </div>
              )}
            </div>
            <div>
              {!isDirectClient && (
                <div className="mb-1rem">
                  <AnghamiAdsFormSelector
                    label="Mobile Operators"
                    mode="multiple"
                    placeholder="Select one or more mobile operators"
                    options={operatorsOptionsByCountry}
                    value={adSet.audienceMobileOperators}
                    onChange={(value: any) => {
                      updateAdSetValue({
                        id: adSet.id,
                        key: 'audienceMobileOperators',
                        value
                      });
                      getReach();
                    }}
                  />
                </div>
              )}
            </div>
            <div className="custom-targeting-section mb-1rem">
              <AnghamiAdsFormSelector
                label="Gender"
                width="49%"
                mode="multiple"
                placeholder="Select gender"
                options={genderOptions}
                value={adSet.audienceGender}
                onChange={(value: any) => {
                  updateAdSetValue({
                    id: adSet.id,
                    key: 'audienceGender',
                    value
                  });
                  getReach();
                }}
              />
              <div className="campaign-form-input-container">
                <label>Age</label>
                <div className="age-selector-container">
                  <Select
                    showSearch
                    style={{ width: '45%' }}
                    bordered={false}
                    placeholder="From"
                    value={adSet.audienceMinAge}
                    onChange={(value: any) => {
                      updateAdSetValue({
                        id: adSet.id,
                        key: 'audienceMinAge',
                        value
                      });
                      getReach();
                    }}
                    filterOption={(input, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {[...Array(101).keys()].map((age: number) => (
                      <Option value={age} key={age}>
                        {age}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    showSearch
                    style={{ width: '45%' }}
                    bordered={false}
                    placeholder="To"
                    value={adSet.audienceMaxAge}
                    onChange={(value: any) => {
                      updateAdSetValue({
                        id: adSet.id,
                        key: 'audienceMaxAge',
                        value
                      });
                      getReach();
                    }}
                    filterOption={(input, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {[...Array(101).keys()].map((age: number) => (
                      <Option value={age} key={age}>
                        {age}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
            <div className="custom-targeting-section mb-1rem">
              <AnghamiAdsFormSelector
                label="Music Preference"
                width="49%"
                mode="multiple"
                placeholder="Select music preference"
                options={musicPreferencesOptions}
                value={adSet.audienceMusicPreference}
                onChange={(value: any) => {
                  updateAdSetValue({
                    id: adSet.id,
                    key: 'audienceMusicPreference',
                    value
                  });
                  getReach();
                }}
              />
              <AnghamiAdsFormSelector
                label="App Language"
                width="49%"
                mode="multiple"
                placeholder="Select app language"
                options={appLanguageOptions}
                value={adSet.audienceAppLanguage}
                onChange={(value: any) => {
                  updateAdSetValue({
                    id: adSet.id,
                    key: 'audienceAppLanguage',
                    value
                  });
                  getReach();
                }}
              />
            </div>
            <div className="custom-targeting-section mb-1rem">
              <AnghamiAdsFormSelector
                label="Medium"
                width="49%"
                placeholder={
                  adSet.adSetType === 'audio'
                      ? 'Select medium'
                      : 'This option is not available'
                }
                disabled={adSet.adSetType !== 'audio'}
                options={isDirectClient ? directClientsMediumOptions : mediumOptions}
                value={adSet.audienceMedium}
                onChange={(value: any) => {
                  updateAdSetValue({
                    id: adSet.id,
                    key: 'audienceMedium',
                    value
                  });
                  getReach();
                }}
              />
              {/* Disable this selector in case medium is not 'anghami_app' */}
              <AnghamiAdsFormSelector
                label="Platforms"
                width="49%"
                mode="multiple"
                placeholder={
                  adSet.audienceMedium === mediumOptions[0].id
                    ? 'Select platforms'
                    : 'This option is not available'
                }
                disabled={adSet.audienceMedium !== mediumOptions[0].id}
                options={[
                  ...platformOptions,
                ]}
                value={adSet.audienceMedium === mediumOptions[0].id && !isAlexaInPlatform ? adSet.audiencePlatforms : []}
                onChange={(value: any) => {
                  updateAdSetValue({
                    id: adSet.id,
                    key: 'audiencePlatforms',
                    value
                  });
                  getReach();
                }}
              />
            </div>
          </div>
          <div className="additional-targeting-options-container">
            {adSet.adSetType !== 'song' && (
              <>
                <div className="campaign-section-title">Re-Targeting</div>
                <div className="additional-targeting-subtitle">
                  Target users who have interacted with or viewed previous ads or creatives
                </div>
                <div className="custom-targeting-section mb-1rem">
                  <AnghamiAdsFormSelector
                    label="Interaction Type"
                    width="49%"
                    placeholder="Select interaction type"
                    options={interactionOptions}
                    value={adSet.audienceRetargeting.interaction}
                    onChange={(value: any) => {
                      updateAdSetValue({
                        id: adSet.id,
                        key: 'audienceRetargeting',
                        value: {
                          ...adSet.audienceRetargeting,
                          interaction: value
                        }
                      });
                      getReach();
                    }}
                  />
                  <AnghamiAdsFormSelector
                    label="Logical Operator"
                    width="49%"
                    placeholder="Select logical operator"
                    options={operatorsOptions}
                    value={adSet.audienceRetargeting.operator}
                    onChange={(value: any) => {
                      updateAdSetValue({
                        id: adSet.id,
                        key: 'audienceRetargeting',
                        value: {
                          ...adSet.audienceRetargeting,
                          operator: value
                        }
                      });
                      getReach();
                    }}
                  />
                </div>
                <div className="mb-1rem">
                  <AnghamiAdsFormSelector
                    label="Campaigns"
                    mode="multiple"
                    placeholder="Select campaigns"
                    options={campaignOptions}
                    value={adSet.audienceRetargeting.campaigns}
                    onChange={(value: any) => {
                      updateAdSetValue({
                        id: adSet.id,
                        key: 'audienceRetargeting',
                        value: {
                          ...adSet.audienceRetargeting,
                          campaigns: value
                        }
                      });
                      getReach();
                    }}
                  />
                </div>
                <div className="additional-targeting-subtitle">
                  * Using logical operator &apos;OR&apos; re-targets users who have seen at least
                  one of the specified campaigns, while using &apos;AND&apos; re-targets users who
                  have seen all specified campaigns.
                </div>
              </>
            )}
          </div>
        </div>

        <div className="targeting-reach-section">
          <div className="campaign-form-input-container">
            <div className="reach-title">Campaign Delivery Likelihood</div>
            <GaugeChart
              id="gauge-chart1"
              percent={gaugeValue / 100}
              colors={['#D60031', '#FFAF5C', '#52D57D']}
              hideText
              animate={false}
            />
          </div>
          {role !== PublisherRoles.DirectClient ? (
            <>
              <div className="total-reach-container">
                <div className="campaign-form-input-container w-11rem">
                  <div className="reach-title">
                    Total Available {adSet.adSetToggleTargetStreams ? 'Streams' : 'Impressions'}
                  </div>
                  <div className="reach-value">
                    {isReachLoading ? (
                      <ThreeDotsLoader />
                    ) : adSet.adSetToggleTargetStreams ? (
                      kFormatter(totalStreams)
                    ) : (
                      kFormatter(totalImpressions)
                    )}
                  </div>
                </div>
                <div className="campaign-form-input-container w-11rem">
                  <div className="reach-title">Total Reach</div>
                  <div className="reach-value">
                    {isReachLoading ? (
                      <ThreeDotsLoader />
                    ) : (
                      `${kFormatter(reachRange.min)}-${kFormatter(reachRange.max)}`
                    )}
                  </div>
                </div>
              </div>
              <div className="campaign-form-input-container w-11rem">
                <div className="reach-title">Estimated Frequency</div>
                <div className="reach-value">
                  {isReachLoading ? <ThreeDotsLoader /> : frequency}
                </div>
              </div>
            </>
          ) : (
            <div className="campaign-form-input-container w-11rem">
              <div className="reach-title">Estimated Impressions</div>
              <div className="reach-value">
                {isReachLoading ? (
                  <ThreeDotsLoader />
                ) : (
                  `${kFormatter(estimatedImpressionsRange.min)}-${kFormatter(
                    estimatedImpressionsRange.max
                  )}`
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
