import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { TusClientProvider, useTus } from 'use-tus';
import useAuth from '../../hooks/useAuth';
import './styles.scss';

type ExtendedFile = {
  file: File;
  isEdit: boolean;
};

type BudgetDocumentUploaderFileProps = {
  extendedFile: ExtendedFile;
  uploadSuccessHandler: (fileName: string) => void;
  removeFile: (fileName: string) => void;
};

const BudgetDocumentUploaderFile = ({
  extendedFile,
  uploadSuccessHandler,
  removeFile
}: BudgetDocumentUploaderFileProps) => {
  const { upload, setUpload, isSuccess } = useTus();
  const [metadataFileName, setMetadataFileName] = useState('');
  const { user } = useAuth();
  const { file, isEdit } = extendedFile;

  const onUploadClick = () => {
    if (isEdit) return;
    const name = `${String(Math.floor(Math.random() * 9999 + 1000))}_${file.name
      .replace(/[^A-Za-z0-9\-._]/g, '')
      .toLowerCase()}`;
    const filename = `${user?.publisher_id
      .toString()
      .replace(/[^A-Za-z0-9\-._]/g, '')
      .toLowerCase()}/${name}`;
    setMetadataFileName(filename);

    setUpload(file, {
      endpoint: 'https://tusk.anghami.com/files/',
      retryDelays: [0, 1000, 3000, 5000],
      metadata: {
        name,
        filename,
        filetype: file.type,
        token: 'C5E386CD1A2A2F1DD93841AD811F7',
        bucket: 'anghami.newads'
      },
      removeFingerprintOnSuccess: true
    });
  };

  useEffect(onUploadClick, []);

  useEffect(() => {
    if (!upload) {
      return;
    }

    upload.start();
  }, [upload]);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    uploadSuccessHandler(`https://anghnewads.anghcdn.co/${metadataFileName}`);
  }, [isSuccess]);

  return (
    <div className="budget-uploader-file">
      <div className="budget-uploader-file-name">{file.name}</div>
      <div className="budget-uploader-file-btn-container">
        {isSuccess || isEdit ? (
          <DeleteOutlined
            onClick={() => {
              removeFile(
                metadataFileName ? `https://anghnewads.anghcdn.co/${metadataFileName}` : file.name
              );
            }}
          />
        ) : (
          <Spin />
        )}
      </div>
    </div>
  );
};

type BudgetDocumentUploaderProps = {
  docs: string[];
  setDocs: (docs: string[]) => void;
};

export const BudgetDocumentUploader = ({ docs, setDocs }: BudgetDocumentUploaderProps) => {
  const [selectedFiles, setSelectedFiles] = useState<ExtendedFile[]>([]);
  const [didDocsEffectAlreadyRun, setDidDocsEffectAlreadyRun] = useState(false);
  const handleSetUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (!files) {
      return;
    }

    setSelectedFiles(
      Array.from(files).map((file) => ({
        file,
        isEdit: false
      }))
    );
  };

  const urlToObject = async (imageURL) => {
    const response = await fetch(imageURL);
    // here image is url/location of image
    const blob = await response.blob();
    const file = new File([blob], imageURL, { type: blob.type });
    return file;
  };

  useEffect(() => {
    if (docs.length > 0 && !didDocsEffectAlreadyRun) {
      Promise.all(docs.map(urlToObject)).then((res) => {
        const newSelectedFiles = res.map((file) => ({
          file,
          isEdit: true
        }));
        setSelectedFiles(newSelectedFiles);
        setDidDocsEffectAlreadyRun(true);
      });
    }
  }, [docs]);

  return (
    <div className="budget-document-uploader">
      <div>
        <button
          type="button"
          className="upload-files-btn"
          onClick={() => {
            const fileInput = document.getElementById('budget-upload') as HTMLInputElement;
            fileInput.click();
          }}
        >
          Upload Your Docs <UploadOutlined />
        </button>
      </div>
      <div style={{ display: 'none' }}>
        <input id="budget-upload" type="file" multiple onChange={handleSetUpload} />
      </div>
      <div>
        {selectedFiles.map((extendedFile, index) => (
          <TusClientProvider key={`${extendedFile.file.name}-${extendedFile.file.lastModified}`}>
            <BudgetDocumentUploaderFile
              key={`${extendedFile.file.name}-${extendedFile.file.lastModified}`}
              extendedFile={extendedFile}
              uploadSuccessHandler={(fileName) => {
                if (docs.find((el) => el === fileName)) {
                  return;
                }
                const newDocs = [...docs];
                newDocs.push(fileName);
                setDocs(newDocs);
              }}
              removeFile={(fileName) => {
                const newDocs = [...docs];
                setDocs(newDocs.filter((el) => el !== fileName));
                const newSelectedFiles = [...selectedFiles];
                setSelectedFiles(
                  newSelectedFiles.filter((_file, fileIndex) => index !== fileIndex)
                );
              }}
            />
          </TusClientProvider>
        ))}
      </div>
    </div>
  );
};
