export const ageOptions = [
  { id: '0-17', text: '0-17' },
  { id: '18-29', text: '18-29' },
  { id: '30-39', text: '30-39' },
  { id: '40+', text: '40-49' },
  { id: '50+', text: '50+' }
];

export const genderOptions = [
  { id: 'Male', text: 'Male' },
  { id: 'Female', text: 'Female' }
];

export const countriesOptions = [
  { id: 'EG', text: 'Egypt' },
  { id: 'AE', text: 'United Arab Emirates' },
  { id: 'SA', text: 'Saudi Arabia' },
  { id: 'KW', text: 'Kuwait' },
  { id: 'BH', text: 'Bahrain' },
  { id: 'OM', text: 'Oman' },
  { id: 'QA', text: 'Qatar' },
  { id: 'LB', text: 'Lebanon' },
  { id: 'JO', text: 'Jordan' },
  { id: 'PS', text: 'Palestine' },
  { id: 'SY', text: 'Syria' },
  { id: 'IQ', text: 'Iraq' },
  { id: 'DZ', text: 'Algeria' },
  { id: 'MA', text: 'Morocco' },
  { id: 'TN', text: 'Tunisia' },
  { id: 'GCC', text: 'GCC (SA, AE, OM, BH, QA, KW)' },
  { id: 'MENA', text: 'MENA (AE, SA, KW, OM, BH, QA, LB, JO, IQ, PS, SY, EG, MA, DZ, TN)' },
  { id: 'LEVANT', text: 'LEVANT (LB, JO, IQ, PS, SY)' },
  { id: 'NA', text: 'NA (EG, MA, DZ, TN)' }
];
