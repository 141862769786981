import React from 'react';
import '../campaignsidebar/styles.scss';
import AnghamiAdsBetaLogo from '../../public/assets/images/Ads-Logo-Dark.png';
import {
  SponsoredPlaylistNavigation,
  SponsoredPlaylistNavigationType
} from '../../enums/sponsoredplaylistsnavigation';

type Props = {
  navigationPage: SponsoredPlaylistNavigationType;
  setNavigationPage: (page: SponsoredPlaylistNavigationType) => void;
  selectedPlaylist: any;
  selectedCountries: any[];
  endDate: any;
  startDate: any;
  budget: any;
  isOverrideToggled: any;
  overrideImageCoverEn: any;
  logoEn: any;
  advertiserId: any;
  playlistTitleEn: any;
  playlistTitleAr: any;
  mode: string;
};

export default function SponsoredPlaylistsSidebar({
  navigationPage,
  setNavigationPage,
  selectedPlaylist,
  selectedCountries,
  endDate,
  startDate,
  budget,
  isOverrideToggled,
  overrideImageCoverEn,
  logoEn,
  advertiserId,
  playlistTitleEn,
  playlistTitleAr,
  mode
}: Props) {
  const isPlaylistClickable = mode === 'create';
  const isAudienceClickable = Boolean(selectedPlaylist);
  const isCoverClickable = selectedCountries.length > 0 && endDate && budget && startDate;
  const isInfoClickable = isOverrideToggled ? Boolean(overrideImageCoverEn) : Boolean(logoEn);
  const isReviewClickable = advertiserId && playlistTitleEn && playlistTitleAr;
  return (
    <div className="campaign-sidebar">
      <div className="sidebar-logo-container">
        <img src={AnghamiAdsBetaLogo} alt="logo" />
      </div>
      <div
        className={`section-title ${
          navigationPage === SponsoredPlaylistNavigation.Playlist ? 'activated-section-title' : ''
        } ${isPlaylistClickable ? '' : 'disabled'}`}
        onClick={() => {
          if (!isPlaylistClickable) return;
          setNavigationPage(SponsoredPlaylistNavigation.Playlist);
        }}
      >
        Playlist
      </div>
      <div
        className={`section-title ${
          navigationPage === SponsoredPlaylistNavigation.Audience ? 'activated-section-title' : ''
        } ${isAudienceClickable ? '' : 'disabled'}`}
        onClick={() => {
          if (!isAudienceClickable) return;
          setNavigationPage(SponsoredPlaylistNavigation.Audience);
        }}
      >
        Audience
      </div>
      <div
        className={`section-title ${
          navigationPage === SponsoredPlaylistNavigation.Cover ? 'activated-section-title' : ''
        } ${isCoverClickable ? '' : 'disabled'}`}
        onClick={() => {
          if (!isCoverClickable) return;
          setNavigationPage(SponsoredPlaylistNavigation.Cover);
        }}
      >
        Cover
      </div>
      <div
        className={`section-title ${
          navigationPage === SponsoredPlaylistNavigation.Info ? 'activated-section-title' : ''
        } ${isInfoClickable ? '' : 'disabled'}`}
        onClick={() => {
          if (!isInfoClickable) return;
          setNavigationPage(SponsoredPlaylistNavigation.Info);
        }}
      >
        Info
      </div>
      <div
        className={`section-title ${
          navigationPage === SponsoredPlaylistNavigation.Review ? 'activated-section-title' : ''
        } ${isReviewClickable ? '' : 'disabled'}`}
        onClick={() => {
          if (!isReviewClickable) return;
          setNavigationPage(SponsoredPlaylistNavigation.Review);
        }}
      >
        Review
      </div>
    </div>
  );
}
