import React, { useState } from 'react';
import moment from 'moment';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AudioAd from '../../public/assets/images/audioad.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VideoAd from '../../public/assets/images/videoad.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DisplayAd from '../../public/assets/images/displayad.svg';
import './styles.scss';
import { AxiosSingleton } from '../../utils/axiosinstance';
import { AnghamiAdsFormSelector } from '../core/AnghamiAdsFormSelector';
import {
  availableCountries, groupByOptions, adTypeOptions
} from './selectorOptions';
import {AnghamiAdsFormRadio} from "../core/AnghamiRadioButton";
import SpinningCircle from "../spinningcircle";


export default function FillRateExport() {
  const axios = AxiosSingleton.axiosInstance;
  const [startMonth, setStartMonth] = useState(moment().subtract(1, 'M').format('YYYY-MM'));
  const [endMonth, setEndMonth] = useState(moment().subtract(1, 'M').format('YYYY-MM'));
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState(['all']);
  const [groupByRadio, setGroupByRadio] = useState('total');
  const [adType, setAdType] = useState('all');
  const [errorMessage, setErrorMessage] = useState('');

  function getQueryType() {
    if (adType == 'all' && groupByRadio == 'total'){
      return "all_ads";
    } else if (adType == 'all' && groupByRadio == 'ad_type'){
      return "ad_type";
    } else if (adType == 'excluding' && groupByRadio == 'total'){
      return "no_display_all";
    }
    return "no_display_ad_type";
  }



  const exportToCSV = () => {
    if(countries.length == 0){
      setErrorMessage("Countries field is empty")
      return;
    }
    setIsLoading(true)
    let startDateString =`${startMonth}-01`;
    const lastDay = new Date(new Date(endMonth).getFullYear(), new Date(endMonth).getMonth() + 1, 0).getDate();
    let endDateString = `${endMonth}-${lastDay}`;
    axios
      .post(
        '/fill-rate/export',
        {
          start_date: startDateString,
          end_date: endDateString,
          countries,
          type: getQueryType(),
        },
        {
          responseType: 'arraybuffer'
        }
      )
      .then((res) => {
        const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const rand = Math.floor(Math.random() * 9999 + 1000);
        link.download = `fillrate${rand}.xlsx`;
        link.click();
        setIsLoading(false)
        setErrorMessage("")
      });
  };

  return (
    <div className="inventory-container">
      <div className="inventory-header">Monthly Fill Rate</div>
      {errorMessage && <div className="failed-msg">{errorMessage}</div>}
      <div className="inventory-flex-container">
        <div className="campaign-form-input-container">
          <label>Start Month</label>
          <input
            type="month"
            value={startMonth}
            onChange={(e) => {
              setStartMonth(e.target.value);
            }}
          />
        </div>
        <div className="campaign-form-input-container">
          <label>End Month</label>
          <input
            type="month"
            min={moment(startMonth).add(1, 'm').format('YYYY-MM')}
            value={endMonth}
            onChange={(e) => {
              setEndMonth(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="margin-top">
      <AnghamiAdsFormSelector
        label="Countries *"
        showSearch
        mode="multiple"
        placeholder="Select one or more countries"
        value={countries}
        options={availableCountries}
        onChange={(value: any) => {

          if (value.includes("all")){
            const lastElement = value[value.length - 1];
            if (lastElement != "all"){
              const filteredCountries = value.filter((country: string) => country !== "all");
              setCountries(filteredCountries);
            } else {
              setCountries(["all"]);
            }
          } else {
            setCountries(value);
          }
        }}
      />
      </div>

      <AnghamiAdsFormRadio
        label="Ad Types"
        placeholder="Select Ad Types"
        value={adType}
        options={adTypeOptions}
        onChange={(value: any) => {
          setAdType(value);
        }}
      />

      <AnghamiAdsFormRadio
        label="Group By"
        placeholder="Group By"
        value={groupByRadio}
        options={groupByOptions}
        onChange={(value: any) => {
          setGroupByRadio(value);
        }}
      />

      <div className="tabbed-section-btns-container">
        <button type="button" onClick={exportToCSV} className="export-btn">
          {isLoading ? <SpinningCircle /> : 'Export Results'}
        </button>
      </div>

    </div>
  );
}
