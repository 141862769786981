import React, { useContext, useState } from 'react';
import { RightOutlined, DownOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { matchPath } from 'react-router';
import { AmplitudeEvents } from '@anghami/neoanalytics/dist/enums/events';
import useAuth from '../../hooks/useAuth';
import AnghamiAdsBetaLogo from '../../public/assets/images/Ads-Logo-Light.png';
import reports from '../../public/assets/images/billings-and-reports.svg';
import campaign from '../../public/assets/images/campaigns.svg';
import overview from '../../public/assets/images/overview.svg';
import question from '../../public/assets/images/help.svg';
import insights from '../../public/assets/images/general-insights.svg';
import audience from '../../public/assets/images/audiences.svg';
import plus from '../../public/assets/images/plus.svg';
import arrow from '../../public/assets/images/arrow-open.svg';
import accounts from '../../public/assets/images/accounts.svg';
import { CampaignAmplitudeEvents } from '../../enums/campaignamplitudeevents';
import './index.scss';
import { logAmplitudeEvent } from '../../utils/analytics';
import { PublisherRoles } from '../../enums/publisherroles';
import { BillingModalContext } from '../basepage';

export default function SideMenu() {
  const { setShowBillingModal } = useContext(BillingModalContext);
  const [showCampaignsSubmenu, setShowCampaignsSubmenu] = useState(false);
  const [showCampaignsSubmenuRefresh, setShowCampaignsSubmenuRefresh] = useState(false);
  const [showMastheadCampaignsSubmenu, setShowMastheadCampaignsSubmenu] = useState(false);
  const [showMastheadCampaignsSubmenuRefresh, setShowMastheadCampaignsSubmenuRefresh] =
    useState(false);
  const [showAudiencesSubmenu, setShowAudiencesSubmenu] = useState(false);
  const [showAudiencesSubmenuRefresh, setShowAudiencesSubmenuRefresh] = useState(false);
  const [showReportsSubmenu, setShowReportsSubmenu] = useState(false);
  const [showReportsSubmenuRefresh, setShowReportsSubmenuRefresh] = useState(false);
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const { user, logout, role } = useAuth();

  const isCampaignsSubmenuActiveList = [
    matchPath(window.location.pathname, '/campaigns'),
    matchPath(window.location.pathname, '/manage-campaigns'),
    matchPath(window.location.pathname, '/insights'),
    matchPath(window.location.pathname, '/research'),
    matchPath(window.location.pathname, '/sponsoredplaylists')
  ];
  if (!showCampaignsSubmenuRefresh && isCampaignsSubmenuActiveList.some((el) => el)) {
    setShowCampaignsSubmenu(true);
    setShowCampaignsSubmenuRefresh(true);
  }

  const isMastheadCampaignsSubmenuActiveList = [
    matchPath(window.location.pathname, '/masthead-campaigns/list'),
    matchPath(window.location.pathname, '/masthead-campaigns/calendar'),
    matchPath(window.location.pathname, '/video-masthead-campaigns/list')
  ];
  if (
    !showMastheadCampaignsSubmenuRefresh &&
    isMastheadCampaignsSubmenuActiveList.some((el) => el)
  ) {
    setShowMastheadCampaignsSubmenu(true);
    setShowMastheadCampaignsSubmenuRefresh(true);
  }

  const isReportsSubmenuActiveList = [
    matchPath(window.location.pathname, '/reports'),
    matchPath(window.location.pathname, '/reports/overview'),
    matchPath(window.location.pathname, '/reports/list'),
    matchPath(window.location.pathname, '/invoices'),
    matchPath(window.location.pathname, '/inventory'),
    matchPath(window.location.pathname, '/str')
  ];
  if (!showReportsSubmenuRefresh && isReportsSubmenuActiveList.some((el) => el)) {
    setShowReportsSubmenu(true);
    setShowReportsSubmenuRefresh(true);
  }

  const isAudiencesSubmenuActiveList = [
    matchPath(window.location.pathname, '/custom-audiences'),
    matchPath(window.location.pathname, '/audience-insights')
  ];
  if (!showAudiencesSubmenuRefresh && isAudiencesSubmenuActiveList.some((el) => el)) {
    setShowAudiencesSubmenu(true);
    setShowAudiencesSubmenuRefresh(true);
  }

  const isDirectClientAdmin =
    user.publisher_type.length > 1 && user?.publisher_type[1] === 'direct_client_admin';

  const canViewSponsoredPlaylists =
    role === PublisherRoles.Anghami ||
    role === PublisherRoles.DMSPublisher ||
    role === PublisherRoles.DMSAdmin ||
    role === PublisherRoles.DMSTrafficker ||
    role === PublisherRoles.DMS_CRM;

  const canViewCampaigns =
    role === PublisherRoles.DirectClient ||
    role === PublisherRoles.Anghami ||
    role === PublisherRoles.AnghamiAdvertiser ||
    role === PublisherRoles.DMSPublisher ||
    role === PublisherRoles.DMSAdmin ||
    role === PublisherRoles.DMSTrafficker ||
    role === PublisherRoles.DMS_CRM ||
    role === PublisherRoles.DMSAdvertiser ||
    role === PublisherRoles.WarnerPublisher;

  const canViewMastheadCampaigns =
    user?.publisher_id === 1 || user?.publisher_id === 2 || user?.publisher_id === 4;
  const canViewVideoMastheadCampaigns =
    user?.publisher_id === 1 || user?.publisher_id === 2;
  const canViewMastheadCampaignsCalendar = user?.publisher_id === 2 || user?.publisher_id === 4;

  const canCreateCampaigns =
    role === PublisherRoles.DirectClient ||
    role === PublisherRoles.Anghami ||
    role === PublisherRoles.DMSPublisher ||
    role === PublisherRoles.DMSAdmin ||
    role === PublisherRoles.DMSTrafficker ||
    role === PublisherRoles.WarnerPublisher;

  const canViewBrandStudies =
    role === PublisherRoles.Anghami ||
    role === PublisherRoles.DMSPublisher ||
    role === PublisherRoles.DMSAdmin ||
    role === PublisherRoles.DMSExternalCampaignResearch;

  const canViewInventory =
    role === PublisherRoles.Anghami ||
    role === PublisherRoles.DMSPublisher ||
    role === PublisherRoles.DMSAdmin ||
    role === PublisherRoles.DMSTrafficker ||
    user?.can_view_inventory;

  const canViewSellThroughRate =
    role === PublisherRoles.Anghami ||
    role === PublisherRoles.DMSPublisher ||
    role === PublisherRoles.DMSAdmin ||
    role === PublisherRoles.DMSTrafficker;

  const canManageReports =
    role === PublisherRoles.Anghami ||
    role === PublisherRoles.DMSPublisher ||
    Boolean(user?.can_manage_reports);

  const canViewClientInvoices =
    role === PublisherRoles.Anghami ||
    Boolean(user?.can_view_invoices) ||
    Boolean(user?.billing.can_view_billing) ||
    isDirectClientAdmin;

  const canViewFillRate =
    role === PublisherRoles.Anghami;

  const canViewFinancialOverview =
    role === PublisherRoles.Anghami || role === PublisherRoles.DMSPublisher;

  const canViewAudienceMatch =
    role === PublisherRoles.Anghami ||
    role === PublisherRoles.DMSPublisher ||
    role === PublisherRoles.DMSAdmin ||
    role === PublisherRoles.DMSTrafficker;

  const canViewAudienceInsights =
    role === PublisherRoles.Anghami ||
    role === PublisherRoles.DMSPublisher ||
    role === PublisherRoles.DMSAdmin ||
    role === PublisherRoles.DMSTrafficker ||
    role === PublisherRoles.DMS_CRM;

  const canViewUsers =
    role === PublisherRoles.Anghami ||
    role === PublisherRoles.DMSAdmin ||
    role === PublisherRoles.DMSPublisher ||
    (role === PublisherRoles.DirectClient && isDirectClientAdmin);

  const canViewBudgets =
    user?.publisher_id === 1 ||
    user?.publisher_id === 2 ||
    role === PublisherRoles.DMSAdmin ||
    role === PublisherRoles.DMSPublisher;

  const renderReports = () => (
    <>
      {(canViewFinancialOverview ||
        canManageReports ||
        canViewClientInvoices ||
        canViewInventory ||
        canViewSellThroughRate) && (
        <div
          className={`section ${
            matchPath(window.location.pathname, '/reports') ? 'active-section-title' : ''
          }`}
          onClick={() => {
            setShowReportsSubmenu(!showReportsSubmenu);
          }}
        >
          <div
            className="section-title"
            onClick={() => {
              setShowReportsSubmenu(!showReportsSubmenu);
            }}
          >
            <div className="section-title-logo">
              <img src={reports} alt="reports" />
            </div>
            Billing & Reports
          </div>
          <div
            className={`section-arrow ${showReportsSubmenu ? 'toggled-section-arrow' : ''}`}
            onClick={() => {
              setShowReportsSubmenu(!showReportsSubmenu);
            }}
          >
            {showReportsSubmenu ? <DownOutlined /> : <RightOutlined />}
          </div>
        </div>
      )}
      {canViewFinancialOverview && (
        <div className={`collapsable-section ${showReportsSubmenu ? '' : 'collapsed'}`}>
          <NavLink
            to="/reports/overview"
            className={({ isActive }) => (isActive ? 'active-sub-section-title' : '')}
            onClick={() =>
              logAmplitudeEvent(AmplitudeEvents.goToTab, {
                tab_name: 'Financial Overview'
              })
            }
          >
            <div className="sub-section-title">Financial Overview</div>
          </NavLink>
        </div>
      )}
      {canManageReports && (
        <div className={`collapsable-section ${showReportsSubmenu ? '' : 'collapsed'}`}>
          <NavLink
            to="/reports/list"
            className={({ isActive }) => (isActive ? 'active-sub-section-title' : '')}
            onClick={() =>
              logAmplitudeEvent(AmplitudeEvents.goToTab, {
                tab_name: 'Manage Reports'
              })
            }
          >
            <div className="sub-section-title">Manage Reports</div>
          </NavLink>
        </div>
      )}
      {canViewClientInvoices && (
        <div className={`collapsable-section ${showReportsSubmenu ? '' : 'collapsed'}`}>
          <NavLink
            to="/invoices"
            className={({ isActive }) => (isActive ? 'active-sub-section-title' : '')}
            onClick={() =>
              logAmplitudeEvent(AmplitudeEvents.goToTab, {
                tab_name: 'Invoices'
              })
            }
          >
            <div className="sub-section-title">My Invoices</div>
          </NavLink>
          {Boolean(user?.billing?.can_view_billing) && (
            <div
              className="sub-section-title pointer"
              onClick={() => {
                setShowBillingModal(true);
                logAmplitudeEvent(AmplitudeEvents.goToTab, {
                  tab_name: 'Billing information'
                });
              }}
            >
              Billing Information
            </div>
          )}
        </div>
      )}

      {canViewInventory && (
        <div className={`collapsable-section ${showReportsSubmenu ? '' : 'collapsed'}`}>
          <NavLink
            to="/inventory"
            className={({ isActive }) => (isActive ? 'active-sub-section-title' : '')}
            onClick={() =>
              logAmplitudeEvent(AmplitudeEvents.goToTab, {
                tab_name: 'Inventory'
              })
            }
          >
            <div className="sub-section-title">Inventory</div>
          </NavLink>
        </div>
      )}

      {canViewFillRate && (
        <div className={`collapsable-section ${showReportsSubmenu ? '' : 'collapsed'}`}>
          <NavLink
            to="/fill-rate"
            className={({ isActive }) => (isActive ? 'active-sub-section-title' : '')}
            onClick={() =>
              logAmplitudeEvent(AmplitudeEvents.goToTab, {
                tab_name: 'Fill Rate'
              })
            }
          >
            <div className="sub-section-title">Monthly Fill Rate</div>
          </NavLink>
        </div>
      )}
      {canViewSellThroughRate && (
        <div className={`collapsable-section ${showReportsSubmenu ? '' : 'collapsed'}`}>
          <NavLink
            to="/str"
            className={({ isActive }) => (isActive ? 'active-sub-section-title' : '')}
            onClick={() =>
              logAmplitudeEvent(AmplitudeEvents.goToTab, {
                tab_name: 'Sell Through Rate'
              })
            }
          >
            <div className="sub-section-title">Sell Through Rate</div>
          </NavLink>
        </div>
      )}
      {canViewBudgets && (
        <div className={`collapsable-section ${showReportsSubmenu ? '' : 'collapsed'}`}>
          <NavLink
            to="/budgets"
            className={({ isActive }) => (isActive ? 'active-sub-section-title' : '')}
            onClick={() =>
              logAmplitudeEvent(AmplitudeEvents.goToTab, {
                tab_name: 'Budgets'
              })
            }
          >
            <div className="sub-section-title">Budgets</div>
          </NavLink>
        </div>
      )}
    </>
  );

  return (
    <div className={`sidemenu-container ${showSideMenu ? 'toggled-sidemenu' : ''}`}>
      <div
        className={`sidemenu-hider ${showSideMenu ? 'toggled-sidemenu-hider' : ''}`}
        onClick={() => {
          setShowSideMenu(!showSideMenu);
        }}
      >
        <img src={arrow} alt="arrow-open" />
      </div>
      <div className="sidemenu">
        <div className="sidemenu-top-container">
          <div className="sidemenu-logo-container">
            <NavLink to="/overview">
              <img src={AnghamiAdsBetaLogo} alt="logo" />
            </NavLink>
          </div>
          <div className="welcome-container">
            Welcome
            <div className={`user-container ${showDropdownMenu ? 'toggled-user-container' : ''}`}>
              <div
                onClick={() => {
                  setShowDropdownMenu(!showDropdownMenu);
                }}
              >
                {user?.name}
              </div>
              <div
                onClick={() => {
                  setShowDropdownMenu(!showDropdownMenu);
                }}
              >
                {showDropdownMenu ? <DownOutlined /> : <RightOutlined />}
              </div>
              <div className={`dropdown-content ${showDropdownMenu ? 'toggled-dropdown' : ''}`}>
                <div className="logout-button" onClick={logout}>
                  Logout
                </div>
              </div>
            </div>
          </div>
          {canCreateCampaigns && (
            <div
              onClick={() => {
                logAmplitudeEvent(AmplitudeEvents.goToTab, {
                  tab_name: 'Create campaign'
                });
                logAmplitudeEvent(CampaignAmplitudeEvents.ClickButton, {
                  button_text: 'Create campaign',
                  page_url: window.location.href
                });
                window.open('/campaign/create');
              }}
            >
              <div className="create-campaign-container">
                <div className="create-campaign-logo">
                  <img src={plus} alt="plus" />
                </div>
                <div className="create-campaign-title">Create Campaign</div>
              </div>
            </div>
          )}
          <NavLink
            to="/overview"
            className={({ isActive }) => (isActive ? 'active-section-title' : '')}
            onClick={() =>
              logAmplitudeEvent(AmplitudeEvents.goToTab, {
                tab_name: 'Overview'
              })
            }
          >
            <div className="section">
              <div className="section-title">
                <div className="section-title-logo">
                  <img src={overview} alt="overview" />
                </div>
                Overview
              </div>
            </div>
          </NavLink>
          {(canViewCampaigns || canCreateCampaigns) && (
            <div
              className={`section ${
                matchPath(window.location.pathname, '/campaigns') ? 'active-section-title' : ''
              }`}
              onClick={() => {
                setShowCampaignsSubmenu(!showCampaignsSubmenu);
              }}
            >
              <div
                className="section-title"
                onClick={() => {
                  setShowCampaignsSubmenu(!showCampaignsSubmenu);
                }}
              >
                <div className="section-title-logo campaign-logo">
                  <img src={campaign} alt="campaign" />
                </div>
                Campaigns
              </div>
              <div
                className={`section-arrow ${showCampaignsSubmenu ? 'toggled-section-arrow' : ''}`}
                onClick={() => {
                  setShowCampaignsSubmenu(!showCampaignsSubmenu);
                }}
              >
                {showCampaignsSubmenu ? <DownOutlined /> : <RightOutlined />}
              </div>
            </div>
          )}
          <div className={`collapsable-section ${showCampaignsSubmenu ? '' : 'collapsed'}`}>
            {canViewCampaigns && (
              <NavLink
                to="/manage-campaigns"
                className={({ isActive }) => (isActive ? 'active-sub-section-title' : '')}
                onClick={() =>
                  logAmplitudeEvent(AmplitudeEvents.goToTab, {
                    tab_name: 'Manage campaigns'
                  })
                }
              >
                <div className="sub-section-title">Manage</div>
              </NavLink>
            )}
            {canCreateCampaigns && (
              <div
                className="sub-section-title"
                onClick={() => {
                  logAmplitudeEvent(CampaignAmplitudeEvents.ClickButton, {
                    button_text: 'Create',
                    page_url: window.location.href
                  });
                  window.open('/campaign/create');
                }}
              >
                Create
              </div>
            )}
            {canViewSponsoredPlaylists && (
              <NavLink
                to="/sponsoredplaylists"
                className={({ isActive }) => (isActive ? 'active-sub-section-title' : '')}
                onClick={() =>
                  logAmplitudeEvent(AmplitudeEvents.goToTab, {
                    tab_name: 'Sponsored playlists'
                  })
                }
              >
                <div className="sub-section-title">Sponsored Playlists</div>
              </NavLink>
            )}
          </div>
          {(canViewMastheadCampaigns || canViewMastheadCampaignsCalendar) && (
            <div
              className={`section ${
                matchPath(window.location.pathname, '/masthead-campaigns')
                  ? 'active-section-title'
                  : ''
              }`}
              onClick={() => {
                setShowMastheadCampaignsSubmenu(!showMastheadCampaignsSubmenu);
              }}
            >
              <div
                className="section-title"
                onClick={() => {
                  setShowMastheadCampaignsSubmenu(!showMastheadCampaignsSubmenu);
                }}
              >
                <div className="section-title-logo campaign-logo">
                  <img src={campaign} alt="campaign" />
                </div>
                Mastheads
              </div>
              <div
                className={`section-arrow ${
                  showMastheadCampaignsSubmenu ? 'toggled-section-arrow' : ''
                }`}
                onClick={() => {
                  setShowMastheadCampaignsSubmenu(!showMastheadCampaignsSubmenu);
                }}
              >
                {showMastheadCampaignsSubmenu ? <DownOutlined /> : <RightOutlined />}
              </div>
            </div>
          )}
          <div className={`collapsable-section ${showMastheadCampaignsSubmenu ? '' : 'collapsed'}`}>
            {canViewMastheadCampaigns && (
              <NavLink
                to="/masthead-campaigns/list"
                className={({ isActive }) => (isActive ? 'active-sub-section-title' : '')}
                onClick={() =>
                  logAmplitudeEvent(AmplitudeEvents.goToTab, {
                    tab_name: 'Masthead campaigns'
                  })
                }
              >
                <div className="sub-section-title">Display</div>
              </NavLink>
            )}
            {canViewVideoMastheadCampaigns && (
                <NavLink
                    to="/video-masthead-campaigns/list"
                    className={({ isActive }) => (isActive ? 'active-sub-section-title' : '')}
                    onClick={() =>
                        logAmplitudeEvent(AmplitudeEvents.goToTab, {
                          tab_name: 'Video Masthead campaigns'
                        })
                    }
                >
                  <div className="sub-section-title">Video</div>
                </NavLink>
            )}
            {canViewMastheadCampaignsCalendar && (
              <NavLink
                to="/masthead-campaigns/calendar"
                className={({ isActive }) => (isActive ? 'active-sub-section-title' : '')}
                onClick={() =>
                  logAmplitudeEvent(AmplitudeEvents.goToTab, {
                    tab_name: 'Masthead campaigns calendar'
                  })
                }
              >
                <div className="sub-section-title">Calendar</div>
              </NavLink>
            )}
          </div>
          {(canViewAudienceInsights || canViewAudienceMatch) && (
            <>
              <div
                className={`section ${
                  matchPath(window.location.pathname, '/campaigns') ? 'active-section-title' : ''
                }`}
                onClick={() => {
                  setShowAudiencesSubmenu(!showAudiencesSubmenu);
                }}
              >
                <div
                  className="section-title"
                  onClick={() => {
                    setShowAudiencesSubmenu(!showAudiencesSubmenu);
                  }}
                >
                  <div className="section-title-logo campaign-logo">
                    <img src={audience} alt="audience" />
                  </div>
                  Audience
                </div>
                <div
                  className={`section-arrow ${showAudiencesSubmenu ? 'toggled-section-arrow' : ''}`}
                  onClick={() => {
                    setShowAudiencesSubmenu(!showAudiencesSubmenu);
                  }}
                >
                  {showAudiencesSubmenu ? <DownOutlined /> : <RightOutlined />}
                </div>
              </div>
              <div className={`collapsable-section ${showAudiencesSubmenu ? '' : 'collapsed'}`}>
                {canViewAudienceMatch && (
                  <NavLink
                    to="/custom-audiences"
                    className={({ isActive }) => (isActive ? 'active-sub-section-title' : '')}
                    onClick={() =>
                      logAmplitudeEvent(AmplitudeEvents.goToTab, {
                        tab_name: 'Audience Match'
                      })
                    }
                  >
                    <div className="sub-section-title">Audience Match</div>
                  </NavLink>
                )}
                {canViewAudienceInsights && (
                  <NavLink
                    to="/audience-insights"
                    className={({ isActive }) => (isActive ? 'active-sub-section-title' : '')}
                    onClick={() =>
                      logAmplitudeEvent(AmplitudeEvents.goToTab, {
                        tab_name: 'Audience Insights'
                      })
                    }
                  >
                    <div className="sub-section-title">Audience Insights</div>
                  </NavLink>
                )}
              </div>
            </>
          )}
          {renderReports()}
          {canViewBrandStudies && (
            <NavLink
              to="/brandupliftstudies"
              className={({ isActive }) => (isActive ? 'active-section-title' : '')}
              onClick={() =>
                logAmplitudeEvent(AmplitudeEvents.goToTab, {
                  tab_name: 'Research'
                })
              }
            >
              <div className="section">
                <div className="section-title">
                  <div className="section-title-logo">
                    <img src={insights} alt="research" />
                  </div>
                  Research
                </div>
              </div>
            </NavLink>
          )}
          {canViewUsers && (
            <NavLink
              to="/manage-users"
              className={({ isActive }) => (isActive ? 'active-section-title' : '')}
              onClick={() =>
                logAmplitudeEvent(AmplitudeEvents.goToTab, {
                  tab_name: 'Users'
                })
              }
            >
              <div className="section">
                <div className="section-title">
                  <div className="section-title-logo">
                    <img src={accounts} alt="users" />
                  </div>
                  Users
                </div>
              </div>
            </NavLink>
          )}
        </div>
        <div
          onClick={() => {
            logAmplitudeEvent(AmplitudeEvents.goToTab, {
              tab_name: 'Help'
            });
            window.open(
              'https://support.anghami.com/hc/en-us/categories/204110868-Advertise-Your-Business'
            );
          }}
        >
          <div className="help-container">
            <div className="help-logo">
              <img src={question} alt="logo" />
            </div>
            <div className="help-title">Need help getting started?</div>
          </div>
        </div>
      </div>
    </div>
  );
}
