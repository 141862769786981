import { createContext } from 'react';
import { Publisher } from '../../interfaces/publisher.interface';

export interface Campaign {
  mode: 'create' | 'edit' | 'preview';
  groupCampaignId: string;
  setGroupCampaignId: any; // (id: string) => void
  groupCampaignName: string;
  setGroupCampaignName: any; // (name: string) => void;
  groupCampaignStatus: number;
  setGroupCampaignStatus: (status: number) => void;
  groupCampaignObjective: 'reach' | 'conversion' | 'lead';
  setGroupCampaignObjective: any; // (objective: string) => void;
  advertiserId: string;
  advertiserName: string;
  setAdvertiserId: any; // (id) => void;
  setAdvertiserName: any; // (name) => void;
  competitorId: any[];
  setCompetitorId: any; // (id) => void;
  agencyId: string;
  setAgencyId: any; // (id) => void;
  industryId: string;
  industryName: string;
  setIndustryId: any; // (id) => void;
  setIndustryName: any; // (name) => void;
  publisherId: string;
  setPublisherId: (id: string) => void;
  publisherName: string;
  setPublisherName: (name: string) => void;
  specialPublishers: Publisher[];
  setSpecialPublishers: (publishers: Publisher[]) => void;
  adSets: any[];
  setAdSets: any; // (adSets) => void;
  addEmptyAdSet: any; // () => void;
  updateAdSetValue: any; // () => void;
  removeAdSetById: any; // () => void;
  updateCreativeValue: any; // () => void;
  addEmptyCreative: any; // (adSetId) => void;
  removeCreativeById: any; // (params) => void;
  saveAll: any; // (params) => void;
  getGroupCampaignDataAsync: any; // (params) => void;
  navigationData: any;
  setNavigationData: any; // (params) => void;
  isSaveAllLoading: boolean;
  updateLeadGenForm: any; // (adSet) => void;
  isSubmitSaveAll: boolean;
  isSaveForLaterSaveAll: boolean;
}

const campaignContext = createContext<Campaign>({
  mode: 'create',
  groupCampaignId: '',
  setGroupCampaignId: () => null,
  groupCampaignName: '',
  setGroupCampaignName: () => null,
  groupCampaignStatus: -1,
  setGroupCampaignStatus: () => null,
  groupCampaignObjective: 'reach',
  setGroupCampaignObjective: () => null,
  advertiserId: '',
  advertiserName: '',
  setAdvertiserId: () => null,
  setAdvertiserName: () => null,
  competitorId: [],
  setCompetitorId: () => null,
  agencyId: '',
  setAgencyId: () => null,
  industryId: '',
  industryName: '',
  setIndustryId: () => null,
  setIndustryName: () => null,
  publisherName: '',
  setPublisherName: () => null,
  publisherId: '',
  setPublisherId: () => null,
  specialPublishers: [],
  setSpecialPublishers: () => null,
  adSets: [],
  setAdSets: () => null,
  addEmptyAdSet: () => null,
  updateAdSetValue: () => null,
  removeAdSetById: () => null,
  updateCreativeValue: () => null,
  addEmptyCreative: () => null,
  removeCreativeById: () => null,
  saveAll: () => null,
  getGroupCampaignDataAsync: () => null,
  navigationData: {},
  setNavigationData: () => null,
  isSaveAllLoading: false,
  updateLeadGenForm: () => null,
  isSubmitSaveAll: false,
  isSaveForLaterSaveAll: false
});

export { campaignContext };
