/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Input, Select } from 'antd';
import '../billing-modal/styles.scss';
import './styles.scss';
import { cardLogo, deleteBilling } from '../../helpers/payment-helper';
import EditIcon from '../../public/assets/images/billing/edit-icon.png';
import DeleteIcon from '../../public/assets/images/billing/delete-icon.png';
import ccValidator from '../../helpers/validator';
import {
  IBillingCard,
  ICardFieldError,
  ICardParams,
  IServerResponse
} from '../../interfaces/billing.interface';
import { message } from '../../utils/custommessage';
import { Messages } from '../../enums/messages';

interface IDate {
  month: string;
  year: string;
}

interface ICardInfo {
  card: ICardParams;
  isEmptyForm: boolean;
}

enum ICardInputTypesEnum {
  cardholder_name = 'cardholder_name',
  ccnumber = 'ccnumber',
  cvv = 'cvv'
}

type Props = {
  data: IBillingCard;
  onSubmitCardInfo: (data: ICardInfo) => void;
  onError: (_err: IServerResponse) => void;
  onSuccessDelete: () => void;
};

const PaymentForm = ({ data, onSubmitCardInfo, onError, onSuccessDelete }: Props) => {
  const location = useLocation();

  const [cardnum, setCardnum] = useState('');
  const [cardholderName, setCardholderName] = useState('');
  const [cardMonth, setCardMonth] = useState('');
  const [cardYear, setCardYear] = useState('');
  const [cardCVV, setCardCVV] = useState('');

  const [cardInfo, setCardInfo] = useState<IBillingCard>(data || {});

  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);

  const [fieldErrors, setFieldErrors] = useState<ICardFieldError>(null);

  const checkInputFields = (inputField, inputValue) => {
    const inputFieldErr = {
      ...fieldErrors,
      ...inputField
    };

    setFieldErrors(inputFieldErr);
    const errFields = Object.keys(inputFieldErr).filter((key) => inputFieldErr[key]);
    let cardParams = null;
    let isEmpty: boolean;

    if (errFields.length > 0) {
      isEmpty = false;
    } else if (
      cardholderName?.length > 0 ||
      cardnum?.length > 0 ||
      cardMonth?.toString().length > 0 ||
      cardYear?.toString().length > 0 ||
      cardCVV?.length > 0
    ) {
      isEmpty = false;
      cardParams = {
        type: 'card',
        name: cardholderName,
        number: cardnum.replace(/\s/g, '').trim(),
        expiry_month: cardMonth.toString(),
        expiry_year: cardYear.toString().length > 0 ? cardYear.toString().slice(-2) : '',
        cvv: cardCVV,
        ...inputValue
      };
      if (Object.keys(cardParams).find((key) => !cardParams[key] || cardParams[key].length === 0)) {
        cardParams = null;
      }
    } else {
      isEmpty = true;
    }
    onSubmitCardInfo({
      card: cardParams,
      isEmptyForm: isEmpty
    });
  };

  const fillCCYear = () => {
    const currentYear = new Date().getFullYear();
    const yearsLst = [];
    // eslint-disable-next-line no-plusplus
    for (let i = currentYear; i < currentYear + 10; i++) {
      yearsLst.push(i);
    }
    setYears(yearsLst);
  };

  const fillCCMonth = (selectedYear) => {
    const today = new Date();
    const currentMonth =
      today.getFullYear() === parseInt(selectedYear, 10) ? today.getMonth() + 1 : 1;
    const monthLst = [];

    // eslint-disable-next-line no-plusplus
    for (let i = currentMonth; i <= 12; i++) {
      monthLst.push(i < 10 ? `0${i}` : i);
    }
    setMonths(monthLst);
  };

  const isValidDate = (ccmonth, ccyear) => {
    if (ccmonth && ccyear && String(ccmonth).length > 0 && String(ccyear).length > 0) {
      // comparing current date, with the last day of month of expiry (added 1 month, with first day)
      let selectedDate;
      const currentDate = new Date();

      if (Number(ccmonth) === 12) {
        selectedDate = new Date(`${Number(ccyear) + 1}/1/1`);
      } else {
        selectedDate = new Date(`${ccyear}/${Number(ccmonth) + 1}/1`);
      }
      return Boolean(Number(currentDate) - selectedDate < 0);
    }
    return false;
  };

  const dateInputBlur = (dateValue?: IDate) => {
    const month = dateValue?.month || cardMonth;
    const year = dateValue?.year || cardYear;
    const expiredErr =
      !year || year.length === 0
        ? null
        : !isValidDate(month, year)
        ? 'The card you added seems to be expired'
        : null;
    checkInputFields({ cardExDate: expiredErr }, { expiry_month: month, expiry_year: year });
  };

  useEffect(() => {
    fillCCMonth();
    fillCCYear();
  }, [data]);

  const onMonthChange = (value) => {
    if (/^[0-9]{0,2}$/.test(value) && (value === '' || Number(value) <= 12) && value !== '00') {
      setCardMonth(value);
      dateInputBlur({
        month: value,
        year: cardYear
      });
    }
  };

  const onYearChange = (value) => {
    if ((/^[0-9]{0,4}$/.test(value) && Number(value) !== 0) || value === '') {
      setCardYear(value);
      dateInputBlur({
        month: cardMonth,
        year: value
      });
    }
    fillCCMonth(value);
  };

  const setCardFormValues = (type: ICardInputTypesEnum, value) => {
    switch (type) {
      case 'cardholder_name':
        setCardholderName(value);
        break;
      case 'ccnumber':
        setCardnum(value);
        break;
      case 'cvv':
        setCardCVV(value);
        break;
      default:
    }
  };

  const cardNumberValidation = (value: string) => {
    let err = null;
    if (value) {
      const res = ccValidator.validateCardNumber(value);
      if (!res || value.length < 17) {
        err = 'The card number you added is invalid';
      }
    }
    checkInputFields({ cardnumber: err }, { number: value });
  };

  const cardNumberChange = (event) => {
    // Only accept numbers and spaces
    if (/^[\d ]*$/.test(event.target.value)) {
      setCardFormValues(event.target.name, ccValidator.formatCardNumber(event.target.value));
    }
  };

  const cardNameChange = (event) => {
    // only accept characters and spaces /^[a-zA-Z\-\. ]+$/;
    if (
      /^[a-zA-Z\-\. ]+$/.test(event.target.value) ||
      event.target.value === '' ||
      /^[\u0621-\u064A\040]+$/.test(event.target.value)
    ) {
      setCardFormValues(event.target.name, event.target.value);
    }
    const err = event.target.value ? null : 'Cardholder name is required';
    checkInputFields({ cardholderName: err }, { name: event.target.value });
  };

  const validateCVV = (event) => {
    // only accept numbers and less then 1000
    const validation = /^[0-9]{3,4}$/.test(event.target.value);
    const err = validation ? null : 'Invalid CVV';
    checkInputFields({ cvv: err }, { cvv: event.target.value });
  };

  const changeCVV = (event) => {
    // only accept numbers and less then 1000
    const val = event.target.value;
    if (/^\d{0,4}$/.test(val) || val === '') {
      setCardFormValues(event.target.name, val);
      validateCVV(event);
    }
  };

  const handleFormInputChange = (type, event) => {
    switch (type) {
      case 'cardholder_name':
        cardNameChange(event);
        break;
      case 'ccnumber':
        cardNumberChange(event);
        break;
      case 'cvv':
        changeCVV(event);
        break;
      default:
    }
  };

  const onEditCard = () => {
    setCardInfo({});
  };

  const onDeleteCard = () => {
    deleteBilling()
      .then((response) => {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const { data } = response;
        if (data?.error) {
          onError(data);
        } else {
          setCardInfo({});
          setFieldErrors({});
          onSuccessDelete();
          message.success(Messages.BillingInformationDeletedSuccessfully);
          setTimeout(() => {
            if (location.pathname.indexOf('/overview') > -1) {
              window.location.reload();
            }
          }, 3000);
        }
      })
      .catch((_err) => {
        const { response } = _err;
        onError(response?.data);
      });
  };

  const renderCCForm = () => (
    <>
      <div className={`input-container ${fieldErrors?.cardnumber ? 'warning' : ''}`}>
        <div className="flexbox colls start">
          <label>card number*</label>
          <div className="input-err-msg">{fieldErrors?.cardnumber}</div>
        </div>
        <Input
          value={cardnum}
          placeholder="•••• •••• •••• ••••"
          name="ccnumber"
          onChange={(e) => {
            handleFormInputChange('ccnumber', e);
          }}
          onBlur={(e) => {
            cardNumberValidation(e.target.value);
          }}
          onMouseOut={(e: any) => {
            cardNumberValidation(e.target.value);
          }}
        />
      </div>
      <div className={`input-container ${fieldErrors?.cardholderName ? 'warning' : ''}`}>
        <div className="flexbox colls start">
          <label>name on card*</label>
          <div className="input-err-msg">{fieldErrors?.cardholderName}</div>
        </div>
        <Input
          value={cardholderName}
          placeholder="Full name"
          name="cardholder_name"
          onChange={(e) => {
            handleFormInputChange('cardholder_name', e);
          }}
        />
      </div>
      <div className="flexbox">
        <div
          className={`input-container no-margin input-container-expirydate
          ${fieldErrors?.cardExDate ? 'warning' : ''}`}
        >
          <div className="flexbox colls start">
            <label>expiry date*</label>
            <div className="input-err-msg">{fieldErrors?.cardExDate}</div>
          </div>
          <div className="flexbox corners">
            <Select
              placeholder="Month"
              className="cc-date-month"
              dropdownClassName="cc-expirydate"
              onChange={(e) => {
                onMonthChange(e);
              }}
              onBlur={() => {
                dateInputBlur();
              }}
            >
              {months &&
                months.map((month) => (
                  <Select.Option key={month} value={month} className="cc-date-value">
                    {month}
                  </Select.Option>
                ))}
            </Select>
            <Select
              placeholder="Year"
              className="cc-date-year"
              dropdownClassName="cc-expirydate"
              onChange={(e) => {
                onYearChange(e);
              }}
              onBlur={() => {
                dateInputBlur();
              }}
            >
              {years &&
                years.map((year) => (
                  <Select.Option key={year} value={year} className="cc-date-value">
                    {year}
                  </Select.Option>
                ))}
            </Select>
          </div>
        </div>
        <div
          className={`input-container no-margin cvv
            ${fieldErrors?.cvv ? 'warning' : ''}`}
        >
          <div className="flexbox colls start">
            <label>cvv*</label>
            <div className="input-err-msg">{fieldErrors?.cvv}</div>
          </div>
          <Input
            value={cardCVV}
            placeholder="CVV"
            name="cvv"
            onChange={(e) => {
              handleFormInputChange('cvv', e);
            }}
          />
        </div>
      </div>
    </>
  );

  const renderCardDetails = () => (
    <>
      {cardInfo && (
        <div className="flexbox colls start card-wrapper">
          <div className="cardholder-details">
            <span className="bold">{cardInfo?.name}</span>
            &nbsp;ending with <span className="bold">{cardInfo?.last4}</span>
          </div>
          <div className="flexbox card-details">
            <img src={cardLogo[cardInfo?.scheme]} className="card-type-icon" alt="" />
            <div>
              <span className="uppercase">{cardInfo?.scheme}</span> expires on{' '}
              <span className="bold">
                {cardInfo?.expiry_month}/{cardInfo?.expiry_year}
              </span>
            </div>
          </div>
          <div className="flexbox btn-wrapper">
            <button
              type="button"
              className="billing-btn flexbox"
              onClick={() => {
                onEditCard();
              }}
            >
              <img src={EditIcon} alt="" />
              <div className="btn-text">Edit</div>
            </button>
            <button
              type="button"
              className="billing-btn spacing flexbox"
              onClick={() => {
                onDeleteCard();
              }}
            >
              <img src={DeleteIcon} alt="" />
              <div className="btn-text">Delete</div>
            </button>
          </div>
        </div>
      )}
    </>
  );

  return (
    <>
      <div className="billing-info-title">Credit Card Details</div>
      {cardInfo && Object.keys(cardInfo).length > 0 ? renderCardDetails() : renderCCForm()}
    </>
  );
};

export default PaymentForm;
