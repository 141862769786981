import { PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { AxiosSingleton } from '../../utils/axiosinstance';
import { customAudiencesTableColumns } from './tableColumns';
import AdsTable from '../adstable';
import './styles.scss';
import CustomAudiencesModal from './CustomAudiencesModal';
import AnghamiAdsFullPageLoader from '../core/AnghamiAdsFullPageLoader';

export default function CustomAudiences() {
  const axios = AxiosSingleton.axiosInstance;
  const [brandStudies, setBrandStudies] = useState([]);

  const [isModalToggled, setIsModalToggled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalMode, setModalMode] = useState<'create' | 'edit'>('create');

  const [selectedTableKeys, setSelectedTableKeys] = useState<any[]>([]);
  const [selectedTableRows, setSelectedTableRows] = useState<any[]>([]);

  const openModal = (mode) => {
    setIsModalToggled(true);
    setModalMode(mode);
  };

  const closeModal = () => {
    setIsModalToggled(false);
  };

  useEffect(() => {
    axios.get('/audiences/custom').then((res) => {
      const { data } = res;
      const formattedStudies = data.map((study) => ({
        ...study,
        key: study.id
      }));
      setBrandStudies(formattedStudies);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <AnghamiAdsFullPageLoader />;
  }

  return (
    <div className="custom-audiences-container">
      <div className="custom-audiences-header">
        <div className="custom-audiences-header-actions">
          <div className="custom-audiences-header-heading">Audience Match</div>
          <button
            type="button"
            className="custom-audiences-header-button create-btn"
            onClick={() => {
              openModal('create');
            }}
          >
            <PlusOutlined />
            CREATE
          </button>
          <button
            type="button"
            className={`custom-audiences-header-button ${
              selectedTableKeys.length !== 1 ? 'disabled' : ''
            }`}
            onClick={() => {
              openModal('edit');
            }}
          >
            EDIT
          </button>
        </div>
      </div>
      {brandStudies.length > 0 ? (
        <AdsTable
          data={brandStudies}
          tableColumns={customAudiencesTableColumns}
          onFetch={() => null}
          isLoading={brandStudies.length === 0}
          isInfiniteLoading={false}
          rowSelection
          selectedIDs={selectedTableKeys}
          onChange={(selectedKeys, selectedRows) => {
            setSelectedTableKeys(selectedKeys);
            setSelectedTableRows(selectedRows);
          }}
        />
      ) : null}

      <Modal
        centered
        visible={isModalToggled}
        footer={null}
        closable
        width="38rem"
        onCancel={closeModal}
        destroyOnClose
      >
        <CustomAudiencesModal
          mode={modalMode}
          selectedAudience={selectedTableRows.length === 1 ? selectedTableRows[0] : null}
          closeModal={closeModal}
        />
      </Modal>
    </div>
  );
}
