import React from 'react';
import { Tooltip } from 'antd';
import './styles.scss';

type Props = {
  countries: string;
};

const CountriesCell = ({ countries }: Props) => {
  const countriesArray: string[] = countries ? countries.split(',') : ['All countries'];
  const showTooltip = countriesArray.length > 7;

  return (
    <div className="CountriesCell">
      <Tooltip
        overlay={
          showTooltip
            ? countriesArray.slice(6, countriesArray.length).map((country) => (
                <span key={country} className="CountriesCell-tag">
                  {country}
                </span>
              ))
            : null
        }
        overlayClassName="CountriesCell-tooltip"
      >
        {countriesArray.slice(0, 6).map((country, i) => (
          <span key={country} className="CountriesCell-tag">
              {countriesArray[i] == 'All countries' ? (
                  countriesArray[i]
              ) : (
                  <>
                  <img width={25} height={25} src={ 'https://flagicons.lipis.dev/flags/4x3/' + countriesArray[i].toLowerCase() + '.svg'}/> {countriesArray[i]}
                  </>
              )}
          </span>
        ))}
        {showTooltip ? (
          <span className="CountriesCell-tag">+{countriesArray.length - 6}</span>
        ) : null}
      </Tooltip>
    </div>
  );
};

export default CountriesCell;
