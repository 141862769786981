import React from 'react';
import FillRate from '../fillrate';
import { IImpressions } from '../overview';

type Props = {
  impressions: IImpressions;
};

const impressionDates = {
  top_last7days: 'last 7 days',
  top_last14days: 'last 14 days'
};

const OverviewImpressions = ({ impressions }: Props) => (
  <>
    {impressions?.fillrate && (
      <FillRate
        data={impressions.fillrate?.top_last7days}
        date={impressionDates.top_last7days}
        totalrate={impressions.fillrate?.total_last7days}
        diffrate={impressions.fillrate?.diff_last7days}
      />
    )}
  </>
);

export default OverviewImpressions;
