import { LeftOutlined } from '@ant-design/icons';
import React, { useContext } from 'react';
import { TusClientProvider } from 'use-tus';
import { CampaignAmplitudeEvents } from '../../enums/campaignamplitudeevents';
import { logAmplitudeEvent } from '../../utils/analytics';
import { message } from '../../utils/custommessage';
import AdSetCreativeSubsection from '../adsetcreativesubsection';
import { IAdSet } from '../campaignhoc';
import { campaignContext } from '../campaignhoc/campaign.context';
import './styles.scss';

type Props = {
  adSet: IAdSet;
};

export default function AdSetCreative({ adSet }: Props) {
  const {
    mode,
    addEmptyAdSet,
    addEmptyCreative,
    groupCampaignObjective,
    navigationData,
    setNavigationData,
    adSets,
    updateLeadGenForm
  } = useContext(campaignContext);

  const validateAllCreativesInAdSetAndNotifyUser: () => boolean = () => {
    const { creatives, adSetType } = adSet;

    let isValid = true;

    if (adSetType === 'song') {
      creatives.forEach((creative) => {
        if (!creative.creativeName) {
          isValid = false;
          message.error('Missing creative name');
        }

        if (!creative.creativeSongId) {
          isValid = false;
          message.error('Missing song');
        }

        if (!creative.creativeReasonText) {
          isValid = false;
          message.error('Missing reason');
        }
      });
    } else {
      creatives.forEach((creative) => {
        if (!creative.creativeName) {
          isValid = false;
          message.error('Missing creative name');
        }

        if (!creative.creativeLinkEn && groupCampaignObjective !== 'lead') {
          isValid = false;
          message.error('Missing CTA URL');
        }

        if (!creative.creativeImageEn) {
          isValid = false;
          message.error('Missing image');
        }

        if (!creative.creativeTitleEn && adSetType !== 'display') {
          isValid = false;
          message.error('Missing post roll text');
        }

        if (adSetType === 'audio' && !creative.creativeAudioEn) {
          isValid = false;
          message.error('Missing audio');
        }

        if (adSetType === 'video' && !creative.creativeVideoEn) {
          isValid = false;
          message.error('Missing video');
        }
      });
    }

    return isValid;
  };

  const handleNext = () => {
    if (mode !== 'preview') {
      const isValid = validateAllCreativesInAdSetAndNotifyUser();

      if (!isValid) {
        return;
      }
    }

    if (groupCampaignObjective === 'lead') {
      updateLeadGenForm(adSet, false);
    }

    if (adSet.id === adSets[adSets.length - 1].id) {
      logAmplitudeEvent(CampaignAmplitudeEvents.CreateCampaignProceed, {
        new: window.location.href.indexOf('create') > -1 ? 1 : 0,
        campaign_objective: groupCampaignObjective,
        campaign_type: adSet.adSetType,
        step: 'review',
        objectid: adSet.adSetId
      });

      setNavigationData({
        navigationTopLevel: 'review',
        navigationAdSetActiveId: 0,
        navigationAdSetSublevel: 'setup'
      });
    } else {
      let currentIndex = 0;
      adSets.forEach((set, index) => {
        if (set.id === adSet.id) {
          currentIndex = index;
        }
      });

      logAmplitudeEvent(CampaignAmplitudeEvents.CreateCampaignProceed, {
        new: window.location.href.indexOf('create') > -1 ? 1 : 0,
        campaign_objective: groupCampaignObjective,
        campaign_type: adSet.adSetType,
        step: 'setup',
        objectid: adSet.adSetId
      });
      setNavigationData({
        ...navigationData,
        navigationAdSetActiveId: adSets[currentIndex + 1].id,
        navigationAdSetSublevel: 'setup'
      });
    }
  };

  return (
    <>
      <div className="campaign-container">
        <div className="campaign-container-header">
          <button
            type="button"
            className="campaign-container-header-back-btn"
            onClick={() => {
              logAmplitudeEvent(CampaignAmplitudeEvents.CreateCampaignProceed, {
                new: window.location.href.indexOf('create') > -1 ? 1 : 0,
                campaign_objective: groupCampaignObjective,
                campaign_type: adSet.adSetType,
                step: 'targeting',
                objectid: adSet.adSetId
              });

              setNavigationData({
                ...navigationData,
                navigationAdSetSublevel: 'targeting'
              });
            }}
          >
            <LeftOutlined />
          </button>
          <div className="campaign-container-header-title">Create Ad Set Creatives</div>
          <button className="campaign-container-header-btn" type="button" onClick={handleNext}>
            Next
          </button>
        </div>
        {adSet.creatives.map((creative, index) => (
          <div key={creative.id}>
            <TusClientProvider>
              <AdSetCreativeSubsection
                campaignType={groupCampaignObjective}
                adType={adSet.adSetType}
                creative={creative}
                adSet={adSet}
                isFirstCreative={index === 0}
                isUniqueCreative={adSet.creatives.length === 1}
              />
            </TusClientProvider>
          </div>
        ))}
      </div>
      <div
        className={`creative-btns-container ${
          mode === 'preview' ? 'campaign-form-preview-mask' : ''
        }`}
      >
        <button
          type="button"
          className="add-creative-btn"
          onClick={() => {
            addEmptyCreative({ adSetId: adSet.id });
          }}
        >
          + ADD MORE CREATIVE
        </button>
        <button type="button" className="add-adset-btn" onClick={addEmptyAdSet}>
          ADD A NEW AD SET
        </button>
      </div>
    </>
  );
}
