const environment = {
  prod_api_endpoint: 'https://aam.anghami.com/rest',
  staging_api_endpoint: 'https://ads-staging.anghami.com/rest',
  amplitude_public_key: '540d0705d2690a3496a883e5aea3144d',
  raygun_public_key: 'qyfHfABj7noJJoZaZMfmYA',
  googleanalytics_public_key: 'G-JG1SXF65GT',
  checkout_public_key:'pk_6p3sfr2vgh75a73b3jnxvvzszy*',
  checkout_url: 'https://api.checkout.com/tokens',
  checkout_sandbox_public_key: 'pk_sbox_yz3dpfy3xrsqejjr2zts6hxylax',
  checkout_sandbox_url: 'https://api.sandbox.checkout.com/tokens',
  cdn_url: 'https://web.anghcdn.co/',
  ads_cdn_url: 'https://anghnewads.anghcdn.co/',
  getBaseAPIUrl: function () {
    if (
      process.env.NODE_ENV === 'development' ||
      (typeof window !== 'undefined' && window.location.href.indexOf('angha.me') > -1)
    ) {
      return this.staging_api_endpoint;
    }
    return this.prod_api_endpoint;
  }
};

export default environment;
