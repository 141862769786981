import React, { useContext, useState } from 'react';
import { Modal } from 'antd';
import './styles.scss';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import AnghamiAdsBetaLogo from '../../public/assets/images/Ads-Logo-Dark.png';
import { campaignContext } from '../campaignhoc/campaign.context';
import WarningModal from '../warningmodal';
import { CampaignTopLevelNavigation, AdSetNavigation } from '../../enums/campaignnavigation';
import useAuth from '../../hooks/useAuth';
import { PublisherRoles } from '../../enums/publisherroles';

const WARNER_PUBLISHER_ROLE_ID = 28;

type Props = {
  ads: any[];
};

export default function CampaignSidebar({ ads }: Props) {
  const {
    mode,
    groupCampaignId,
    groupCampaignName,
    addEmptyAdSet,
    removeAdSetById,
    navigationData,
    setNavigationData
  } = useContext(campaignContext);

  const navigate = useNavigate();

  const { user, role } = useAuth();

  const isAnghamiOrDms =
    role === PublisherRoles.DMSAdmin ||
    role === PublisherRoles.DMSPublisher ||
    role === PublisherRoles.DMSTrafficker ||
    role === PublisherRoles.DMSAdvertiser ||
    role === PublisherRoles.Anghami ||
    role === PublisherRoles.AnghamiAdvertiser;

  const [showWarningModal, setShowWarningModal] = useState(false);
  const [showDeleteAdSetModal, setShowDeleteAdsetModal] = useState<boolean>(false);

  const truncate = (str, maxLen) => {
    if (!str) {
      return '';
    }
    return `${str.substring(0, maxLen)}${str.length < maxLen ? '' : '...'}`;
  };

  return (
    <div className="campaign-sidebar">
      <div className="sidebar-logo-container">
        <img src={AnghamiAdsBetaLogo} alt="logo" />
      </div>
      <div
        className={`section-title ${
          navigationData.navigationTopLevel === CampaignTopLevelNavigation.Setup
            ? 'activated-section-title'
            : ''
        }`}
        onClick={() => {
          setNavigationData({
            navigationTopLevel: CampaignTopLevelNavigation.Setup,
            navigationAdSetActiveId: 0,
            navigationAdSetSublevel: AdSetNavigation.Setup
          });
        }}
      >
        Campaign Overview
      </div>
      <div
        className={`section-subtitle ${
          navigationData.navigationTopLevel === CampaignTopLevelNavigation.Setup
            ? 'activated-section-subtitle'
            : ''
        }`}
        onClick={() => {
          setNavigationData({
            navigationTopLevel: CampaignTopLevelNavigation.Setup,
            navigationAdSetActiveId: 0,
            navigationAdSetSublevel: AdSetNavigation.Setup
          });
        }}
      >
        {truncate(groupCampaignName, 18) || 'Name Of The Campaign Here'}
        <div className="section-subtitle-underline" />
      </div>
      {ads.map((ad) => (
        <div key={ad.id}>
          <div
            className={`section-title ${groupCampaignId ? '' : 'op0pt5'} ${
              navigationData.navigationTopLevel === CampaignTopLevelNavigation.AdSets &&
              navigationData.navigationAdSetSublevel === AdSetNavigation.Setup &&
              navigationData.navigationAdSetActiveId === ad.id
                ? 'activated-section-title'
                : ''
            }`}
            onClick={() => {
              if (!groupCampaignId) {
                return;
              }

              setNavigationData({
                navigationTopLevel: CampaignTopLevelNavigation.AdSets,
                navigationAdSetActiveId: ad.id,
                navigationAdSetSublevel: AdSetNavigation.Setup
              });
            }}
          >
            <div className="arrow-down" />
            Ad Set{' '}
            {ads.length > 1 && mode !== 'preview' && (
              <DeleteOutlined
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowDeleteAdsetModal(true);
                }}
              />
            )}
          </div>
          <Modal
            centered
            visible={showDeleteAdSetModal}
            footer={null}
            closable={false}
            width="26rem"
          >
            <WarningModal
              onOk={async () => {
                await removeAdSetById(ad.id);
                setShowDeleteAdsetModal(false);
              }}
              onCancel={() => {
                setShowDeleteAdsetModal(false);
              }}
              titleText="Delete"
              subtitleText="Are you sure you want to delete this item?"
              okButtonText="Yes"
              cancelButtonText="Cancel"
            />
          </Modal>
          <div className="ad-set-navigation-subsections">
            <div
              className={`section-subtitle pl-3pt3rem ${
                navigationData.navigationTopLevel === CampaignTopLevelNavigation.AdSets &&
                navigationData.navigationAdSetSublevel === AdSetNavigation.Setup &&
                navigationData.navigationAdSetActiveId === ad.id
                  ? 'activated-section-subtitle'
                  : ''
              }`}
              onClick={() => {
                if (!groupCampaignId) {
                  return;
                }

                setNavigationData({
                  navigationTopLevel: CampaignTopLevelNavigation.AdSets,
                  navigationAdSetActiveId: ad.id,
                  navigationAdSetSublevel: AdSetNavigation.Setup
                });
              }}
            >
              {truncate(ad.adSetName, 18) || 'Ad Set Name Here'}
              <div className="section-subtitle-underline" />
            </div>
            <div
              className={`section-title pl-3pt3rem ${
                ad.adSetName &&
                (ad.adSetPriority || user?.role_id === WARNER_PUBLISHER_ROLE_ID) &&
                ad.adSetType &&
                (ad.adSetBudget || isAnghamiOrDms || user?.role_id === WARNER_PUBLISHER_ROLE_ID)
                  ? ''
                  : 'op0pt5'
              } ${
                navigationData.navigationTopLevel === CampaignTopLevelNavigation.AdSets &&
                navigationData.navigationAdSetSublevel === AdSetNavigation.Targeting &&
                navigationData.navigationAdSetActiveId === ad.id
                  ? 'activated-section-title'
                  : ''
              }`}
              onClick={() => {
                const isBudgetTooLow =
                  !ad.adSetBudget || ad.adSetBudget < 100 || /[^\d]/.test(String(ad.adSetBudget));
                if (
                  isBudgetTooLow &&
                  !isAnghamiOrDms &&
                  user?.role_id !== WARNER_PUBLISHER_ROLE_ID
                ) {
                  return;
                }

                if (
                  !ad.adSetName ||
                  (!ad.adSetPriority && user?.role_id !== WARNER_PUBLISHER_ROLE_ID) ||
                  !ad.adSetType
                ) {
                  return;
                }

                setNavigationData({
                  navigationTopLevel: CampaignTopLevelNavigation.AdSets,
                  navigationAdSetActiveId: ad.id,
                  navigationAdSetSublevel: AdSetNavigation.Targeting
                });
              }}
            >
              Targeting
            </div>
            <div
              className={`section-title pl-3pt3rem ${ad.adSetId ? '' : 'op0pt5'}  ${
                navigationData.navigationTopLevel === CampaignTopLevelNavigation.AdSets &&
                navigationData.navigationAdSetSublevel === AdSetNavigation.Creative &&
                navigationData.navigationAdSetActiveId === ad.id
                  ? 'activated-section-title'
                  : ''
              }`}
              onClick={() => {
                if (!ad.adSetId) {
                  return;
                }

                setNavigationData({
                  navigationTopLevel: CampaignTopLevelNavigation.AdSets,
                  navigationAdSetActiveId: ad.id,
                  navigationAdSetSublevel: AdSetNavigation.Creative,
                  navigationCreativeActiveId: 0
                });
              }}
            >
              Creatives
            </div>
            {ad.creatives.map((creative) => (
              <div
                key={creative.id}
                className={`section-subtitle pl-3pt3rem ${
                  navigationData.navigationTopLevel === CampaignTopLevelNavigation.AdSets &&
                  navigationData.navigationAdSetSublevel === AdSetNavigation.Creative &&
                  navigationData.navigationAdSetActiveId === ad.id &&
                  navigationData.navigationCreativeActiveId === creative.id
                    ? 'activated-section-subtitle'
                    : ''
                }`}
                onClick={() => {
                  if (!ad.adSetId) {
                    return;
                  }

                  setNavigationData({
                    navigationTopLevel: CampaignTopLevelNavigation.AdSets,
                    navigationAdSetActiveId: ad.id,
                    navigationAdSetSublevel: AdSetNavigation.Creative,
                    navigationCreativeActiveId: creative.id
                  });

                  window.location.href = `${window.location.href.split('#')[0]}#${creative.id}`;
                  setTimeout(() => {
                    window.history.pushState(
                      '',
                      document.title,
                      window.location.pathname + window.location.search
                    );
                  }, 1000);
                }}
              >
                {truncate(creative.creativeName, 18) || 'Creative Name Here'}
                <div className="section-subtitle-underline" />
              </div>
            ))}
          </div>
        </div>
      ))}
      <div
        onClick={() => {
          addEmptyAdSet();
        }}
        className={`add-ad-setup ${mode === 'preview' ? 'campaign-form-preview-mask' : ''}`}
      >
        New Ad Setup <PlusOutlined />
      </div>
      <div
        className={`section-title ${
          navigationData.navigationTopLevel === CampaignTopLevelNavigation.Review
            ? 'activated-section-title'
            : ''
        }`}
        onClick={() => {
          setNavigationData({
            navigationTopLevel: CampaignTopLevelNavigation.Review,
            navigationAdSetActiveId: 0,
            navigationAdSetSublevel: AdSetNavigation.Setup
          });
        }}
      >
        Review Campaign
      </div>
      <button
        type="button"
        className="campaign-close-btn"
        onClick={() => {
          setShowWarningModal(true);
        }}
      >
        Close
      </button>
      <Modal centered visible={showWarningModal} footer={null} closable={false} width="26rem">
        <WarningModal
          onOk={() => {
            navigate('/manage-campaigns');
          }}
          onCancel={() => {
            setShowWarningModal(false);
          }}
          titleText="Are you sure you want to close?"
          subtitleText="Some of the progress might be lost"
          okButtonText="Close"
          cancelButtonText="Cancel"
        />
      </Modal>
    </div>
  );
}
