import React from 'react';
import { formatNumber } from '../../helpers/format-numbers';
import './styles.scss';

type Props = {
  targetImpressions: any;
  impressions: any;
  paceProgress: any;
};

const calculateBlackBarLocation = (paceProgress: any) => {
  if (!paceProgress) {
    return 0;
  }

  return paceProgress < 100 ? paceProgress : 100;
};

const calculatePercentage = (totalImpressions: any, targetImpressions: any) => {
  const percentage = Math.round((totalImpressions / targetImpressions) * 10000) / 100;
  return percentage < 100 ? percentage : 100;
};

const calculateSliderThumbBackground = (percentage: any, paceProgress: any) => {
  const dangerColour = '#c0392b';
  const warningColour = '#f39c12';
  const inprogressColour = '#ffd52e';
  const completeColour = '#27ae60';

  if (Number(paceProgress) <= 0) {
    return inprogressColour;
  }

  if (Number(percentage) / Number(paceProgress) < 0.8) {
    return dangerColour;
  }

  if (Number(percentage) / Number(paceProgress) < 0.9) {
    return warningColour;
  }

  return completeColour;
};

const ImpressionsCell = ({ targetImpressions, impressions, paceProgress }: Props) => {
  if (!targetImpressions) {
    return <span>-</span>;
  }

  if (targetImpressions && !impressions) {
    return (
      <div className="ImpressionsCell">
        <div className="ImpressionsCell-is">{formatNumber(Number(targetImpressions))}</div>
        <div className="ImpressionsCell-of"> impressions served</div>
      </div>
    );
  }

  const blackBarLocation = calculateBlackBarLocation(paceProgress);
  const percentage = calculatePercentage(impressions, targetImpressions);
  const sliderThumbBackground = calculateSliderThumbBackground(percentage, paceProgress);

  return (
    <div className="ImpressionsCell">
      <div className="ImpressionsCell-is">{formatNumber(Number(impressions)) || '0'}</div>
      <div className="ImpressionsCell-of">
        out of {formatNumber(Number(targetImpressions)) || 0}
      </div>
      {paceProgress !== undefined ? (
        <div className="ImpressionsCell-slider">
          <div
            className="ImpressionsCell-slider-thumb"
            style={{
              width: `${percentage}%`,
              backgroundColor: sliderThumbBackground
            }}
          />
          {Boolean(blackBarLocation) && (
            <div
              className="ImpressionsCell-slider-black-bar"
              style={{ left: `${blackBarLocation}%` }}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ImpressionsCell;
