import React, { useEffect, useState } from 'react';
import { AxiosSingleton } from '../../utils/axiosinstance';
import { message } from '../../utils/custommessage';
import { AnghamiAdsFormInput } from '../core/AnghamiAdsFormInput';
import { AnghamiAdsFormSelector } from '../core/AnghamiAdsFormSelector';
import './styles.scss';

type Props = {
  mode: 'edit' | 'create';
  user: {
    id: string | number;
    name: string;
    email: string;
    publisher_id: string;
    role_id: string;
    password: string;
  } | null;
  closeModal: () => void;
};

const ManageUsers = ({ mode, user, closeModal }: Props) => {
  const axios = AxiosSingleton.axiosInstance;

  const [rolesOptions, setRolesOptions] = useState([]);
  const [publishersOptions, setPublishersOptions] = useState([]);

  const [name, setName] = useState(user?.name || '');
  const [email, setEmail] = useState(user?.email || '');
  const [publisherId, setPublisherId] = useState(user?.publisher_id || '');
  const [roleId, setRoleId] = useState(user?.role_id || '');
  const [password, setPassword] = useState(
    mode === 'edit' ? user?.password : Math.random().toString(36).slice(-8)
  );

  const save = () => {
    const endpoint = mode === 'edit' ? '/admin/users/update' : '/admin/users/store';
    const params = {
      ...user,
      name,
      email,
      publisher_id: publisherId,
      role_id: roleId,
      password
    };

    axios
      .post(endpoint, params)
      .then((res) => {
        const { data } = res;
        if (data.message === 'success') {
          message.success(`User successfully ${mode === 'create' ? 'created' : 'updated'}`);
          closeModal();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.errors) {
          err.response.data.errors.forEach((error) => {
            message.error(error);
          });
        } else {
          message.error('An error occurred. Please try again later.');
        }
      });
  };

  const fetchRoles = () => {
    axios.get('/admin/roles/all').then((res) => {
      const { data } = res;
      setRolesOptions(
        data.map((role) => ({
          id: role.id,
          text: role.name
        }))
      );
    });
  };

  const fetchPublishers = () => {
    axios.get('/admin/publishers/all').then((res) => {
      const { data } = res;
      setPublishersOptions(
        data.map((publisher) => ({
          id: publisher.id,
          text: publisher.name
        }))
      );
    });
  };

  useEffect(() => {
    fetchPublishers();
    fetchRoles();
  }, []);

  return (
    <div className="manage-users-modal">
      <div style={{ marginTop: '1rem' }}>
        <AnghamiAdsFormInput
          label="Full Name"
          placeholder="Enter user's full name"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div style={{ marginTop: '1rem' }}>
        <AnghamiAdsFormInput
          label="Email"
          placeholder="Enter user's email"
          disabled={mode === 'edit'}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </div>
      <div style={{ marginTop: '1rem' }}>
        <AnghamiAdsFormSelector
          label="Publisher"
          disabled={mode === 'edit'}
          value={publisherId || undefined}
          options={publishersOptions}
          placeholder="Select publisher"
          onChange={(value: any) => {
            setPublisherId(value);
          }}
        />
      </div>
      <div style={{ marginTop: '1rem' }}>
        <AnghamiAdsFormSelector
          label="Role"
          value={roleId || undefined}
          placeholder="Select role"
          options={rolesOptions}
          onChange={(value: any) => {
            setRoleId(value);
          }}
        />
      </div>
      {mode === 'create' && (
        <div style={{ marginTop: '1rem' }}>
          <AnghamiAdsFormInput
            label="Password (Auto-generated)"
            value={password}
            placeholder="Enter user's password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
      )}
      <button type="button" style={{ marginTop: '1rem' }} onClick={save}>
        Save
      </button>
    </div>
  );
};

export default ManageUsers;
