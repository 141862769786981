import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import '../overview/styles.scss';
import './styles.scss';
import ArrowUp from '../../public/assets/images/arrows/arrow-up.png';
import ArrowDown from '../../public/assets/images/arrows/arrow-down.png';
import FillRateCountry from '../fillrate-country';

type Props = {
  totalrate: number;
  diffrate: number;
  date: string;
  data: any[];
};

const FillRate = ({ totalrate, diffrate, date, data }: Props) => {
  const [countryRows, setCountryRows] = useState<any[]>([]);
  const initializeRows = () => {
    const arr = new Array<number>(Math.ceil(data.length / 3));
    setCountryRows(arr);
  };

  useEffect(() => {
    if (data && countryRows.length === 0) {
      initializeRows();
    }

    window.addEventListener('resize', initializeRows);

    return () => {
      window.removeEventListener('resize', initializeRows);
    };
  }, [countryRows]);

  return (
    <div className="Overview-grid FillRate">
      <Row gutter={[32, 32]} align="bottom">
        <Col>
          <div className="Overview-grid-header">Fill Rate</div>
        </Col>
        <Col>
          <div className="FillRate-header-date">{date}</div>
        </Col>
      </Row>
      <Row gutter={[32, 32]} className="FillRate-header-spacing">
        <Col span={13}>
          <div className="FillRate-header-title">Per Top Countries</div>
        </Col>
        <Col span={11}>
          <div className="FillRate-header-title">total fill rate</div>
        </Col>
      </Row>
      <Row align="top" justify="space-between">
        <Col span={12} className="FillRate-country-wrapper">
          <Row align="top" gutter={[0, 32]}>
            {data.map((elt, index) => (
              <FillRateCountry
                rowIndex={Math.floor(index / 3)}
                minHeight={countryRows[Math.floor(index / 3)] || 2}
                data={elt}
                key={elt.country}
                sendHeight={(evt) => {
                  if (!countryRows[evt.index] || countryRows[evt.index] < evt.height) {
                    countryRows[evt.index] = evt.height + 0.7;
                  }
                  const updatedrows = JSON.parse(JSON.stringify(countryRows));
                  setCountryRows(updatedrows);
                }}
              />
            ))}
          </Row>
        </Col>
        <Col span={4} offset={1} className="FillRate-total-fillrate">
          <Row align="middle">
            <Col>
              <div className="FillRate-diff-value">{totalrate}%</div>
            </Col>
            <Col>
              <img
                src={Number(diffrate) < 0 ? ArrowDown : ArrowUp}
                className="FillRate-arrow-img"
              />
            </Col>
            <Col>
              <div
                style={{
                  color: Number(diffrate) < 0 ? '#D03150' : '#52D57D',
                  fontSize: '1.2rem'
                }}
              >
                {Number(Math.abs(diffrate)).toFixed(2)}
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={6} />
      </Row>
    </div>
  );
};

export default FillRate;
