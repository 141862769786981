export enum PublisherRoles {
  Unknown = 'unknown',
  Anghami = 'anghami',
  MediaAlgeria = 'media_algeria',
  DMSPublisher = 'dms',
  DirectClient = 'direct_client',
  AnghamiAdvertiser = 'anghami_advertiser',
  DMSAdvertiser = 'dms_advertiser',
  MediaPartner = 'media_partner',
  DMS_CRM = 'dms_crm',
  DMSTrafficker = 'dms_trafficker',
  DMSAdmin = 'dms_admin',
  DMSExternalCampaignResearch = 'dms_external_campaign_research',
  DMSExternalCampaignReporting = 'dms_external_campaign_reporting',
  WarnerPublisher = 'warner_publisher'
}
