import React from 'react';
import { CreativesStatusTypes } from '../../enums';
import notActiveImg from '../../public/assets/images/invalid.png';
import activeImg from '../../public/assets/images/Approve.svg';

import './styles.scss';

type Props = {
  status: CreativesStatusTypes;
};

const CreativeStatusCell = ({ status }: Props) => {
  let renderedComponent = <span>{status}</span>;

  switch (status) {
    case CreativesStatusTypes.active:
      renderedComponent = (
        <span className="StatusCell-status running">
          Active
          <img src={activeImg} />
        </span>
      );
      break;

    case CreativesStatusTypes.notactive:
      renderedComponent = (
        <span className="StatusCell-status error">
          Not Active
          <img src={notActiveImg} />
        </span>
      );
      break;

    default:
      renderedComponent = <span className="StatusCell-status">-</span>;
  }

  return renderedComponent;
};

export default CreativeStatusCell;
