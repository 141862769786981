import { Radio } from 'antd';
import React from 'react';
import './styles.scss';

type RadioOptionType = {
  id: string | number;
  text: string | number;
};

interface AnghamiAdsFormRadioProps {
  label: string;
  value?: string | number | null;
  options: RadioOptionType[];
  disabled?: boolean;
  onChange?: (value: any) => void;
}

export const AnghamiAdsFormRadio = ({
                                         label,
                                         options,
                                         value,
                                         disabled,
                                         onChange,
                                       }: AnghamiAdsFormRadioProps) => (
  <div className="anghami-ads-form-radio">
    <label className="label">{label}</label>
    <Radio.Group
      value={value}
      onChange={(e) => onChange?.(e.target.value)}
      disabled={disabled}
    >
      {options.map((option: RadioOptionType) => (
        <Radio value={option.id} key={option.id}>
          {option.text}
        </Radio>
      ))}
    </Radio.Group>
  </div>
);
