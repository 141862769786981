import { PlusOutlined } from '@ant-design/icons';
import { Col, Modal, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosSingleton } from '../../utils/axiosinstance';
import PageHeader from '../pageheader';
import { countries, statusOptions, currencyOptions } from './selectorOptions';
import { message } from '../../utils/custommessage';
import './styles.scss';
import { SubBudget } from './SubBudget';
import { SubBudgetPreview } from './SubBudgetPreview';
import SpinningCircle from '../spinningcircle';
import { BudgetDocumentUploader } from './BudgetDocumentUploader';
import { AnghamiAdsFormInput } from '../core/AnghamiAdsFormInput';

interface ISubBudget {
  id?: string | number;
  type: string;
  campaign_id: string | number;
  text: string;
  amount: string | number;
  status: number;
}
interface BudgetRequest {
  id?: string | number;
  name: string;
  budget: string | number;
  cost: string | number;
  booking_order_number: string | number;
  currency: string;
  start_date: string;
  end_date: string;
  created_by: string;
  office: string;
  country: string[];
  status: number;
  docs: string[];
  subbudgets: ISubBudget[];
}

type Props = {
  mode: 'edit' | 'create';
};

export default function Budget({ mode }: Props) {
  const axios = AxiosSingleton.axiosInstance;
  const navigate = useNavigate();

  const { id } = useParams();

  const [subBudgetTypeOptions, setSubBudgetTypeOptions] = useState([]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [officeOptions, setOfficeOptions] = useState([]);

  const [name, setName] = useState('');
  const [budget, setBudget] = useState<undefined | string | number>();
  const [cost, setCost] = useState<undefined | string | number>();
  const [bookingOrderNumber, setBookingOrderNumber] = useState<undefined | string | number>();
  const [currency, setCurrency] = useState(currencyOptions[0].id);
  const [country, setCountry] = useState([countries[0].id]);
  const [startDate, setStartDate] = useState('');
  const [status, setStatus] = useState<undefined | number>();
  const [endDate, setEndDate] = useState('');
  const [createdBy, setCreatedBy] = useState('');
  const [office, setOffice] = useState<undefined | number>();
  const [isSaving, setIsSaving] = useState(false);
  const [docs, setDocs] = useState<string[]>([]);

  const [selectedSubBudgetForEditing, setSelectedSubBudgetForEditing] = useState<undefined | any>(
    undefined
  );
  const [selectedSubBudgetIndexForEditing, setSelectedSubBudgetIndexForEditing] = useState<
    undefined | any
  >(undefined);

  const [subBudgets, setSubBudgets] = useState<any[]>([]);

  const [isSubBudgetModalOpen, setIsSubBudgetModalOpen] = useState(false);

  useEffect(() => {
    axios.get('/budget/gettypes').then((res) => {
      const { data } = res;
      setSubBudgetTypeOptions(
        data.map((type) => ({
          id: type.id,
          text: type.type
        }))
      );
    });

    axios.get('/budget/getoffices').then(({ data }) => {
      setOfficeOptions(data);
    });

    axios.get('/campaigns/all?simple=1&retargeting=1').then((res) => {
      setCampaignOptions(res.data);
    });

    if (mode === 'edit') {
      axios.get(`/budget/get?id=${id}`).then(({ data }) => {
        if (data?.budget?.length === 0) {
          return;
        }

        const budgetResponse = data?.budget[0];
        setName(budgetResponse.name);
        setBudget(budgetResponse.budget);
        setCost(budgetResponse.cost);
        setBookingOrderNumber(budgetResponse.booking_order_number);
        setCurrency(budgetResponse.currency);
        setCountry(budgetResponse.country);
        setStartDate(budgetResponse.start_date.split(' ')[0]);
        setEndDate(budgetResponse.end_date.split(' ')[0]);
        setCreatedBy(budgetResponse.created_by);
        setOffice(Number(budgetResponse.office));
        setStatus(budgetResponse.status);
        setSubBudgets(
          budgetResponse.sub_budgets?.map((subBudget) => ({
            ...subBudget,
            campaign: subBudget.campaign_id
          })) || []
        );
        setDocs(budgetResponse.files?.map((file) => file.file) || []);
      });
    }
  }, []);

  const saveBudget = () => {
    setIsSaving(true);
    axios
      .post<BudgetRequest>('/budget/save', {
        budget: {
          name,
          budget,
          cost,
          booking_order_number: bookingOrderNumber,
          currency,
          start_date: startDate,
          end_date: endDate,
          created_by: createdBy,
          office,
          country,
          status,
          docs
        },
        subbudgets: subBudgets.map((subBudget) => ({
          type: subBudget.type,
          campaign_id: subBudget.campaign,
          text: subBudget.text,
          amount: subBudget.amount,
          status: subBudget.status
        }))
      })
      .then(() => {
        setIsSaving(false);
        message.success('Budget successfully saved!');
        navigate('/budgets');
      })
      .catch(({ response }) => {
        message.error(response.data.message);
        setIsSaving(false);
      });
  };

  const editBudget = () => {
    setIsSaving(true);
    axios
      .post<BudgetRequest>('/budget/updateAll', {
        budget: {
          id,
          name,
          budget,
          cost,
          booking_order_number: bookingOrderNumber,
          currency,
          start_date: startDate,
          end_date: endDate,
          created_by: createdBy,
          office,
          country,
          status,
          docs
        },
        subbudgets: subBudgets.map((subBudget) => ({
          id: subBudget.id,
          type: subBudget.type,
          campaign_id: subBudget.campaign,
          text: subBudget.text,
          amount: subBudget.amount,
          status: subBudget.status,
          budget_id: id
        }))
      })
      .then(() => {
        setIsSaving(false);
        message.success('Budget successfully updated!');
        navigate('/budgets');
      })
      .catch(({ response }) => {
        message.error(response.data.message);
        setIsSaving(false);
      });
  };

  return (
    <div className="budget-container">
      <div className="budget-page-title">
        <PageHeader title={`${mode} Budget`} />
      </div>
      <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
        <Col span={12}>
          <AnghamiAdsFormInput
            label="Name"
            placeholder="Insert budget name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Col>
        <Col span={12}>
          <div className="campaign-form-input-container">
            <label>Status*</label>
            <Select
              showSearch
              style={{ width: '100%' }}
              bordered={false}
              placeholder="Select status"
              value={status}
              onChange={(value: any) => {
                if (value === -1) return;
                setStatus(value);
              }}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {statusOptions.map((option: any) => (
                <Select.Option disabled={option.id === -1} value={option?.id} key={option?.text}>
                  {option?.text}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
        <Col span={12}>
          <div className="campaign-form-input-container">
            <label>Budget*</label>
            <Row>
              <Col>
                <Select
                  style={{ width: '100%' }}
                  bordered={false}
                  value={currency}
                  onChange={(value: any) => {
                    setCurrency(value);
                  }}
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {currencyOptions.map((option: any) => (
                    <Select.Option value={option.id} key={option.id}>
                      {option.text}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col>
                <input
                  type="text"
                  placeholder="Enter your budget"
                  value={budget}
                  onChange={(e) => {
                    setBudget(e.target.value);
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col span={12}>
          <div className="campaign-form-input-container">
            <label>Cost</label>
            <input
              type="text"
              placeholder="Enter cost"
              value={cost}
              onChange={(e) => {
                setCost(e.target.value);
              }}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
        <Col span={12}>
          <div className="campaign-form-input-container">
            <label>Start Date*</label>
            <input
              type="date"
              placeholder="Insert start date"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
            />
          </div>
        </Col>
        <Col span={12}>
          <div className="campaign-form-input-container">
            <label>End Date*</label>
            <input
              type="date"
              placeholder="Insert start date"
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
            />
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
        <Col span={12}>
          <div className="campaign-form-input-container">
            <label>Office*</label>
            <Select
              style={{ width: '100%' }}
              bordered={false}
              value={office}
              onChange={(value: any) => {
                setOffice(value);
              }}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {officeOptions.map((option: any) => (
                <Select.Option value={option.id} key={option.id}>
                  {option.text}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
        <Col span={12}>
          <AnghamiAdsFormInput
            label="Booking Order Number"
            placeholder="Insert booking order number"
            value={bookingOrderNumber}
            onChange={(e) => {
              setBookingOrderNumber(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
        <Col span={12}>
          <div className="campaign-form-input-container">
            <label>Countries*</label>
            <Select
              showSearch
              mode="multiple"
              style={{ width: '100%' }}
              bordered={false}
              placeholder="Select countries"
              value={country}
              onChange={(value: any) => {
                if (value.indexOf('mena') > -1) {
                  setCountry(
                    countries
                      .filter((countryOption) => countryOption.id !== 'ROW')
                      .map((countryOption) => countryOption.id)
                  );
                  return;
                }
                setCountry(value);
              }}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {countries.map((operator: any) => (
                <Select.Option value={operator?.id} key={operator?.text}>
                  {operator?.text}
                </Select.Option>
              ))}
              <Select.Option value="mena" key="mena">
                MENA
              </Select.Option>
            </Select>
          </div>
        </Col>
        <Col span={12}>
          <AnghamiAdsFormInput
            label="Created By*"
            placeholder="Insert name"
            value={createdBy}
            onChange={(e) => {
              setCreatedBy(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: '1rem' }}>
        <Col span={12}>
          <BudgetDocumentUploader docs={docs} setDocs={setDocs} />
        </Col>
      </Row>
      {subBudgets.length > 0 && (
        <>
          <div className="budget-page-title">
            <PageHeader title="Sub-Budgets" />
          </div>
          <div className="subbudgets-container">
            {subBudgets.map((subBudget, index) => (
              <SubBudgetPreview
                key={subBudget?.id || index}
                data={subBudget}
                subBudgetTypeOptions={subBudgetTypeOptions}
                campaignOptions={campaignOptions}
                editSubBudget={() => {
                  setSelectedSubBudgetForEditing(subBudget);
                  setSelectedSubBudgetIndexForEditing(index);
                  setIsSubBudgetModalOpen(true);
                }}
                removeSubBudget={() => {
                  const newSubBudgets = subBudgets.filter(
                    (_, subBudgetIndex) => subBudgetIndex !== index
                  );
                  setSubBudgets(newSubBudgets);

                  if (subBudget.id) {
                    axios.post('/budget/destroysubbudget', {
                      ids: [subBudget.id]
                    });
                  }
                }}
              />
            ))}
          </div>
        </>
      )}
      <div className="budget-btn-container">
        <button
          type="button"
          className="subbudget-addition-btn"
          onClick={() => {
            setIsSubBudgetModalOpen(true);
          }}
        >
          <PlusOutlined />
          Add Sub-Budget
        </button>
        <button
          type="button"
          className="subbudget-save-btn"
          onClick={() => {
            if (mode === 'create') {
              saveBudget();
            } else {
              editBudget();
            }
          }}
        >
          {isSaving ? <SpinningCircle /> : 'Save'}
        </button>
      </div>
      <Modal
        visible={isSubBudgetModalOpen}
        closable
        maskClosable
        footer={null}
        destroyOnClose
        onCancel={() => {
          setIsSubBudgetModalOpen(false);
        }}
      >
        <SubBudget
          subBudgetTypeOptions={subBudgetTypeOptions}
          statusOptions={statusOptions}
          selectedSubBudgetForEditing={selectedSubBudgetForEditing}
          addSubBudget={(data) => {
            const newSubBudgets = [...subBudgets];
            newSubBudgets.push(data);
            setSubBudgets(newSubBudgets);
            setIsSubBudgetModalOpen(false);
          }}
          editSubBudget={(data) => {
            const newSubBudgets = [...subBudgets];
            newSubBudgets[selectedSubBudgetIndexForEditing] = {
              ...subBudgets[selectedSubBudgetIndexForEditing],
              ...data
            };
            setSubBudgets(newSubBudgets);
            setSelectedSubBudgetForEditing(undefined);
            setSelectedSubBudgetIndexForEditing(undefined);
            setIsSubBudgetModalOpen(false);
          }}
          currency={currency?.toUpperCase()}
        />
      </Modal>
    </div>
  );
}
