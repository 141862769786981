import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import SponsoredPlaylistsSidebar from '../sponsoredplaylistssidebar';
import {
  SponsoredPlaylistNavigation,
  SponsoredPlaylistNavigationType
} from '../../enums/sponsoredplaylistsnavigation';
import './styles.scss';
import SponsoredPlaylistsPlaylist from './SponsoredPlaylistsPlaylist';
import SponsoredPlaylistsAudience from './SponsoredPlaylistsAudience';
import SponsoredPlaylistsCover from './SponsoredPlaylistsCover';
import SponsoredPlaylistsInfo from './SponsoredPlaylistsInfo';
import SponsoredPlaylistsReview from './SponsoredPlaylistsReview';
import { AxiosSingleton } from '../../utils/axiosinstance';
import { message } from '../../utils/custommessage';

type Props = {
  mode: 'create' | 'edit';
};

type SelectedPlaylistType = {
  title: string;
  title_ar: string;
  id: string;
  image: string;
  cover_location: string;
  cover_ar_location: string;
  cover_fr_location: string;
  user: string;
  userid: string;
  artists: any[];
  artist_ids: any[];
};

export default function SponsoredPlaylists({ mode }: Props) {
  const axios = AxiosSingleton.axiosInstance;
  const { id } = useParams();
  const [navigationPage, setNavigationPage] = useState<SponsoredPlaylistNavigationType>(
    mode === 'create' ? SponsoredPlaylistNavigation.Playlist : SponsoredPlaylistNavigation.Audience
  );

  const [selectedPlaylist, setSelectedPlaylist] = useState<SelectedPlaylistType | undefined>();

  const [artistNames, setArtistNames] = useState([]);
  const [artistIds, setArtistIds] = useState([]);

  const [brandId, setBrandId] = useState();
  const [advertiserId, setAdvertiserId] = useState();
  const [playlistTitleEn, setPlaylistTitleEn] = useState('');
  const [playlistTitleAr, setPlaylistTitleAr] = useState('');

  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().add(7, 'days').format('YYYY-MM-DD'));
  const [budget, setBudget] = useState('');
  const [selectedCountries, setSelectedCountries] = useState<any[]>([]);
  const [gender, setGender] = useState('all');
  const [maxAge, setMaxAge] = useState();
  const [minAge, setMinAge] = useState();

  const [isOverrideToggled, setIsOverrideToggled] = useState(false);
  const coverImage = selectedPlaylist
    ? `https://angimg.anghcdn.co/${selectedPlaylist.cover_location}`
    : '';
  const coverImageAr = selectedPlaylist
    ? `https://angimg.anghcdn.co/${
        selectedPlaylist.cover_ar_location || selectedPlaylist.cover_location
      }`
    : '';

  const [logoEn, setLogoEn] = useState('');
  const [logoAr, setLogoAr] = useState('');
  const [coverWithLogoEn, setCoverWithLogoEn] = useState(coverImage);
  const [coverWithLogoAr, setCoverWithLogoAr] = useState(coverImageAr);
  const [logoPositionX, setLogoPositionX] = useState(0);
  const [logoPositionY, setLogoPositionY] = useState(0);
  const [logoWidth, setLogoWidth] = useState(180);

  const [overrideImageCoverEn, setOverrideImageCoverEn] = useState('');
  const [overrideImageCoverFr, setOverrideImageCoverFr] = useState('');
  const [overrideImageCoverAr, setOverrideImageCoverAr] = useState('');

  const navigate = useNavigate();

  const setCampaignDataFromPlaylistPage = ({ artists, campaignTitle, campaignTitleAr }) => {
    setArtistNames(artists.map((artist) => artist.text));
    setArtistIds(artists.map((artist) => artist.id));
    setPlaylistTitleAr(campaignTitleAr);
    setPlaylistTitleEn(campaignTitle);
  };

  useEffect(() => {
    if (mode === 'edit') {
      axios
        .get('/sponsored-playlists/get', {
          params: {
            campaign_id: id
          }
        })
        .then((res) => {
          const { data } = res;
          const campaignData = data.promotion;

          setSelectedPlaylist({
            artists: campaignData.artists,
            cover_location: campaignData.cover_location,
            cover_ar_location: campaignData.cover_ar_location,
            cover_fr_location: campaignData.cover_fr_location,
            title: campaignData.playlistname,
            title_ar: campaignData.playlistnamear,
            id: campaignData.playlistid,
            userid: campaignData.userid,
            artist_ids: campaignData.artistsids,
            image: campaignData.cover,
            user: ''
          });

          setArtistNames(campaignData.artists.map((artist) => artist.text));
          setArtistIds(campaignData.artists.map((artist) => artist.id));
          setSelectedCountries(campaignData.regions ? campaignData.regions.split(',') : []);
          setBrandId(campaignData.brandid);
          setStartDate(moment(campaignData.start_date).format('YYYY-MM-DD'));
          setEndDate(moment(campaignData.end_date).format('YYYY-MM-DD'));
          setBudget(campaignData.budget);
          setGender(campaignData.gender);
          setMinAge(campaignData.min_age);
          setMaxAge(campaignData.max_age);
          setPlaylistTitleEn(campaignData.title);
          setPlaylistTitleAr(campaignData.title_ar);
          setAdvertiserId(campaignData.advertiser_id);
          setLogoEn(campaignData.logo);
          setLogoAr(campaignData.logo_ar);
          setIsOverrideToggled(Boolean(Number(campaignData.override_covers)));
          setOverrideImageCoverEn(campaignData.cover);
          setOverrideImageCoverAr(campaignData.cover_ar);
          setOverrideImageCoverFr(campaignData.cover_fr);
          setLogoPositionX(Number(campaignData.position_x));
          setLogoPositionY(Number(campaignData.position_y));
          setLogoWidth(Number(campaignData.position_w));
        });
    }
  }, [mode]);

  const save = (isDraft) => {
    const params = {
      action: mode === 'create' ? 'add' : 'edit',
      promotionid: id,
      output: 'json',
      is_draft: isDraft,
      playlistid: selectedPlaylist?.id,
      brandid: brandId,
      brandname: '', // need to figure out how to get this from sponsoredplaylistinfo component
      advertiser_id: advertiserId,
      start_date: startDate,
      end_date: endDate,
      title: playlistTitleEn,
      title_ar: playlistTitleAr,
      budget,
      discard_ads: '', // internal??
      regions: selectedCountries,
      artistids: artistIds,
      gender,
      min_age: minAge,
      max_age: maxAge,
      target_all: '', // havent added this yet
      excluded_regions: '', // need to add with target all
      logo: logoEn,
      logo_ar: logoAr,
      override_covers: isOverrideToggled,
      cover: overrideImageCoverEn,
      cover_ar: overrideImageCoverAr,
      cover_fr: overrideImageCoverFr,
      position_x: logoPositionX,
      position_y: logoPositionY,
      position_w: logoWidth,
      placement: 'all',
      send_push: false,
      push_date: moment().format('YYYY-MM-DD HH:mm'),
      push_title: '',
      push_title_ar: '',
      push_title_fr: '',
      push_text: '',
      push_text_ar: '',
      push_text_fr: ''
    };

    axios.post('/sponsored-playlists/save', params).then((res) => {
      const { data } = res;

      if (data.id) {
        navigate('/sponsoredplaylists');
      } else {
        message.error('An error occurred.');
      }
    });
  };

  return (
    <div className="campaign">
      <div className="sidebar-container">
        <SponsoredPlaylistsSidebar
          navigationPage={navigationPage}
          setNavigationPage={setNavigationPage}
          selectedPlaylist={selectedPlaylist}
          selectedCountries={selectedCountries}
          startDate={startDate}
          endDate={endDate}
          budget={budget}
          isOverrideToggled={isOverrideToggled}
          overrideImageCoverEn={overrideImageCoverEn}
          logoEn={logoEn}
          advertiserId={advertiserId}
          playlistTitleEn={playlistTitleEn}
          playlistTitleAr={playlistTitleAr}
          mode={mode}
        />
      </div>
      <div className="pages-container sponsored-playlists-pages">
        <div
          className={
            navigationPage === SponsoredPlaylistNavigation.Playlist ? 'visible' : 'invisible'
          }
        >
          <SponsoredPlaylistsPlaylist
            mode={mode}
            setSelectedPlaylist={setSelectedPlaylist}
            selectedPlaylist={selectedPlaylist}
            setNavigationPage={setNavigationPage}
            setCampaignDataFromPlaylistPage={setCampaignDataFromPlaylistPage}
          />
        </div>
        <div
          className={
            navigationPage === SponsoredPlaylistNavigation.Audience ? 'visible' : 'invisible'
          }
        >
          <SponsoredPlaylistsAudience
            mode={mode}
            setNavigationPage={setNavigationPage}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            budget={budget}
            setBudget={setBudget}
            selectedCountries={selectedCountries}
            setSelectedCountries={setSelectedCountries}
            gender={gender}
            setGender={setGender}
            minAge={minAge}
            setMinAge={setMinAge}
            maxAge={maxAge}
            setMaxAge={setMaxAge}
            artists={artistNames}
          />
        </div>
        <div
          className={navigationPage === SponsoredPlaylistNavigation.Cover ? 'visible' : 'invisible'}
        >
          <SponsoredPlaylistsCover
            mode={mode}
            setNavigationPage={setNavigationPage}
            isOverrideToggled={isOverrideToggled}
            setIsOverrideToggled={setIsOverrideToggled}
            coverImage={coverImage}
            coverImageAr={coverImageAr}
            logoEn={logoEn}
            setLogoEn={setLogoEn}
            logoAr={logoAr}
            setLogoAr={setLogoAr}
            coverWithLogoEn={coverWithLogoEn}
            setCoverWithLogoEn={setCoverWithLogoEn}
            coverWithLogoAr={coverWithLogoAr}
            setCoverWithLogoAr={setCoverWithLogoAr}
            logoPositionX={logoPositionX}
            setLogoPositionX={setLogoPositionX}
            logoPositionY={logoPositionY}
            setLogoPositionY={setLogoPositionY}
            logoWidth={logoWidth}
            setLogoWidth={setLogoWidth}
            overrideImageCoverEn={overrideImageCoverEn}
            setOverrideImageCoverEn={setOverrideImageCoverEn}
            overrideImageCoverFr={overrideImageCoverFr}
            setOverrideImageCoverFr={setOverrideImageCoverFr}
            overrideImageCoverAr={overrideImageCoverAr}
            setOverrideImageCoverAr={setOverrideImageCoverAr}
          />
        </div>
        <div
          className={navigationPage === SponsoredPlaylistNavigation.Info ? 'visible' : 'invisible'}
        >
          <SponsoredPlaylistsInfo
            mode={mode}
            setNavigationPage={setNavigationPage}
            brandId={brandId}
            setBrandId={setBrandId}
            advertiserId={advertiserId}
            setAdvertiserId={setAdvertiserId}
            playlistTitleEn={playlistTitleEn}
            setPlaylistTitleEn={setPlaylistTitleEn}
            playlistTitleAr={playlistTitleAr}
            setPlaylistTitleAr={setPlaylistTitleAr}
          />
        </div>
        <div
          className={
            navigationPage === SponsoredPlaylistNavigation.Review ? 'visible' : 'invisible'
          }
        >
          <SponsoredPlaylistsReview
            selectedPlaylist={selectedPlaylist}
            advertiser={advertiserId}
            brand={brandId}
            playlistTitleEn={playlistTitleEn}
            playlistTitleAr={playlistTitleAr}
            budget={budget}
            countries={selectedCountries}
            artists={artistNames}
            gender={gender}
            minAge={minAge}
            maxAge={maxAge}
            startDate={startDate}
            endDate={endDate}
            save={save}
            cover={isOverrideToggled ? overrideImageCoverEn : coverWithLogoEn}
            overrideImageCoverFr={overrideImageCoverFr}
            overrideImageCoverAr={overrideImageCoverAr}
          />
        </div>
      </div>
    </div>
  );
}
