import { Button, Form, Input } from 'antd';
import React, { useState } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import AnghamiAdsBetaLogo from '../../public/assets/images/Ads-Logo-Dark.png';
import { isEmailValid } from '../../helpers/email-validaton';
import { AxiosSingleton } from '../../utils/axiosinstance';

export default function ResetPassword() {
  const axios = AxiosSingleton.axiosInstance;
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  const submit = () => {
    setLoading(true);

    const params = {
      email,
      password,
      password_confirmation: confirmPassword,
      token
    };

    if (!isEmailValid(email)) {
      setLoading(false);
      setErrorMessage('Email is not valid');
      return;
    }

    if (password.length < 6) {
      setErrorMessage('Password length must be 6 or greater');
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      setLoading(false);
      return;
    }

    axios
      .post('/password/reset', params)
      .then((res) => {
        setLoading(false);

        if (res.data.message === 'error') {
          setErrorMessage(res.data.error);
          return;
        }
        navigate('/login?reset=true');
      })
      .catch(() => {
        setErrorMessage('Something went wrong, please try again');
        setLoading(false);
      });
  };

  return (
    <div className="auth-container">
      <div className="auth-logo">
        <img src={AnghamiAdsBetaLogo} alt="Anghami Beta Ads" />
      </div>
      <div className="auth-title">Enter new password</div>
      {errorMessage && <div className="auth-failed-msg">{errorMessage}</div>}
      <Form onFinish={submit}>
        <div className="auth-input-container">
          <label>Email</label>
          <Input
            placeholder="user@example.com"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className="auth-input-container">
          <label>Enter New Password</label>
          <Input
            placeholder="••••••••"
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </div>
        <div className="auth-input-container">
          <label>Confirm New Password</label>
          <Input
            placeholder="••••••••"
            type="password"
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />
        </div>
        <div className="auth-primary-btn-container">
          <Button
            className="auth-primary-btn"
            type="primary"
            loading={loading}
            onClick={submit}
            disabled={loading}
          >
            Reset Password
          </Button>
        </div>
      </Form>
      <div className="auth-footer">
        <div className="auth-footer-text">Already have an account?</div>
        <Link to="/login">
          <button className="auth-secondary-btn" type="button">
            Sign In
          </button>
        </Link>
      </div>
    </div>
  );
}
