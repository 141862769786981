import { CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHashScroll } from 'react-hash-scroll';
import './styles.scss';
import { campaignContext } from '../campaignhoc/campaign.context';
import { AxiosSingleton } from '../../utils/axiosinstance';
import CreativeUpload from '../creativeupload';
import { IAdSet, ICreative } from '../campaignhoc';
import { message } from '../../utils/custommessage';
import PreviewComponent from '../newpreview';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { AnghamiAdsFormInput } from '../core/AnghamiAdsFormInput';
import { AnghamiAdsFormSelector } from '../core/AnghamiAdsFormSelector';
import { ctaKeyToTextMap } from '../../utils/constants';
import useAuth from '../../hooks/useAuth';
import { PublisherRoles } from '../../enums/publisherroles';
import DebounceSelect from '../debounceselect';
import { ctaButtonTextOptions, songCampaignReasonOptions } from './selectorOptions';
import { Modal } from 'antd';
import WarningModal from '../warningmodal';

const WARNER_PUBLISHER_ROLE_ID = 28;

type Props = {
  campaignType: string;
  adType: string;
  creative: ICreative;
  adSet: IAdSet;
  isFirstCreative: boolean;
  isUniqueCreative: boolean;
};

type ReportingMode = 'impression' | 'click' | 'quartile';

export default function AdSetCreativeSubsection({
  campaignType,
  adType,
  creative,
  adSet,
  isFirstCreative,
  isUniqueCreative
}: Props) {
  const axios = AxiosSingleton.axiosInstance;

  const { user, role } = useAuth();
  const isDirectClient = role === PublisherRoles.DirectClient;

  const [showDeleteCreativeModal, setShowDeleteCreativeModal] = useState<boolean>(false);

  const {
    mode,
    groupCampaignObjective,
    updateCreativeValue,
    updateAdSetValue,
    advertiserId,
    removeCreativeById,
    updateLeadGenForm,
    setNavigationData,
    navigationData,
    publisherId,
    specialPublishers
  } = useContext(campaignContext);

  useEffect(() => {
    if (user?.role_id === WARNER_PUBLISHER_ROLE_ID) {
      updateCreativeValue({
        adSetId: adSet.id,
        creativeId: creative.id,
        key: 'creativeReasonText',
        value: 'label_commitment'
      });

      // API expects us to send the reason as notes
      updateCreativeValue({
        adSetId: adSet.id,
        creativeId: creative.id,
        key: 'creativeNotes',
        value: 'label_commitment'
      });
    }
  }, []);

  const saveCreative = () => {
    const params = {
      id: creative.creativeId,
      name: creative.creativeName,
      type: adSet.adSetType,
      title_en: creative.creativeTitleEn,
      link_en: creative.creativeLinkEn,
      audio_en: creative.creativeAudioEn,
      video_en: creative.creativeVideoEn,
      image_en: creative.creativeImageEn,
      button_text_en: creative.creativeButtonTextEn,
      start_date: creative.creativeStartDate,
      end_date: creative.creativeEndDate,
      advertiser_id: advertiserId,
      thirdparty_start: creative.creativeThirdPartyStart,
      thirdparty_end: creative.creativeThirdPartyEnd,
      thirdparty_tap: creative.creativeThirdPartyTap,
      thirdparty_quartile: creative.creativeThirdPartyQuartile,
      daysofweek: creative.creativeDaysOfWeek, // for scheduled ads
      start_time: creative.creativeStartTime, // for scheduled ads
      end_time: creative.creativeEndTime, // for scheduled ads;
      progress: 1, // send 1 when saving progress
      adplanner: 1, // send 1 when saving progress
      publisher_id: specialPublishers.find((p) => p.id === publisherId.toString())
        ? publisherId
        : undefined
    };

    const endpoint = `ads/${creative.creativeId ? 'update' : 'store'}`;

    axios
      .post(endpoint, params)
      .then((res) => {
        const { data } = res;

        updateCreativeValue({
          adSetId: adSet.id,
          creativeId: creative.id,
          key: 'creativeId',
          value: data
        });
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  };

  const fetchSongs = (query) =>
    axios
      .get('/songs/search', {
        params: {
          q: query,
          search_type: 'songs'
        }
      })
      .then((res) => {
        const { data } = res;
        let results;
        if (query.toString().indexOf('anghami.com/song/') !== -1 || Number(query)) {
          let id = query.split('/');
          id = id[id.length - 1];
          // eslint-disable-next-line prefer-destructuring
          id = id.split('?')[0];
          id = Number(id);
          results = data.unshift({
            _value: `Load from [${query}]`,
            _attributes: {
              type: 'song',
              id
            }
          });
        }

        results = data.reduce((result, item) => {
          // eslint-disable-next-line no-underscore-dangle
          if (item._attributes.type === 'song') {
            // eslint-disable-next-line no-underscore-dangle
            result.push({ id: item._attributes.id, text: item._value });
          }
          return result;
        }, []);

        return results.map((result) => ({
          label: result.text,
          value: result.id
        }));
      });

  const getSongData = (songUrl) => {
    axios
      .get(`/songs/get?url=${songUrl}`)
      .then((res) => {
        const { data } = res.data;
        const song = {
          id: data.id,
          artistID: data.artistID,
          title: data.title,
          artist: data.artist,
          genre: data.genre,
          ownerid: data.ownerid,
          languageid: data.languageid,
          duration: data.duration,
          coverArt: data.CoverArtID
            ? `https://angartwork.anghcdn.co/?id=${data.CoverArtID}&size=252`
            : null
        };

        updateCreativeValue({
          adSetId: adSet.id,
          creativeId: creative.id,
          key: 'creativeSong',
          value: song
        });
      })
      .catch(() => {
        updateCreativeValue({
          adSetId: adSet.id,
          creativeId: creative.id,
          key: 'creativeSong',
          value: {}
        });
      });
  };

  const [reportingMode, setReportingMode] = useState<ReportingMode>('impression');

  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {
    threshold: 0.3
  });
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    if (isVisible) {
      setNavigationData({
        ...navigationData,
        navigationCreativeActiveId: creative.id
      });
    }
  }, [isVisible]);

  const scrollRef = useHashScroll(`#${creative.id}`, {
    position: 'start'
  });

  const renderLeadGenerationInputs = () => (
    <>
      <div>
        <div className="campaign-section-title">Upload Header Image*</div>
        <CreativeUpload uploadType="image" isLeadGen adSet={adSet} creative={creative} />
        <div className="campaign-form-input-subtitle mb-1rem">
          * Creative size should be 600x600, 600x500 or 320x480.
        </div>
        <div className="campaign-section-title">Customize the form</div>
        <div className="mb-1rem">
          <AnghamiAdsFormInput
            label="Creative Description"
            type="text"
            placeholder="Submit your info to share your details with the brand!"
            value={adSet.adSetForm.description}
            onChange={(e) => {
              updateAdSetValue({
                id: adSet.id,
                key: 'adSetForm',
                value: {
                  ...adSet.adSetForm,
                  description: e.target.value
                }
              });
            }}
          />
        </div>
        <div className="mb-1rem">
          <AnghamiAdsFormInput
            label="Terms & Conditions"
            type="text"
            placeholder="Type your terms and conditions here…"
            value={adSet.adSetForm.terms}
            onChange={(e) => {
              updateAdSetValue({
                id: adSet.id,
                key: 'adSetForm',
                value: {
                  ...adSet.adSetForm,
                  terms: e.target.value
                }
              });
            }}
          />
        </div>
        <div className="mb-1rem">
          <AnghamiAdsFormSelector
            label="CTA Button Text"
            placeholder="Know More"
            value={adSet.adSetForm.button_cta}
            options={ctaButtonTextOptions}
            onChange={(value: string) => {
              updateAdSetValue({
                id: adSet.id,
                key: 'adSetForm',
                value: {
                  ...adSet.adSetForm,
                  button_cta: value
                }
              });
            }}
          />
        </div>
        <div className="campaign-section-title">What information would you want to ask for?</div>
        <div className="day-selector-container">
          <div className="campaign-form-input-container day-selector form-field-selector">
            <div>FULL NAME</div>{' '}
            <div className="check visible-check">
              <CheckOutlined />
            </div>
          </div>
          <div className="campaign-form-input-container day-selector form-field-selector">
            <div>EMAIL</div>{' '}
            <div className="check visible-check">
              <CheckOutlined />
            </div>
          </div>
          <div className="campaign-form-input-container day-selector form-field-selector">
            <div>PHONE NUMBER</div>{' '}
            <div className="check visible-check">
              <CheckOutlined />
            </div>
          </div>
          <div
            className="campaign-form-input-container day-selector form-field-selector"
            onClick={() => {
              updateAdSetValue({
                id: adSet.id,
                key: 'adSetForm',
                value: {
                  ...adSet.adSetForm,
                  fields: {
                    ...adSet.adSetForm.fields,
                    birthday: !adSet.adSetForm.fields.birthday
                  }
                }
              });
            }}
          >
            <div>BIRTHDATE</div>{' '}
            <div className={`check ${adSet.adSetForm.fields.birthday ? 'visible-check' : ''}`}>
              <CheckOutlined />
            </div>
          </div>
          <div
            className="campaign-form-input-container day-selector form-field-selector"
            onClick={() => {
              updateAdSetValue({
                id: adSet.id,
                key: 'adSetForm',
                value: {
                  ...adSet.adSetForm,
                  fields: {
                    ...adSet.adSetForm.fields,
                    gender: !adSet.adSetForm.fields.gender
                  }
                }
              });
            }}
          >
            <div>GENDER</div>{' '}
            <div className={`check ${adSet.adSetForm.fields.gender ? 'visible-check' : ''}`}>
              <CheckOutlined />
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const renderCreativeBody = () => (
    <>
      <div className="campaign-section-title">Ad Background</div>
      <div className="mb-1rem">
        <AnghamiAdsFormInput
          label="Creative Name*"
          type="text"
          placeholder="Give a name for your creative"
          value={creative.creativeName}
          onChange={(e) => {
            updateCreativeValue({
              adSetId: adSet.id,
              creativeId: creative.id,
              key: 'creativeName',
              value: e.target.value
            });
          }}
        />
      </div>
      {adSet.adSetType !== 'song' && (
        <>
          {adSet.adSetType !== 'display' && (
            <>
              <div className="mb-1rem">
                <AnghamiAdsFormInput
                  label="Post Roll Text"
                  type="text"
                  placeholder="Default post roll text"
                  value={creative.creativeTitleEn}
                  maxLength={90}
                  onChange={(e) => {
                    updateCreativeValue({
                      adSetId: adSet.id,
                      creativeId: creative.id,
                      key: 'creativeTitleEn',
                      value: e.target.value
                    });
                  }}
                />
              </div>
              <div className="campaign-form-input-subtitle mb-1rem">
                *{90 - creative.creativeTitleEn?.length} characters remaining
              </div>
            </>
          )}
          {groupCampaignObjective !== 'lead' && (
            <div className="mb-1rem">
              <AnghamiAdsFormInput
                label="CTA URL"
                type="text"
                placeholder="E.g. https://www.example.com"
                value={creative.creativeLinkEn}
                onChange={(e) => {
                  updateCreativeValue({
                    adSetId: adSet.id,
                    creativeId: creative.id,
                    key: 'creativeLinkEn',
                    value: e.target.value
                  });
                }}
              />
            </div>
          )}
          <div className="campaign-form-input-subtitle mb-1rem">
            * URL should start with http:// or https://, you can also use a valid Anghami deeplink.
            E.g. anghami://webs/example.com
          </div>
          <div className="mb-1rem">
            <AnghamiAdsFormSelector
              label="CTA Button Text"
              placeholder="Know More"
              value={creative.creativeButtonTextEn}
              options={ctaButtonTextOptions}
              onChange={(value: string) => {
                updateCreativeValue({
                  adSetId: adSet.id,
                  creativeId: creative.id,
                  key: 'creativeButtonTextEn',
                  value
                });
              }}
            />
          </div>
        </>
      )}

      {adType === 'video' && (
        <div className="video-ad-upload-section">
          <CreativeUpload uploadType="video" isLeadGen={false} adSet={adSet} creative={creative} />
          <CreativeUpload uploadType="image" isLeadGen={false} adSet={adSet} creative={creative} />
        </div>
      )}
      {adType === 'audio' && (
        <div className="audio-ad-upload-section">
          <CreativeUpload uploadType="audio" isLeadGen={false} adSet={adSet} creative={creative} />
          <CreativeUpload uploadType="image" isLeadGen={false} adSet={adSet} creative={creative} />
        </div>
      )}
      {adType === 'display' && (
        <div className="display-ad-upload-section">
          <CreativeUpload uploadType="image" isLeadGen={false} adSet={adSet} creative={creative} />
        </div>
      )}
      {adType === 'song' && (
        <div>
          <div className="campaign-form-input-container mb-1rem">
            <label>Search For a Song</label>
            <DebounceSelect
              showSearch
              value={creative.creativeSongId}
              placeholder="Search songs, or enter an Anghami URL eg: https://www.anghami.com/song/SONGID"
              fetchOptions={fetchSongs}
              onChange={(newValue) => {
                const songUrl = String(newValue).includes('https://play.anghami.com/song/')
                  ? newValue
                  : `https://play.anghami.com/song/${newValue}`;

                updateCreativeValue({
                  adSetId: adSet.id,
                  creativeId: creative.id,
                  key: 'creativeSongId',
                  value: newValue
                });

                updateCreativeValue({
                  adSetId: adSet.id,
                  creativeId: creative.id,
                  key: 'creativeLinkEn',
                  value: songUrl
                });

                getSongData(songUrl);
              }}
              style={{
                width: '100%'
              }}
            />
          </div>
          <div className="mb-1rem">
            <AnghamiAdsFormSelector
              label="Choose a reason for this song"
              disabled={user?.role_id === WARNER_PUBLISHER_ROLE_ID}
              placeholder="Pick a reason"
              value={creative.creativeReasonText}
              options={songCampaignReasonOptions}
              onChange={(value: string) => {
                updateCreativeValue({
                  adSetId: adSet.id,
                  creativeId: creative.id,
                  key: 'creativeReasonText',
                  value
                });

                // API expects us to send the reason as notes
                updateCreativeValue({
                  adSetId: adSet.id,
                  creativeId: creative.id,
                  key: 'creativeNotes',
                  value: value === 'other' ? '' : value
                });
              }}
            />
          </div>
          {creative.creativeReasonText === 'other' && (
            <div className="mb-1rem">
              <AnghamiAdsFormInput
                label="Please state other reason"
                type="text"
                placeholder="Reason"
                value={creative.creativeNotes}
                onChange={(e) => {
                  updateCreativeValue({
                    adSetId: adSet.id,
                    creativeId: creative.id,
                    key: 'creativeNotes',
                    value: e.target.value
                  });
                }}
              />
            </div>
          )}
        </div>
      )}
      <div className="campaign-section-title">Third Party Reporting</div>
      <div className="reporting-selectors-container mb-1rem">
        <div
          className={`reporting-selector ${reportingMode === 'impression' ? 'selected' : ''}`}
          onClick={() => {
            setReportingMode('impression');
          }}
        >
          Impression Tracker
        </div>
        <div
          className={`reporting-selector ${reportingMode === 'click' ? 'selected' : ''}`}
          onClick={() => {
            setReportingMode('click');
          }}
        >
          Click Tracker
        </div>
        {!isDirectClient && (
          <div
            className={`reporting-selector ${reportingMode === 'quartile' ? 'selected' : ''}`}
            onClick={() => {
              setReportingMode('quartile');
            }}
          >
            Quartile Tracker
          </div>
        )}
      </div>
      {reportingMode === 'impression' && (
        <div>
          {creative.creativeThirdPartyStart.map((hook, index) => (
            <div key={hook} className="webhooks-input-container">
              <div className="campaign-form-input-container mb-1rem">
                <label>Webhooks</label>
                <input
                  type="text"
                  placeholder="E.g. https://third-party-service.com/webhooks/start"
                  value={hook}
                  onChange={(e) => {
                    const newHooks = [...creative.creativeThirdPartyStart];
                    newHooks[index] = e.target.value;
                    updateCreativeValue({
                      adSetId: adSet.id,
                      creativeId: creative.id,
                      key: 'creativeThirdPartyStart',
                      value: newHooks
                    });
                  }}
                />
              </div>
              <div
                className="remove-webhook"
                onClick={() => {
                  const newHooks = [...creative.creativeThirdPartyStart];
                  newHooks.splice(index, 1);
                  updateCreativeValue({
                    adSetId: adSet.id,
                    creativeId: creative.id,
                    key: 'creativeThirdPartyStart',
                    value: newHooks
                  });
                }}
              >
                <CloseOutlined />
              </div>
            </div>
          ))}
          <div
            className="add-more-reporting"
            onClick={() => {
              const newHooks = [...creative.creativeThirdPartyStart];
              newHooks.push('');
              updateCreativeValue({
                adSetId: adSet.id,
                creativeId: creative.id,
                key: 'creativeThirdPartyStart',
                value: newHooks
              });
            }}
          >
            + ADD MORE
          </div>
        </div>
      )}
      {reportingMode === 'click' && (
        <div>
          {creative.creativeThirdPartyTap.map((hook, index) => (
            <div key={hook} className="webhooks-input-container">
              <div className="campaign-form-input-container mb-1rem">
                <label>Webhooks</label>
                <input
                  type="text"
                  placeholder="E.g. https://third-party-service.com/webhooks/start"
                  value={hook}
                  onChange={(e) => {
                    const newHooks = [...creative.creativeThirdPartyTap];
                    newHooks[index] = e.target.value;
                    updateCreativeValue({
                      adSetId: adSet.id,
                      creativeId: creative.id,
                      key: 'creativeThirdPartyTap',
                      value: newHooks
                    });
                  }}
                />
              </div>
              <div
                className="remove-webhook"
                onClick={() => {
                  const newHooks = [...creative.creativeThirdPartyTap];
                  newHooks.splice(index, 1);
                  updateCreativeValue({
                    adSetId: adSet.id,
                    creativeId: creative.id,
                    key: 'creativeThirdPartyTap',
                    value: newHooks
                  });
                }}
              >
                <CloseOutlined />
              </div>
            </div>
          ))}
          <div
            className="add-more-reporting"
            onClick={() => {
              const newHooks = [...creative.creativeThirdPartyTap];
              newHooks.push('');
              updateCreativeValue({
                adSetId: adSet.id,
                creativeId: creative.id,
                key: 'creativeThirdPartyTap',
                value: newHooks
              });
            }}
          >
            + ADD MORE
          </div>
        </div>
      )}
      {reportingMode === 'quartile' && (
        <div>
          {creative.creativeThirdPartyQuartile.map((hook, index) => (
            <div key={hook} className="webhooks-input-container">
              <div className="campaign-form-input-container mb-1rem">
                <label>Webhooks</label>
                <input
                  type="text"
                  placeholder="E.g. https://third-party-service.com/webhooks/start"
                  value={hook}
                  onChange={(e) => {
                    const newHooks = [...creative.creativeThirdPartyQuartile];
                    newHooks[index] = e.target.value;
                    updateCreativeValue({
                      adSetId: adSet.id,
                      creativeId: creative.id,
                      key: 'creativeThirdPartyQuartile',
                      value: newHooks
                    });
                  }}
                />
              </div>
              <div
                className="remove-webhook"
                onClick={() => {
                  const newHooks = [...creative.creativeThirdPartyQuartile];
                  newHooks.splice(index, 1);
                  updateCreativeValue({
                    adSetId: adSet.id,
                    creativeId: creative.id,
                    key: 'creativeThirdPartyQuartile',
                    value: newHooks
                  });
                }}
              >
                <CloseOutlined />
              </div>
            </div>
          ))}
          <div
            className="add-more-reporting"
            onClick={() => {
              const newHooks = [...creative.creativeThirdPartyQuartile];
              newHooks.push('');
              updateCreativeValue({
                adSetId: adSet.id,
                creativeId: creative.id,
                key: 'creativeThirdPartyQuartile',
                value: newHooks
              });
            }}
          >
            + ADD MORE
          </div>
        </div>
      )}
      <div className="campaign-section-title">Creative Schedule</div>
      <div className="creative-schedule-time-container mb-1rem">
        <div className="campaign-form-input-container">
          <label>Start Time</label>
          <input
            type="time"
            value={creative.creativeStartTime}
            onChange={(e) => {
              updateCreativeValue({
                adSetId: adSet.id,
                creativeId: creative.id,
                key: 'creativeStartTime',
                value: e.target.value
              });
            }}
          />
        </div>
        <div className="campaign-form-input-container">
          <label>End Time</label>
          <input
            type="time"
            value={creative.creativeEndTime}
            onChange={(e) => {
              updateCreativeValue({
                adSetId: adSet.id,
                creativeId: creative.id,
                key: 'creativeEndTime',
                value: e.target.value
              });
            }}
          />
        </div>
      </div>
      <div className="mb-1rem">SELECT DAYS</div>
      <div className="day-selector-container">
        <div
          className="campaign-form-input-container day-selector"
          onClick={() => {
            updateCreativeValue({
              adSetId: adSet.id,
              creativeId: creative.id,
              key: 'creativeDaysOfWeek',
              value: {
                ...creative.creativeDaysOfWeek,
                monday: !creative.creativeDaysOfWeek.monday
              }
            });
          }}
        >
          <div>Monday</div>{' '}
          <div className={`check ${creative.creativeDaysOfWeek.monday ? 'visible-check' : ''}`}>
            <CheckOutlined />
          </div>
        </div>
        <div
          className="campaign-form-input-container day-selector ml-0pt5rem"
          onClick={() => {
            updateCreativeValue({
              adSetId: adSet.id,
              creativeId: creative.id,
              key: 'creativeDaysOfWeek',
              value: {
                ...creative.creativeDaysOfWeek,
                tuesday: !creative.creativeDaysOfWeek.tuesday
              }
            });
          }}
        >
          <div>Tuesday</div>{' '}
          <div className={`check ${creative.creativeDaysOfWeek.tuesday ? 'visible-check' : ''}`}>
            <CheckOutlined />
          </div>
        </div>
        <div
          className="campaign-form-input-container day-selector ml-0pt5rem"
          onClick={() => {
            updateCreativeValue({
              adSetId: adSet.id,
              creativeId: creative.id,
              key: 'creativeDaysOfWeek',
              value: {
                ...creative.creativeDaysOfWeek,
                wednesday: !creative.creativeDaysOfWeek.wednesday
              }
            });
          }}
        >
          <div>Wednesday</div>{' '}
          <div className={`check ${creative.creativeDaysOfWeek.wednesday ? 'visible-check' : ''}`}>
            <CheckOutlined />
          </div>
        </div>
        <div
          className="campaign-form-input-container day-selector ml-0pt5rem"
          onClick={() => {
            updateCreativeValue({
              adSetId: adSet.id,
              creativeId: creative.id,
              key: 'creativeDaysOfWeek',
              value: {
                ...creative.creativeDaysOfWeek,
                thursday: !creative.creativeDaysOfWeek.thursday
              }
            });
          }}
        >
          <div>Thursday</div>{' '}
          <div className={`check ${creative.creativeDaysOfWeek.thursday ? 'visible-check' : ''}`}>
            <CheckOutlined />
          </div>
        </div>
        <div
          className="campaign-form-input-container day-selector"
          onClick={() => {
            updateCreativeValue({
              adSetId: adSet.id,
              creativeId: creative.id,
              key: 'creativeDaysOfWeek',
              value: {
                ...creative.creativeDaysOfWeek,
                friday: !creative.creativeDaysOfWeek.friday
              }
            });
          }}
        >
          <div>Friday</div>{' '}
          <div className={`check ${creative.creativeDaysOfWeek.friday ? 'visible-check' : ''}`}>
            <CheckOutlined />
          </div>
        </div>
        <div
          className="campaign-form-input-container day-selector ml-0pt5rem"
          onClick={() => {
            updateCreativeValue({
              adSetId: adSet.id,
              creativeId: creative.id,
              key: 'creativeDaysOfWeek',
              value: {
                ...creative.creativeDaysOfWeek,
                saturday: !creative.creativeDaysOfWeek.saturday
              }
            });
          }}
        >
          <div>Saturday</div>{' '}
          <div className={`check ${creative.creativeDaysOfWeek.saturday ? 'visible-check' : ''}`}>
            <CheckOutlined />
          </div>
        </div>
        <div
          className="campaign-form-input-container day-selector ml-0pt5rem"
          onClick={() => {
            updateCreativeValue({
              adSetId: adSet.id,
              creativeId: creative.id,
              key: 'creativeDaysOfWeek',
              value: {
                ...creative.creativeDaysOfWeek,
                sunday: !creative.creativeDaysOfWeek.sunday
              }
            });
          }}
        >
          <div>Sunday</div>{' '}
          <div className={`check ${creative.creativeDaysOfWeek.sunday ? 'visible-check' : ''}`}>
            <CheckOutlined />
          </div>
        </div>
      </div>
      {campaignType === 'lead' && isFirstCreative && renderLeadGenerationInputs()}
      <button
        type="button"
        className="save-creative-btn"
        onClick={() => {
          saveCreative();
        }}
      >
        Save
      </button>
    </>
  );

  return (
    <>
      <div className="adset-creative-container" ref={ref}>
        <div
          className={`ad-set-creative-content ${
            mode === 'preview' ? 'campaign-form-preview-mask' : ''
          }`}
          ref={scrollRef as any}
        >
          <div className="adset-creative-header">
            <div className="adset-creative-header-text">
              <div className="adset-creative-header-title">Creative {creative.id + 1}</div>
            </div>
            <div className="creative-controls">
              {!isUniqueCreative && (
                <div
                  onClick={() => {
                    setShowDeleteCreativeModal(true);
                  }}
                >
                  <DeleteOutlined />
                </div>
              )}
            </div>
          </div>
          <div>{renderCreativeBody()}</div>
        </div>
        <div
          className="adset-creative-preview"
          style={{
            opacity: isVisible ? '1' : '0',
            zIndex: isVisible ? creative.id : '-1'
          }}
        >
          <PreviewComponent
            creative={creative}
            adSet={adSet}
            groupCampaignObjective={groupCampaignObjective}
            updateLeadGenForm={updateLeadGenForm}
            buttonText={
              ctaKeyToTextMap[creative.creativeButtonTextEn] || creative.creativeButtonTextEn
            }
            songData={creative.creativeSong}
          />
        </div>
      </div>
      <Modal
        centered
        visible={showDeleteCreativeModal}
        footer={null}
        closable={false}
        width="26rem"
      >
        <WarningModal
          onOk={() => {
            removeCreativeById({
              adSetId: adSet.id,
              creativeId: creative.id
            });
            setShowDeleteCreativeModal(false);
          }}
          onCancel={() => {
            setShowDeleteCreativeModal(false);
          }}
          titleText="Delete"
          subtitleText="Are you sure you want to delete this item?"
          okButtonText="Yes"
          cancelButtonText="Cancel"
        />
      </Modal>
    </>
  );
}
