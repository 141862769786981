import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import React from 'react';
import { statusOptions } from './selectorOptions';
import './styles.scss';

type Props = {
  data: {
    type: string;
    campaign: string;
    text: string;
    amount: string;
    status: string;
  };
  removeSubBudget: () => void;
  editSubBudget: () => void;
  subBudgetTypeOptions: any[];
  campaignOptions: any[];
};

const convertIdToText = (id, options) => options?.find((el) => String(el.id) === String(id))?.text;

export const SubBudgetPreview = ({
  data,
  removeSubBudget,
  editSubBudget,
  subBudgetTypeOptions,
  campaignOptions
}: Props) => (
  <div className="subbudget-preview">
    <div className="edit-btn" onClick={editSubBudget}>
      <EditOutlined />
    </div>
    <div className="delete-btn" onClick={removeSubBudget}>
      <DeleteOutlined />
    </div>
    <div>Type: {convertIdToText(data.type, subBudgetTypeOptions)}</div>
    <div>Campaign: {convertIdToText(data.campaign, campaignOptions) || data.campaign}</div>
    <div>Text: {data.text}</div>
    <div>Amount: {data.amount}</div>
    <div>Status: {convertIdToText(data.status, statusOptions)}</div>
  </div>
);
