import { useState, useEffect } from 'react';
import { AxiosSingleton } from '../../utils/axiosinstance';

export const useFetchUsers = () => {
  const axios = AxiosSingleton.axiosInstance;
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    axios.get('/admin/users/all').then((res) => {
      const { data } = res;
      setTableData(data);
    });
  }, []);

  return { tableData };
};
