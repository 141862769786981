import AudienceReach from '../public/assets/images/AudienceReach.png';
// import LeadGeneration from '../public/assets/images/LeadGeneration.png';
// import WebConversion from '../public/assets/images/WebConversion.png';

export const countries = [
  { id: 'AF', text: 'Afghanistan', icon: 'flag-icon flag-icon-af' },
  { id: 'AX', text: 'Åland Islands', icon: 'flag-icon flag-icon-ax' },
  { id: 'AL', text: 'Albania', icon: 'flag-icon flag-icon-al' },
  { id: 'DZ', text: 'Algeria', icon: 'flag-icon flag-icon-dz' },
  { id: 'AS', text: 'American Samoa', icon: 'flag-icon flag-icon-as' },
  { id: 'AD', text: 'Andorra', icon: 'flag-icon flag-icon-ad' },
  { id: 'AO', text: 'Angola', icon: 'flag-icon flag-icon-ao' },
  { id: 'AI', text: 'Anguilla', icon: 'flag-icon flag-icon-ai' },
  { id: 'AQ', text: 'Antarctica', icon: 'flag-icon flag-icon-aq' },
  { id: 'AG', text: 'Antigua and Barbuda', icon: 'flag-icon flag-icon-ag' },
  { id: 'AR', text: 'Argentina', icon: 'flag-icon flag-icon-ar' },
  { id: 'AM', text: 'Armenia', icon: 'flag-icon flag-icon-am' },
  { id: 'AW', text: 'Aruba', icon: 'flag-icon flag-icon-aw' },
  { id: 'AU', text: 'Australia', icon: 'flag-icon flag-icon-au' },
  { id: 'AT', text: 'Austria', icon: 'flag-icon flag-icon-at' },
  { id: 'AZ', text: 'Azerbaijan', icon: 'flag-icon flag-icon-az' },
  { id: 'BS', text: 'Bahamas', icon: 'flag-icon flag-icon-bs' },
  { id: 'BH', text: 'Bahrain', icon: 'flag-icon flag-icon-bh' },
  { id: 'BD', text: 'Bangladesh', icon: 'flag-icon flag-icon-bd' },
  { id: 'BB', text: 'Barbados', icon: 'flag-icon flag-icon-bb' },
  { id: 'BY', text: 'Belarus', icon: 'flag-icon flag-icon-by' },
  { id: 'BE', text: 'Belgium', icon: 'flag-icon flag-icon-be' },
  { id: 'BZ', text: 'Belize', icon: 'flag-icon flag-icon-bz' },
  { id: 'BJ', text: 'Benin', icon: 'flag-icon flag-icon-bj' },
  { id: 'BM', text: 'Bermuda', icon: 'flag-icon flag-icon-bm' },
  { id: 'BT', text: 'Bhutan', icon: 'flag-icon flag-icon-bt' },
  { id: 'BO', text: 'Bolivia', icon: 'flag-icon flag-icon-bo' },
  { id: 'BA', text: 'Bosnia and Herzegovina', icon: 'flag-icon flag-icon-ba' },
  { id: 'BW', text: 'Botswana', icon: 'flag-icon flag-icon-bw' },
  { id: 'BV', text: 'Bouvet Island', icon: 'flag-icon flag-icon-bv' },
  { id: 'BR', text: 'Brazil', icon: 'flag-icon flag-icon-br' },
  { id: 'IO', text: 'British Indian Ocean Territory', icon: 'flag-icon flag-icon-io' },
  { id: 'BN', text: 'Brunei Darussalam', icon: 'flag-icon flag-icon-bn' },
  { id: 'BG', text: 'Bulgaria', icon: 'flag-icon flag-icon-bg' },
  { id: 'BF', text: 'Burkina Faso', icon: 'flag-icon flag-icon-bf' },
  { id: 'BI', text: 'Burundi', icon: 'flag-icon flag-icon-bi' },
  { id: 'KH', text: 'Cambodia', icon: 'flag-icon flag-icon-kh' },
  { id: 'CM', text: 'Cameroon', icon: 'flag-icon flag-icon-cm' },
  { id: 'CA', text: 'Canada', icon: 'flag-icon flag-icon-ca' },
  { id: 'CV', text: 'Cape Verde', icon: 'flag-icon flag-icon-cv' },
  { id: 'KY', text: 'Cayman Islands', icon: 'flag-icon flag-icon-ky' },
  { id: 'CF', text: 'Central African Republic', icon: 'flag-icon flag-icon-cf' },
  { id: 'TD', text: 'Chad', icon: 'flag-icon flag-icon-td' },
  { id: 'CL', text: 'Chile', icon: 'flag-icon flag-icon-cl' },
  { id: 'CN', text: 'China', icon: 'flag-icon flag-icon-cn' },
  { id: 'CX', text: 'Christmas Island', icon: 'flag-icon flag-icon-cx' },
  { id: 'CC', text: 'Cocos (Keeling) Islands', icon: 'flag-icon flag-icon-cc' },
  { id: 'CO', text: 'Colombia', icon: 'flag-icon flag-icon-co' },
  { id: 'KM', text: 'Comoros', icon: 'flag-icon flag-icon-km' },
  { id: 'CG', text: 'Congo', icon: 'flag-icon flag-icon-cg' },
  { id: 'CD', text: 'Congo, The Democratic Republic of the', icon: 'flag-icon flag-icon-cd' },
  { id: 'CK', text: 'Cook Islands', icon: 'flag-icon flag-icon-ck' },
  { id: 'CR', text: 'Costa Rica', icon: 'flag-icon flag-icon-cr' },
  { id: 'CI', text: "Cote D'Ivoire", icon: 'flag-icon flag-icon-ci' },
  { id: 'HR', text: 'Croatia', icon: 'flag-icon flag-icon-hr' },
  { id: 'CU', text: 'Cuba', icon: 'flag-icon flag-icon-cu' },
  { id: 'CY', text: 'Cyprus', icon: 'flag-icon flag-icon-cy' },
  { id: 'CZ', text: 'Czech Republic', icon: 'flag-icon flag-icon-cz' },
  { id: 'DK', text: 'Denmark', icon: 'flag-icon flag-icon-dk' },
  { id: 'DJ', text: 'Djibouti', icon: 'flag-icon flag-icon-dj' },
  { id: 'DM', text: 'Dominica', icon: 'flag-icon flag-icon-dm' },
  { id: 'DO', text: 'Dominican Republic', icon: 'flag-icon flag-icon-do' },
  { id: 'EC', text: 'Ecuador', icon: 'flag-icon flag-icon-ec' },
  { id: 'EG', text: 'Egypt', icon: 'flag-icon flag-icon-eg' },
  { id: 'SV', text: 'El Salvador', icon: 'flag-icon flag-icon-sv' },
  { id: 'GQ', text: 'Equatorial Guinea', icon: 'flag-icon flag-icon-gq' },
  { id: 'ER', text: 'Eritrea', icon: 'flag-icon flag-icon-er' },
  { id: 'EE', text: 'Estonia', icon: 'flag-icon flag-icon-ee' },
  { id: 'ET', text: 'Ethiopia', icon: 'flag-icon flag-icon-et' },
  { id: 'FK', text: 'Falkland Islands (Malvinas)', icon: 'flag-icon flag-icon-fk' },
  { id: 'FO', text: 'Faroe Islands', icon: 'flag-icon flag-icon-fo' },
  { id: 'FJ', text: 'Fiji', icon: 'flag-icon flag-icon-fj' },
  { id: 'FI', text: 'Finland', icon: 'flag-icon flag-icon-fi' },
  { id: 'FR', text: 'France', icon: 'flag-icon flag-icon-fr' },
  { id: 'GF', text: 'French Guiana', icon: 'flag-icon flag-icon-gf' },
  { id: 'PF', text: 'French Polynesia', icon: 'flag-icon flag-icon-pf' },
  { id: 'TF', text: 'French Southern Territories', icon: 'flag-icon flag-icon-tf' },
  { id: 'GA', text: 'Gabon', icon: 'flag-icon flag-icon-ga' },
  { id: 'GM', text: 'Gambia', icon: 'flag-icon flag-icon-gm' },
  { id: 'GE', text: 'Georgia', icon: 'flag-icon flag-icon-ge' },
  { id: 'DE', text: 'Germany', icon: 'flag-icon flag-icon-de' },
  { id: 'GH', text: 'Ghana', icon: 'flag-icon flag-icon-gh' },
  { id: 'GI', text: 'Gibraltar', icon: 'flag-icon flag-icon-gi' },
  { id: 'GR', text: 'Greece', icon: 'flag-icon flag-icon-gr' },
  { id: 'GL', text: 'Greenland', icon: 'flag-icon flag-icon-gl' },
  { id: 'GD', text: 'Grenada', icon: 'flag-icon flag-icon-gd' },
  { id: 'GP', text: 'Guadeloupe', icon: 'flag-icon flag-icon-gp' },
  { id: 'GU', text: 'Guam', icon: 'flag-icon flag-icon-gu' },
  { id: 'GT', text: 'Guatemala', icon: 'flag-icon flag-icon-gt' },
  { id: 'GG', text: 'Guernsey', icon: 'flag-icon flag-icon-gg' },
  { id: 'GN', text: 'Guinea', icon: 'flag-icon flag-icon-gn' },
  { id: 'GW', text: 'Guinea-Bissau', icon: 'flag-icon flag-icon-gw' },
  { id: 'GY', text: 'Guyana', icon: 'flag-icon flag-icon-gy' },
  { id: 'HT', text: 'Haiti', icon: 'flag-icon flag-icon-ht' },
  { id: 'HM', text: 'Heard Island and Mcdonald Islands', icon: 'flag-icon flag-icon-hm' },
  { id: 'VA', text: 'Holy See (Vatican City State)', icon: 'flag-icon flag-icon-va' },
  { id: 'HN', text: 'Honduras', icon: 'flag-icon flag-icon-hn' },
  { id: 'HK', text: 'Hong Kong', icon: 'flag-icon flag-icon-hk' },
  { id: 'HU', text: 'Hungary', icon: 'flag-icon flag-icon-hu' },
  { id: 'IS', text: 'Iceland', icon: 'flag-icon flag-icon-is' },
  { id: 'IN', text: 'India', icon: 'flag-icon flag-icon-in' },
  { id: 'ID', text: 'Indonesia', icon: 'flag-icon flag-icon-id' },
  { id: 'IR', text: 'Iran, Islamic Republic Of', icon: 'flag-icon flag-icon-ir' },
  { id: 'IQ', text: 'Iraq', icon: 'flag-icon flag-icon-iq' },
  { id: 'IE', text: 'Ireland', icon: 'flag-icon flag-icon-ie' },
  { id: 'IM', text: 'Isle of Man', icon: 'flag-icon flag-icon-im' },
  { id: 'IL', text: 'Israel', icon: 'flag-icon flag-icon-il' },
  { id: 'IT', text: 'Italy', icon: 'flag-icon flag-icon-it' },
  { id: 'JM', text: 'Jamaica', icon: 'flag-icon flag-icon-jm' },
  { id: 'JP', text: 'Japan', icon: 'flag-icon flag-icon-jp' },
  { id: 'JE', text: 'Jersey', icon: 'flag-icon flag-icon-je' },
  { id: 'JO', text: 'Jordan', icon: 'flag-icon flag-icon-jo' },
  { id: 'KZ', text: 'Kazakhstan', icon: 'flag-icon flag-icon-kz' },
  { id: 'KE', text: 'Kenya', icon: 'flag-icon flag-icon-ke' },
  { id: 'KI', text: 'Kiribati', icon: 'flag-icon flag-icon-ki' },
  { id: 'KP', text: "Korea, Democratic People's Republic of", icon: 'flag-icon flag-icon-kp' },
  { id: 'KR', text: 'Korea, Republic of', icon: 'flag-icon flag-icon-kr' },
  { id: 'KW', text: 'Kuwait', icon: 'flag-icon flag-icon-kw' },
  { id: 'KG', text: 'Kyrgyzstan', icon: 'flag-icon flag-icon-kg' },
  { id: 'LA', text: "Lao People's Democratic Republic", icon: 'flag-icon flag-icon-la' },
  { id: 'LV', text: 'Latvia', icon: 'flag-icon flag-icon-lv' },
  { id: 'LB', text: 'Lebanon', icon: 'flag-icon flag-icon-lb' },
  { id: 'LS', text: 'Lesotho', icon: 'flag-icon flag-icon-ls' },
  { id: 'LR', text: 'Liberia', icon: 'flag-icon flag-icon-lr' },
  { id: 'LY', text: 'Libyan Arab Jamahiriya', icon: 'flag-icon flag-icon-ly' },
  { id: 'LI', text: 'Liechtenstein', icon: 'flag-icon flag-icon-li' },
  { id: 'LT', text: 'Lithuania', icon: 'flag-icon flag-icon-lt' },
  { id: 'LU', text: 'Luxembourg', icon: 'flag-icon flag-icon-lu' },
  { id: 'MO', text: 'Macao', icon: 'flag-icon flag-icon-mo' },
  { id: 'MK', text: 'Macedonia, The Former Yugoslav Republic of', icon: 'flag-icon flag-icon-mk' },
  { id: 'MG', text: 'Madagascar', icon: 'flag-icon flag-icon-mg' },
  { id: 'MW', text: 'Malawi', icon: 'flag-icon flag-icon-mw' },
  { id: 'MY', text: 'Malaysia', icon: 'flag-icon flag-icon-my' },
  { id: 'MV', text: 'Maldives', icon: 'flag-icon flag-icon-mv' },
  { id: 'ML', text: 'Mali', icon: 'flag-icon flag-icon-ml' },
  { id: 'MT', text: 'Malta', icon: 'flag-icon flag-icon-mt' },
  { id: 'MH', text: 'Marshall Islands', icon: 'flag-icon flag-icon-mh' },
  { id: 'MQ', text: 'Martinique', icon: 'flag-icon flag-icon-mq' },
  { id: 'MR', text: 'Mauritania', icon: 'flag-icon flag-icon-mr' },
  { id: 'MU', text: 'Mauritius', icon: 'flag-icon flag-icon-mu' },
  { id: 'YT', text: 'Mayotte', icon: 'flag-icon flag-icon-yt' },
  { id: 'MX', text: 'Mexico', icon: 'flag-icon flag-icon-mx' },
  { id: 'FM', text: 'Micronesia, Federated States of', icon: 'flag-icon flag-icon-fm' },
  { id: 'MD', text: 'Moldova, Republic of', icon: 'flag-icon flag-icon-md' },
  { id: 'MC', text: 'Monaco', icon: 'flag-icon flag-icon-mc' },
  { id: 'ME', text: 'Montenegro', icon: 'flag-icon flag-icon-me' },
  { id: 'MN', text: 'Mongolia', icon: 'flag-icon flag-icon-mn' },
  { id: 'MS', text: 'Montserrat', icon: 'flag-icon flag-icon-ms' },
  { id: 'MA', text: 'Morocco', icon: 'flag-icon flag-icon-ma' },
  { id: 'MZ', text: 'Mozambique', icon: 'flag-icon flag-icon-mz' },
  { id: 'MM', text: 'Myanmar', icon: 'flag-icon flag-icon-mm' },
  { id: 'NA', text: 'Namibia', icon: 'flag-icon flag-icon-na' },
  { id: 'NR', text: 'Nauru', icon: 'flag-icon flag-icon-nr' },
  { id: 'NP', text: 'Nepal', icon: 'flag-icon flag-icon-np' },
  { id: 'NL', text: 'Netherlands', icon: 'flag-icon flag-icon-nl' },
  { id: 'NC', text: 'New Caledonia', icon: 'flag-icon flag-icon-nc' },
  { id: 'NZ', text: 'New Zealand', icon: 'flag-icon flag-icon-nz' },
  { id: 'NI', text: 'Nicaragua', icon: 'flag-icon flag-icon-ni' },
  { id: 'NE', text: 'Niger', icon: 'flag-icon flag-icon-ne' },
  { id: 'NG', text: 'Nigeria', icon: 'flag-icon flag-icon-ng' },
  { id: 'NU', text: 'Niue', icon: 'flag-icon flag-icon-nu' },
  { id: 'NF', text: 'Norfolk Island', icon: 'flag-icon flag-icon-nf' },
  { id: 'MP', text: 'Northern Mariana Islands', icon: 'flag-icon flag-icon-mp' },
  { id: 'NO', text: 'Norway', icon: 'flag-icon flag-icon-no' },
  { id: 'OM', text: 'Oman', icon: 'flag-icon flag-icon-om' },
  { id: 'PK', text: 'Pakistan', icon: 'flag-icon flag-icon-pk' },
  { id: 'PW', text: 'Palau', icon: 'flag-icon flag-icon-pw' },
  { id: 'PS', text: 'Palestinian Territory, Occupied', icon: 'flag-icon flag-icon-ps' },
  { id: 'PA', text: 'Panama', icon: 'flag-icon flag-icon-pa' },
  { id: 'PG', text: 'Papua New Guinea', icon: 'flag-icon flag-icon-pg' },
  { id: 'PY', text: 'Paraguay', icon: 'flag-icon flag-icon-py' },
  { id: 'PE', text: 'Peru', icon: 'flag-icon flag-icon-pe' },
  { id: 'PH', text: 'Philippines', icon: 'flag-icon flag-icon-ph' },
  { id: 'PN', text: 'Pitcairn', icon: 'flag-icon flag-icon-pn' },
  { id: 'PL', text: 'Poland', icon: 'flag-icon flag-icon-pl' },
  { id: 'PT', text: 'Portugal', icon: 'flag-icon flag-icon-pt' },
  { id: 'PR', text: 'Puerto Rico', icon: 'flag-icon flag-icon-pr' },
  { id: 'QA', text: 'Qatar', icon: 'flag-icon flag-icon-qa' },
  { id: 'RE', text: 'Reunion', icon: 'flag-icon flag-icon-re' },
  { id: 'RO', text: 'Romania', icon: 'flag-icon flag-icon-ro' },
  { id: 'RU', text: 'Russian Federation', icon: 'flag-icon flag-icon-ru' },
  { id: 'RW', text: 'Rwanda', icon: 'flag-icon flag-icon-rw' },
  { id: 'SH', text: 'Saint Helena', icon: 'flag-icon flag-icon-sh' },
  { id: 'KN', text: 'Saint Kitts and Nevis', icon: 'flag-icon flag-icon-kn' },
  { id: 'LC', text: 'Saint Lucia', icon: 'flag-icon flag-icon-lc' },
  { id: 'PM', text: 'Saint Pierre and Miquelon', icon: 'flag-icon flag-icon-pm' },
  { id: 'VC', text: 'Saint Vincent and the Grenadines', icon: 'flag-icon flag-icon-vc' },
  { id: 'WS', text: 'Samoa', icon: 'flag-icon flag-icon-ws' },
  { id: 'SM', text: 'San Marino', icon: 'flag-icon flag-icon-sm' },
  { id: 'ST', text: 'Sao Tome and Principe', icon: 'flag-icon flag-icon-st' },
  { id: 'SA', text: 'Saudi Arabia', icon: 'flag-icon flag-icon-sa' },
  { id: 'SN', text: 'Senegal', icon: 'flag-icon flag-icon-sn' },
  { id: 'RS', text: 'Serbia', icon: 'flag-icon flag-icon-rs' },
  { id: 'SC', text: 'Seychelles', icon: 'flag-icon flag-icon-sc' },
  { id: 'SL', text: 'Sierra Leone', icon: 'flag-icon flag-icon-sl' },
  { id: 'SG', text: 'Singapore', icon: 'flag-icon flag-icon-sg' },
  { id: 'SK', text: 'Slovakia', icon: 'flag-icon flag-icon-sk' },
  { id: 'SI', text: 'Slovenia', icon: 'flag-icon flag-icon-si' },
  { id: 'SB', text: 'Solomon Islands', icon: 'flag-icon flag-icon-sb' },
  { id: 'SO', text: 'Somalia', icon: 'flag-icon flag-icon-so' },
  { id: 'ZA', text: 'South Africa', icon: 'flag-icon flag-icon-za' },
  {
    id: 'GS',
    text: 'South Georgia and the South Sandwich Islands',
    icon: 'flag-icon flag-icon-gs'
  },
  { id: 'ES', text: 'Spain', icon: 'flag-icon flag-icon-es' },
  { id: 'LK', text: 'Sri Lanka', icon: 'flag-icon flag-icon-lk' },
  { id: 'SD', text: 'Sudan', icon: 'flag-icon flag-icon-sd' },
  { id: 'SR', text: 'Suriname', icon: 'flag-icon flag-icon-sr' },
  { id: 'SJ', text: 'Svalbard and Jan Mayen', icon: 'flag-icon flag-icon-sj' },
  { id: 'SZ', text: 'Swaziland', icon: 'flag-icon flag-icon-sz' },
  { id: 'SE', text: 'Sweden', icon: 'flag-icon flag-icon-se' },
  { id: 'CH', text: 'Switzerland', icon: 'flag-icon flag-icon-ch' },
  { id: 'SY', text: 'Syrian Arab Republic', icon: 'flag-icon flag-icon-sy' },
  { id: 'TW', text: 'Taiwan, Province of China', icon: 'flag-icon flag-icon-tw' },
  { id: 'TJ', text: 'Tajikistan', icon: 'flag-icon flag-icon-tj' },
  { id: 'TZ', text: 'Tanzania, United Republic of', icon: 'flag-icon flag-icon-tz' },
  { id: 'TH', text: 'Thailand', icon: 'flag-icon flag-icon-th' },
  { id: 'TL', text: 'Timor-Leste', icon: 'flag-icon flag-icon-tl' },
  { id: 'TG', text: 'Togo', icon: 'flag-icon flag-icon-tg' },
  { id: 'TK', text: 'Tokelau', icon: 'flag-icon flag-icon-tk' },
  { id: 'TO', text: 'Tonga', icon: 'flag-icon flag-icon-to' },
  { id: 'TT', text: 'Trinidad and Tobago', icon: 'flag-icon flag-icon-tt' },
  { id: 'TN', text: 'Tunisia', icon: 'flag-icon flag-icon-tn' },
  { id: 'TR', text: 'Turkey', icon: 'flag-icon flag-icon-tr' },
  { id: 'TM', text: 'Turkmenistan', icon: 'flag-icon flag-icon-tm' },
  { id: 'TC', text: 'Turks and Caicos Islands', icon: 'flag-icon flag-icon-tc' },
  { id: 'TV', text: 'Tuvalu', icon: 'flag-icon flag-icon-tv' },
  { id: 'UG', text: 'Uganda', icon: 'flag-icon flag-icon-ug' },
  { id: 'UA', text: 'Ukraine', icon: 'flag-icon flag-icon-ua' },
  { id: 'AE', text: 'United Arab Emirates', icon: 'flag-icon flag-icon-ae' },
  { id: 'GB', text: 'United Kingdom', icon: 'flag-icon flag-icon-gb' },
  { id: 'US', text: 'United States', icon: 'flag-icon flag-icon-us' },
  { id: 'UY', text: 'Uruguay', icon: 'flag-icon flag-icon-uy' },
  { id: 'UZ', text: 'Uzbekistan', icon: 'flag-icon flag-icon-uz' },
  { id: 'VU', text: 'Vanuatu', icon: 'flag-icon flag-icon-vu' },
  { id: 'VE', text: 'Venezuela', icon: 'flag-icon flag-icon-ve' },
  { id: 'VN', text: 'Viet Nam', icon: 'flag-icon flag-icon-vn' },
  { id: 'VG', text: 'Virgin Islands, British', icon: 'flag-icon flag-icon-vg' },
  { id: 'VI', text: 'Virgin Islands, U.S.', icon: 'flag-icon flag-icon-vi' },
  { id: 'WF', text: 'Wallis and Futuna', icon: 'flag-icon flag-icon-wf' },
  { id: 'EH', text: 'Western Sahara', icon: 'flag-icon flag-icon-eh' },
  { id: 'YE', text: 'Yemen', icon: 'flag-icon flag-icon-ye' },
  { id: 'ZM', text: 'Zambia', icon: 'flag-icon flag-icon-zm' },
  { id: 'ZW', text: 'Zimbabwe', icon: 'flag-icon flag-icon-zw' }
];

export const countryCodes = [
  {
    name: 'Afghanistan',
    text: '+93',
    id: 'AF'
  },
  {
    name: 'Aland Islands',
    text: '+358',
    id: 'AX'
  },
  {
    name: 'Albania',
    text: '+355',
    id: 'AL'
  },
  {
    name: 'Algeria',
    text: '+213',
    id: 'DZ'
  },
  {
    name: 'AmericanSamoa',
    text: '+1684',
    id: 'AS'
  },
  {
    name: 'Andorra',
    text: '+376',
    id: 'AD'
  },
  {
    name: 'Angola',
    text: '+244',
    id: 'AO'
  },
  {
    name: 'Anguilla',
    text: '+1264',
    id: 'AI'
  },
  {
    name: 'Antarctica',
    text: '+672',
    id: 'AQ'
  },
  {
    name: 'Antigua and Barbuda',
    text: '+1268',
    id: 'AG'
  },
  {
    name: 'Argentina',
    text: '+54',
    id: 'AR'
  },
  {
    name: 'Armenia',
    text: '+374',
    id: 'AM'
  },
  {
    name: 'Aruba',
    text: '+297',
    id: 'AW'
  },
  {
    name: 'Australia',
    text: '+61',
    id: 'AU'
  },
  {
    name: 'Austria',
    text: '+43',
    id: 'AT'
  },
  {
    name: 'Azerbaijan',
    text: '+994',
    id: 'AZ'
  },
  {
    name: 'Bahamas',
    text: '+1242',
    id: 'BS'
  },
  {
    name: 'Bahrain',
    text: '+973',
    id: 'BH'
  },
  {
    name: 'Bangladesh',
    text: '+880',
    id: 'BD'
  },
  {
    name: 'Barbados',
    text: '+1246',
    id: 'BB'
  },
  {
    name: 'Belarus',
    text: '+375',
    id: 'BY'
  },
  {
    name: 'Belgium',
    text: '+32',
    id: 'BE'
  },
  {
    name: 'Belize',
    text: '+501',
    id: 'BZ'
  },
  {
    name: 'Benin',
    text: '+229',
    id: 'BJ'
  },
  {
    name: 'Bermuda',
    text: '+1441',
    id: 'BM'
  },
  {
    name: 'Bhutan',
    text: '+975',
    id: 'BT'
  },
  {
    name: 'Bolivia, Plurinational State of',
    text: '+591',
    id: 'BO'
  },
  {
    name: 'Bosnia and Herzegovina',
    text: '+387',
    id: 'BA'
  },
  {
    name: 'Botswana',
    text: '+267',
    id: 'BW'
  },
  {
    name: 'Brazil',
    text: '+55',
    id: 'BR'
  },
  {
    name: 'British Indian Ocean Territory',
    text: '+246',
    id: 'IO'
  },
  {
    name: 'Brunei Darussalam',
    text: '+673',
    id: 'BN'
  },
  {
    name: 'Bulgaria',
    text: '+359',
    id: 'BG'
  },
  {
    name: 'Burkina Faso',
    text: '+226',
    id: 'BF'
  },
  {
    name: 'Burundi',
    text: '+257',
    id: 'BI'
  },
  {
    name: 'Cambodia',
    text: '+855',
    id: 'KH'
  },
  {
    name: 'Cameroon',
    text: '+237',
    id: 'CM'
  },
  {
    name: 'Canada',
    text: '+1',
    id: 'CA'
  },
  {
    name: 'Cape Verde',
    text: '+238',
    id: 'CV'
  },
  {
    name: 'Cayman Islands',
    text: '+ 345',
    id: 'KY'
  },
  {
    name: 'Central African Republic',
    text: '+236',
    id: 'CF'
  },
  {
    name: 'Chad',
    text: '+235',
    id: 'TD'
  },
  {
    name: 'Chile',
    text: '+56',
    id: 'CL'
  },
  {
    name: 'China',
    text: '+86',
    id: 'CN'
  },
  {
    name: 'Christmas Island',
    text: '+61',
    id: 'CX'
  },
  {
    name: 'Cocos (Keeling) Islands',
    text: '+61',
    id: 'CC'
  },
  {
    name: 'Colombia',
    text: '+57',
    id: 'CO'
  },
  {
    name: 'Comoros',
    text: '+269',
    id: 'KM'
  },
  {
    name: 'Congo',
    text: '+242',
    id: 'CG'
  },
  {
    name: 'Congo, The Democratic Republic of the Congo',
    text: '+243',
    id: 'CD'
  },
  {
    name: 'Cook Islands',
    text: '+682',
    id: 'CK'
  },
  {
    name: 'Costa Rica',
    text: '+506',
    id: 'CR'
  },
  {
    name: "Cote d'Ivoire",
    text: '+225',
    id: 'CI'
  },
  {
    name: 'Croatia',
    text: '+385',
    id: 'HR'
  },
  {
    name: 'Cuba',
    text: '+53',
    id: 'CU'
  },
  {
    name: 'Cyprus',
    text: '+357',
    id: 'CY'
  },
  {
    name: 'Czech Republic',
    text: '+420',
    id: 'CZ'
  },
  {
    name: 'Denmark',
    text: '+45',
    id: 'DK'
  },
  {
    name: 'Djibouti',
    text: '+253',
    id: 'DJ'
  },
  {
    name: 'Dominica',
    text: '+1767',
    id: 'DM'
  },
  {
    name: 'Dominican Republic',
    text: '+1849',
    id: 'DO'
  },
  {
    name: 'Ecuador',
    text: '+593',
    id: 'EC'
  },
  {
    name: 'Egypt',
    text: '+20',
    id: 'EG'
  },
  {
    name: 'El Salvador',
    text: '+503',
    id: 'SV'
  },
  {
    name: 'Equatorial Guinea',
    text: '+240',
    id: 'GQ'
  },
  {
    name: 'Eritrea',
    text: '+291',
    id: 'ER'
  },
  {
    name: 'Estonia',
    text: '+372',
    id: 'EE'
  },
  {
    name: 'Ethiopia',
    text: '+251',
    id: 'ET'
  },
  {
    name: 'Falkland Islands (Malvinas)',
    text: '+500',
    id: 'FK'
  },
  {
    name: 'Faroe Islands',
    text: '+298',
    id: 'FO'
  },
  {
    name: 'Fiji',
    text: '+679',
    id: 'FJ'
  },
  {
    name: 'Finland',
    text: '+358',
    id: 'FI'
  },
  {
    name: 'France',
    text: '+33',
    id: 'FR'
  },
  {
    name: 'French Guiana',
    text: '+594',
    id: 'GF'
  },
  {
    name: 'French Polynesia',
    text: '+689',
    id: 'PF'
  },
  {
    name: 'Gabon',
    text: '+241',
    id: 'GA'
  },
  {
    name: 'Gambia',
    text: '+220',
    id: 'GM'
  },
  {
    name: 'Georgia',
    text: '+995',
    id: 'GE'
  },
  {
    name: 'Germany',
    text: '+49',
    id: 'DE'
  },
  {
    name: 'Ghana',
    text: '+233',
    id: 'GH'
  },
  {
    name: 'Gibraltar',
    text: '+350',
    id: 'GI'
  },
  {
    name: 'Greece',
    text: '+30',
    id: 'GR'
  },
  {
    name: 'Greenland',
    text: '+299',
    id: 'GL'
  },
  {
    name: 'Grenada',
    text: '+1473',
    id: 'GD'
  },
  {
    name: 'Guadeloupe',
    text: '+590',
    id: 'GP'
  },
  {
    name: 'Guam',
    text: '+1671',
    id: 'GU'
  },
  {
    name: 'Guatemala',
    text: '+502',
    id: 'GT'
  },
  {
    name: 'Guernsey',
    text: '+44',
    id: 'GG'
  },
  {
    name: 'Guinea',
    text: '+224',
    id: 'GN'
  },
  {
    name: 'Guinea-Bissau',
    text: '+245',
    id: 'GW'
  },
  {
    name: 'Guyana',
    text: '+595',
    id: 'GY'
  },
  {
    name: 'Haiti',
    text: '+509',
    id: 'HT'
  },
  {
    name: 'Holy See (Vatican City State)',
    text: '+379',
    id: 'VA'
  },
  {
    name: 'Honduras',
    text: '+504',
    id: 'HN'
  },
  {
    name: 'Hong Kong',
    text: '+852',
    id: 'HK'
  },
  {
    name: 'Hungary',
    text: '+36',
    id: 'HU'
  },
  {
    name: 'Iceland',
    text: '+354',
    id: 'IS'
  },
  {
    name: 'India',
    text: '+91',
    id: 'IN'
  },
  {
    name: 'Indonesia',
    text: '+62',
    id: 'ID'
  },
  {
    name: 'Iran, Islamic Republic of Persian Gulf',
    text: '+98',
    id: 'IR'
  },
  {
    name: 'Iraq',
    text: '+964',
    id: 'IQ'
  },
  {
    name: 'Ireland',
    text: '+353',
    id: 'IE'
  },
  {
    name: 'Isle of Man',
    text: '+44',
    id: 'IM'
  },
  {
    name: 'Israel',
    text: '+972',
    id: 'IL'
  },
  {
    name: 'Italy',
    text: '+39',
    id: 'IT'
  },
  {
    name: 'Jamaica',
    text: '+1876',
    id: 'JM'
  },
  {
    name: 'Japan',
    text: '+81',
    id: 'JP'
  },
  {
    name: 'Jersey',
    text: '+44',
    id: 'JE'
  },
  {
    name: 'Jordan',
    text: '+962',
    id: 'JO'
  },
  {
    name: 'Kazakhstan',
    text: '+77',
    id: 'KZ'
  },
  {
    name: 'Kenya',
    text: '+254',
    id: 'KE'
  },
  {
    name: 'Kiribati',
    text: '+686',
    id: 'KI'
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    text: '+850',
    id: 'KP'
  },
  {
    name: 'Korea, Republic of South Korea',
    text: '+82',
    id: 'KR'
  },
  {
    name: 'Kuwait',
    text: '+965',
    id: 'KW'
  },
  {
    name: 'Kyrgyzstan',
    text: '+996',
    id: 'KG'
  },
  {
    name: 'Laos',
    text: '+856',
    id: 'LA'
  },
  {
    name: 'Latvia',
    text: '+371',
    id: 'LV'
  },
  {
    name: 'Lebanon',
    text: '+961',
    id: 'LB'
  },
  {
    name: 'Lesotho',
    text: '+266',
    id: 'LS'
  },
  {
    name: 'Liberia',
    text: '+231',
    id: 'LR'
  },
  {
    name: 'Libyan Arab Jamahiriya',
    text: '+218',
    id: 'LY'
  },
  {
    name: 'Liechtenstein',
    text: '+423',
    id: 'LI'
  },
  {
    name: 'Lithuania',
    text: '+370',
    id: 'LT'
  },
  {
    name: 'Luxembourg',
    text: '+352',
    id: 'LU'
  },
  {
    name: 'Macao',
    text: '+853',
    id: 'MO'
  },
  {
    name: 'Macedonia',
    text: '+389',
    id: 'MK'
  },
  {
    name: 'Madagascar',
    text: '+261',
    id: 'MG'
  },
  {
    name: 'Malawi',
    text: '+265',
    id: 'MW'
  },
  {
    name: 'Malaysia',
    text: '+60',
    id: 'MY'
  },
  {
    name: 'Maldives',
    text: '+960',
    id: 'MV'
  },
  {
    name: 'Mali',
    text: '+223',
    id: 'ML'
  },
  {
    name: 'Malta',
    text: '+356',
    id: 'MT'
  },
  {
    name: 'Marshall Islands',
    text: '+692',
    id: 'MH'
  },
  {
    name: 'Martinique',
    text: '+596',
    id: 'MQ'
  },
  {
    name: 'Mauritania',
    text: '+222',
    id: 'MR'
  },
  {
    name: 'Mauritius',
    text: '+230',
    id: 'MU'
  },
  {
    name: 'Mayotte',
    text: '+262',
    id: 'YT'
  },
  {
    name: 'Mexico',
    text: '+52',
    id: 'MX'
  },
  {
    name: 'Micronesia, Federated States of Micronesia',
    text: '+691',
    id: 'FM'
  },
  {
    name: 'Moldova',
    text: '+373',
    id: 'MD'
  },
  {
    name: 'Monaco',
    text: '+377',
    id: 'MC'
  },
  {
    name: 'Mongolia',
    text: '+976',
    id: 'MN'
  },
  {
    name: 'Montenegro',
    text: '+382',
    id: 'ME'
  },
  {
    name: 'Montserrat',
    text: '+1664',
    id: 'MS'
  },
  {
    name: 'Morocco',
    text: '+212',
    id: 'MA'
  },
  {
    name: 'Mozambique',
    text: '+258',
    id: 'MZ'
  },
  {
    name: 'Myanmar',
    text: '+95',
    id: 'MM'
  },
  {
    name: 'Namibia',
    text: '+264',
    id: 'NA'
  },
  {
    name: 'Nauru',
    text: '+674',
    id: 'NR'
  },
  {
    name: 'Nepal',
    text: '+977',
    id: 'NP'
  },
  {
    name: 'Netherlands',
    text: '+31',
    id: 'NL'
  },
  {
    name: 'Netherlands Antilles',
    text: '+599',
    id: 'AN'
  },
  {
    name: 'New Caledonia',
    text: '+687',
    id: 'NC'
  },
  {
    name: 'New Zealand',
    text: '+64',
    id: 'NZ'
  },
  {
    name: 'Nicaragua',
    text: '+505',
    id: 'NI'
  },
  {
    name: 'Niger',
    text: '+227',
    id: 'NE'
  },
  {
    name: 'Nigeria',
    text: '+234',
    id: 'NG'
  },
  {
    name: 'Niue',
    text: '+683',
    id: 'NU'
  },
  {
    name: 'Norfolk Island',
    text: '+672',
    id: 'NF'
  },
  {
    name: 'Northern Mariana Islands',
    text: '+1670',
    id: 'MP'
  },
  {
    name: 'Norway',
    text: '+47',
    id: 'NO'
  },
  {
    name: 'Oman',
    text: '+968',
    id: 'OM'
  },
  {
    name: 'Pakistan',
    text: '+92',
    id: 'PK'
  },
  {
    name: 'Palau',
    text: '+680',
    id: 'PW'
  },
  {
    name: 'Palestinian Territory, Occupied',
    text: '+970',
    id: 'PS'
  },
  {
    name: 'Panama',
    text: '+507',
    id: 'PA'
  },
  {
    name: 'Papua New Guinea',
    text: '+675',
    id: 'PG'
  },
  {
    name: 'Paraguay',
    text: '+595',
    id: 'PY'
  },
  {
    name: 'Peru',
    text: '+51',
    id: 'PE'
  },
  {
    name: 'Philippines',
    text: '+63',
    id: 'PH'
  },
  {
    name: 'Pitcairn',
    text: '+872',
    id: 'PN'
  },
  {
    name: 'Poland',
    text: '+48',
    id: 'PL'
  },
  {
    name: 'Portugal',
    text: '+351',
    id: 'PT'
  },
  {
    name: 'Puerto Rico',
    text: '+1939',
    id: 'PR'
  },
  {
    name: 'Qatar',
    text: '+974',
    id: 'QA'
  },
  {
    name: 'Romania',
    text: '+40',
    id: 'RO'
  },
  {
    name: 'Russia',
    text: '+7',
    id: 'RU'
  },
  {
    name: 'Rwanda',
    text: '+250',
    id: 'RW'
  },
  {
    name: 'Reunion',
    text: '+262',
    id: 'RE'
  },
  {
    name: 'Saint Barthelemy',
    text: '+590',
    id: 'BL'
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    text: '+290',
    id: 'SH'
  },
  {
    name: 'Saint Kitts and Nevis',
    text: '+1869',
    id: 'KN'
  },
  {
    name: 'Saint Lucia',
    text: '+1758',
    id: 'LC'
  },
  {
    name: 'Saint Martin',
    text: '+590',
    id: 'MF'
  },
  {
    name: 'Saint Pierre and Miquelon',
    text: '+508',
    id: 'PM'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    text: '+1784',
    id: 'VC'
  },
  {
    name: 'Samoa',
    text: '+685',
    id: 'WS'
  },
  {
    name: 'San Marino',
    text: '+378',
    id: 'SM'
  },
  {
    name: 'Sao Tome and Principe',
    text: '+239',
    id: 'ST'
  },
  {
    name: 'Saudi Arabia',
    text: '+966',
    id: 'SA'
  },
  {
    name: 'Senegal',
    text: '+221',
    id: 'SN'
  },
  {
    name: 'Serbia',
    text: '+381',
    id: 'RS'
  },
  {
    name: 'Seychelles',
    text: '+248',
    id: 'SC'
  },
  {
    name: 'Sierra Leone',
    text: '+232',
    id: 'SL'
  },
  {
    name: 'Singapore',
    text: '+65',
    id: 'SG'
  },
  {
    name: 'Slovakia',
    text: '+421',
    id: 'SK'
  },
  {
    name: 'Slovenia',
    text: '+386',
    id: 'SI'
  },
  {
    name: 'Solomon Islands',
    text: '+677',
    id: 'SB'
  },
  {
    name: 'Somalia',
    text: '+252',
    id: 'SO'
  },
  {
    name: 'South Africa',
    text: '+27',
    id: 'ZA'
  },
  {
    name: 'South Sudan',
    text: '+211',
    id: 'SS'
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    text: '+500',
    id: 'GS'
  },
  {
    name: 'Spain',
    text: '+34',
    id: 'ES'
  },
  {
    name: 'Sri Lanka',
    text: '+94',
    id: 'LK'
  },
  {
    name: 'Sudan',
    text: '+249',
    id: 'SD'
  },
  {
    name: 'Suriname',
    text: '+597',
    id: 'SR'
  },
  {
    name: 'Svalbard and Jan Mayen',
    text: '+47',
    id: 'SJ'
  },
  {
    name: 'Swaziland',
    text: '+268',
    id: 'SZ'
  },
  {
    name: 'Sweden',
    text: '+46',
    id: 'SE'
  },
  {
    name: 'Switzerland',
    text: '+41',
    id: 'CH'
  },
  {
    name: 'Syrian Arab Republic',
    text: '+963',
    id: 'SY'
  },
  {
    name: 'Taiwan',
    text: '+886',
    id: 'TW'
  },
  {
    name: 'Tajikistan',
    text: '+992',
    id: 'TJ'
  },
  {
    name: 'Tanzania, United Republic of Tanzania',
    text: '+255',
    id: 'TZ'
  },
  {
    name: 'Thailand',
    text: '+66',
    id: 'TH'
  },
  {
    name: 'Timor-Leste',
    text: '+670',
    id: 'TL'
  },
  {
    name: 'Togo',
    text: '+228',
    id: 'TG'
  },
  {
    name: 'Tokelau',
    text: '+690',
    id: 'TK'
  },
  {
    name: 'Tonga',
    text: '+676',
    id: 'TO'
  },
  {
    name: 'Trinidad and Tobago',
    text: '+1868',
    id: 'TT'
  },
  {
    name: 'Tunisia',
    text: '+216',
    id: 'TN'
  },
  {
    name: 'Turkey',
    text: '+90',
    id: 'TR'
  },
  {
    name: 'Turkmenistan',
    text: '+993',
    id: 'TM'
  },
  {
    name: 'Turks and Caicos Islands',
    text: '+1649',
    id: 'TC'
  },
  {
    name: 'Tuvalu',
    text: '+688',
    id: 'TV'
  },
  {
    name: 'Uganda',
    text: '+256',
    id: 'UG'
  },
  {
    name: 'Ukraine',
    text: '+380',
    id: 'UA'
  },
  {
    name: 'United Arab Emirates',
    text: '+971',
    id: 'AE'
  },
  {
    name: 'United Kingdom',
    text: '+44',
    id: 'GB'
  },
  {
    name: 'United States',
    text: '+1',
    id: 'US'
  },
  {
    name: 'Uruguay',
    text: '+598',
    id: 'UY'
  },
  {
    name: 'Uzbekistan',
    text: '+998',
    id: 'UZ'
  },
  {
    name: 'Vanuatu',
    text: '+678',
    id: 'VU'
  },
  {
    name: 'Venezuela, Bolivarian Republic of Venezuela',
    text: '+58',
    id: 'VE'
  },
  {
    name: 'Vietnam',
    text: '+84',
    id: 'VN'
  },
  {
    name: 'Virgin Islands, British',
    text: '+1284',
    id: 'VG'
  },
  {
    name: 'Virgin Islands, U.S.',
    text: '+1340',
    id: 'VI'
  },
  {
    name: 'Wallis and Futuna',
    text: '+681',
    id: 'WF'
  },
  {
    name: 'Yemen',
    text: '+967',
    id: 'YE'
  },
  {
    name: 'Zambia',
    text: '+260',
    id: 'ZM'
  },
  {
    name: 'Zimbabwe',
    text: '+263',
    id: 'ZW'
  }
];

export const campaignObjectives = [
  {
    id: 'reach',
    icon: AudienceReach,
    title: 'Reach',
    description:
      'Let your message be seen/heard by highest number of users within your target audience. This will help you increase your ad recall, awareness & consideration.'
  }
  /* {
    id: 'lead',
    icon: LeadGeneration,
    title: 'Lead Generation',
    description: 'Grow your database of users interested in your business. Invite them to sign up and know more about what you can offer. Data privacy guaranteed.',
  }, */
  /* {
    id: 'conversion',
    icon: WebConversion,
    title: 'Web Conversion',
    description: 'Drive people to take actions on your website & track their interactions. A tag will be generated to help you track conversions and measure the effectiveness of your ads.',
  }, */
];

export const ctaKeyToTextMap: { [key: string]: string } = {
  adbtn_knowMore: 'Know More',
  adbtn_discoverNow: 'Discover Now',
  adbtn_playNow: 'Play Now',
  adbtn_discoverMore: 'Discover More',
  adbtn_getItNow: 'Get It Now',
  adbtn_subscribeNow: 'Subscribe Now',
  adbtn_getYourOffer: 'Get Your Offer',
  adbtn_startFreeTrial: 'Start Your Free Trial',
  adbtn_listenNow: 'Listen Now',
  adbtn_bookNow: 'Book Now',
  adbtn_watchMore: 'Watch More',
  adbtn_installNow: 'Install Now',
  adbtn_download: 'Download',
  adbtn_learnMore: 'Learn More',
  adbtn_playGame: 'Play Game',
  adbtn_shopNow: 'Shop Now',
  adbtn_signUp: 'Sign Up',
  adbtn_useApp: 'Use App',
  adbtn_joinNow: 'Join Now',
  adbtn_watch: 'Watch',
  adbtn_applyNow: 'Apply Now',
  adbtn_contactUs: 'Contact Us',
  adbtn_visitSite: 'Visit Site',
  adbtn_seeMore: 'See More',
  adbtn_submitNow: 'Submit Now',
  adbtn_getStarted: 'Get Started'
};

export const ctaTextToKeyMap: { [key: string]: string } = {
  'Apply Now': 'adbtn_applyNow',
  'Book Now': 'adbtn_bookNow',
  'Contact Us': 'adbtn_contactUs',
  'Discover More': 'adbtn_discoverMore',
  'Discover Now': 'adbtn_discoverNow',
  Download: 'adbtn_download',
  'Get It Now': 'adbtn_getItNow',
  'Get Started': 'adbtn_getStarted',
  'Get Your Offer': 'adbtn_getYourOffer',
  'Install Now': 'adbtn_installNow',
  'Know More': 'adbtn_knowMore',
  'Learn More': 'adbtn_learnMore',
  'Listen Now': 'adbtn_listenNow',
  'Play Game': 'adbtn_playGame',
  'Play Now': 'adbtn_playNow',
  'See More': 'adbtn_seeMore',
  'Shop Now': 'adbtn_shopNow',
  'Sign Up': 'adbtn_signUp',
  'Start Your Free Trial': 'adbtn_startFreeTrial',
  'Submit Now': 'adbtn_submitNow',
  'Subscribe Now': 'adbtn_subscribeNow',
  'Use App': 'adbtn_useApp',
  'Visit Site': 'adbtn_visitSite',
  'Join Now': 'adbtn_joinNow',
  Watch: 'adbtn_watch',
  'Watch More': 'adbtn_watchMore'
};
