import {Spin} from 'antd';
import moment from 'moment';
import React, {useState} from 'react';
import {useNavigate} from 'react-router';
import useAuth from '../../hooks/useAuth';
import {AxiosSingleton} from '../../utils/axiosinstance';
import {message} from '../../utils/custommessage';

export type TableColumnType = {
  title: string;
  width: string;
  dataIndex: string;
  key: string;
  fixed?: string;
  render: (value: any, data: any) => any;
};

enum ExportButtonState {
  active = 'active',
  loading = 'loading',
}


const PreviewCreative = ({imageUrl}: { imageUrl: string }) => {
  if (!imageUrl) {
    return <span>-</span>;
  }

  return (
    <div>
      <img src={imageUrl} alt="Preview" style={{width: '100%'}}/>
    </div>
  );
};

const renderImpressions = (impressions) => (
  <div style={{display: 'flex', justifyContent: 'center'}}>
    {impressions || (impressions === null || impressions === 0 ? '-' : <Spin/>)}
  </div>
);


const today = Date.parse((new Date().toISOString().slice(0, 10)))
const VideoMastheadControls = ({campaign}: { campaign: any }) => {
  const axios = AxiosSingleton.axiosInstance;
  const navigate = useNavigate();
  const [isDownloading, setIsDownloading] = useState(ExportButtonState.active);
  const {user} = useAuth();
  const edit = (campaignId) => {
    navigate(`/video-masthead-campaigns/edit/${campaignId}`);
  };
  const downloadInsights = (id: any) => {
    setIsDownloading(ExportButtonState.loading)
    axios
      .post('/masthead-campaigns/export', {
        mastheadid: id,
      }, {
        responseType: 'arraybuffer'
      })
      .then(({data}) => {
        console.log(data)
        const buffer = new Uint8Array(data);
        const blob = new Blob([buffer], {type: 'application/vnd.ms-excel'});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const rand = Math.floor(Math.random() * 9999 + 1000);
        link.download = `videomasthead_${rand}.xlsx`;
        link.click();
        setIsDownloading(ExportButtonState.active)
      });
  };
  const activate = (campaignId) => {
    axios
      .post('/masthead-campaigns/change-status', {
        ids: [campaignId],
        status: 1
      })
      .then(({data}) => {
        if (data.message === 'success') {
          message.success('Campaign successfully activated!');
          setTimeout(() => {
            navigate(0);
          }, 300);
        }
      })
      .catch(({response}) => {
        const {data} = response;
        if (data.error) {
          message.error(data.error);
        } else {
          message.error('Something went wrong');
        }
      });
  };

  const deactivate = (campaignId) => {
    axios
      .post('/masthead-campaigns/change-status', {
        ids: [campaignId],
        status: 0
      })
      .then(({data}) => {
        if (data.message === 'success') {
          message.success('Campaign successfully deactivated!');
          setTimeout(() => {
            navigate(0);
          }, 300);
        }
      })
      .catch(({response}) => {
        const {data} = response;
        if (data.error) {
          message.error(data.error);
        } else {
          message.error('Something went wrong');
        }
      });
  };

  return (
    <div className="report-name-cell">
      <div className="report-name">{campaign.title_en}</div>
      <div className="report-name-actions">
        <span
          className="report-name-action"
          onClick={() => {
            edit(campaign.id);
          }}
        >
          Edit
        </span>
        {user?.publisher_id === 2 &&
          (Boolean(campaign.status) ? (
            <span
              className="report-name-action"
              onClick={() => {
                deactivate(campaign.id);
              }}
            >
              Deactivate
            </span>
          ) : (
            <span
              className="report-name-action"
              onClick={() => {
                activate(campaign.id);
              }}
            >
              Activate
            </span>
          ))}
        {user?.publisher_id === 2 && Boolean(campaign.status) && new Date() > new Date(campaign.day) ? (
          isDownloading === ExportButtonState.active ? (
            <span className={`report-name-action ${isDownloading}`} onClick={() => {
              downloadInsights(campaign.id)
            }}>
          Export Insights
        </span>
              ) : (
                <span className={`report-name-action ${isDownloading}`}>
          Loading Insights
        </span>
              )
            ) : null}
          </div>
        </div>
  );
};

export const videoMastheadCampaignsTableColumn: TableColumnType[] = [
  {
    title: 'Name',
    dataIndex: 'title_en',
    key: '0',
    width: '10rem',
    render: (_, data) => <VideoMastheadControls campaign={data}/>
  },
  {
    title: 'Selected Date',
    dataIndex: 'day',
    key: '1',
    width: '5rem',
    render: (day: string) => <span>{moment(day).format('MMM DD, YYYY')}</span>
  },
  {
    title: 'Countries',
    dataIndex: 'countries',
    key: '2',
    width: '5rem',
    render: (countries: string) => <span>{countries.toString()}</span>
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: '3',
    width: '5rem',
    render: (_, campaign) => (
      <span>{Boolean(campaign.status) ? 'Active' : 'Inactive'}</span>
    )
  },
  {
    title: 'Unique Clicks',
    dataIndex: 'unique_clicks',
    key: '7',
    width: '6rem',
    render: renderImpressions
  },
  {
    title: 'Clicks',
    dataIndex: 'clicks',
    key: '8',
    width: '6rem',
    render: renderImpressions
  },
  {
    title: 'Unique Impressions',
    dataIndex: 'unique_impressions',
    key: '9',
    width: '6rem',
    render: renderImpressions
  },
  {
    title: 'Impressions',
    dataIndex: 'impressions',
    key: '10',
    width: '6rem',
    render: renderImpressions
  }
];
