import React from 'react';

export type TableColumnType = {
  title: string;
  width: string;
  dataIndex: string;
  key: string;
  fixed?: string;
  render: (value: any, data: any) => any;
};

const formatStatus = (status) => {
  switch (status) {
    case 0:
      return 'Draft';
    case 1:
      return 'Pending';
    case 2:
      return 'Running';
    case 3:
      return 'Paused';
    case 4:
      return 'Completed';
    case 5:
      return 'Scheduled';
    case -2:
      return 'Rejected';
    default:
      return 'Inactive';
  }
};

export const customAudiencesTableColumns: TableColumnType[] = [
  {
    title: 'Name',
    width: '9rem',
    dataIndex: 'name',
    key: '0',
    render: (name: string) => <span>{name}</span>
  },
  {
    title: 'Audience Size',
    dataIndex: 'users_count',
    key: '1',
    width: '6rem',
    render: (audienceSize: number) => <span>{audienceSize}</span>
  },
  {
    title: 'Active Audience',
    dataIndex: 'active_users_count',
    key: '2',
    width: '6rem',
    render: (activeAudience: number) => <span>{activeAudience}</span>
  },
  {
    title: 'Notes',
    dataIndex: 'error',
    key: '3',
    width: '12rem',
    render: (notes: string) => <div style={{ whiteSpace: 'initial' }}>{notes || '-'}</div>
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: '4',
    width: '6rem',
    render: (status: number) => <span>{formatStatus(Number(status))}</span>
  },
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: '5',
    width: '6rem',
    render: (createdAt: string) => <span>{createdAt}</span>
  }
];
