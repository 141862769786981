import React from 'react';
import { useNavigate } from 'react-router-dom';
import { countries } from '../budget/selectorOptions';
import './styles.scss';

type DataType = {
  amount: string;
  created_at: string;
  email: string;
  id: number;
  name: string;
  payment_id: string;
  publisher_id: number;
  publisher_name: string;
  key: number;
  status: number;
};

export type TableColumnType = {
  title: string;
  width: string;
  dataIndex: string;
  key: string;
  fixed?: string;
  // eslint-disable-next-line no-undef
  render: (value: any, data: DataType) => JSX.Element;
};

type BudgetsControlsProps = {
  budget: any;
};

const formatCountries = (countriesArray: string[]) =>
  countriesArray.map((country) => countries.find((el) => el.id === country)?.text).join(', ');

const BudgetsControls = ({ budget }: BudgetsControlsProps) => {
  const navigate = useNavigate();
  const edit = (budgetId) => {
    navigate(`/budget/edit/${budgetId}`);
  };

  return (
    <div className="report-name-cell">
      <div className="report-name">{budget.name}</div>
      <div className="report-name-actions">
        <span
          className="report-name-action"
          onClick={() => {
            edit(budget.id);
          }}
        >
          Edit
        </span>
      </div>
    </div>
  );
};

const getStatus = (status) => {
  if (status === -1) {
    return 'Incomplete';
  }
  if (status === 0) {
    return 'Canceled';
  }
  if (status === 1) {
    return 'Booked';
  }
  if (status === 2) {
    return 'Paid';
  }
};

export const budgetListTableColumn: TableColumnType[] = [
  {
    title: 'Name',
    width: '12rem',
    dataIndex: 'name',
    key: '0',
    fixed: 'left',
    render: (_, budget: any) => <BudgetsControls budget={budget} />
  },
  {
    title: 'Booking Order Number',
    dataIndex: 'booking_order_number',
    key: '1',
    width: '9rem',
    render: (number: string) => <span>{number}</span>
  },
  {
    title: 'Countries',
    dataIndex: 'country',
    key: '2',
    width: '9rem',
    render: (country: string[]) => <span>{formatCountries(country)}</span>
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: '3',
    width: '8rem',
    render: (status: string) => <span>{getStatus(status)}</span>
  },
  {
    title: 'Created By',
    dataIndex: 'created_by',
    key: '4',
    width: '8rem',
    render: (user: any) => <span>{user}</span>
  }
];
