export const campaignTypeOptions = [
  { id: 'standard', text: 'Standard' },
  { id: 'sponsorship', text: 'Sponsorship' }
];

export const currencyOptions = [
  { id: 'usd', text: 'USD' },
  { id: 'egp', text: 'EGP' },
  { id: 'lbp', text: 'LBP' }
];
