import { FiltersSelectTypes } from '../../enums';

export enum FilterTypes {
  multiselect = 'multiselect',
  bool = 'bool',
  date = 'date',
  number = 'number',
  numberInArray = 'numberInArray',
  multiNumbers = 'multiNumbers'
}

export type FilterValue = {
  value: string[] | number[] | string;
  operator: string;
  min?: string;
  max?: string;
};

type Options = {
  id?: string | number;
  text: string;
  children?: Options[];
};

export interface IMastheadFilters {
  id: string;
  title: string;
  type: FilterTypes;
  default: string | boolean | any;
  options?: Options[];
  select: FiltersSelectTypes[];
  defaultSelect: FiltersSelectTypes;
  hideSelect: boolean;
  hideSearch: boolean;
  initiallyHideDropdown?: boolean;
  lazyLoad?: boolean;
  width?: number;
  value?: FilterValue;
}

const countries: Options[] = [
  {
    text: 'GCC',
    children: [
      { id: 'AE', text: 'United Arab Emirates' },
      { id: 'SA', text: 'Saudi Arabia' },
      { id: 'KW', text: 'Kuwait' },
      { id: 'BH', text: 'Bahrain' },
      { id: 'OM', text: 'Oman' },
      { id: 'QA', text: 'Qatar' }
    ]
  },
  {
    text: 'North Africa',
    children: [
      { id: 'EG', text: 'Egypt' },
      { id: 'SS', text: 'South Sudan' },
      { id: 'SD', text: 'Sudan' }
    ]
  },
  {
    text: 'Levant',
    children: [
      { id: 'LB', text: 'Lebanon' },
      { id: 'JO', text: 'Jordan' },
      { id: 'PS', text: 'Palestine' },
      { id: 'SY', text: 'Syria' }
    ]
  },
  {
    text: 'Iraq',
    children: [{ id: 'IQ', text: 'Iraq' }]
  },
  {
    text: 'Maghreb',
    children: [
      { id: 'DZ', text: 'Algeria' },
      { id: 'LY', text: 'Libya' },
      { id: 'MA', text: 'Morocco' },
      { id: 'TN', text: 'Tunisia' }
    ]
  },
  {
    text: 'Other',
    children: [{ id: 'ROW', text: 'Rest of the World' }]
  }
];

export const mastheadFilters: IMastheadFilters[] = [
  {
    id: 'status',
    title: 'Status',
    type: FilterTypes.multiselect,
    default: '',
    options: [
      {
        id: 0,
        text: 'Inactive'
      },
      {
        id: 1,
        text: 'Active'
      }
    ],
    select: [FiltersSelectTypes.is, FiltersSelectTypes.isNot],
    defaultSelect: FiltersSelectTypes.is,
    hideSelect: false,
    hideSearch: false,
    width: 21.875
  },
  {
    id: 'country',
    title: 'Country',
    type: FilterTypes.multiselect,
    default: '',
    options: countries,
    select: [FiltersSelectTypes.is, FiltersSelectTypes.isNot],
    defaultSelect: FiltersSelectTypes.is,
    hideSelect: false,
    hideSearch: false,
    width: 21.875
  },

  {
    id: 'start_date',
    title: 'Date',
    type: FilterTypes.date,
    default: '',
    select: [
      FiltersSelectTypes.between,
      FiltersSelectTypes.lessThan,
      FiltersSelectTypes.greaterThan
    ],
    defaultSelect: FiltersSelectTypes.lessThan,
    hideSelect: false,
    hideSearch: true,
    width: 26.875
  },
];
