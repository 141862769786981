import React, { useEffect } from 'react';
import {
  ArrowUpOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  ArrowDownOutlined
} from '@ant-design/icons';
import { Slider } from 'antd';
import { TusClientProvider } from 'use-tus';
import SponsoredPlaylistsUploadBox from './SponsoredPlaylistsUploadBox';
import { SponsoredPlaylistNavigation } from '../../enums/sponsoredplaylistsnavigation';

type Props = {
  mode: 'create' | 'edit';
  setNavigationPage: (navigationPage: any) => void;
  isOverrideToggled: boolean;
  setIsOverrideToggled: (value: boolean) => void;
  coverImage: string;
  coverImageAr: string;
  logoEn: string;
  setLogoEn: (s: string) => void;
  logoAr: string;
  setLogoAr: (s: string) => void;
  coverWithLogoEn: string;
  setCoverWithLogoEn: (s: string) => void;
  coverWithLogoAr: string;
  setCoverWithLogoAr: (s: string) => void;
  logoPositionX: number;
  setLogoPositionX: (n: number) => void;
  logoPositionY: number;
  setLogoPositionY: (n: number) => void;
  logoWidth: number;
  setLogoWidth: (n: number) => void;
  overrideImageCoverEn: string;
  setOverrideImageCoverEn: (s: string) => void;
  overrideImageCoverFr: string;
  setOverrideImageCoverFr: (s: string) => void;
  overrideImageCoverAr: string;
  setOverrideImageCoverAr: (s: string) => void;
};

export default function SponsoredPlaylistsCover({
  mode,
  setNavigationPage,
  isOverrideToggled,
  setIsOverrideToggled,
  coverImage,
  coverImageAr,
  logoEn,
  setLogoEn,
  logoAr,
  setLogoAr,
  coverWithLogoEn,
  setCoverWithLogoEn,
  coverWithLogoAr,
  setCoverWithLogoAr,
  logoPositionX,
  setLogoPositionX,
  logoPositionY,
  setLogoPositionY,
  logoWidth,
  setLogoWidth,
  overrideImageCoverEn,
  setOverrideImageCoverEn,
  overrideImageCoverFr,
  setOverrideImageCoverFr,
  overrideImageCoverAr,
  setOverrideImageCoverAr
}: Props) {
  useEffect(() => {
    if (coverImage) {
      setCoverWithLogoEn(
        `${coverImage}?blend64=${btoa(
          logoEn
        )}&bm=normal&bw=${logoWidth}&bx=${logoPositionX}&by=${logoPositionY}`
      );
    }

    if (coverImageAr) {
      setCoverWithLogoAr(
        `${coverImageAr}?blend64=${btoa(logoAr || logoEn)}&bm=normal&bw=${logoWidth}&bx=${
          1020 - logoWidth - logoPositionX
        }&by=${logoPositionY}`
      );
    }
  }, [logoAr, logoEn, logoWidth, logoPositionX, logoPositionY]);

  const canGoToNextPage = isOverrideToggled ? Boolean(overrideImageCoverEn) : Boolean(logoEn);

  return (
    <div className="campaign-container">
      <div className="campaign-container-header">
        {mode === 'create' && (
          <div className="campaign-container-header-title">Setup Your New Campaign</div>
        )}
        {mode === 'edit' && (
          <div className="campaign-container-header-title">Edit Your Campaign</div>
        )}
        <button
          className={`campaign-container-header-btn ${canGoToNextPage ? '' : 'disabled'}`}
          type="button"
          onClick={() => {
            if (!canGoToNextPage) {
              return;
            }
            setNavigationPage(SponsoredPlaylistNavigation.Info);
          }}
        >
          Next
        </button>
      </div>
      <div className="campaign-section-title">Branded Playlist Covers</div>
      <div className="override-checkbox-container">
        <input
          type="checkbox"
          className="override-checkbox"
          checked={isOverrideToggled}
          onChange={(e) => {
            setIsOverrideToggled(e.target.checked);
          }}
        />{' '}
        OVERRIDE COVERS
      </div>
      {isOverrideToggled ? (
        <div className="override-section">
          <div className="logo-upload-boxes-section">
            <div className="logo-upload-box-container">
              <div className="campaign-section-title">Upload Cover</div>
              <TusClientProvider>
                <SponsoredPlaylistsUploadBox
                  uploadSuccessHandler={(fileLocation) => {
                    setOverrideImageCoverEn(fileLocation);
                  }}
                  clearUploadHandler={() => {
                    setOverrideImageCoverEn('');
                  }}
                  fileName={overrideImageCoverEn}
                  inputID="overrideImageCoverEn"
                  key="overrideImageCoverEn"
                />
              </TusClientProvider>
              <img className="override-cover-preview" src={overrideImageCoverEn} />
              <div className="campaign-section-title">Upload French Cover</div>
              <TusClientProvider>
                <SponsoredPlaylistsUploadBox
                  uploadSuccessHandler={(fileLocation) => {
                    setOverrideImageCoverFr(fileLocation);
                  }}
                  clearUploadHandler={() => {
                    setOverrideImageCoverFr('');
                  }}
                  fileName={overrideImageCoverFr}
                  inputID="overrideImageCoverFr"
                  key="overrideImageCoverFr"
                />
              </TusClientProvider>
              <img className="override-cover-preview" src={overrideImageCoverFr} />
            </div>
            <div className="logo-upload-box-container">
              <div className="campaign-section-title">Upload Arabic Cover</div>
              <TusClientProvider>
                <SponsoredPlaylistsUploadBox
                  uploadSuccessHandler={(fileLocation) => {
                    setOverrideImageCoverAr(fileLocation);
                  }}
                  clearUploadHandler={() => {
                    setOverrideImageCoverAr('');
                  }}
                  fileName={overrideImageCoverAr}
                  inputID="overrideImageCoverAr"
                  key="overrideImageCoverAr"
                />
              </TusClientProvider>
              <img className="override-cover-preview" src={overrideImageCoverAr} />
            </div>
          </div>
        </div>
      ) : (
        <div className="no-override-section">
          <div className="logo-upload-boxes-section">
            <div className="logo-upload-box-container">
              <div className="campaign-section-title">Upload Logo</div>
              <TusClientProvider>
                <SponsoredPlaylistsUploadBox
                  uploadSuccessHandler={(fileLocation) => {
                    setLogoEn(fileLocation);
                  }}
                  clearUploadHandler={() => {
                    setLogoEn('');
                  }}
                  fileName={logoEn}
                  inputID="logoEn"
                  key="logoEn"
                />
              </TusClientProvider>
            </div>
            <div className="logo-upload-box-container">
              <div className="campaign-section-title">Upload Arabic Logo</div>
              <TusClientProvider>
                <SponsoredPlaylistsUploadBox
                  uploadSuccessHandler={(fileLocation) => {
                    setLogoAr(fileLocation);
                  }}
                  clearUploadHandler={() => {
                    setLogoAr('');
                  }}
                  fileName={logoAr}
                  inputID="logoAr"
                  key="logoAr"
                />
              </TusClientProvider>
            </div>
          </div>
          <div className="campaign-section-title">Branded Playlist Covers</div>
          <div className="cover-preview-container">
            <div className="cover-image-container">
              <img src={coverWithLogoEn || coverImage} />
            </div>
            <div className="controls-container">
              <div>Logo Position</div>
              <div className="grid-container">
                <div />
                <div
                  style={{
                    opacity: logoPositionY === 0 ? '0.5' : '1',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    if (logoPositionY >= 10 && logoPositionY < 160) {
                      setLogoPositionY(logoPositionY - 10);
                    }
                  }}
                >
                  <ArrowUpOutlined />
                </div>
                <div />
                <div
                  style={{
                    paddingRight: '0.5rem',
                    opacity: logoPositionX === 0 ? '0.5' : '1',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    if (logoPositionX >= 10) {
                      setLogoPositionX(logoPositionX - 10);
                    }
                  }}
                >
                  <ArrowLeftOutlined />
                </div>
                <div />
                <div
                  style={{
                    paddingLeft: '0.5rem',
                    opacity: logoPositionX === 50 ? '0.5' : '1',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    if (logoPositionX >= 0 && logoPositionX < 50) {
                      setLogoPositionX(logoPositionX + 10);
                    }
                  }}
                >
                  <ArrowRightOutlined />
                </div>
                <div />
                <div
                  style={{
                    opacity: logoPositionY === 50 ? '0.5' : '1',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    if (logoPositionY >= 0 && logoPositionY < 50) {
                      setLogoPositionY(logoPositionY + 10);
                    }
                  }}
                >
                  <ArrowDownOutlined />
                </div>
                <div />
              </div>
              <div>Size</div>
              <Slider
                min={150}
                max={220}
                defaultValue={180}
                onChange={(value) => {
                  setLogoWidth(value);
                }}
              />
            </div>
            <div className="cover-image-container">
              <img src={coverWithLogoAr || coverImageAr} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
