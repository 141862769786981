import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';
import SpinningLoader from '../spinningloader';
import { formatNumber } from '../../helpers/format-numbers';
import { InsightsNoData } from './insightsnodata';
import { truncateNumber } from '../../helpers/truncate-number';

type Props = {
  rangeDate: any[];
  isLoading: boolean;
  daily_streams: {
    streams: number;
    day: string;
  }[];
};

export const StreamsPerDayChart = ({ isLoading, daily_streams, rangeDate }: Props) => {
  const [activeTooltipData, setActiveTooltipData] = useState(null);
  const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 });

  const calculateTooltipPosition = (e: any) => {
    let { clientX, clientY } = e;

    if (clientX + 180 >= window.innerWidth) {
      clientX -= 150;
    }

    if (clientY + 150 >= window.innerHeight) {
      clientY -= 120;
    }

    setPointerPosition({ x: clientX + 20, y: clientY + 20 });
  };

  return (
    <>
      <div className="CampaignInsights-chart fixed-full-height">
        <div className="CampaignInsights-chart-header">STREAMS PER DAY</div>
        {!isLoading && rangeDate ? (
          <div className="CampaignInsights-chart-date">
            From {rangeDate[0]?.format('YYYY-MM-DD')} to {rangeDate[1]?.format('YYYY-MM-DD')}
          </div>
        ) : null}

        {!isLoading ? (
          Array.isArray(daily_streams) && daily_streams.length > 0 ? (
            <ResponsiveContainer width="100%" height="100%" debounce={200}>
              <LineChart
                data={daily_streams}
                margin={{
                  right: 25,
                  top: 5,
                  left: 10,
                  bottom: 15
                }}
              >
                <CartesianGrid vertical={null} />
                <XAxis dataKey="day" tick={{ fill: '#000', fontSize: '1rem' }} dy={15} />
                <YAxis
                  dataKey="streams"
                  tick={{ fill: '#000', fontSize: '1rem' }}
                  tickFormatter={(value) => String(truncateNumber(value))}
                  dx={-15}
                />
                <Line
                  type="monotone"
                  dataKey="streams"
                  stroke="#945cff"
                  strokeWidth={3}
                  dot={{
                    r: 5,
                    stroke: '#945cff',
                    fill: '#945cff',
                    strokeWidth: 3,
                    onMouseEnter: (data, e) => {
                      setActiveTooltipData(data);
                      calculateTooltipPosition(e);
                    },
                    onMouseMove: (_, e) => {
                      calculateTooltipPosition(e);
                    },
                    onMouseLeave: () => {
                      setActiveTooltipData(null);
                    }
                  }}
                />
              </LineChart>
            </ResponsiveContainer>
          ) : (
            <InsightsNoData />
          )
        ) : (
          <SpinningLoader />
        )}
      </div>

      {activeTooltipData ? (
        <div
          className="BarChart-tooltip"
          style={{ left: pointerPosition.x, top: pointerPosition.y }}
        >
          <div className="HorizontalChart-tooltip-title">
            {activeTooltipData.title || activeTooltipData.payload.day}
          </div>
          <span>
            {activeTooltipData.percentage
              ? `percentage: ${activeTooltipData.percentage}%`
              : `streams: ${formatNumber(activeTooltipData.payload.streams)}`}
          </span>
        </div>
      ) : null}
    </>
  );
};
