import React from 'react';
import { Navigate } from 'react-router-dom';
import { PublisherRoles } from '../../enums/publisherroles';
import useAuth from '../../hooks/useAuth';

type Props = {
  children: React.ReactChild;
};

const ClientInvoicesGuard = ({ children }: Props) => {
  const { role, user } = useAuth();
  const canViewPage =
    role === PublisherRoles.Anghami ||
    Boolean(user.can_view_invoices) ||
    Boolean(user.billing.can_view_billing);

  if (canViewPage) {
    return <>{children}</>;
  }

  return <Navigate replace to="/overview" />;
};

export default ClientInvoicesGuard;
