import React from 'react';
import SpinningCircle from '../../spinningcircle';
import './styles.scss';

interface AnghamiAdsButtonProps {
  /**
   * Is the button in a loading state?
   */
  isLoading?: boolean;
  /**
   * Is the button disabled?
   */
  disabled?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * What background color to use
   */
  textColor?: string;
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Button contents
   */
  label: string;
  /**
   * Button style
   */
  style?: any;
  /**
   * Optional click handler
   */
  onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const AnghamiAdsButton = ({
  isLoading = false,
  size = 'medium',
  backgroundColor = '#945CFF',
  textColor = 'white',
  label,
  disabled,
  style,
  ...props
}: AnghamiAdsButtonProps) => (
  <button
    type="button"
    className={[
      'anghami-ads-btn',
      disabled ? 'anghami-ads-btn-disabled' : '',
      `anghami-ads-btn--${size}`
    ].join(' ')}
    style={{
      backgroundColor,
      color: textColor,
      ...style
    }}
    {...props}
  >
    {isLoading ? <SpinningCircle /> : label}
  </button>
);
