import React, { useEffect, useState } from 'react';
import { Col, DatePicker, Row } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { AmplitudeEvents } from '@anghami/neoanalytics/dist/enums/events';
import BarChart from '../charts/barchart';
import HorizontalChart from '../charts/horizontalchart';
import { AxiosSingleton } from '../../utils/axiosinstance';
import SpinningLoader from '../spinningloader';
import { InsightsHeader } from './insightsheader';
import { InsightsTotal } from './insightstotal';
import { InsightsSummary } from './insightssummary';
import { InsightsPerDayChart } from './insightsperdaychart';
import { InsightsPerGenderChart } from './insightspergender';
import { InsightsPerCountryChart } from './insightspercountrychart';
import { InsightsNoData } from './insightsnodata';
import ErrorPage, { ErrorPageProps } from '../errorpage';
import privateImg from '../../public/assets/images/private.png';
import { logAmplitudeEvent } from '../../utils/analytics';
import './styles.scss';
import { StreamsPerDayChart } from './streamsperdaychart';

interface Istate<T> {
  total_insights?: T;
  insights_range?: T;
  country?: T;
  age?: T;
  gender?: T;
  platform?: T;
  music_preference?: T;
  language?: T;
  daily_impressions?: T;
  daily_streams?: T;
}

export interface ICampaignData {
  name: string;
  start_date: string;
  end_date: string;
  message: string;
  streams_based: number;
}

type Props = {
  insightsType: 'campaign' | 'groupcampaign';
};

const CampaignInsights = ({ insightsType }: Props) => {
  const navigate = useNavigate();
  const axios = AxiosSingleton.axiosInstance;
  const [insights, setInsights] = useState<Istate<any>>({});
  const [isLoading, setIsLoading] = useState<Istate<boolean>>({
    age: true,
    gender: true,
    country: true,
    language: true,
    platform: true,
    insights_range: true,
    total_insights: true,
    daily_impressions: true,
    music_preference: true
  });
  const [campaignData, setCampaignData] = useState<ICampaignData>(null);
  const [errorPageProps, setErrorPageProps] = useState<ErrorPageProps>(null);
  const [rangeDate, setRangeDate] = useState(null);
  const { campaignId } = useParams();
  const [pageLoading, setPageloading] = useState(true);

  useEffect(() => {
    logAmplitudeEvent(AmplitudeEvents.viewCampaignInsights);
  }, []);

  const fetchInsight = async (
    type: string,
    sendIdSetArray = false,
    responseAsMap = false,
    otherParams: any = {}
  ) => {
    setIsLoading((prevState) => ({
      ...prevState,
      [type]: true
    }));

    try {
      const endpoint =
        insightsType === 'campaign' ? '/analytics/impressions' : '/analytics/group-impressions';
      const params = {
        ...otherParams,
        type,
        from: rangeDate ? rangeDate[0]?.format('YYYY-MM-DD') : null,
        to: rangeDate ? rangeDate[1].format('YYYY-MM-DD') : null
      };

      if (insightsType === 'campaign') {
        params.campaignid = sendIdSetArray ? [Number(campaignId)] : campaignId;
      }

      if (insightsType === 'groupcampaign') {
        params.groupcampaignid = sendIdSetArray ? [Number(campaignId)] : campaignId;
      }

      const response = await axios.post<ICampaignData>(endpoint, params);
      setPageloading(false);

      if (response.data.message && response.data.message !== 'success') {
        return setErrorPageProps({
          title: 'Looks like you don’t have access to this page.',
          img: privateImg,
          ctaTitle: 'Go to Manage Campaigns',
          ctaFunc: () => navigate('/manage-campaigns', { replace: true })
        });
      }

      setInsights((prevState) => ({
        ...prevState,
        [type]: responseAsMap ? response.data[campaignId as any] : response.data
      }));

      setIsLoading((prevState) => ({
        ...prevState,
        [type]: false
      }));
    } catch (e) {
      setIsLoading((prevState) => ({
        ...prevState,
        [type]: false
      }));
    }
  };

  // effects that run on time
  useEffect(() => {
    const endpoint = insightsType === 'campaign' ? '/campaigns/get' : '/group-campaigns/get';
    axios
      .post<ICampaignData>(endpoint, {
        simple: true,
        campaignid: campaignId
      })
      .then((response) => {
        setPageloading(false);
        if (response.data.message === 'success') {
          setCampaignData(response.data);

          const today = moment();
          const startDate = moment(response.data.start_date);
          const endDate = moment(response.data.end_date);

          // if it's not completed yet
          if (endDate.diff(today, 'days') >= 0) {
            // get the diff between the start_date and the end_date
            // if it's more than 7 days we set the start_date to start_date - 7
            if (today.diff(startDate, 'days') >= 7) {
              setRangeDate([today.clone().subtract(7, 'd'), today]);
            } else {
              // or we set it to the start_date to not get out of bound
              setRangeDate([startDate, today]);
            }
          } else if (endDate.diff(startDate, 'days') >= 7) {
            setRangeDate([endDate.clone().subtract(7, 'd'), endDate]);
          } else {
            setRangeDate([startDate, endDate]);
          }
        } else {
          return setErrorPageProps({
            title: 'Looks like you don’t have access to this page.',
            img: privateImg,
            ctaTitle: 'Go to Manage Campaigns',
            ctaFunc: () => navigate('/manage-campaigns', { replace: true })
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });

    fetchInsight('total_insights', true, true);
  }, []);

  // effects run on date change
  useEffect(() => {
    if (!rangeDate) return;

    fetchInsight('insights_range', true, true);
    fetchInsight('daily_impressions');
    fetchInsight('daily_streams');
    fetchInsight('gender');
    fetchInsight('age');
    fetchInsight('language');
    fetchInsight('music_preference');
    fetchInsight('platform');
    fetchInsight('country');
  }, [rangeDate]);

  if (pageLoading) {
    return <SpinningLoader />;
  }

  if (errorPageProps) {
    return <ErrorPage {...errorPageProps} />;
  }

  return (
    <div id="CampaignInsights" className="CampaignInsights">
      <InsightsHeader
        campaignId={campaignId}
        campaignData={campaignData}
        insightsType={insightsType}
      />

      <InsightsTotal
        total_insights={insights.total_insights}
        isLoading={isLoading.total_insights}
      />

      <span className="CampaignInsights-divider" />

      <div className="CampaignInsights-summary">
        <div className="CampaignInsights-summary-dateselector">
          <span className="CampaignInsights-summary-dateselector-text">By Date</span>
          <DatePicker.RangePicker
            showNow
            clearIcon={null}
            format="YYYY-MM-DD"
            className="CampaignInsights-summary-dateselector-input"
            value={rangeDate}
            onChange={(range: any) => setRangeDate(range)}
            disabledDate={(current) => {
              const today = moment();
              const futrueDiff = current.diff(today, 'days');
              const startDate = moment(campaignData?.start_date || null);
              const pastDiff = startDate.diff(current, 'days');

              return futrueDiff >= 0 || pastDiff > 0;
            }}
          />
        </div>

        <InsightsSummary
          isLoading={isLoading.insights_range}
          insights_range={insights.insights_range}
        />
      </div>

      {campaignData?.streams_based ? (
        <StreamsPerDayChart
          isLoading={isLoading.daily_streams}
          daily_streams={insights.daily_streams}
          rangeDate={rangeDate}
        />
      ) : (
        <InsightsPerDayChart
          isLoading={isLoading.daily_impressions}
          daily_impressions={insights.daily_impressions}
          rangeDate={rangeDate}
        />
      )}

      <Row gutter={32}>
        <Col span={12}>
          <InsightsPerGenderChart
            isLoading={isLoading.gender}
            gender={insights.gender}
            rangeDate={rangeDate}
          />
        </Col>

        <Col span={12}>
          <div className="CampaignInsights-chart fixed-full-height">
            <div className="CampaignInsights-chart-header">IMPRESSIONS PER AGE</div>
            {!isLoading?.age && rangeDate ? (
              <div className="CampaignInsights-chart-date">
                From {rangeDate[0]?.format('YYYY-MM-DD')} to {rangeDate[1]?.format('YYYY-MM-DD')}
              </div>
            ) : null}

            {!isLoading?.age ? (
              Array.isArray(insights.age) && insights.age.length > 0 ? (
                <BarChart color="#44e6b0" values={insights.age} />
              ) : (
                <InsightsNoData />
              )
            ) : (
              <SpinningLoader />
            )}
          </div>
        </Col>
      </Row>

      <InsightsPerCountryChart
        isLoading={isLoading.country}
        country={insights.country}
        rangeDate={rangeDate}
      />

      <Row gutter={32}>
        <Col span={12}>
          <div className="CampaignInsights-chart fixed-half-height">
            <div className="CampaignInsights-chart-header">IMPRESSIONS PER LANGUAGE</div>
            {!isLoading?.age && rangeDate ? (
              <div className="CampaignInsights-chart-date">
                From {rangeDate[0]?.format('YYYY-MM-DD')} to {rangeDate[1]?.format('YYYY-MM-DD')}
              </div>
            ) : null}

            {!isLoading?.language ? (
              Array.isArray(insights.language) && insights.language.length > 0 ? (
                <HorizontalChart values={insights.language} color="#945cff" />
              ) : (
                <InsightsNoData />
              )
            ) : (
              <SpinningLoader />
            )}
          </div>
        </Col>
        <Col span={12}>
          <div className="CampaignInsights-chart fixed-half-height">
            <div className="CampaignInsights-chart-header">IMPRESSIONS PER MUSIC PREFERENCE</div>
            {!isLoading?.age && rangeDate ? (
              <div className="CampaignInsights-chart-date">
                From {rangeDate[0]?.format('YYYY-MM-DD')} to {rangeDate[1]?.format('YYYY-MM-DD')}
              </div>
            ) : null}

            {!isLoading?.music_preference ? (
              Array.isArray(insights.music_preference) && insights.music_preference.length > 0 ? (
                <HorizontalChart values={insights.music_preference} color="#ffd800" />
              ) : (
                <InsightsNoData />
              )
            ) : (
              <SpinningLoader />
            )}
          </div>
        </Col>
      </Row>

      <div className="CampaignInsights-chart fixed-half-height">
        <div className="CampaignInsights-chart-header">IMPRESSIONS PER PLATFORM</div>
        {!isLoading?.age && rangeDate ? (
          <div className="CampaignInsights-chart-date">
            From {rangeDate[0]?.format('YYYY-MM-DD')} to {rangeDate[1]?.format('YYYY-MM-DD')}
          </div>
        ) : null}

        {!isLoading?.platform ? (
          Array.isArray(insights.platform) && insights.platform.length > 0 ? (
            <HorizontalChart values={insights.platform} color="#37a07c" />
          ) : (
            <InsightsNoData />
          )
        ) : (
          <SpinningLoader />
        )}
      </div>
    </div>
  );
};

export default CampaignInsights;
