import { Badge, Calendar, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { countriesOptions } from '../mastheadcampaign/selectorOptions';
import { AnghamiAdsFormSelector } from '../core/AnghamiAdsFormSelector';
import AnghamiAdsPageTitle from '../core/AnghamiAdsPageTitle';
import { AxiosSingleton } from '../../utils/axiosinstance';
import { AnghamiAdsButton } from '../core/AnghamiAdsButton';
import './styles.scss';

function MastheadCampaignCalendar() {
  const axios = AxiosSingleton.axiosInstance;
  const navigate = useNavigate();
  const [countries, setCountries] = useState<any[]>([]);
  const [bookings, setBookings] = useState<any[]>([]);
  const [dateToBookingMap, setDateToBookingMap] = useState<any>({});
  const [dateRange, setDateRange] = useState([
    moment(moment().format('YYYY-MM')).format('YYYY-MM-DD'),
    moment(moment().format('YYYY-MM')).add(1, 'months').format('YYYY-MM-DD')
  ]);
  const [blockedDates, setBlockedDates] = useState({});

  const dateCellRender = (value) => {
    const listData = dateToBookingMap[value.format('YYYY-MM-DD')];
    const isBooked = Object.prototype.hasOwnProperty.call(
      dateToBookingMap,
      moment(value).format('YYYY-MM-DD')
    ); // date is already booked by another masthead campaign
    const isBlocked = Object.prototype.hasOwnProperty.call(
      blockedDates,
      moment(value).format('dddd')
    ); // date is blocked by product
    const isInDateRange =
      moment(value) >= moment(dateRange[0]) &&
      moment(value) < moment(dateRange[1]) &&
      moment(value) >= moment(moment().format('YYYY-MM-DD'));

    if (!isInDateRange || isBlocked) {
      return;
    }

    if (isBooked) {
      return (
        <>
          <Badge status="warning" />
          <span style={{ color: '#faad14' }}>Booked</span>
          <ul className="events" style={{ paddingInlineStart: '1em' }}>
            {listData?.map((item) => (
              <li key={item.id}>{item.title}</li>
            ))}
          </ul>
        </>
      );
    }

    return (
      <>
        <Badge status="success" />
        <span style={{ color: '#52c41a' }}>Available</span>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <AnghamiAdsButton
            label="Book"
            style={{ marginTop: '1rem' }}
            onClick={() => {
              navigate(
                `/masthead-campaigns/create?countryParam=${countries.toString()}&dateParam=${value}`
              );
            }}
          />
        </div>
      </>
    );
  };

  const getBookings = (selectedCountries) => {
    const [startDate, endDate] = dateRange;
    axios
      .get('/masthead/bookings', {
        params: {
          countries: selectedCountries,
          start_date: startDate,
          end_date: endDate
        }
      })
      .then(({ data }) => {
        setBookings(data.data);
      });
  };

  const getBlockedDates = (selectedCountries) => {
    axios
      .get('/masthead/blocked-dates', {
        params: {
          country: selectedCountries.toString()
        }
      })
      .then(({ data }) => {
        const blockedDaysMap = {};
        data.data.forEach((el) => {
          blockedDaysMap[el.blocked_day] = true;
        });
        setBlockedDates(blockedDaysMap);
      });
  };

  useEffect(() => {
    const map = {};
    bookings.forEach((booking) => {
      map[moment(booking.startdate).format('YYYY-MM-DD')] = map[
        moment(booking.startdate).format('YYYY-MM-DD')
      ]
        ? [...map[moment(booking.startdate).format('YYYY-MM-DD')], booking]
        : [booking];
    });
    setDateToBookingMap(map);
  }, [bookings]);

  useEffect(() => {
    getBookings(countries);
    getBlockedDates(countries);
  }, [dateRange, countries]);

  const onCalendarDateChange = (date) => {
    setDateRange([
      moment(date.format('YYYY-MM')).format('YYYY-MM-DD'),
      moment(date.format('YYYY-MM')).add(1, 'months').format('YYYY-MM-DD')
    ]);
  };

  return (
    <div
      style={{
        paddingTop: '1rem',
        paddingBottom: '1rem',
        paddingLeft: '3rem',
        paddingRight: '2rem'
      }}
      className="masthead-campaign-calendar"
    >
      <AnghamiAdsPageTitle label="Masthead Campaign Calendar" />
      <Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Col span={12}>
          <AnghamiAdsFormSelector
            label="Country*"
            placeholder="Select country"
            value={countries}
            options={countriesOptions}
            onChange={(value) => {
              setCountries([value]);
            }}
          />
        </Col>
      </Row>
      <Calendar
        dateCellRender={dateCellRender}
        onChange={onCalendarDateChange}
        disabledDate={(day) =>
          Object.prototype.hasOwnProperty.call(
            dateToBookingMap,
            moment(day).format('YYYY-MM-DD')
          ) || Object.prototype.hasOwnProperty.call(blockedDates, moment(day).format('dddd'))
        }
        validRange={[moment(), moment().add(Infinity, 'days')]}
      />
    </div>
  );
}

export default MastheadCampaignCalendar;
