import axios from 'axios';
import environment from '../components/env/env';
import { AxiosSingleton } from '../utils/axiosinstance';
import {
  IBillingParams,
  ICardParams,
  ICheckoutResponse,
  IServerResponse
} from '../interfaces/billing.interface';

const axiosInst = AxiosSingleton.axiosInstance;

interface ICheckout {
  errors?: string[];
  id?: string;
  card?: ICheckoutResponse;
}

export const getCheckoutData = async (params: ICardParams) => {
  const publicKey = environment.checkout_public_key;
  const checkoutUrl = environment.checkout_url;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: publicKey
  };
  try {
    return await axios.post<ICheckout>(checkoutUrl, params, { headers });
  } catch (_err: any) {
    const { response } = _err;
    return response;
  }
};

export const createBilling = (params: IBillingParams) =>
  axiosInst.post<IServerResponse>('/billing/create', params);

export const updateBilling = (params: IBillingParams) =>
  axiosInst.post<IServerResponse>('/billing/update', params);

export const deleteBilling = () => axiosInst.post<IServerResponse>('/billing/delete', {});

export const cardLogo = {
  Visa: `${environment.cdn_url}web/assets/img/payment/visa_logo.png`,
  master: `${environment.cdn_url}web/assets/img/payment/mastercard_logo.png`
};
