export const ctaButtonTextOptions = [
  { id: 'adbtn_knowMore', text: 'Know More' },
  { id: 'adbtn_discoverNow', text: 'Discover Now' },
  { id: 'adbtn_playNow', text: 'Play Now' },
  { id: 'adbtn_discoverMore', text: 'Discover More' },
  { id: 'adbtn_getItNow', text: 'Get It Now' },
  { id: 'adbtn_subscribeNow', text: 'Subscribe Now' },
  { id: 'adbtn_getYourOffer', text: 'Get Your Offer' },
  { id: 'adbtn_startFreeTrial', text: 'Start Your Free Trial' },
  { id: 'adbtn_listenNow', text: 'Listen Now' },
  { id: 'adbtn_bookNow', text: 'Book Now' },
  { id: 'adbtn_watchMore', text: 'Watch More' },
  { id: 'adbtn_installNow', text: 'Install Now' },
  { id: 'adbtn_download', text: 'Download' },
  { id: 'adbtn_learnMore', text: 'Learn More' },
  { id: 'adbtn_playGame', text: 'Play Game' },
  { id: 'adbtn_shopNow', text: 'Shop Now' },
  { id: 'adbtn_signUp', text: 'Sign Up' },
  { id: 'adbtn_useApp', text: 'Use App' },
  { id: 'adbtn_joinNow', text: 'Join Now' },
  { id: 'adbtn_watch', text: 'Watch' },
  { id: 'adbtn_applyNow', text: 'Apply Now' },
  { id: 'adbtn_contactUs', text: 'Contact Us' },
  { id: 'adbtn_visitSite', text: 'Visit Site' },
  { id: 'adbtn_seeMore', text: 'See More' },
  { id: 'adbtn_submitNow', text: 'Submit Now' },
  { id: 'adbtn_getStarted', text: 'Get Started' }
];

export const songCampaignReasonOptions = [
  { id: 'branded_song', text: 'Branded song' },
  { id: 'paid_artist', text: 'Paid artist promo' },
  { id: 'free_artist', text: 'Free artist promo' },
  { id: 'label_commitment', text: ' Label commitment' },
  { id: 'other', text: 'Other' }
];
