import { Spin } from 'antd';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router';
import useAuth from '../../hooks/useAuth';
import { AxiosSingleton } from '../../utils/axiosinstance';
import { message } from '../../utils/custommessage';

export type TableColumnType = {
  title: string;
  width: string;
  dataIndex: string;
  key: string;
  fixed?: string;
  render: (value: any, data: any) => any;
};

const PreviewCreative = ({ imageUrl }: { imageUrl: string }) => {
  if (!imageUrl) {
    return <span>-</span>;
  }

  return (
    <div>
      <img src={imageUrl} alt="Preview" style={{ width: '100%' }} />
    </div>
  );
};

const renderImpressions = (impressions) => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    {impressions || (impressions === null || impressions === 0 ? '-' : <Spin />)}
  </div>
);

const MastheadControls = ({ campaign }: { campaign: any }) => {
  const axios = AxiosSingleton.axiosInstance;
  const navigate = useNavigate();
  const { user } = useAuth();
  const edit = (campaignId) => {
    navigate(`/masthead-campaigns/edit/${campaignId}`);
  };

  const activate = (campaignId) => {
    axios
      .post('/masthead/change-status', {
        ids: [campaignId],
        status: 1
      })
      .then(({ data }) => {
        if (data.message === 'success') {
          message.success('Campaign successfully activated!');
          setTimeout(() => {
            navigate(0);
          }, 300);
        }
      })
      .catch(({ response }) => {
        const { data } = response;
        if (data.error) {
          message.error(data.error);
        } else {
          message.error('Something went wrong');
        }
      });
  };

  const deactivate = (campaignId) => {
    axios
      .post('/masthead/change-status', {
        ids: [campaignId],
        status: 0
      })
      .then(({ data }) => {
        if (data.message === 'success') {
          message.success('Campaign successfully deactivated!');
          setTimeout(() => {
            navigate(0);
          }, 300);
        }
      })
      .catch(({ response }) => {
        const { data } = response;
        if (data.error) {
          message.error(data.error);
        } else {
          message.error('Something went wrong');
        }
      });
  };

  return (
    <div className="report-name-cell">
      <div className="report-name">{campaign.title}</div>
      <div className="report-name-actions">
        <span
          className="report-name-action"
          onClick={() => {
            edit(campaign.id);
          }}
        >
          Edit
        </span>
        {user?.publisher_id === 2 &&
          (Boolean(campaign.isactive) && Boolean(campaign.enabled) ? (
            <span
              className="report-name-action"
              onClick={() => {
                deactivate(campaign.id);
              }}
            >
              Deactivate
            </span>
          ) : (
            <span
              className="report-name-action"
              onClick={() => {
                activate(campaign.id);
              }}
            >
              Activate
            </span>
          ))}
      </div>
    </div>
  );
};

export const mastheadCampaignsTableColumn: TableColumnType[] = [
  {
    title: 'Name',
    dataIndex: 'title',
    key: '0',
    width: '9rem',
    render: (_, data) => <MastheadControls campaign={data} />
  },
  {
    title: 'Creative',
    dataIndex: 'image',
    key: '1',
    width: '9rem',
    render: (imageUrl) => <PreviewCreative imageUrl={imageUrl} />
  },
  {
    title: 'Creative (Arabic)',
    dataIndex: 'imageAr',
    key: '2',
    width: '9rem',
    render: (imageUrl) => <PreviewCreative imageUrl={imageUrl} />
  },
  {
    title: 'Creative (French)',
    dataIndex: 'imageFr',
    key: '3',
    width: '9rem',
    render: (imageUrl) => <PreviewCreative imageUrl={imageUrl} />
  },
  {
    title: 'Selected Date',
    dataIndex: 'startdate',
    key: '4',
    width: '6rem',
    render: (startDate: string) => <span>{moment(startDate).format('MMM DD, YYYY')}</span>
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: '5',
    width: '6rem',
    render: (_, campaign) => (
      <span>{Boolean(campaign.isactive) && Boolean(campaign.enabled) ? 'Active' : 'Inactive'}</span>
    )
  },
  {
    title: 'Countries',
    dataIndex: 'usercountries',
    key: '6',
    width: '6rem',
    render: (countries) => <span>{countries.join(', ')}</span>
  },
  {
    title: 'Unique Clicks',
    dataIndex: 'unique_clicks',
    key: '7',
    width: '6rem',
    render: renderImpressions
  },
  {
    title: 'Clicks',
    dataIndex: 'clicks',
    key: '8',
    width: '6rem',
    render: renderImpressions
  },
  {
    title: 'Unique Impressions',
    dataIndex: 'unique_impressions',
    key: '9',
    width: '6rem',
    render: renderImpressions
  },
  {
    title: 'Impressions',
    dataIndex: 'impressions',
    key: '10',
    width: '6rem',
    render: renderImpressions
  }
];
